import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./style.css";
import ReactToPrint from 'react-to-print';
import { getEmployeeList, getPayrollData, getPayrollAggregate } from "../../../actions/loginActions"
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    marginTop: "auto",
    padding: "0.2rem",
    width: "80%",
    marginLeft: "10%",
  },
  table: {
    minWidth: 650,
  },
}));

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

interface Props {
  getEmployeeList: Function,
  getPayrollData: Function,
  getPayrollAggregate: Function,
  employeesList: any,
  payroll_aggregate: any,
  payroll_data: any,
}


const Payslip: React.FC<Props> = ({ getEmployeeList, employeesList, getPayrollData, payroll_data, getPayrollAggregate, payroll_aggregate }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const payslipData = [
    { earning: "Basic", deduction: "Penalty" },
    { earning: "HRA", deduction: "Security Deduction" },
    { earning: "Special Allowance", deduction: "Last Month Security Deduction" },
    { earning: "Statutory Bonus", deduction: "Donation" },
    { earning: "Meal Allowance", deduction: "Assets Deduction" },
    { earning: "Leave Travel Allowance", deduction: "Other Deduction" },

    { earning: "Telephone Reimbursement", deduction: "Insurance" },
    { earning: "Books Newspaper Reimbursement", deduction: "Last Month Salary Processed As Advance" },
    { earning: "Car Vehicle Allowance", deduction: "Penalty" },
    { earning: "Fuel Reimbursement", deduction: "Salary Advance" },
    { earning: "Bonus", deduction: "PF" },
    { earning: "Incentive", deduction: "PF Arrears" },
    { earning: "Night All", deduction: "VPF" },
    { earning: "Overtime", deduction: "ESIC" },
    { earning: "Referral Bonus", deduction: "Prof Tax" },
    { earning: "Other Pay", deduction: "LWF" },
    { earning: "Joining Bonus", deduction: "Income Tax " },
  ];



  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button style={{ backgroundColor: "white", color: "black", margin: " 0 auto" }}>Print Receipt</Button>;
  }, []);


  useEffect(() => {
    getEmployeeList("")
    getPayrollData("")
    getPayrollAggregate("")

  }, [])



  return (
    <div className={classes.paper} style={{ display: "block", position: "absolute", top: "30%" }}>
      <h1 style={{
        marginTop: "50px", textAlign: "center", width: "100%",
        color: "rgb(14, 63, 108)",

      }}>Download Payslip from here !!!</h1>
      <div style={{ display: "flex", justifyContent: "center", fontSize: "1.5rem", fontWeight: "bold" }}>
        Select the month and year
        <LocalizationProvider dateAdapter={AdapterDayjs}   >
          <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]}>
            <DatePicker
              className="input"
              label={"month and year"}
              views={["month", "year"]}
              onChange={handleOpen}
            />
          </DemoContainer>
        </LocalizationProvider>

        {/* <input type="date" onChange={handleOpen} style={{ padding:"5px", fontSize:"16px", borderRadius:"5px", border:"1px solid #ccc", marginLeft:"8px"}} onFocus={(e) => { e.target.style.outline = 'none'; e.target.style.boxShadow = '0 0 3px 1px #007bff'; }} /> */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        style={{ overflowY: "scroll" }}

      >

        <div>
          <div style={{ display: "flex", margin: "0 40%" }}>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="Payslip"
              trigger={reactToPrintTrigger}
            />
            <Button style={{ backgroundColor: "white", color: "black" }} onClick={handleClose}>Close</Button>
          </div>
          <Paper style={{ width: "80%", margin: "20px auto" }} ref={componentRef}>
            <TableContainer

              component={Paper}
              style={{ width: "80%", margin: " auto" }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img src="https://bsmedia.business-standard.com/_media/bs/img/article/2023-09/27/full/20230927111547.jpg" alt="" width={150} height={110} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "30px", marginLeft: "30px" }}>
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}> REDCLIFFE LIFETECH PRIVATE LIMITED </span>
                  <span style={{ fontSize: "12px" }}>H-55, 3RD FLOOR, SECTOR 63, NOIDA, GAUTAM BUDDHA NAGAR, UTTAR PRADESH, INDIA 201301</span>
                  <p style={{ fontWeight: "bold" }}>Payslip for the month of March 2024</p>
                </div>


              </div>
              <Table
                size="small"
                aria-label="a dense table"
              >
                {
                  employeesList && employeesList.length > 0 && employeesList.map((data: any) => (
                    <TableBody style={{ border: "2px solid black" }}>
                      <TableRow>
                        <TableCell align="left">Code</TableCell>
                        <TableCell align="left">{data.employee_id}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left">Location</TableCell>
                        <TableCell align="left">{data.address}</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left">Date of Birth</TableCell>
                        <TableCell align="left">{data.date_of_birth}</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Department</TableCell>
                        <TableCell align="left">{data.department}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left">UAN No.</TableCell>
                        <TableCell align="left">{data.uan_number}</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Designation</TableCell>
                        <TableCell align="left">{data.designation}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left">ESIC No.</TableCell>
                        <TableCell align="left">{data.esi_number}</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                      <TableRow style={{ borderBottom: "1px solid black" }}>
                        <TableCell align="left">LOP</TableCell>
                        <TableCell align="left" ></TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left">PAN No.</TableCell>
                        <TableCell align="left">{data.pan_number}</TableCell>
                        <TableCell align="left"
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                }
              </Table>

              <Table
                size="small"
                aria-label="a dense table"

              >
                <TableBody style={{ border: "2px solid black" }}>
                  <TableRow style={{ borderBottom: "2px solid black" }}>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>Earning</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>Deduction</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow style={{ borderBottom: "2px solid black" }}>
                    <TableCell align="left" style={{ fontWeight: "bold", borderRight: "2px solid" }}>
                      Particular
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold", borderRight: "2px solid black" }}>
                      Fixed
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold", borderRight: "2px solid black" }}>
                      Earning
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold", borderRight: "2px solid black" }}>
                      Particular
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      Amount
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: "white" }}
                    ></TableCell>
                  </TableRow>
                  {
                    payroll_data && payroll_data.length > 0 && payroll_data?.map((data: any) => (
                      <TableRow  >
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}>{data.component_details.name}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }} >{data.amount}</TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    ))
                  }

                  <TableRow style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>GROSS SALARY: INR</TableCell>
                    <TableCell align="left">{payroll_aggregate.total_earning}</TableCell>
                    <TableCell align="left" style={{ borderRight: "2px solid black" }}></TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>GROSS DEDUCTION:INR</TableCell>
                    <TableCell align="left">{payroll_aggregate.total_deduction}</TableCell>
                    <TableCell align="left"></TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Net Salary: INR</TableCell>
                    <TableCell align="left"> {payroll_aggregate.net_amount}</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>


                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <p style={{ textAlign: "center", margin: "10px 0" }}>
              This is system generated payslip and does not require a singature
            </p>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  payroll_data: state.loginReducer.payroll_data,
  payroll_aggregate: state.loginReducer.payroll_aggregate,

});

export default connect(mapStateToProps, {
  getEmployeeList, getPayrollData, getPayrollAggregate
})(Payslip);
