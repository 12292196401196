import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "./bulk.css";
import { connect } from "react-redux";
import Loader from "../Loader2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Box from "@mui/material/Box";
import BackupIcon from "@mui/icons-material/Backup";
import {
  postDepartmentBulkUploader,
  postBulkUploaderEmployee,
  postBulkUploaderPayslip,
  updateEmployeeUploader,
  
} from "../../../actions/loginActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    item: {},
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    blinker: {
      animation: `$blinker 1.5s linear infinite`,
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0.5,
      },
    },
  })
);

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Props {
  postDepartmentBulkUploader: any;
  postBulkUploaderEmployee: any;
  postBulkUploaderPayslip: any;
  loading: any;
  updateEmployeeUploader:any;
}
const UpdateBulkUpload: React.FC<Props> = ({
  postDepartmentBulkUploader,
  postBulkUploaderEmployee,
  postBulkUploaderPayslip,
  loading,
  updateEmployeeUploader,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [file_name, setFileName] = React.useState<string>("");
  const [file, setFile] = React.useState<any>([]);
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [buttonStateDep, setButtonStateDep] = useState<boolean>(false);
  const [fileTypeError, setFileTypeError] = useState<any>("");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [fileTypeErrorEmployee, setFileTypeErrorEmployee] = useState<any>("");
  const [file_nameEmployee, setFileNameEmployee] = React.useState<string>("");
  const [fileEmployee, setFileEmployee] = React.useState<any>([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleFileChangeEmployee = (e: React.ChangeEvent<{ files: any }>) => {
    setFileEmployee(e.target.files[0]);
    if (e.target.files[0]?.type === "text/csv") {
      setFileNameEmployee(e.target.files[0].name);
      setFileTypeErrorEmployee("");
    } else {
      setFileTypeErrorEmployee("Please select csv file");
    }
  };

  const submitFileEmployee = async () => {
    setIsButtonDisabled(true);
    const formData = new FormData();
    formData.append("file", fileEmployee);
    await updateEmployeeUploader(formData);
    setButtonState(true);
    setTimeout(() => {
        setButtonState(false);
      }, 1000 * 60 * 10);
    setFileNameEmployee("");
    setFileEmployee("");
    history.push("/dashboard/payrollManager/update-BulkUpload");
  };


  const [fileTypeErrorSalary, setFileTypeErrorSalary] = useState<any>("");
  const [file_nameSalary, setFileNameSalary] = React.useState<string>("");
  const [fileSalary, setFileSalary] = React.useState<any>([]);

  const handleFileChangeSalary = (e: React.ChangeEvent<{ files: any }>) => {
    setFileSalary(e.target.files[0]);
    if (e.target.files[0]?.type === "text/csv") {
      setFileNameSalary(e.target.files[0].name); // Corrected line
      setFileTypeErrorSalary("");
    } else {
      setFileTypeErrorSalary("Please select csv file");
    }
  };

  const submitFileSalary = async () => {
    const formData = new FormData();
    formData.append("file", fileSalary);
    await postBulkUploaderEmployee(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
    setFileNameSalary("");
    setFileSalary("");
  };

  
 
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          display: "block",
          width: "auto",
          textAlign: "center",
          color: "rgb(14, 63, 108)",
          marginTop: "10px",
        }}
      >
       Update Employee Details
      </h2>
      <div style={{ marginTop: 50, width: "100%" }}>
        <Grid container spacing={4}>
          {" "}
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <AppBar position="static">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ justifyContent: "space-around", width: "100%" }}
          >
            <Tab
              style={{
                backgroundColor: "white",
                color: "black",
              }}
              label="Employee"
              {...a11yProps(0)}
            />
          </Tabs>
        </AppBar>
      </div>

      {value === 0 ? (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div
                  className="upload-cont"
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    left: "25%",
                    right: "0",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    className="uplaod-inner"
                    style={{
                      height: "100%",
                      width: "800px",
                      border: "2px dashed grey",
                      borderRadius: "10px",
                      textAlign: "center",
                      padding: "30px 10px",
                    }}
                  >
                    <div className="files-first" style={{}}>
                      <BackupIcon
                        style={{
                          margin: "0 5px",
                          width: "100px",
                          color: "blue",
                        }}
                      />
                      <h5>Upload File</h5>
                    </div>
                    <Grid item xs={12} sm={6} md={8}>
                      <input
                        className="button-17"
                        type="text/csv"
                        style={{
                          height: "50px",
                          padding: "6px 10px",
                          marginLeft: "255px",
                        }}
                        accept=".csv"
                        placeholder="File Name"
                        value={file_nameEmployee}
                        disabled
                      />
                      <p style={{ color: "red", marginLeft: "255px" }}>
                        {fileTypeErrorEmployee}
                      </p>
                    </Grid>

                    <div className="upload-btn">
                      <Button
                        className="button-17"
                        style={{ height: "56px" }}
                        component="label"
                        id="upload-btn"
                      >
                        Select CSV File
                        <input
                          type="file"
                          name="file"
                          id="file"
                          hidden
                          onChange={handleFileChangeEmployee}
                        />
                      </Button>
                    </div>

                    <div className="file-det">
                      <span style={{ marginRight: "10px" }}>
                        Supported file type:{" "}
                      </span>
                      <strong>Note : Only CSV File Can Be upload</strong>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button
                              className="button-17"
                              startIcon={<CloudUploadIcon />}
                              component="label"
                              style={{
                                height: "56px",
                                width: "100%",
                                marginLeft: "-50px",
                                marginTop: "10px",
                              }}
                              onClick={submitFileEmployee}
                              disabled={
                                buttonState ||
                                file_nameEmployee === "" ||
                                fileTypeErrorEmployee !== "" ||
                                loading
                              }
                            >
                              Upload
                            </Button>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <a
                              href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/9725/b301a9a9-9625-47ad-9c36-73e4f39c6bdd.csv"
                              target=""
                            >
                              <Button
                                className="button-17"
                                fullWidth
                                style={{
                                  height: "56px",
                                  width: "100",
                                  marginTop: "10px",
                                }}
                                startIcon={<CloudDownloadIcon />}
                                component="label"
                              >
                                Download Sample CSV File
                              </Button>
                            </a>
                          </Grid>
                        </>
                      )}
                    </div>
                  </div>
                  {loading && (
                    <div
                      className={classes.blinker}
                      style={{
                        color: "#007BFF",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "20px auto",
                        marginLeft: "20px",
                        padding: "10px",
                        border: "2px solid #007BFF",
                        borderRadius: "10px",
                        backgroundColor: "#f0f8ff",
                        boxShadow: "0 4px 8px rgba(0, 123, 255, 0.2)",
                        animation: "blinker 1.5s linear infinite",
                        maxWidth: "800px",
                        width: "100%",
                      }}
                    >
                      Please wait, we are uploading employee data. It will take
                      a little bit of time...
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        ""
      )}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.loginReducer.loading,
});
export default connect(mapStateToProps, {
  postDepartmentBulkUploader,
  postBulkUploaderEmployee,
  postBulkUploaderPayslip,
  updateEmployeeUploader,
})(UpdateBulkUpload);
