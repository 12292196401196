import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, TextField, MenuItem, Checkbox, Select, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLocationList } from "../../../actions/loginActions";
import { deleteEducation, getEducationDetails } from "../../actions/PayrollActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid green",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "600px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    setOpenDeleteEducation: Function;
    openDeleteEducation: boolean;
    loading:boolean;
    selectedId:any;
    deleteEducation:any;
    getEducationDetails:any;
};

const DeleteEducationModal: React.FC<ModalProps> = ({
    setOpenDeleteEducation,
    openDeleteEducation,
    loading,
    selectedId,
    deleteEducation,
    getEducationDetails,

}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const handleClose = () => {
        setOpenDeleteEducation(false);
    };
    const handleSubmit = async() =>{
        await deleteEducation(selectedId);
        setOpenDeleteEducation(false);
        await getEducationDetails();
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openDeleteEducation}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openDeleteEducation}>
                <div className={classes.paper}>
                    <>
                        <h4 style={{ display: "flex", justifyContent: 'center' }}>Delete Education</h4>
                        <Grid container spacing={2} direction="row" style={{ marginTop: "30px" }}>
                            <Grid item xs={12} className="centerItem">
                                <Typography style={{fontSize:"18px"}}>Are you sure you want to delete this Education Detail ?</Typography>
                            </Grid>
                            <Grid container spacing={3} xs={12} style={{marginTop:"30px"}} className="centerItem">
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleSubmit}
                                >
                                    yes
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleClose()}
                            >
                                No
                            </Button>
                                </Grid>
                           
                           
                            </Grid>
                        </Grid>
                       

                    </>

                </div>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.PayrollReducer.loading,

});

export default connect(mapStateToProps, {
deleteEducation,
getEducationDetails,
})(DeleteEducationModal);