import { useEffect, useState } from "react";

type WindowSize = [number, number];

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>([
    window.innerWidth || 0,
    window.innerHeight || 0,
  ]);

  useEffect(() => {
    function getWindowSize(): void {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", getWindowSize);

    return () => {
      window.removeEventListener("resize", getWindowSize);
    };
  }, []);

  return windowSize;
};
