import { ACTIONS } from "../interfaces/actionTypes/Admin";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";


export const changePassword = (body: any) => async (
    dispatch: Function
) => {
    try {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        await AXIOS.post(`${Prefix.api}/auth/password_reset/reset_password/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            });
        dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
        snackBarUpdate({
            payload: {
                message: "Password Updated",
                status: true,
                type: "success",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = JSON.stringify(err.response.data?.errors).replace(
                /"|{|}|[|]/g,
                ""
            );
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};


export const getDisplayHolidaysList = () => async ( dispatch: Function) => {
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/national-holidays-list/`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: data });  
    } catch (err:any) {
        let title=""
        if(err.response){

        }else{
            title="Can't Fetch Data"
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.DISPLAY_HOLIDAYS, payload: err });
        throw err;
    }
}

export const leaveApplication  = (body:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/leave_application`,
        body,
      );
      dispatch({ type: ACTIONS.LEAVE_APPLICATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Application sumbit  Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.status === 404) {
        title = "Something went wrong"
      } else if (err?.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  }

  export const Regularization  = (body:any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`/regularization/`,
        body,
      );
      dispatch({ type: ACTIONS.REGULARIZATION, payload: data });
      snackBarUpdate({
        payload: {
          message: "Application sumbit  Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.status === 404) {
        title = "Something went wrong"
      } else if (err?.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  }