import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  // uploadBulkPackage,
  // getImagingTestList,
  // getSubCenterInfo,
  // getCities,
  // getPincode,
} from "../../actions/PayrollActions";
import ExportTest from "./exportTestList";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExportFormat from "./exportTestList";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  uploadBulkPackage: any;
  getImagingTestList: any;
  imagingTestList: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  getCities: any;
  cities: any;
  getPincode: any;
  pincodeList: any;
  // coustomFileUpload: Function;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  uploadBulkPackage,
  getImagingTestList,
  imagingTestList,
  getSubCenterInfo,
  subCenterInfor,
  getCities,
  cities,
  getPincode,
  pincodeList,
}) => {
  const classes = useStyles();
  const [file, setFile] = React.useState<any>("");
  const [file_name, setFileName] = React.useState<string>("");
  const [pincodeTxt, setPincodeTxt] = useState<any>("");
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [centerId, setCenterId] = useState<any>("");
  const timer = useRef<any>(0);
  const [city, setCity] = useState<any>("");
  const handleFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  useEffect(() => {
    getImagingTestList();
    // getSubCenterInfo(`?imaging=true`);
  }, []);
  const submitFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("center_address", centerId);
    await uploadBulkPackage(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
  };

  return (
    <main className={classes.content}>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <p>STEP 1: Download Test File and Update All Details</p>
          <ExportFormat imagingTestList={imagingTestList} loading={loading} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          ></Grid>
          <br />
          <p>STEP 2:Select Center</p>
          <div className="file-uploader">
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={3}>
                <Autocomplete
                  id="pincode"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => String(item));
                    }
                    setPincodeTxt(sourceIds);
                    getSubCenterInfo(
                      `?imaging=true&multiple_pincode=${sourceIds}&multiple_city=${city}`
                    );
                  }}
                  options={ []}
                  disableClearable={true}
                  disableCloseOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  freeSolo
                  getOptionLabel={(option: any) => String(option)}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPincode(`?search=${newInputValue}`);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="input"
                      label="Pincodes"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "1rem" }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  id="city"
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  //     setCity(obj.id);
                  //     getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${obj.id}`);
                  //   }
                  // }}
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item.id);
                    }
                    setCity(sourceIds);
                    getSubCenterInfo(
                      `?imaging=true&multiple_pincode=${pincodeTxt}&multiple_city=${sourceIds}`
                    );
                  }}
                  options={cities}
                  disableClearable={true}
                  disableCloseOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  getOptionLabel={(option: any) => option.name}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCities(newInputValue);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="input"
                      label="City"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "1rem" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="center"
                  onChange={(event, newValue) => {
                    let ids: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      ids.push(obj.id);
                    });
                    setCenterId(ids);
                  }}
                  options={ []}
                  multiple
                  getOptionDisabled={(option: any) => {
                    return centerId.length > 0
                      ? centerId.includes(option.id)
                      : [];
                  }}
                  freeSolo
                  loading={loading}
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  getOptionLabel={(option: any) =>
                    `Center:${option?.center?.name},Address:${option?.address}`
                  }
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getSubCenterInfo(
                        `?imaging=true&name=${newInputValue}&multiple_pincode=${pincodeTxt}`
                      );
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="CENTRE NAME"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <br />
            <p>STEP 3:Browse Updated File</p>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  fullWidth
                  disabled={!centerId}
                >
                  Browse File
                  <input
                    type="file"
                    name="file"
                    id="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              <br />
              {file_name !== "" ? `File Name:${file_name}` : ""}
            </Grid>
            <br />
            <p>STEP 4:Upload </p>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                color="primary"
                onClick={submitFile}
                disabled={centerId === "" || file === ""}
              >
                Upload
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.PayrollReducer.loading,
  imagingTestList: state.PayrollReducer.imagingTestList,
  subCenterInfor: state.PayrollReducer.subCenterInfor,
  cities: state.PayrollReducer.cities,
  pincodeList: state.PayrollReducer.pincodeList,
});

export default connect(mapStateToProps, {
  // uploadBulkPackage,
  // getImagingTestList,
  // getSubCenterInfo,
  // getCities,
  // getPincode,
})(CustomUploader);
