import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { 
    Button,
   } from "@material-ui/core";
import { getCreateModule } from "../../../actions/loginActions";
import { deleteCreateModule } from "../../actions/EmployeeActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "400px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    setOpen: Function;
    idToDelete: any;
    deleteCreateModule: any;
    getCreateModule: any;
};

const DeleteModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    idToDelete,
    deleteCreateModule,
    getCreateModule,
}) => {
    const classes = useStyles();
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const handleClose = () => {
        setOpen(false)
    };
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div>
                            <div className="delete">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h2 style={{ fontSize: "16px", padding: "25px 0 0 15px" }}>Are you sure you want to delete this Module?</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button style={{
                                        backgroundColor: "white",
                                        color: "rgb(12 12 12 / 93%)",
                                        borderRadius: "8px",
                                        width: "120px",
                                        height: "40px",
                                        margin: "25px 15px 0 0px",
                                        border: "1px solid rgb(12 12 12 / 93%)",
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                    size="small"
                                    >
                                        <p style={{ margin: "0", fontSize: "15px" }}>No</p>
                                    </Button>
                                    <Button style={{
                                        backgroundColor: "#c70e0ef7",
                                        color: "white",
                                        borderRadius: "8px",
                                        width: "120px",
                                        height: "40px",
                                        margin: "25px 15px 0 0px",
                                        border: "1px solid",
                                        textTransform: "none",
                                    }}
                                    onClick={async () => {
                                        setOpen(false);
                                        await deleteCreateModule(idToDelete);
                                       // await getCreateModule();
                                    }}
                                    size="small"
                                    >
                                        <p style={{ margin: "0", fontSize: "15px" }}>Yes</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    deleteCreateModule,
    getCreateModule,
})(DeleteModal);