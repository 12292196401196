import React from "react";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import "./index.sass";
import LoginForm from "../../components/LoginForm";
import { login, } from "../../actions/loginActions";
import Logo from "../../celara.jpeg"

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory()
  const { loading, loadingOTP } = useSelector((state: any) => state.loginReducer);

  const handleForm = async (form: object) => {
    try {
      await dispatch(login(form))
      //  dispatch(getUserDetails())
      history.push('/dashboard')
    } catch (error) {
      return error;
    }

  };
  return (
    <div className="login-container" >
      <div className="login-inner-cont" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
        <div className="mainHeading" style={{ width: "500px" }} >
          <img src={Logo} alt=""
            style={{ width: "350p x" }} />
          {/* <p style={{ fontSize: "2rem", color: "white" }}>Healthy India Ki Trusted Lab </p> */}
        </div>
        <div className="login-container__form" style={{ width: "350px" }}>
          <LoginForm loading={loading} loadingOTP={loadingOTP} handleForm={handleForm} />
        </div>
      </div>
    </div>
  );
}