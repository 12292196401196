import { ACTIONS } from "../actions/customSnackBar";


type SnackBarState = {
  status: boolean;
  message: string;
  type: 'default' | 'error' | 'success' | 'warning' | 'info';
}

const initialState: SnackBarState = {
  status: false,
  message: '',
  type: 'default',
};

const customBarReducer = (state = initialState, action: any): SnackBarState => {

  switch (action.type) {
    case ACTIONS.STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default customBarReducer;
