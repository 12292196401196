import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import Box from '@mui/material/Box';
import BackupIcon from '@mui/icons-material/Backup';
import "./FileUploader.css"


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
            ...theme.mixins.toolbar,
        },
        item: {
        },
        root: {
            flexGrow: 0,
            marginTop: "20px",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            wordSpacing: 3,
            justifyContent: "space-between",
        },
    })
);

interface FileCardProps {
    file: File;
}


interface Props {

}
const FileUploader: React.FC = (Props: any) => {

    const classes = useStyles();
    const [file, setFile] = React.useState<any>([]);
    const [file_name, setFileName] = React.useState<string>("");
    const [buttonState, setButtonState] = useState<boolean>(false);
    const [disableFilterBtn, setDisableFilterBtn] = useState<boolean>(false);

    const [fileTypeError, setFileTypeError] = useState<any>("")
    const [files, setFiles] = useState<File[]>([]);

    const FileCard: React.FC<FileCardProps> = ({ file }) => {
        return (
            <div style={{
                margin: "1rem",
                padding: "10px",
                width: "100%"
            }}>
                <Paper>
                    <Box sx={{ flexGrow: 1 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <div className="file-card"
                                    style={{
                                        display: 'flex',
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "500px"

                                    }}>

                                    <div className="file-name"
                                        style={{
                                            textAlign: "center",
                                            width: "100%",

                                        }}>
                                        <h6 style={{ lineBreak: "auto", width: "200px" }}>{file.name}</h6>
                                    </div>
                                    <div className="type-size"
                                        style={{ paddingLeft: "3rem" }}
                                    >
                                        <p style={{ width: "max-content", }}>Size: {file.size} bytes</p>
                                        <p style={{ width: "max-content" }}>Type: {file.type}</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </div>


        );
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            setFiles(selectedFiles);
        }
    };

    return (

        <main style={{ marginTop: "3rem", alignContent: "center" }} >

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <div className="upload-cont" style={{ textAlign: "center", right: "0" }} >
                            <div className="uplaod-inner" style={{ height: "100%", maxWidth: "500px", minWidth: "350px", border: "2px dashed grey", borderRadius: "10px", textAlign: "center", padding: "30px 10px" }}>

                                <div className="files-first" style={{}}>

                                    <BackupIcon style={{ margin: "0 5px", width: "100px", color: "blue" }} />
                                    <h5 >Drag or Upload File</h5>
                                    <p>or</p>
                                </div>
                                <div className="upload-btn">
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                        id="upload-btn"

                                    >
                                        Upload file
                                        <VisuallyHiddenInput
                                            onChange={handleFileChange}
                                            type="file" multiple />
                                    </Button>
                                </div>

                                <div className="file-det">
                                    <span style={{ marginRight: "10px" }}>Supported file type:    </span>
                                    <strong>PNG,JPg,GIF,SVG</strong>
                                </div>
                            </div>

                            <div className="show-uploads">
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <div className="file-cards" style={{ width: "800px", marginTop: "2rem" }}>
                                            {files.map((file, index) => (
                                                <FileCard key={index} file={file} />
                                            ))}
                                        </div>

                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>




        </main >
    )

}

export default FileUploader;