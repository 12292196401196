import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, TextField, MenuItem, Checkbox, Select, FormControl, InputLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLocationList } from "../../../actions/loginActions";
import { AddEducation, getEducationDetails } from "../../actions/EmployeeActions";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid green",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "700px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    setOpenAddEducation: Function;
    openAddEducation: boolean;
    loading:boolean;
    AddEducation:any;
    employeesList:any;
    getEducationDetails:any
};

const AddEducationModal: React.FC<ModalProps> = ({
    setOpenAddEducation,
    openAddEducation,
    loading,
    AddEducation,
    employeesList,
    getEducationDetails,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");
    const [location, setLocation] = useState<any>();
    const [school, setTitle] = useState<any>("");
    const [employmentType, setEmploymentType] = useState<any>("none");
    const [isCurrentlyStudying, setIsCurrentlyStudying] = useState<any>(false);
    const [description, setDescription] = useState<any>("")
    const [degree,setDegree] = useState<any>("")
    const [field,setField] = useState<any>("")
    const [grade, setGrade] = useState<any>("");
    const [file, setFile] = React.useState<any>();
    const [errors, setErrors] = useState({
        school: false,
        degree: false,
        field: false,
        startDate: false,
        endDate: false,
        fileSize: false,
        endDateBeforeStart: false,
    });
    const handleClose = () => {
        setOpenAddEducation(false);
    };

const validateForm = () => {
    const newErrors = {
        school: !school.trim(),
        degree: !degree.trim(),
        field: !field.trim(),
        startDate: !start_date,
        endDate: !isCurrentlyStudying && (!end_date || end_date <= start_date),
        fileSize: file && file.size > 10 * 1024 * 1024,
        endDateBeforeStart: !isCurrentlyStudying && end_date < start_date,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).includes(true);
};
    const handleSubmit = async() =>{
        const isValid = validateForm();
        if (!isValid) {
            return;
        }

        const empId = employeesList?.results &&
        employeesList?.results?.length > 0 &&
        employeesList?.results[0]?.uuid
        const formattedStartDate = `${start_date}-01`;
        const formattedEndDate = (end_date !== "" && end_date !== null) ? `${end_date}-01` : "";
        const formData = new FormData();
        formData.append("employee", empId);
        formData.append("degree", degree);
        formData.append("field_of_study", field);
        formData.append("school_college_name", school);
        formData.append("is_currently_studying", isCurrentlyStudying);
        formData.append("start_date", formattedStartDate);
        formData.append("grade",grade)
        formData.append("end_date",formattedEndDate)
        formData.append("description", description);
        if(file){
            formData.append("document", file);
        }
        
       await AddEducation(formData);
       setOpenAddEducation(false);
       await getEducationDetails()
    }

    const blockCharInAutocompleteField = (e: React.KeyboardEvent<HTMLDivElement>) => {
        return ("~!@#$%^&*()_+{}|:<>?,./;'[]\=-0987654321`").includes(e.key) && e.preventDefault();
      }

    const fileSelectedHandler = async (e: React.ChangeEvent<{ files: any }>) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 10 * 1024 * 1024) {
            setErrors((prev) => ({ ...prev, fileSize: true }));
        } else {
            setFile(selectedFile);
            setErrors((prev) => ({ ...prev, fileSize: false }));
        }
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openAddEducation}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openAddEducation}>
                <div className={classes.paper}>
                    <>
                        <h4 style={{ display: "flex", justifyContent: 'center' }}>Add Education</h4>
                        <Grid container spacing={2} direction="row" style={{ marginTop: "20px" }}>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="school"
                                    id="school"
                                    value={school}
                                    onChange={(e:any)=>{
                                        setTitle((e.target.value))
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, school:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, school:true
                                            })
                                        }
                                        
                                    }}
                                    required
                                    className="input"
                                    label="School/College/University Name"
                                    variant="outlined"
                                    error={errors.school}
                                    helperText={errors.school && "School/College/University Name is required"}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="Degree"
                                    onChange={(e:any)=>{setDegree(e.target.value);
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, degree:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, degree:true
                                            })
                                        }}}
                                    id="degree"
                                    value={degree}
                                    required
                                    error={errors.degree}
                                    helperText={errors.degree && "Degree is required"}
                                    className="input"
                                    label="Degree"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="field"
                                    onChange={(e:any)=>{setField(e.target.value);
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, field:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, field:true
                                            })
                                        }}}
                                    id="field"
                                    value={field}
                                    required
                                    error={errors.field}
                                    helperText={errors.field && "Field of study is required"}
                                    className="input"
                                    label="Field of study"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={isCurrentlyStudying}
                                    onChange={(e) =>
                                    {
                                        setIsCurrentlyStudying(e.target.checked);
                                        setEnd_date("")
                                        if(errors?.endDateBeforeStart){
                                            setErrors({...errors, endDateBeforeStart: false })
                                        }
                                    }
                                       
                                    }
                                    name="currentlyStudying"
                                    style={{
                                    margin: "0",
                                
                                    marginLeft: "10px",
                                    color:"#413c9f"
                                    }}
                                   
                                />
                                }
                                style={{ paddingTop: "7px" }}
                                label="I am currently studying."
                            />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    className="input"
                                    name="start_date"
                                    type="month"
                                    label="Start Date"
                                    required
                                    value={
                                        start_date
                                    }
                                    variant="outlined"
                                    onChange={(e) => {setStart_date(e.target.value as string);
                                        if(e.target.value && e.target.value !== null && e.target.value !== ""){
                                            setErrors({
                                                ...errors, startDate:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, startDate:true
                                            })
                                        }
                                        if(!isCurrentlyStudying){
                                            if(e.target.value <= end_date){
                                                setErrors({
                                                    ...errors, endDateBeforeStart: false
                                                })
                                            }else{
                                                setErrors({
                                                    ...errors, endDateBeforeStart: true
                                                })
                                            }
                                        }
                                    }}
                                    error={errors.startDate}
                                    helperText={errors.startDate && "Start Date is required"}
                                    style={{ width: "100%" }}
                                    onKeyDown={blockCharInAutocompleteField}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="end_date"
                                    type="month"
                                    value={
                                        end_date
                                    }
                                    disabled={isCurrentlyStudying}
                                    className="input disableDate"
                                    required={!isCurrentlyStudying}
                                    error={(!isCurrentlyStudying && errors.endDateBeforeStart) || (!isCurrentlyStudying && errors.endDate)}
                                    helperText={
                                        !isCurrentlyStudying && errors.endDateBeforeStart
                                            ? "End date cannot be before the start date"
                                            : !isCurrentlyStudying && errors.endDate && "End Date is required"
                                    }
                                    label="End Date"
                                    variant="outlined"
                                    onKeyDown={blockCharInAutocompleteField}
                                    onChange={(e) => {
                                        setEnd_date(e.target.value as string);
                                        if (!isCurrentlyStudying && e.target.value && e.target.value >= start_date) {
                                            setErrors({ ...errors, endDate: false, endDateBeforeStart: false });
                                        } else if (!isCurrentlyStudying && e.target.value) {
                                            setErrors({ ...errors, endDateBeforeStart: true });
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="grade"
                                    id="grade"
                                    value={grade}
                                    onChange={(e:any)=>{
                                        setGrade((e.target.value)) 
                                    }}
                                    className="input"
                                    label="Grade"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                            <FormControl style={{ width: "100%" }} className="uploadInput" >
                                <InputLabel>Upload Document</InputLabel>
                                <input
                                    className="uploadField"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={fileSelectedHandler}
                                />
                            {errors?.fileSize && <p className="validationError">File size should not exceed 10 MB</p>}

                            </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="centerItem">
                                <TextField
                                    name="Description"
                                    id="discription"
                                    value={description}
                                    onChange={(e:any)=>{
                                        setDescription((e.target.value))
                                    }}
                                    multiline
                                    rows={2}
                                    className="input"
                                    label="Description"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid container spacing={3} xs={12} style={{marginTop:"20px"}} className="centerItem">
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>

                </div>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.EmployeeReducer.loading,
    employeesList: state.loginReducer.employeesList,
});

export default connect(mapStateToProps, {
    AddEducation,
    getEducationDetails
})(AddEducationModal);