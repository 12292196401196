import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import backgroundImage from "./certificate.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    downloadButton: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 3),
      backgroundColor: "#4caf50",
      color: "#fff",
      cursor: "pointer",
      borderRadius: "5px",
      border: "none",
      marginLeft:"40%"
    },
    certificateContainer: {
      position: "relative",
      textAlign: "center",
    },
    certificateText: {
      position: "absolute",
      top: "54%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#000",
      fontSize: "24px",
      fontWeight: "bold",
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  myModule: any;
}

const LmsCourses: React.FC<Props> = ({ open, setOpen, myModule }) => {
  const classes = useStyles();
  const certificateRef = useRef<HTMLDivElement>(null);

  const downloadPDF = async () => {
    if (!certificateRef.current) return;
    const canvas = await html2canvas(certificateRef.current);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("landscape", "pt", "a4");
    const imgWidth = 800;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("certificate.pdf");
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
      >

        <div>
        <div ref={certificateRef} className={classes.certificateContainer}>
          <img src={backgroundImage} width="800px" height="500px" alt="Certificate Background" />
          <p className={classes.certificateText}>{myModule?.name}</p>
        </div>
        <button onClick={downloadPDF} className={classes.downloadButton}>
            Download as PDF
        </button>
        </div>
       
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(LmsCourses);
