import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  TableCell,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      //   marginTop: "1rem",
      padding: "-1rem 2rem 2rem 2rem",
      width: "100%",
      borderRadius: "10px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    
    
    hoveItem: {
      cursor: "pointer",
    },
    
    container: {
      position: "sticky",
      marginTop: 30,
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    inputField: {
      backgroundColor: "rgb(230 242 255)",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#1C4E80",
      color: theme.palette.common.white,
      fontSize: "16px",
    },
    body: {
      fontSize: 14,
      padding: "5px",
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {}
const Questions: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
  const [openCreateModuleDrawer, setOpenCreateModuleDrawer] =
    React.useState(false);
  const [answerType, setAnswerType] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [numOfOptions, setNumOfOptions] = React.useState(0);
  const [limitError, setLimitError] = React.useState(false);
  const [selectQuestions, setSelectQuestions] = React.useState<any>([]);
  const [openAction, setOpenAction] = React.useState(false);



  const [options, setOptions] = React.useState([
    {
      optionName: "",
      optionActive: false,
    },
  ]);

 

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenFilterDrawer(open);
    };

  const toggleDrawer2 =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenCreateModuleDrawer(open);
    };

  const handleAddOption = (index: number) => {
    if (index >= 4) {
      alert("max limit reached");
    } else {
      setOptions([
        ...options,
        {
          optionName: "",
          optionActive: false,
        },
      ]);
    }
  };

  const handleDeleteOption = (index: number) => {
    if (options.length !== 1) {
      const list = [...options];
      list.splice(index, 1);
      setOptions(list);
    }
  };

  const handleUpdateQuestion= (questionId:string) => {

  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4
            style={{
              fontWeight: "bold",
              textAlign: "left",

              color: "#2d148a",
              fontSize: "2rem",
              fontFamily: "'Arial Black', sans-serif",
              marginTop: "20px",
              display: "inline-block",
            }}
          >
            LMS Questions
          </h4>
        </div>
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ModeEditOutlineIcon />}
              onClick={()=> setOpenAction(true)}
            >
              Action
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer(true)(event);
              }}
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer2(true)(event);
              }}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </div>
        </div>
      </div>

      {(["right"] as const).map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
          >
            <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div>
                <h3
                  style={{
                    marginTop: "10px",
                    color: "#1C4E80",
                    fontWeight: 900,
                    letterSpacing: "0.3px",
                    textAlign: "center",
                  }}
                >
                  Apply Filters
                </h3>
              </div>
              <hr style={{ borderBottom: "3px solid #007BFF" }} />

              <div>
              <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Question Id/Name
                </label>
              </div>
              <div>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    // onChange={(e: any) =>
                    //   handleInputChange("nationality", e.target.value)
                    // }

                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Question Status
                </label>
                <div>
                  <TextField
                    className={classes.inputField}
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    // onChange={(e: any) =>
                    //   handleInputChange("nationality", e.target.value)
                    // }

                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="published">Published</MenuItem>
                    <MenuItem value="unpublished">Unpublished</MenuItem>
                    <MenuItem value="unpublished">
                      Archived (Deleted one)
                    </MenuItem>
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Batch
                </label>
                <div>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "Batch-1",
                      value: "batch1",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Batch"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
               <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Days
                </label>
                <div>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "30 days",
                      value: "30-days",
                    },
                    {
                        text: "60 days",
                        value: "60-days",
                      },
                      {
                        text: "90 days",
                        value: "90-days",
                      },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Batch"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Course Name
                </label>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "Course-1",
                      value: "course",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Course"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Module Name
                </label>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "Module-1",
                      value: "module1",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Module"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button variant="contained">Apply</Button>
                <Button variant="contained">Reset</Button>
              </div>
            </Box>
          </Drawer>
        </>
      ))}
      {(["right"] as const).map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openCreateModuleDrawer}
            onClose={() => setOpenCreateModuleDrawer(false)}
          >
            <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div>
                <div>
                  <h3
                    style={{
                      marginTop: "10px",
                      color: "#1C4E80",
                      fontWeight: 900,
                      letterSpacing: "0.3px",
                      textAlign: "center",
                    }}
                  >
                    Create Question
                  </h3>
                </div>
              </div>
              <hr style={{ borderBottom: "3px solid #007BFF" }} />

              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Question
                </label>
                <div>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    // onChange={(e: any) =>
                    //   handleInputChange("nationality", e.target.value)
                    // }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Question Summary (Optional)
                </label>
                <div>
                  <TextareaAutosize
                    className={classes.inputField}
                    style={{ width: "100%", borderRadius: "4px" }}
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Type summary here..."
                  />
                </div>
              </div>
              <div style={{ display:"flex", gap:"30px"}}>
              <div style={{ display:"flex", justifyContent:"center", alignItems:"center", gap:"5px"}}>
                <div style={{ fontWeight: "bold" }}>   
                 Question manadatory
                </div>
                <div style={{ marginTop:"5px"}}>
                  <input type="checkbox" name="" id="" />
                </div>
              </div>
              <div style={{ display:"flex", justifyContent:"center", alignItems:"center", gap:"5px"}}>
                <div style={{ fontWeight: "bold"}}>
                
                 Question Shuffle
                
                </div>
                <div style={{ marginTop:"5px"}}>
                  <input type="checkbox" name="" id="" />
                </div>
              </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Batch
                </label>
                <div>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "Batch-1",
                      value: "batch1",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Batch"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
               <div>
                <label
                  htmlFor="reporting-manager"
                  style={{ fontWeight: "bold" }}
                >
                  Days
                </label>
                <div>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "30 days",
                      value: "30-days",
                    },
                    {
                        text: "60 days",
                        value: "60-days",
                      },
                      {
                        text: "90 days",
                        value: "90-days",
                      },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Batch"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Select Course
                </label>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="employee"
                  options={[
                    {
                      text: "Course-1",
                      value: "course",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Course"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="module-name" style={{ fontWeight: "bold" }}>
                  Select Module
                </label>
                <Autocomplete
                  //   onChange={(event, obj) => {
                  //     if (obj) {
                  //       setEmployeeUuid(obj.uuid);
                  //     }
                  //   }}
                  id="module"
                  options={[
                    {
                      text: "Module-1",
                      value: "module-1",
                    },
                  ]}
                  //   onInputChange={(event, newInputValue) => {
                  //     clearTimeout(timer.current);
                  //     timer.current = setTimeout(() => {
                  //       newInputValue &&
                  //         getEmployeeList(
                  //           `get_all=true&employee_id=${newInputValue}`
                  //         );
                  //     }, 1000);
                  //   }}
                  getOptionLabel={(option: any) => {
                    return option?.text;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.text}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className={classes.inputField}
                      {...params}
                      variant="outlined"
                      placeholder="Select Course"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Weightage
                </label>
                <div>
                  <TextField
                    className={classes.inputField}
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    // onChange={(e: any) =>
                    //   handleInputChange("nationality", e.target.value)
                    // }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="percentage">Percentage</MenuItem>
                    <MenuItem value="marks">Marks</MenuItem>
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Passing Weightage (Percentage/Marks)
                </label>
                <div>
                  <TextField
                    className={classes.inputField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    // onChange={(e: any) =>
                    //   handleInputChange("nationality", e.target.value)
                    // }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Answer type
                </label>
                <div>
                  <TextField
                    className={classes.inputField}
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    //value={personalData.nationality}
                    //defaultValue={"indian"}
                    onChange={(e: any) => setAnswerType(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="optional">Optional</MenuItem>
                    <MenuItem value="written">Written</MenuItem>
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>

              {answerType === "written" && (
                <>
                  <div>
                    <TextareaAutosize
                      className={classes.inputField}
                      style={{ width: "100%", borderRadius: "4px" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Type summary here..."
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                </>
              )}

              {answerType === "optional" && (
                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Options
                  </label>
                  {options.map((option, index) => (
                    <div>
                      <TextField
                        className={classes.inputField}
                        size="small"
                        variant="outlined"
                        label={`Option-${index + 1}`}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                          }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {options.length < 5 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div>
                              <ControlPointIcon
                                onClick={() => handleAddOption(index)}
                              />
                            </div>
                            <div>Add Option</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div>
                            <DeleteRoundedIcon
                              onClick={() => handleDeleteOption(index)}
                            />
                          </div>
                          <div>Delete Option</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <input type="checkbox" name="" id="" />
                          <div>Right Option</div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button variant="contained">Save as Draft</Button>
                <Button variant="contained">Save & Published</Button>
              </div>
            </Box>
          </Drawer>
        </>
      ))}

      <hr style={{ borderBottom: "3px solid #007BFF" }} />
      <TableContainer
        className={classes.container}
        style={{ maxHeight: "540px" }}
        component={Paper}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">
                <input
                  type="checkbox"
                  className="input"
                  name="selectall"
                  id="selectall"
                  style={{ transform: "scale(1.5)" }}
                //   onChange={(e) =>
                //     e.target.checked
                //       ? setSelectQuestions(
                //           mockQuestionData.map(
                //               (data:any, index:number)=>{
                //                 return data.QuestionId;
                //               }
                //             )
                //         )
                //       : setSelectQuestions([])
                //   }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Question Id</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Question</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Question Status</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Course Name</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Module Name</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Answer Only</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>True Option (Answer)</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Total Option</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Question Shuffle</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Is Mandatory</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Click to action</b>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
           
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openAction}
        onClose={() => setOpenAction(false)}
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
      >
        <DialogTitle style={{ fontWeight:"600"}}>ACTIONS</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              className={classes.inputField}
              select
              fullWidth
              size="small"
              variant="outlined"
              // onChange={(e: any) =>
              //   handleInputChange("nationality", e.target.value)
              // }
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="unpublished">Unpublished</MenuItem>
              <MenuItem value="unpublished">Delete</MenuItem>
            </TextField>
          </div>
          <div style={{ marginTop:"10px", display:"flex", justifyContent:"center"}}>
            <Button variant="contained" size="small" >Submit</Button>
          </div>
        </DialogContent>
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, {})(Questions);
