import React, { useState, useRef, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  MenuItem,
  Select,
  Dialog,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
  // getBankName,
  // getEmailTemplates,
  // getMessageTemplates,
  // getWhatsappTemplates,
  // createPartner,
  // getPartner,
  // updatePartner,
  // getpartnerCode
} from "../../../actions/loginActions";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import { ACTIONS } from "../../../CollectionCenter-Admin/interfaces/actionTypes"
 import "./index.sass";
import moment from "moment";
import PartnerTable from "./EmployeeTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  userDetails: any;
  bankName: any;
  getBankName: any;
  getEmailTemplates: any;
  emailTemplate: any;
  getMessageTemplates: any;
  getWhatsappTemplates: any;
  messageTemplate: any;
  whatsappsTemplate: any;
  createPartner: any;
  partnerList: any;
  getPartner: any;
  partnerCodename: any;
  getpartnerCode: any;
  updatePartner: any;
}
const CreatePartnerPage: React.FC<Props> = ({
  userDetails,
  bankName,
  getBankName,
  getEmailTemplates,
  emailTemplate,
  getMessageTemplates,
  getWhatsappTemplates,
  messageTemplate,
  whatsappsTemplate,
  createPartner,
  partnerList,
  getPartner,
  partnerCodename,
  getpartnerCode,
  updatePartner,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  let dispatch = useDispatch();
  const [centerShow, setCentreShow] = useState<boolean>(false);
  const [partnerId,setpartnerId] = useState<any>("");
  const [partnerName, setPartnerName] = useState<any>("");
  const [partnerCode, setPartnerCode] = useState<any>("");
  const [partnerNameLegal, setPartnerNameLegal] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState("");
  const [contractDate, setContractDate] = useState("");
  const [orgType, setOrgType] = useState<any>("none");
  const [userType, setUserType] = useState<any>("none");
  const [centerKey, setCenterKey] = useState<any>("");
  const [centerControlingShow, setCentreControllingShow] =
    useState<boolean>(false);
  const [email_shared, setEmail_Shared] = useState<any>("none");
  const [whatsapp_shared, setWhatsapp_Shared] = useState<any>("none");
  const [sms_shared, setSms_Shared] = useState<any>("none");
  const [phoneNumber, setPhoneNumber] = useState<String>("");
  const [mobileValidation, setMobileValidation] = useState({
    phoneNumber: true,
    partnerHeadalContactNumber: true,
    centerHeadalterNateNumber: true,

  });
  const [email, setEmail] = React.useState("");
  const [designation, setDesignation] = useState<any>("");
  const [ownerName, setOwnerName] = useState<any>("");
  const [partnerHeadalContactNumber, setPartnerHeadContactNumber] =
    useState<any>();
  const [emailTemplates, setEmailTemplates] = useState<any>([]);
  const [messagesTemplates, setMessagesTemplates] = useState<any>([]);
  const [whatsappTemplates, setwhatsappTemplates] = useState<any>([]);

  const [messagesTemplatesDef, setMessagesTemplatesDef] = useState<any>([]);
  const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
    useState<any>();
  const [gstinNumber, setGstinNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [BankDetailShow, setBankDetailShow] = useState<boolean>(false);
  const [AdditionalDetailsShow, setAdditionalDetailsShow] =
    useState<boolean>(false);
  const [bankAccountHolder, setbankAccountHolder] = React.useState("");
  const [bank, setBank] = React.useState<any>({name:""});
  const [bankInput, setBankInput] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [salesManagerText, setSalesManagerText] = useState<any>("");
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [centerHeadShow, setCentreHeadShow] = useState<boolean>(false);
  const [editButton, setEditButton] = useState<any>(false);
  const [messagesSentThrough, setMessagesSentThrough] = useState<any>("none");
  const [messagesTemplatesDef1, setmessagesTemplatesDef1] = useState<any>([]);
  const [key, setKey] = useState<any>(0);
  const [partnerAddress, setPartnerAddress] = React.useState<any>("");
  



  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(phoneNumber && phoneNumber[0]) < 6 ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          phoneNumber: false,
        }));
      }
    }
     if (type === "mobilenumber") {
      if (
        parseInt(partnerHeadalContactNumber && partnerHeadalContactNumber[0]) < 6 ||
        (partnerHeadalContactNumber && partnerHeadalContactNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          partnerHeadalContactNumber: false,
        }));
      }
    }
     if (type === "mobilenumbers") {
      if (
        parseInt(centerHeadalterNateNumber && centerHeadalterNateNumber[0]) < 6 ||
        (centerHeadalterNateNumber && centerHeadalterNateNumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          centerHeadalterNateNumber: false,
        }));
      }
    }
  };

  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });



  const salesManagerTextData = [
    {
      value: "Manoj Guleria",
      text: "Manoj Guleria",
    },
    {
      value: "Mansi",
      text: "Mansi",
    },
    {
      value: "Fuzail Khan",
      text: "Fuzail Khan",
    },
    {
      value: "Irshad Ansari",
      text: "Irshad Ansari",
    },
    {
      value: "Suman Tewary",
      text: "Suman Tewary",
    },
    {
      value: "Ashish Pokhriyal",
      text: "Ashish Pokhriyal",
    },
    {
      value: "Manish Bharadwaj",
      text: "Manish Bharadwaj",
    },
    {
      value: "Jayraj Chauhan",
      text: "Jayraj Chauhan",
    },
    {
      value: "Abhay Singavi",
      text: "Abhay Singavi",
    },
    {
      value: "Sachin Sharma",
      text: "Sachin Sharma",
    },
    {
      value: "Ashish Pokhriyal",
      text: "Ashish Pokhriyal",
    },
    {
      value: "Snehith Goud",
      text: "Snehith Goud",
    },
    {
      value: "P.K.Sharma",
      text: "P.K.Sharma",
    },
    {
      value: "Tushar Oza",
      text: "Tushar Oza",
    },
    {
      value: "Satya Prakash",
      text: "Satya Prakash",
    },
    {
      value: "Anjali Gupta",
      text: "Anjali Gupta",
    },
    {
      value: "Kuldeep Singh",
      text: "Kuldeep Singh",
    },
    {
      value: "Anisha Rautela",
      text: "Anisha Rautela",
    },
    {
      value: "DSR",
      text: "DSR",
    },
    {
      value: "Himanshu Mohan",
      text: "Himanshu Mohan",
    },
  ];

  const salutationData = [
    {
      value: "Mr",
      text: "Mr",
    },
    {
      value: "Mrs",
      text: "Mrs",
    },
    {
      value: "Miss",
      text: "Miss",
    },
    {
      value: "Smt",
      text: "Smt",
    },
    {
      value: "Dr.",
      text: "Dr.",
    },
  ];

  const handleSubmitCentrePreview = (e: any) => {
    e.preventDefault();
    setOpenPreviewModal(true);

    setCentreControllingShow(false);
    setBankDetailShow(false);
    // setSpocDetailsShow(false);
    setAdditionalDetailsShow(false);
  };
  const handleClosePreview = () => {
    setOpenPreviewModal(false);
    // setOpenPreviewModal1(false);
  };

  // useEffect(() => {
  //   getPartner("?show_all=true&booking_partner=true");
  // }, []);

  // useEffect(() => {
  //   getpartnerCode();
  // }, []);

  useEffect(() => {
    if (partnerCodename?.code) {
      setPartnerCode(partnerCodename?.code);
    }
  }, [partnerCodename]);

  const handleSubmitPartner = async (e: any) => {

    const array1 = [messagesTemplates];
    const array2 = [whatsappTemplates];

    const array3 = [...array1, ...array2];
    const flatArray = array3.flat();

    //       const nestedArray = [[messagesTemplates], [whatsappTemplates]];
    // const flatArray = nestedArray.flat();

    e.preventDefault();
    const data: any = {
      partner_name: partnerName,
      partner_code: partnerCode,
      legal_partner_name: partnerNameLegal,
      partner_mobile: phoneNumber,
      partner_email: email,
      sales_manager_name: salesManagerText,
      contract_start_date: contractDate,
      contract_end_date: expiryDate,
      usertype: userType,
      org_type: orgType,
      owner_salutation: designation,
      owner_name: ownerName,
      owner_mobile: partnerHeadalContactNumber,
      owner_alternate_mobile: centerHeadalterNateNumber,
      partner_key: centerKey ? centerKey : "null",
      account_holder_name: bankAccountHolder,
      bank: bank!==""?bank?.id:null,
      gstin: gstinNumber,
      pan: panNumber,
      templates: flatArray,
      // templates1:whatsappTemplates,
      bank_ifsc: ifscCode,
      bank_account_number: accountNumber,
      email_templates: emailTemplates.map((val: any) => val?.id),
      report_email_sent_to: email_shared,
      report_sms_sent_to: sms_shared,
      report_whatsapp_sent_to: whatsapp_shared,
      partner_address:partnerAddress

      // templates: messagesTemplates.map((val: any) => val?.id),
    };
    if (gstinNumber === "") {
      delete data.gstin
    }
    if (panNumber === "") {
      delete data.pan
    }

    if (email_shared==="none") {
      delete data.report_email_sent_to
    }
    if (whatsapp_shared==="none"){
      delete data.report_whatsapp_sent_to
    }
    if (sms_shared==="none"){
      delete data.report_sms_sent_to
    }
    // await createPartner(data);
    if (editButton) {
      await updatePartner(data, partnerId);
    } else {
      await createPartner(data)
    }
    history.push("/dashboard/cca/manage_partner");
  };


  const handleEdit = (data: any) => {
    //CentreShow
    setCentreShow(true);
    setPartnerName(data?.partner_name);
    setpartnerId(data?.id)
    setPartnerCode(data?.partner_code);
    setPartnerNameLegal(data?.legal_partner_name);
    setPhoneNumber(data?.partner_mobile);
    setEmail(data?.partner_email);
    setSalesManagerText(data?.sales_manager_name);
    setPartnerAddress(data?.partner_address);
    setContractDate(
      data?.contract_start_date
        ? data?.contract_start_date
        : moment().format("YYYY-MM-DD")
    );
    setExpiryDate(
      data?.contract_end_date
        ? data?.contract_end_date
        : moment().add(5, "years").format("YYYY-MM-DD")
    );
    setOrgType(data?.org_type || "");
    setUserType(data?.usertype || "");
    //CentreHeadShow
    setCentreHeadShow(true);
    setDesignation(data?.owner_salutation || "");
    setOwnerName(data?.owner_name || "");
    setPartnerHeadContactNumber(data?.owner_mobile || "");
    setCentreHeadAlternateNumber(data?.owner_alternate_mobile || "");
    setCenterKey(data?.partner_key)
    let arrSMS=data?.message_template?.length > 0
    ? data?.message_template.filter((val: any) => val?.send_on === "sms")
    : []
    setMessagesTemplatesDef(arrSMS);
    setMessagesTemplates(arrSMS.length>0&&arrSMS?.map((val:any)=>val.id));

    let arrwhatsapp =data?.message_template?.length > 0
    ? data?.message_template.filter((val: any) => val?.send_on === "whatsapp")
    : [] 
    setmessagesTemplatesDef1(arrwhatsapp);
  setwhatsappTemplates(arrSMS.length>0&&arrSMS?.map((val:any)=>val.id));
    setBankDetailShow(true)
    setbankAccountHolder(data.account_holder_name);
    setBankInput(data?.bank_name);
    setBank({name:data?.bank_name,id:data?.bank});
    setIfscCode(data?.bank_ifsc);
    setAccountNumber(data?.bank_account_number);
    setGstinNumber(data?.gstin)
    setPanNumber(data?.pan === null || data?.pan === null ? "" : data?.pan);
    setAdditionalDetailsShow(true)
    setEmail_Shared(data?.report_email_sent_to);
    setEmailTemplates(data?.email_templates || []);
    setMessagesSentThrough(data?.msgs_send_on || "");
    setSms_Shared(data?.report_sms_sent_to);
    setCentreControllingShow(true);
    setWhatsapp_Shared(data?.report_whatsapp_sent_to);
    setKey(1+key);
    setEditButton(true);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={5} className={classes.paper}>
        <form onSubmit={handleSubmitPartner}>
          <h4
            className={classes.hoveItem}
            style={{ fontWeight: "bold", background: "#398993", color: "#fff" }}
            onClick={() => setCentreShow(centerShow ? false : true)}
          >
            {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} EMPLOYEE
            DETAILS
          </h4>
          {centerShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="partner_code"
                  type="text"
                  label="Employee Code"
                  value={partnerCode}
                  disabled
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              </Grid>
             
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="partner_name"
                  type="text"
                  label="Employee Name"
                  value={partnerName}
                  required
                  variant="outlined"
                  onChange={(e) => setPartnerName(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                  inputProps={{ maxLength: 100}}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="legal_partner_name"
                  type="text"
                  label="Legal Employee Name"
                  value={partnerNameLegal}
                  required
                  variant="outlined"
                  onChange={(e) => setPartnerNameLegal(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                  inputProps={{ maxLength: 150}}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  type="number"
                  value={phoneNumber}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "")))
                      .toString()
                      .slice(0, 10);
                  }}
                  required
                  label="Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setPhoneNumber(e.target.value as String)}
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      phoneNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.phoneNumber && "Incorrect Phone Number"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="email"
                  type="email"
                  value={email}
                  className="input"
                  label="Email Id"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                  inputProps={{ maxLength: 50 }}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="sales-manager-text"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setSalesManagerText(obj.value);
                    }
                  }}
                  defaultValue={
                    salesManagerTextData &&
                    salesManagerTextData.find((code: any) => {
                      return code.value === salesManagerText;
                    })
                  }
                  options={salesManagerTextData}
                  blurOnSelect
                  // disabled={
                  //   userDetails?.profile?.executive_type === "SuperAdmin" &&
                  //     editButton === true
                  //     ? false
                  //     : userDetails?.profile?.executive_type === "DSA" &&
                  //       editButton === true
                  //       ? false
                  //       : editButton === true
                  //         ? true
                  //         : false
                  // }
                  getOptionLabel={(option: any) => `${option?.text}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sales Manager SPOC"
                      variant="outlined"
                      style={{ width: "100%" }}
                      className="input"
                      required
                    />
                    
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="contract_date"
                  type="date"
                  value={contractDate}
                  className="input"
                  label="Contract Start Date"
                  variant="outlined"
                  required
                  inputProps={{
                    min: editButton
                      ? moment(contractDate).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => setContractDate(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "") as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="expiry_date"
                  type="date"
                  disabled={contractDate === ""}
                  value={expiryDate}
                  className="input"
                  label="Contract End Date"
                  inputProps={{
                    min: moment(contractDate).add(1, "days").format("YYYY-MM-DD"),
                  }}
                  helperText={
                    moment(expiryDate).isAfter(contractDate)
                      ? ""
                      : "Expiry Date Should Greater Than Contract Date"
                  }
                  variant="outlined"
                  onChange={(e) => setExpiryDate(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "") as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  // key={orgType}
                  aria-required
                  className="input"
                  variant="outlined"
                  style={{ width: "100%", height: "40px" }}
                  label="Lab Payment Type*"
                  value={orgType}
                  onChange={(e) => setOrgType(e.target.value)}
                  required
                >
                  <MenuItem disabled value={"none"}>
                    {" "}
                    Org Type*{" "}
                  </MenuItem>
                  <MenuItem value={"imaging"}>Imaging </MenuItem>
                  <MenuItem value={"bml"}>Bml</MenuItem>
                  <MenuItem value={"homedx"}>HomeDx</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  // key={orgType}
                  aria-required
                  className="input"
                  variant="outlined"
                  style={{ width: "100%", height: "40px" }}
                  label="Lab Payment Type*"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  required
                >
                  <MenuItem disabled value={"none"}>
                    {" "}
                    User Type*{" "}
                  </MenuItem>
                  <MenuItem value={"b2b"}>B2B </MenuItem>
                  <MenuItem value={"b2c"}>B2C</MenuItem>
                  <MenuItem value={"both"}>Both</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="centreaddress"
                    type="text"
                    label="Employee Address"
                    value={partnerAddress}
                    required
                    variant="outlined"
                    onChange={(e) => setPartnerAddress(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                    style={{ width: "100%" }}
                  />
                </Grid>
            </Grid>
          ) : (
            ""
          )}
          <h4
            className={classes.hoveItem}
            onClick={() => setCentreHeadShow(centerHeadShow ? false : true)}
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              background: "#398993",
              color: "#fff",
            }}
          >
            {centerHeadShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} EMPLOYEE
            HEAD DETAILS
          </h4>
          {centerHeadShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="salutation"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setDesignation(obj.value);
                    }
                  }}
                  defaultValue={
                    salutationData &&
                    salutationData.find((code: any) => {
                      return code.value === designation;
                    })
                  }
                  options={salutationData}
                  blurOnSelect
                  getOptionLabel={(option: any) => option?.text}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Salutation"
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="Ownername"
                  type="text"
                  value={ownerName}
                  className="input"
                  label="Employee Name"
                  variant="outlined"
                  onChange={(e) => setOwnerName(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                  style={{ width: "100%" }}
                  inputProps={{ maxLength: 128}}
                  required
                />
              </Grid>
                <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="contact"
                  type="number"
                  value={partnerHeadalContactNumber}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "")))
                      .toString()
                      .slice(0, 10);
                  }}
                  label="Employee Head Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setPartnerHeadContactNumber(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "") as String)}
                  onBlur={() => verifyMobile("mobilenumber")}
                  required
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      partnerHeadalContactNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.partnerHeadalContactNumber && "Incorrect Phone Number"
                  }
                />
              </Grid>
                 <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="contact"
                  type="number"
                  value={centerHeadalterNateNumber}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "")))
                      .toString()
                      .slice(0, 10);
                  }}
                  
                  label="Employee Head Alternate Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", padding: "1px" }}
                  onChange={(e) => setCentreHeadAlternateNumber(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "") as String)}
                  onBlur={() => verifyMobile("mobilenumbers")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      centerHeadalterNateNumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.centerHeadalterNateNumber && "Incorrect Phone Number"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="centerkey"
                  type="text"
                  value={centerKey}
                  className="input"
                  label="Employee Key"
                  variant="outlined"
                  onChange={(e) => setCenterKey(e.target.value.trimStart().replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "") as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <h4
            className={classes.hoveItem}
            onClick={() => setBankDetailShow(BankDetailShow ? false : true)}
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              background: "#398993",
              color: "#fff",
            }}
          >
            {BankDetailShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            EMPLOYEE BANK DETAILS{" "}
          </h4>
          {BankDetailShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="acountholder"
                  type="text"
                  value={bankAccountHolder}
                  className="input"
                  label="Account Holder Name"
                  variant="outlined"
                  onChange={(e) =>
                    setbankAccountHolder(
                      e.target.value.replace(/[^a-z\s/A-Z]/, "").replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "").trimStart()
                    )
                  }
                  inputProps={{ maxLength: 128}}

                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  
                  id="bank"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setBank(obj);
                    }
                  }}
                 value={bank}
                  blurOnSelect
                  options={bankName?.results || []}
                  disableCloseOnSelect
                  getOptionLabel={(option: any) => option?.name}
                  onInputChange={(event, newInputValue) => {
                    getBankName(`?name=${newInputValue}`);
                    setBankInput(newInputValue);
                  }}
                 
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="input"
                      label="Bank Name"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="accountNumber"
                  type="text"
                  value={accountNumber}
                  className="input"
                  label="Bank Account Number"
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  onChange={(e) =>
                    setAccountNumber(e.target.value?.replace(/[^0-9\s]/, "").replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "").trimStart())
                  }
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="IFSC"
                  type="text"
                  value={ifscCode}
                  className="input"
                  label="IFSC Code"
                  variant="outlined"
                  onChange={(e) => setIfscCode(e.target.value.trimStart().replace(/[^A-Za-z0-9\s]/, '').replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, ""))}
                  style={{ width: "100%" }}
                  inputProps={{ maxLength: 100}}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="GSTINNumber"
                  type="text"
                  value={gstinNumber}
                  className="input"
                  label="GSTIN"
                  variant="outlined"
                  inputProps={{ maxLength: 20 }}               
                  onChange={(e) => setGstinNumber(e.target.value.replace(/[^A-Za-z0-9\s]/, '').replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "").trimStart())}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="panNumber"
                  type="text"
                  value={panNumber}
                  className="input"
                  label="PAN Number"
                  variant="outlined"
                  inputProps={{ maxLength: 20 }}
                  // inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={(e) =>
                    setPanNumber(String(e.target.value).toUpperCase().replace(/[^A-Za-z0-9\s]/, '').replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]/g, "").trimStart())
                  }
                  style={{ width: "100%" }}
                  helperText="Ex:- ABCDE9999F"
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <h4
            className={classes.hoveItem}
            onClick={() =>
              setAdditionalDetailsShow(AdditionalDetailsShow ? false : true)
            }
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              background: "#398993",
              color: "#fff",
            }}
          >
            {AdditionalDetailsShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            EMPLOYEE COMMUNICATION CONFIGURATION{" "}
          </h4>
          {AdditionalDetailsShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    type="text"
                    value={email_shared}
                    variant="outlined"
                    style={{ height: "40px" }}
                    required
                    onChange={(e) => setEmail_Shared(e.target.value)}
                    fullWidth
                  >
                    <MenuItem disabled value="none">
                      Email to be shared with
                    </MenuItem>
                    <MenuItem value="client">Client</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="not_send">Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        setEmailTemplates(packs);
                      }
                    }}
                    value={emailTemplates}
                    multiple
                    limitTags={1}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmailTemplates(`?search=${newInputValue}`);
                      }, 1000);
                    }}
                    // disabled={reportEmailSentTo === ""}
                    // aria-required={reportEmailSentTo !== "not_send"}
                    options={emailTemplate || []}
                    getOptionLabel={(option: any) =>
                      `${option?.name ? option?.name : option?.template_name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label=" Select email templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    type="text"
                    value={sms_shared}
                    variant="outlined"
                    style={{ height: "40px" }}
                    required
                    onChange={(e) => setSms_Shared(e.target.value)}
                    fullWidth
                  >
                    <MenuItem disabled value="none">
                      SMS to be shared with
                    </MenuItem>
                    <MenuItem value="client">Client</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="not_send">Not Send</MenuItem>
                  </Select>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj.id);
                        });
                        setMessagesTemplates(packs);
                      }
                    }}
                    freeSolo
                    multiple
                    limitTags={1}
                    defaultValue={messagesTemplatesDef}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getMessageTemplates(`?search=${newInputValue}`);
                      }, 1000);
                    }}
                    // disabled={
                    //   messagesSentThrough === "none" ||
                    //   messagesSentThrough === "not_send"
                    // }
                    // aria-required={
                    //   messagesSentThrough !== "none" ||
                    //   messagesSentThrough !== "not_send"
                    // }
                    options={messageTemplate || []}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label="Select Messages Templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(
                            JSON.stringify(val, null, " ")
                          );
                          packs.push(obj.id);
                        });
                        setMessagesTemplates(packs);
                      }
                    }} 
                    multiple
                    limitTags={1}
                    defaultValue={messagesTemplatesDef}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getMessageTemplates(`?search=${newInputValue}`);
                      }, 1000);
                    }}

                    aria-required={
                      messagesSentThrough !== "none" ||
                      messagesSentThrough !== "not_send"
                    }
                    options={messageTemplate || []}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label="Select Messages Templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    type="text"
                    value={whatsapp_shared}
                    variant="outlined"
                    style={{ height: "40px" }}
                    required
                    onChange={(e) => setWhatsapp_Shared(e.target.value)}
                    fullWidth
                  >
                    <MenuItem disabled value="none">
                      whatsapp to be shared with
                    </MenuItem>
                    <MenuItem value="client">Client</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                    <MenuItem value="both">Both</MenuItem>
                    <MenuItem value="not_send">Not Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue?.map((val: any) => {
                          let obj = JSON.parse(
                            JSON.stringify(val, null, " ")
                          );
                          packs.push(obj.id);
                        });
                        setwhatsappTemplates(packs);
                      }
                    }}
                    multiple
                    limitTags={2}
                    defaultValue={messagesTemplatesDef1}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getWhatsappTemplates(`?search=${newInputValue}`);
                      }, 1000);
                    }}

                    aria-required={
                      messagesSentThrough !== "none" ||
                      messagesSentThrough !== "not_send"
                    }
                    options={whatsappsTemplate || []}
                    getOptionLabel={(option: any) => `${option?.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label="Select Whatsapp Templates"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!mobileValidation.phoneNumber || !mobileValidation.centerHeadalterNateNumber || !mobileValidation.partnerHeadalContactNumber}
                type="submit"
              >
                {editButton
                  ? "Update Employee"
                  : "Create Employee"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/payroll/employee")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/payroll/employee")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
          <Dialog
            open={openPreviewModal}
            // onClose={() => handleClosePreview()}
            fullWidth
            maxWidth="lg"
          >
            <Grid container style={{ padding: 20 }} spacing={3}>
              <Grid sm={12} />
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!mobileValidation.phoneNumber}
                >
                  {editButton ? "Update Centre" : "Create Center"}
                </Button>
              </Grid>
              {!editButton ? (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => history.push("/dashboard/payroll/employee")}
                  >
                    Reset
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => history.push("/dashboard/payroll/employee")}
                  >
                    Cancel Update
                  </Button>
                </Grid>
              )}
            </Grid>
          </Dialog>
        </form>
      </Paper>
      {/* <PartnerTable */}
        // partnerList={partnerList}
        // getPartner={getPartner}
        // handleEdit={handleEdit}
      {/* /> */}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.PayrollReducer.userDetails,
  bankName: state.PayrollReducer.bankName,
  emailTemplate: state.PayrollReducer.emailTemplate,
  messageTemplate: state.PayrollReducer.messageTemplate,
  whatsappsTemplate: state.PayrollReducer.whatsappsTemplate,
  partnerList: state.PayrollReducer.partnerList,
  partnerCodename: state.PayrollReducer.partnerCodename,
});
export default connect(mapStateToProps, {
  // getBankName,
  // getEmailTemplates,
  // getMessageTemplates,
  // getWhatsappTemplates,
  // createPartner,
  // getPartner,
  // getpartnerCode,
  // updatePartner,
})(CreatePartnerPage);
