import React, { SelectHTMLAttributes, useEffect, useState } from 'react';
import axios from 'axios';
import "./style.css"
import Paper from '@mui/material/Paper';
import { Grid, TextField, TextareaAutosize, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SelectOption, Textarea } from '@mui/joy';
import { Value } from 'react-multi-date-picker';
import { connect } from "react-redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { __String } from 'typescript';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import JobCard from './JobUpdates'



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface JobCardProps {
  title: string;
  designation: string;
  location: string;
}

type JobFormValues = {
  jobTitle: string;
  company: string;
  location: string;
  description: string;
  howToApply: string;
  employmentType: string;
  workingHours: string;
  salary: string;
  imageUrl1?: string;
  imageUrl2?: string;
};






const JobApplicationForm: React.FC<JobFormValues> = () => {

  // const JobForm=() => {
  const [formValues, setFormValues] = useState<JobFormValues>({
    jobTitle: '',
    company: '',
    location: 'Location',
    description: 'Job Profile',
    howToApply: '',
    employmentType: '',
    workingHours: '',
    salary: '',
  });

  // const [showSection, setShowSection] = useState<any>(
  //   {
  //     jobApplication: true,
  //     showApplication: false,
  //   },
  // );

  // const handleShowSection = (newShowState: any) => {
  //   setShowSection(() => ({
  //     ...showSection,
  //     ...newShowState,
  //   }));
  // }


  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  const [selectDesignation, setselectDesignation] = React.useState('');
  const [selectLocation, setSelectLocation] = React.useState('');


  const handleChangeDesignation = (event: SelectChangeEvent) => {
    setselectDesignation(event.target.value)
  };

  const hanldeChangeLocation = (event: SelectChangeEvent) => {
    setSelectLocation(event.target.value)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };


 
  const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    axios.post('/api/jobs', formValues).then((response) => {
      
    });
  }


  const [open, setOpen] = useState(false);

  const handleClickOpenUpdates = () => {
    setOpen(true);
  };

  const handleCloseUpdates = () => {
    setOpen(false);
  };

  const handleSubmitUpdates = () => {
    // Handle form submission logic here
    handleCloseUpdates(); // Close the dialog after submission
  };

  return (

    <Grid container spacing={4}
      style={{ display: "flex", justifyContent: "center", marginTop: "2rem", width: "1000px", marginLeft: "auto", marginRight: "auto", paddingBottom: "2rem" }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <div
          className="ud-container"
          style={{
            display: "block",
            width: "initial",
            margin: "3rem 3rem",
            backgroundColor: "white",
            padding: "20px"
          }}
        >

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="">
                <Tab label="Job Application" {...a11yProps(0)} />
                <Tab label="Job Updates" {...a11yProps(1)} />

              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div className="application-Form">
                <h1 style={{ marginLeft: "30%", color: "#413c9f", fontWeight: "700", letterSpacing: "2px" }}>Job Applications </h1>
                <hr style={{ background: "#413c9f", height: "1.5px" }} />

                <form onSubmit={handleSubmit}  >

                  <div className="formItem" >
                    {/* <label htmlFor="jobTitle"  >Job Title</label> */}
                    <h5>Job Title</h5>

                    <TextField
                      // hiddenLabel
                      type="text"
                      name="jobTitle"
                      label="Job Title"
                      id='jobTitle'
                      placeholder='Front-End Developer'
                      value={formValues.jobTitle}
                      onChange={handleInputChange}
                      style={{ width: "420px", height: "40px" }}
                      variant="outlined"
                    />
                  </div>

                  <div className="formItem" >
                    {/* <label htmlFor="job-description">Job Description</label> */}
                    <h5>Job Designation</h5>
                    <Box sx={{ minWidth: "350px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="desig-label">Designation</InputLabel>
                        <Select
                          labelId="designation-label"
                          id="designation-select"
                          value={selectDesignation ? selectDesignation : "Enter Profile"}
                          label="Designation"
                          onChange={handleChangeDesignation}
                        >
                          <MenuItem value={"Front-End Developer"}>Front-End Developer</MenuItem>
                          <MenuItem value={"Back-End Developer"}>Back-End Developer</MenuItem>
                          <MenuItem value={"Software Engineer"}>Software Engineer</MenuItem>
                          <MenuItem value={"Senior Software Engineer"}>Senior Software Engineer</MenuItem>
                          <MenuItem value={'HR'}>HR</MenuItem>
                          <MenuItem value={"Finance"}>Finance</MenuItem>
                          <MenuItem value={"Product Manager"}>Product Manager</MenuItem>
                          <MenuItem value={"Tech Manager"}>Tech Manager</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                  </div>

                  <div className="formItem" >
                    {/* <label htmlFor="job-description">Job Location</label> */}
                    <h5>Job Location</h5>
                    <Box sx={{ minWidth: "350px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="loc-label">Location</InputLabel>
                        <Select
                          labelId="location-label"
                          id="location-select"
                          value={selectLocation}
                          label="Location"
                          onChange={hanldeChangeLocation}
                        >
                          <MenuItem value={"Noida"}>Noida</MenuItem>
                          <MenuItem value={"Gurugran"}>Gurugran</MenuItem>
                          <MenuItem value={"Mumbai"}>Mumbai</MenuItem>
                          <MenuItem value={"Bangalore"}>Bangalore</MenuItem>
                          <MenuItem value={'HR'}>Pune</MenuItem>
                          <MenuItem value={"Hyderabad"}>Hyderabad</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                  </div>
                  <hr />

                  <div className="formItem" >
                    <div>
                      {/* <label htmlFor="job-description">Working Hours</label> */}
                      <h5>Working Hours</h5>
                      <p>Pick one or both options</p>
                    </div>
                    <div >
                      <Box width={"400px"}
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '25ch', minWidth: '200px' },
                        }}
                        noValidate
                        autoComplete="off">
                        <FormControl fullWidth>
                          <FormGroup style={{ display: "block", width: "400px" }}>
                            <FormControlLabel id='checkBox' control={<Checkbox />} label="Part-TIme" />
                            <FormControlLabel id='checkBox' style={{ marginLeft: "1rem" }} control={<Checkbox />} label="Full-Time" />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </div>
                  </div>
                  <hr />

                  <div className="formItem" >
                    <div>
                      {/* <label htmlFor="job-description">Working Hours</label> */}
                      <h5>Employement Type</h5>
                      <p>Pick one or mulitple options </p>
                    </div>
                    <div >
                      <Box sx={{ minWidth: "200px" }} width={"400px"}>
                        <FormControl fullWidth>
                          <FormGroup style={{ display: "block", width: "400px", }}>
                            <FormControlLabel id='checkBox' control={<Checkbox />} label="Permanent" />
                            <FormControlLabel id='checkBox' style={{ marginLeft: "1rem" }} control={<Checkbox />} label="Contract" />
                            <FormControlLabel id='checkBox' control={<Checkbox />} label="Temporary" />
                            <FormControlLabel id='checkBox' style={{ marginLeft: "1rem" }} control={<Checkbox />} label="Working Students" />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </div>
                  </div>

                  <hr />

                  <div className="formItem" >
                    <div>
                      {/* <label htmlFor="job-description">Working Hours</label> */}
                      <h5>Salary</h5>
                    </div>
                    <div >
                      <Box sx={{ minWidth: "200px" }} width={"400px"}>
                        <FormControl fullWidth >

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} id="select-salary">
                            <FormGroup style={{ display: "block", width: "auto", }}>

                              <TextField
                                select
                                id="outlined-select-currency"
                                label="Currency"
                                defaultValue="EUR"
                                // helperText="Please select your currency"
                                variant='outlined'
                                style={{ width: "100px" }}
                              >
                                {currencies.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>

                              <TextField
                                hiddenLabel
                                // type="number"
                                name="salary"
                                id='outlined-basic'
                                // placeholder='Amount'
                                label='Amount 1'
                                value={formValues.salary}
                                onChange={handleInputChange}
                                variant="outlined"
                              />
                            </FormGroup>
                          </div>

                        </FormControl>
                      </Box>
                    </div>
                  </div>
                  <hr />

                  <div className="formItem" style={{ display: "block", textAlign: "center" }} >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >

                      <h5>Job Description</h5>
                      <p>Max-Words:500</p>
                    </div>
                    <hr />
                    <div >
                      <Box sx={{ minWidth: "200px" }} width={"100%"}  >
                        {/* <FormControl fullWidth> */}

                        <TextareaAutosize style={{
                          display: "block", width: "100%", minHeight: "300px",
                          backgroundColor: "#ebebeb", borderRadius: "10px", padding: "5px 15px"
                        }} ></TextareaAutosize>

                        {/* </FormControl> */}
                      </Box>
                    </div>
                  </div>

                  <hr />

                  <div className="submit-btn">
                    <Button id='submit-btn' type={"submit"} >Submit</Button>
                  </div>


                </form>
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <JobCard
                title="Data Analyst"
                designation="Data Science Engineer"
                location="San Francisco"
              />
            </CustomTabPanel>

          </Box>
        </div>
      </Grid >
    </Grid >
  );

};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {

})(JobApplicationForm);

