import React, { useState, useEffect, useRef, FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
  Button,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Grid, Paper, Tooltip } from "@material-ui/core";
import "./index.sass";

import { editKraDetails, getEmployeeList } from "../../../actions/loginActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    rightContentHeader: {
      float: "right",
      textAlign: "right",
      paddingRight: "50px",
    },
    textarea: {
      padding: "6px 12px",
      borderRadius: "8px",
      lineHeight: "1.5rem",
      width: "110%",
      margin: "0",
      fontFamily: "monospace",
      fontSize: "1rem",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  editKraDetails: Function;
  getEmployeeList: Function;
  employeesList: any;
}

const KRATable: React.FC<Props> = ({
  editKraDetails,
  getEmployeeList,
  employeesList,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const names = [
    "Customer-Centric",
    "TAT",
    "Quality",
    "Efficiency & Innovation",
  ];
  const initialKraState = names.map((name) => ({
    keyResult: "",
    targetLevel: "",
    KpiWeightage: "",
    KraWeightage: "",
    selfAchievement: "",
    selfWtScore: "",
    reportingManagerAchievement: "",
    reportingManagerWtScore: "",
    name: name,
  }));
  const [kra, setKra] = useState<any>(initialKraState);

  const handleRemoveClick = (rowIndexToRemove: number) => {
    setKra((prevKra: any) => {
      return prevKra.filter(
        (_: any, index: number) =>
          index < rowIndexToRemove || index >= rowIndexToRemove + 4
      );
    });
  };

  const handleAddClick = () => {
    const newRows = Array.from({ length: 4 }, (_, index) => {
      const nameToUse = names[index % names.length];
      return {
        name: nameToUse,
        keyResult: "",
        targetLevel: "",
        KpiWeightage: "",
        KraWeightage: "",
        selfAchievement: "",
        selfWtScore: "",
        reportingManagerAchievement: "",
        reportingManagerWtScore: "",
      };
    });
    setKra((prevKra: any) => [...prevKra, ...newRows]);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = e.target;
    setKra((prevKra: any) => {
      return prevKra.map((item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            [fieldName]: value,
          };
        }
        return item;
      });
    });
  };

  const submitForm = () => {
    const fieldValues = kra.map((kraItem: any) => ({
      key_result_area: kraItem.keyResult,
      key_result_performance_indicator: kraItem.targetLevel,
      measurement_method: "abc",
      kpi_weightage: kraItem.KpiWeightage,
      kra_wt: kraItem.KraWeightage,
      self_achievement: kraItem.selfAchievement,
      self_wt_score: kraItem.selfWtScore,
      manager_achievement: kraItem.reportingManagerAchievement,
      manager_wt_score: kraItem.reportingManagerWtScore,
    }));

    const data = {
      field_values: fieldValues,
      employee_signature: "abc",
      manager_signature: "abc",
      form: null,
    };

    editKraDetails(data);
  };

  useEffect(() => getEmployeeList(""), []);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          width: "100%",
          textAlign: "center",
          color: "rgb(14, 63, 108)",
        }}
      >
        KRA PLANNING AND REVIEW FORM: FY23-24
      </h2>
      <div style={{ marginTop: 70, width: "100%" }}>
        <Grid
          container
          spacing={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer component={Paper} style={{ minHeight: "100px" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Welcome StyledTableCell </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                {employeesList &&
                  employeesList.length > 0 &&
                  employeesList.map((data: any) => (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell
                      
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {" "}
                          Name:
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {data.name}{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Employee Code
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {" "}
                          {data.employee_id}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Designation{" "}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {data.designation}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Location{" "}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {data.address}
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Reporting Manager
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.manager}
                        </StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <div style={{ width: "100%" }} className="data-table">
          <Grid
            container
            spacing={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
                style={{ maxHeight: "560px" }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        Add Stops
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        KEY RESULT AREA
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        KEY RESULT/ PERFORMANCE INDICATOR{" "}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        KPI Weightage
                      </StyledTableCell>
                      <StyledTableCell align="center">KRA WT.</StyledTableCell>
                      <StyledTableCell align="center">
                        Self
                        <Table stickyHeader aria-label="simple table">
                          <StyledTableRow>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: "0.7px solid white",
                                width: "30%",
                              }}
                            >
                              ACHIEVEMENT (%age)
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: "0.7px solid white",
                                width: "33%",
                              }}
                            >
                              WT. SCORE (%age)
                            </StyledTableCell>
                          </StyledTableRow>
                        </Table>
                      </StyledTableCell>

                      <StyledTableCell className={classes.tableHeadCell}>
                        Reporting Manager
                        <Table stickyHeader aria-label="simple table">
                          <StyledTableRow>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: "1px solid white",
                                width: "30%",
                              }}
                            >
                              ACHIEVEMENT (%age)
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: "1px solid white",
                                width: "33%",
                              }}
                            >
                              WT. SCORE (%age)
                            </StyledTableCell>
                          </StyledTableRow>
                        </Table>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {kra.map((row: any, index: number) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index === kra.length - 1 && (
                            <AddCircleIcon
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              onClick={handleAddClick}
                            />
                          )}
                          {index % 4 === 0 && (
                            <HighlightOffIcon
                              style={{
                                height: "30px",
                                width: "30px",
                                cursor: "pointer",
                                borderRadius: "50%",
                                visibility:
                                  index === kra.length - 1
                                    ? "hidden"
                                    : "visible",
                              }}
                              onClick={() => handleRemoveClick(index)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          <textarea
                            name="keyResult"
                            className={classes.textarea}
                            value={row.keyResult}
                            placeholder="Write Key Result..."
                            onChange={(e) =>
                              handleInputChange(e, index, "keyResult")
                            }
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <TextField
                            className="input"
                            variant="outlined"
                            name="KpiWeightage"
                            style={{
                              width: "70%",
                              margin: "0",
                              padding: "0",
                            }}
                            label="Kpi Weightage"
                            value={row.KpiWeightage}
                            onChange={(e) =>
                              handleInputChange(e, index, "KpiWeightage")
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TextField
                            className="input"
                            variant="outlined"
                            name="KraWeightage"
                            style={{
                              width: "70%",
                              margin: 0,
                              padding: 0,
                            }}
                            label="Kra Weightage"
                            // className={classes.textarea}
                            value={row.KraWeightage}
                            onChange={(e) =>
                              handleInputChange(e, index, "KraWeightage")
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Table style={{ backgroundColor: "transparent" }}>
                            <StyledTableRow
                              style={{ backgroundColor: "transparent" }}
                            >
                              <StyledTableCell>
                                <TextField
                                  className="input"
                                  variant="outlined"
                                  name="selfAchievement"
                                  style={{
                                    width: "70%",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  label="Self Achievement"
                                  // className={classes.textarea}
                                  value={row.selfAchievement}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "selfAchievement"
                                    )
                                  }
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  className="input"
                                  variant="outlined"
                                  name="selfWtScore"
                                  style={{
                                    width: "70%",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  label="Self Wt Score"
                                  value={row.selfWtScore}
                                  onChange={(e) =>
                                    handleInputChange(e, index, "selfWtScore")
                                  }
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Table>
                            <StyledTableRow
                              style={{ backgroundColor: "transparent" }}
                            >
                              <StyledTableCell>
                                <TextField
                                  className="input"
                                  variant="outlined"
                                  name="reportManagerAchievement"
                                  style={{
                                    width: "70%",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  label="RM Achievementt"
                                  value={row.reportManagerAchievement}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "reportManagerAchievement"
                                    )
                                  }
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  className="input"
                                  variant="outlined"
                                  style={{
                                    width: "70%",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  name="reportingManagerWtScore"
                                  label="RM Wt Score"
                                  value={row.reportingManagerWtScore}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "reportingManagerWtScore"
                                    )
                                  }
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          </Table>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={submitForm}
            style={{
              color: "white",
              margin: "20px 0",
              backgroundColor: "rgb(14, 63, 108",
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
});
export default connect(mapStateToProps, {
  editKraDetails,
  getEmployeeList,
})(KRATable);
