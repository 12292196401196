import React from "react";
import { Drawer, IconButton, Typography } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

interface CustomDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  children: React.ReactNode;
  drawerTitle: string;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  open,
  toggleDrawer,
  drawerTitle,
  children,
}) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        sx={{ "& .MuiDrawer-paper": { width: "30%" ,paddingTop:'4.5rem' } }}
      >
        <div className='px-3 m-0 d-flex align-items-center justify-content-start gap-3'
            >
          <IconButton onClick={toggleDrawer} edge="start" color="default" aria-label="menu">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component={'div'}  sx={{ textAlign: 'end' }}>
            {drawerTitle}
          </Typography>
        </div>
        <div className="p-2">

        {children}
        </div>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
