import React, { useState, useEffect } from 'react';
import { Button, Container, List, ListItem, ListItemAvatar, ListItemText, Typography, Paper } from '@material-ui/core'; // Import Paper component
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { getNotification, getEmployeeList } from "../../../actions/loginActions";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '60px'
  },
  unread: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    color: "rgb(184, 0, 0)",
    display: "none"

  },
  read: {
    borderLeft: `4px solid ${theme.palette.grey[500]}`,
    boxShadow: "2px 2px 5px lightgrey",
    color: "rgb(38, 80, 115)"

  },
  lightGreyBackground: {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: "rgb(225, 240, 218)",
    },
    margin: '10px',
  },
  button: {
    backgroundColor: "rgb(14, 63, 108)",
    '&:hover': {
      backgroundColor: "rgb(112, 119, 161)",
    },
  },
}));

interface Notification {
  getNotification: any;
  get_notification: any;
  getEmployeeList: any;
  employeesList: any;
  loading: any;
}

const App: React.FC<Notification> = ({
  getNotification,
  get_notification,
  getEmployeeList,
  employeesList,
  loading,
}) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState<any>([]);

  useEffect(() => {
    getNotification();
    getEmployeeList();
  }, []);

  const history = useHistory();


  const markAsRead = (id: number) => {
    const updatedNotifications = notifications.map((notification: any) =>
      notification.id === id ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
  };

  const manageNotification = (id: any, uuid: any) => {
    const body = {
      is_read: true
    }
    getNotification(body, id);
    history.push("/dashboard/payrollManager/inbox");
    // history.push(`/dashboard/payrollManager/request-center/${uuid}`);
  }

  return (
    <Paper elevation={3}>
      <Container style={{ maxWidth: "100%", marginLeft: '0' }} className={classes.root}>
        <List>
          {get_notification && get_notification?.map((notification: any, index: any) => (

            <Paper elevation={5} className={classes.lightGreyBackground}>
              <ListItem
                key={notification?.id}
                className={notification?.is_read === true ? classes.unread : classes.read}
              >
                <div className="noti-time" style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <div style={{ display: "flex" }}>
                    <ListItemAvatar>
                      <Avatar alt={notification?.comments} src={`images/users/${notification?.comments?.toLowerCase()}.jpg`} />
                    </ListItemAvatar>
                    <ListItemText
                    >
                      <p style={{
                        width: "auto",
                        fontSize: "1.5rem",
                        fontWeight: "700"

                      }}>{`${notification?.comments}`}</p>
                    </ListItemText>
                  </div>
                  <div
                  >
                    <ListItemText  >
                      <p style={{
                        fontSize: "1.2rem",
                        fontWeight: "600"
                      }}>
                        {notification?.created_at?.slice(0, 11)}
                      </p>
                    </ListItemText>
                  </div>
                </div>
                <div className="mark-btn" style={{ width: "100%", display: "flex", justifyContent: "center", margin: '10px 0' }}>
                  {notification?.is_read === false && (
                    <Button className={classes.button} variant="contained" color="primary" onClick={() => manageNotification(notification?.id, notification?.employee)}>
                      Mark as Read
                    </Button>
                  )}
                </div>
              </ListItem>
            </Paper>
          ))}
        </List>
      </Container>
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  loading: state.loginReducer.loading,
  get_notification: state.loginReducer.get_notification,
});
export default connect(mapStateToProps, {
  getNotification,
  getEmployeeList,
})(App);
