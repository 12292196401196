import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { TextField, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getMyTeamDetails, getShiftList, weeklyRoster, getWeeklyRoster } from "../../../actions/loginActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import dayjs, { Dayjs } from "dayjs";
import Chip from "@mui/material/Chip";
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
} from "@material-ui/core";

dayjs.extend(isBetweenPlugin);


// interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
//   isSelected: boolean;
//   isHovered: boolean;
//   day: Dayjs;
// }

// const CustomPickersDay = styled(PickersDay, {
//   shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
// })<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
//   borderRadius: 0,
//   ...(isSelected && {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     '&:hover, &:focus': {
//       backgroundColor: theme.palette.primary.main,
//     },
//   }),
//   ...(isHovered && {
//     backgroundColor: theme.palette.primary.light,
//     '&:hover, &:focus': {
//       backgroundColor: theme.palette.primary.light,
//     },
//     ...theme.applyStyles('dark', {
//       backgroundColor: theme.palette.primary.dark,
//       '&:hover, &:focus': {
//         backgroundColor: theme.palette.primary.dark,
//       },
//     }),
//   }),
//   ...(day.day() === 0 && {
//     borderTopLeftRadius: '50%',
//     borderBottomLeftRadius: '50%',
//   }),
//   ...(day.day() === 6 && {
//     borderTopRightRadius: '50%',
//     borderBottomRightRadius: '50%',
//   }),
// })) as React.ComponentType<CustomPickerDayProps>;

// const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
//   if (dayB == null) {
//     return false;
//   }

//   return dayA.isSame(dayB, 'week');
// };

// function Day(
//   props: PickersDayProps<Dayjs> & {
//     selectedDay?: Dayjs | null;
//     hoveredDay?: Dayjs | null;
//   },
// ) {
//   const { day, selectedDay, hoveredDay, ...other } = props;

//   return (
//     <CustomPickersDay
//       {...other}
//       day={day}
//       sx={{ px: 2.5 }}
//       disableMargin
//       selected={false}
//       isSelected={isInSameWeek(day, selectedDay)}
//       isHovered={isInSameWeek(day, hoveredDay)}
//     />
//   );
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "190%",
      marginTop: "50px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      padding: "10px 20px",
      gap: "10px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },

    tableContainer: {
      position: "sticky",
      padding: "20px",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
      border: "1px solid #fff",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
      border: "1px solid #fff",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      border: "1px solid #fff",
    },
  })
)(TableRow);

interface Props {
  getMyTeamDetails: any;
  team_details: any;
  getShiftList: any;
  shiftList: any;
  weeklyRoster: any;
  getWeeklyRoster: any;
  getWeeklyShift: any;
}

const WeeklyRoster: React.FC<Props> = ({ getMyTeamDetails, team_details, getShiftList, shiftList, weeklyRoster, getWeeklyRoster, getWeeklyShift }) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<any>(false);
  const [dates, setDates] = useState<any>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [shiftListData, setshiftListData] = useState<any>("");
  const [userShiftTime, setUserShiftTime] = useState<any>("");
  const [WeekOffDays, setWeekOffDays] = useState<any>("");
  const [PreviousWeekOffDays, setPreviousWeekOffDays] = useState<any>([]);
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [formattedDate, setFormattedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [currentDate, setCurrentDate] = useState<any>(dayjs().format('YYYY-MM-DD'));

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const handleAutocompleteChange = (event: any, newValue: any) => {
    if (newValue) {
      let obj = JSON.parse(JSON.stringify(newValue, null, ""));
      setshiftListData(obj.id);
      setUserShiftTime(`Total Hours: ${obj.total_hour}`);
    }
  };

  useEffect(() => {
    getMyTeamDetails();
    getWeeklyRoster(`date=${currentDate}`);
    getShiftList();
  }, []);

  const getNext7Days = (selectedDate: Date) => {
    const Dates = [];
    const startDate = new Date(selectedDate);

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);
      Dates.push(
        nextDate.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
      );
    }
    return Dates;
  };

  const checkSelectedEmployees = (event: any, empId: number) => {
    if (selectedEmployees?.length < 10) {
      !event.target.checked
        ? setSelectedEmployees(
          selectedEmployees.filter((emp: any) => emp !== empId)
        )
        : setSelectedEmployees((prev: any) => [...prev, empId]);

    } else {
      return false;
    }
  };
  
  useEffect(()=>{
    if(!isDrawerOpen){
      setWeekOffDays("")
    }
  },[isDrawerOpen]);

  const getStartOfWeek = (date: string) => {
    const selected = new Date(date);
    const day = selected.getDay();
    const diff = selected.getDate() - day + (day === 0 ? -6 : 1);
    const startOfWeek = new Date(selected.setDate(diff));
    const formattedStartOfWeek = startOfWeek.toISOString().split('T')[0];

    const Dates = [];
    const startDate = new Date(formattedStartOfWeek);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);

      const year = nextDate.getFullYear();
      const month = (nextDate.getMonth() + 1).toString().padStart(2, "0");
      const day = nextDate.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const dayOfWeek = dayNames[nextDate.getDay()];
      Dates.push({ date: formattedDate, day: dayOfWeek });
    }
    return Dates;
  };

  const handleChangeDate = (newValue: Dayjs | null) => {
    if (newValue) {
      setValue(newValue);
      setFormattedDate(newValue.format('YYYY-MM-DD'));
    }
  };

  useEffect(()=>{
    const selectedDate = currentDate;
    const startOfWeekDate = getStartOfWeek(selectedDate);
    setDates(startOfWeekDate);

  },[]);
  const handleChange = (event: any) => {
    const selectedDate = event.target.value;
    const startOfWeekDate = getStartOfWeek(selectedDate);
    const selectedDates = getNext7Days(selectedDate);
    setDates(startOfWeekDate);
    getWeeklyRoster(`date=${selectedDate}`);
  };



  const handleAssignShift = async () => {
    const body = {
      employee_id: selectedEmployees,
      weekly_off_days: WeekOffDays,
      // date: formattedDate,
      date:dates[1]?.date,
      shift: shiftListData,
    }
    await weeklyRoster(body);
    setIsDrawerOpen(false);
    getWeeklyRoster(`date=${currentDate}`);
    setSelectedEmployees([]);
    setFormattedDate("");
    setshiftListData("");
    setWeekOffDays("");
    setUserShiftTime("");
  }

  return (
    <>
      <div className={classes.content}>

        <h4
          style={{
            color: "rgb(14, 63, 108)",
            fontSize: "35px",
            fontWeight: "bolder",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "10px",
            borderRadius: "15px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          Weekly Roster
        </h4>

        <Grid
          container
          spacing={3}
          direction="row"
          style={{ alignItems: "left", marginTop: "1rem" }}
        >
          <Grid item xs={12} sm={2} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Date"
              variant="outlined"
              // value={currentDate}
              onChange={handleChange}
              style={{ width: "100%" }}
              inputProps={{
                min: currentDate,
              }}
            />

          </Grid>
         { getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 &&getWeeklyShift?.roster_data[0]?.is_manager!==false&&
         <Grid item xs={12} sm={2} md={2}>
            <Button
              variant="contained"
              color="primary"
              // style={{ margin: "2px 15px" }}
              style={{
                width: "100%",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={(event) => {
                toggleDrawer(true)(event);
              }}
              disabled={selectedEmployees.length === 0|| dates.length === 0}
            >
              Assign
            </Button>
          </Grid>}
          <Grid item xs={12} sm={2} md={2}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
              onClick={() =>
                history.push("/dashboard/employee/weekly-roster")
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 &&getWeeklyShift?.roster_data[0]?.is_manager!==false&&
        <div
          style={{
            padding: "8px",
            marginTop: "1.5rem",
            border: "1px solid #ced4da",
            borderRadius: "8px",
            backgroundColor: "#e9ecef",
            display: "inline-block",
            fontSize: "16px",
            color: "#495057",
            fontWeight: "500",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <span style={{ fontSize: "18px", fontWeight: "600", color: "#343a40" }}>
            👉 Please select a date and employee to assign the roster.
          </span>
        </div>}

        {(["right"] as const).map((anchor) => (
          <>
            <Drawer
              anchor={anchor}
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            >

              <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                    padding: "12px 20px",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  <h4
                    style={{
                      color: "rgb(14, 63, 108)",
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "35px",
                      fontWeight: "bolder"
                    }}
                  >
                    Update Roster
                  </h4>
                </div>

                <div>
                  <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                    Select employees
                  </label>
                </div>

                <div>
                  <Autocomplete
                    multiple
                    limitTags={0}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(
                            JSON.stringify(val, null, " ")
                          );
                          packs.push(obj.employee_id);
                        });
                        setSelectedEmployees(packs);
                      }
                    }}
                    defaultValue={getWeeklyShift && getWeeklyShift?.roster_data?.filter((employee: any) =>
                      selectedEmployees.includes(employee.employee_id))}
                    id="checkboxes-tags-demo"
                    options={getWeeklyShift?.roster_data || []}
                    getOptionLabel={(option: any) => {
                      return option?.name + " " + option.employee_id;
                    }}
                    getOptionDisabled={(option: any) =>
                      selectedEmployees.includes(option.employee_id)
                    }
                    renderOption={(option: any) => {
                      return (
                        <p style={{ margin: "0 10px" }}>
                          <strong style={{ color: "blue" }}>
                            {option?.name.toString()}
                          </strong>
                          ({option?.employee_id?.toUpperCase()})
                        </p>
                      );
                    }}
                    filterOptions={(options, state) => options}
                    // onInputChange={(event, newInputValue) => {
                    //   clearTimeout(timer.current);
                    //   timer.current = setTimeout(() => {
                    //     newInputValue &&
                    //       getWeeklyRoster(`employee_rcl=${newInputValue}&date=${currentDate}`);
                    //   }, 1000);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div style={{ padding: "8px 0" }}></div>

                <div>
                  <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                    Shift Duration
                  </label>
                </div>
                <div style={{ display: "flex", fontSize:"16px" }}>
                  <div style={{ width: "100%" }}>
                  {dates[0]?.date}{' '} 
                  { dates[0]?.day}
                  </div>
                  <div style={{ width: "100%" }}>
                  {dates[6]?.date}{' '} 
                  { dates[6]?.day}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={value}
                      onChange={handleChangeDate}
                      showDaysOutsideCurrentMonth
                      // slots={{ day: Day }}
                      slotProps={{
                        day: (ownerState) =>
                          ({
                            selectedDay: value,
                            hoveredDay,
                            onPointerEnter: () => setHoveredDay(ownerState.day),
                            onPointerLeave: () => setHoveredDay(null),
                          }) as any,
                      }}
                    />
                  </LocalizationProvider> */}
                  {/* <TextField
                        className="input"
                        name="start_date"
                        label="Date"
                        id="start-date"
                        type="date"
                        value={WeekDate}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setWeekDate(e.target.value)}
                        style={{ width: "100%" }}

                        inputProps={{
                          max: moment().format("DD-MM-YYYY"),
                          min: moment().format("DD-MM-YYYY"),
                        }}
                      /> */}
                </div>

                {/* <div style={{ padding: "5px 0" }}></div>

                <div>
                  <label
                    htmlFor="reporting-manager"
                    style={{ fontWeight: "bold" }}
                  >
                    Shift plan
                  </label>
                </div> */}

                {/* <div>
                  <Autocomplete
                    onChange={handleAutocompleteChange}
                    id="shift-list"
                    options={shiftList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getShiftList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return userShiftTime
                        ? userShiftTime
                        : option.start_time + " To " + option.end_time;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}> Shift :</b>
                          <strong>
                            {option?.start_time} To {option.end_time}
                          </strong>
                          <br />
                          <b style={{ color: "red" }}>Total Hours:</b>
                          {option?.total_hour}
                        </p>
                      );
                    }}
                    defaultValue={userShiftTime ? userShiftTime : null}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </div> */}

                <div style={{ padding: "5px 0" }}></div>

                {/* <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Clock-In method
                      </label>
                    </div> */}
                {/* 
                    <div>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        // onChange={(e: any) =>
                        //   handleInputChange("nationality", e.target.value)
                        // }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value="indian">App</MenuItem>
                        <MenuItem value="others">Bio-metric</MenuItem>
                      </TextField>
                    </div> */}


                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Week-off
                  </label>
                </div>

                <div>
                  <Autocomplete
                    id="ticket_status"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item.value);
                      }
                      setWeekOffDays(sourceIds);
                    }}
                    options={[
                      {
                        text: "Monday",
                        value: "Monday",
                      },
                      {
                        text: "Tuesday",
                        value: "Tuesday",
                      },
                      {
                        text: "Wednesday",
                        value: "Wednesday",
                      },
                      {
                        text: "Thursday",
                        value: "Thursday",
                      },

                      {
                        text: "Friday",
                        value: "Friday",
                      },
                      {
                        text: "Saturday",
                        value: "Saturday",
                      },
                      {
                        text: "Sunday",
                        value: "Sunday",
                      },
                    ]}
                    freeSolo
                    defaultValue={PreviousWeekOffDays}
                    // blurOnSelect
                    aria-required
                    limitTags={1}
                    multiple
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return WeekOffDays.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.text}
                    </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </div>

                <div style={{ padding: "5px 0" }}></div>


                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    disabled={WeekOffDays === ""}
                    onClick={handleAssignShift}>
                    Submit
                  </Button>
                </div>
              </Box>
            </Drawer>
          </>
        ))}

        <div style={{ width: "100%", display: "flex", borderRadius: "10px", marginTop: "20px" }}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                     { getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 &&getWeeklyShift?.roster_data[0]?.is_manager!==false&& 
                      <div>
                        <input
                          type="checkbox"
                          className="input"
                          name="selectall"
                          id="selectall"
                          onChange={(e) =>
                            e.target.checked
                              ? setSelectedEmployees(
                                getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 && getWeeklyShift?.roster_data?.map
                                  (
                                    (data: any, index: number) => {
                                      if (index < 100) {
                                        return data?.employee_id;
                                      }
                                    }
                                  )
                              )
                              : setSelectedEmployees([])
                          }
                        />
                      </div>}

                      <input
                        type="text"
                        style={{ width: "80%", padding: "5px", borderRadius: "10px", backgroundColor: "white" }}
                        placeholder="Search by RCL ID"
                        onChange={(e) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getWeeklyRoster(`employee_rcl=${e.target.value}&date=${currentDate}`);
                          }, 1000);
                        }}
                      />

                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">  {dates[0]?.date}
                    <br />
                    {dates[0]?.day}</StyledTableCell>
                  <StyledTableCell align="center">{dates[1]?.date}
                    <br />
                    {dates[1]?.day}</StyledTableCell>
                  <StyledTableCell align="center">{dates[2]?.date}
                    <br />
                    {dates[2]?.day}</StyledTableCell>
                  <StyledTableCell align="center">{dates[3]?.date}
                    <br />
                    {dates[3]?.day}
                  </StyledTableCell>
                  <StyledTableCell align="center">{dates[4]?.date}
                    <br />
                    {dates[4]?.day}
                  </StyledTableCell>
                  <StyledTableCell align="center">{dates[5]?.date}
                    <br />
                    {dates[5]?.day}
                  </StyledTableCell>
                  <StyledTableCell align="center">{dates[6]?.date}
                    <br />
                    {dates[6]?.day}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 && getWeeklyShift?.roster_data?.map((empDetail: any, index: number) => (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        {getWeeklyShift && getWeeklyShift?.roster_data?.length > 0 &&getWeeklyShift?.roster_data[0]?.is_manager!==false&& 
                          <div>
                          <input
                            type="checkbox"
                            name="selectall"
                            id="selectall"
                            checked={selectedEmployees.find(
                              (qa: any) => qa === empDetail.employee_id
                            )}
                            onChange={(e) =>
                              checkSelectedEmployees(e, empDetail.employee_id)
                            }
                          />
                        </div>}
                        <div>
                          <Avatar
                            // key={data.id}
                            // alt="User Avatar"
                            // src={
                            //   data.profile_picture
                            //     ? data.profile_picture
                            //     : "/static/images/avatar/1.jpg"
                            // }
                            style={{
                              backgroundColor: "rgb(14, 63, 108)",
                              cursor: "pointer",
                              border: "1px solid black",
                              height: "50px",
                              width: "50px",
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <p>
                            {/* <b style={{ color: "blue" }}>Employee Name:</b> */}
                            <strong>{empDetail.name}</strong>
                            <br />
                            <Chip
                              label={empDetail.employee_id}
                              color="primary"
                              variant="outlined"
                            />
                            <Chip
                              label={empDetail.employee_designation}
                              color="success"
                              variant="outlined"
                              style={{ marginLeft: "5px" }}
                            />
                          </p>
                        </div>
                      </div>
                    </StyledTableCell>

                    {dates?.map((date: any) => (
                      <StyledTableCell align="center" style={{ backgroundColor: "#e9ecef" }} key={date.date}>
                        {
                          empDetail?.roster_data?.find(
                            (data: any) =>
                              data.date === date.date
                              &&
                              data.weekly_off?.includes(date.day)
                          ) ? (
                            // "Weekly Off"
                            <Chip
                              label="Weekly Off"
                              color="primary"
                              variant="outlined"
                            />
                          ) : (
                            empDetail?.roster_data?.find(
                              (data: any) => data.date === date.date
                            )?.shift?.total_hour + " hours" || "NA"
                          )}

                      </StyledTableCell>
                    ))}

                    {/* <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      10 hours
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift2}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift3}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift4}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift5}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift6}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ backgroundColor: "#ffe3b8" }}
                    >
                      {empDetail.shift ? (
                        `${empDetail.shift} hours`
                      ) : (
                        <Chip
                          label={empDetail.shift7}
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </StyledTableCell> */}
                  </StyledTableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  team_details: state.loginReducer.team_details,
  shiftList: state.loginReducer.shiftList,
  getWeeklyShift: state.loginReducer.getWeeklyShift,
});

export default connect(mapStateToProps, {
  getMyTeamDetails,
  getShiftList,
  weeklyRoster,
  getWeeklyRoster,
})(WeeklyRoster);