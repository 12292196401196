import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import GroupIcon from "@mui/icons-material/Group";
import CakeIcon from "@mui/icons-material/Cake";
import AspectRatio from "@mui/joy/AspectRatio";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Grid, TextField } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { useHistory } from "react-router";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Box, Button, backdropClasses } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AvatarGroup from "@mui/material/AvatarGroup";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import EmailIcon from "@mui/icons-material/Email";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CallIcon from "@mui/icons-material/Call";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CelebrationIcon from "@mui/icons-material/Celebration";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Groups2Icon from "@mui/icons-material/Groups2";
// import MultipleSelectPlaceholder from './MultipleSelectPlaceholder';
import "./style.css";
import './style.css';
import './filter.sass';
import styled from "@emotion/styled/types/base";
import { Description, Flare, Style } from "@material-ui/icons";
import { getDisplayHolidaysList } from "../../actions/HrMangerActions";

const ListItemComponent: React.FC<{ text: string; index: number }> = ({
  text,
  index,
}) => (
  <ListItem key={text} disablePadding>
    <ListItemButton>
      <ListItemText primary={text ?? ""} />
    </ListItemButton>
  </ListItem>
);

const generateList = (items: React.ReactNode[]) => (
  <List>
    {items.map((item, index) => (
      <ListItemComponent
        key={index.toString()}
        text={item?.toString() ?? ""}
        index={index}
      />
    ))}
  </List>
);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

interface Props {
  children: React.ReactNode;
  getDisplayHolidaysList: Function;
  holidayList: any;
}

interface Holiday {
  date: string;
  name: string;
}

interface TeamMember {
  id: number;
  name: string;
  position: string;
}

interface PersonalInfo {
  name: string;
  position: string;
  department: string;
  email: string;
}

interface PayrollInfo {
  salary: string;
  bankAccount: string;
  taxRate: string;
}

interface AttendanceInfo {
  totalHours: string;
  overtimeHours: string;
  punchInTime: string;
  punchOutTime: string;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  children,
  getDisplayHolidaysList,
  holidayList,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showAllHolidays, setShowAllHolidays] = useState(false);
  const [showSalary, setShowSalary] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [changePosition, setChangePosition] = React.useState(false);
  const [gmail, setGmail] = React.useState(false);
  const [manager, setManager] = React.useState(false);
  const [end_date, setEnd_date] = useState("");
  const toggleShowAllHolidays = () => {
    setShowAllHolidays(!showAllHolidays);
  };

  const toggleShowSalary = () => {
    setShowSalary(!showSalary);
  };

  const data =
    "https://static-redcliffelabs.s3.amazonaws.com/media/booking-documents/report-25.pdf";

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    };

  const [upcomingHolidays, setUpcomingHolidays] = useState("");


  const myTeam: TeamMember[] = [
    { id: 1, name: "Pradeep Tomar", position: "Engineering Manager" },
    { id: 2, name: "Pranjal Kaushik", position: "Backend Developer" },
  ];

  const personalInfo: PersonalInfo = {
    name: "Keshav K Mishra",
    position: " Software Engineer",
    department: "Technology",
    email: "Keshav@redcliffelabs.com",
  };

  const payrollInfo: PayrollInfo = {
    salary: "25973",
    bankAccount: "XXXX-XXXX-XXXX",
    taxRate: "15%",
  };

  const attendanceInfo: AttendanceInfo = {
    totalHours: "10 hours",
    overtimeHours: "2 hours",
    punchInTime: "9:57 AM",
    punchOutTime: "7:40 PM",
  };

  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [fileLink, setFileLink] = useState<any>("");

  // States for three sections
  const [showSection, setShowSection] = useState<any>([
    {
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
    },
  ]);
  useEffect(() => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
    }));
  }, []);

  const handleUserData = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: true,
      showUserProfile: false,
      showDocs: false,
    }));
  };

  const handleProfileSection = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: true,
      showDocs: false,
    }));
  };

  const handleDocuments = () => {
    setShowSection(() => ({
      ...showSection,
      showUserdata: false,
      showUserProfile: false,
      showDocs: true,
    }));
  };

  const [showProperty, setShowProperty] = useState<any>(
    {
      showAbout: false,
      showAdd: false,
      showContact: false,
      showBiodata: false,
      showDoB: false,
      showDep: false,
      updateAdd: false,
    },
  );

  const handleShowAbout = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: true,
      showAdd: false,
      showContact: false,
      showBiodata: false,
      showDoB: false,
      showDep: false,
      updateAdd: false,
    }));
  };

  const handleShowAdd = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: false,
      showAdd: true,
      showContact: false,
      showBiodata: false,
      showDoB: false,
      showDep: false,
      updateAdd: false,
    }));
  };

  const handleContact = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: false,
      showAdd: false,
      showContact: true,
      showBiodata: false,
      showDoB: false,
      showDep: false,
      updateAdd: false,
    }));
  };

  const handleShowBiodata = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: false,
      showAdd: false,
      showContact: false,
      showBiodata: true,
      showDoB: false,
      showDep: false,
      updateAdd: false,
    }));
  };

  const handleShowDoB = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: false,
      showAdd: false,
      showContact: false,
      showBiodata: false,
      showDoB: true,
      showDep: false,
      updateAdd: false,
    }));
  };

  const handleShowDep = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAbout: false,
      showAdd: false,
      showContact: false,
      showBiodata: false,
      showDoB: false,
      showDep: true,
      updateAdd: false,
    }));
  };

  const handleUpdateAdd = () => {
    setShowProperty(() => ({
      ...showProperty,
      showAdd: false,
      updateAdd: true,
    }));
  
  };

  // Work Profiles

  const [profileProperty, setProfileProperty] = useState<any>([
    {
      workDet: false,
      repOffice: false,
      currExp: false,
      pastExp: false,
    },
  ]);

  const handleWorkDet = () => {
    setProfileProperty(() => ({
      ...profileProperty,
      workDet: true,
      repOffice: false,
      currExp: false,
      pastExp: false,
    }));
  };

  const handleRepOffice = () => {
    setProfileProperty(() => ({
      ...profileProperty,
      workDet: false,
      repOffice: true,
      currExp: false,
      pastExp: false,
    }));
  };

  const handleCurrExp = () => {
    setProfileProperty(() => ({
      ...profileProperty,
      workDet: false,
      repOffice: false,
      currExp: true,
      pastExp: false,
    }));
  };

  const handlePastExp = () => {
    setProfileProperty(() => ({
      ...profileProperty,
      workDet: false,
      repOffice: false,
      currExp: false,
      pastExp: true,
    }));
  };

  const [showDocuments, setShowDocument] = useState<any>([
    {
      userIdentity: false,
      userOthers: false,
    },
  ]);

  const handleIdentity = () => {
    setShowDocument(() => ({
      ...showDocuments,
      userIdentity: true,
      userOthers: false,
    }));
  };

  const handleUsersOther = () => {
    setShowDocument(() => ({
      ...showDocuments,
      userIdentity: false,
      userOthers: true,
    }));
  };

  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const handleViewPdf = () => {
    setOpenPdf(true);
  };

  useEffect(() => {
    getDisplayHolidaysList();
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="contentHeader">
          <div className="leftContentHeader">
            <h2 style={{ textAlign: "center", color: "rgb(14, 63, 108)" }}>
              Welcome to Celara{" "}
            </h2>
          </div>
          <div style={{ display: "flex" }}>
            <Card
              sx={{
                width: 320,
                maxWidth: "100%",
                boxShadow: "lg",

              }}
              style={{ backgroundColor: "#e9ecef" }}

            >
              <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
                <Avatar src="/static/images/avatar/1.jpg" style={{ marginTop: "10px" }}
                  sx={{ "--Avatar-size": "8rem" }} />
                <div style={{ padding: "8px 10px" }}>
                  <Typography level="title-lg">Keshav K Mishra</Typography>
                  <Typography level="body-sm" sx={{ maxWidth: "24ch" }}>
                    Software Engineer
                  </Typography>
                </div>
                <div className="userDetails">
                  <ul>
                    <li
                      className="user-item"
                      onMouseEnter={() => setChangePosition(true)}
                      onMouseLeave={() => setChangePosition(false)}
                    >
                      {changePosition ? (
                        "Software Engineer"
                      ) : (
                        <span>
                          {" "}
                          <DeveloperModeIcon /> Position
                        </span>
                      )}
                    </li>
                    <li
                      className="user-item"
                      onMouseEnter={() => setGmail(true)}
                      onMouseLeave={() => setGmail(false)}
                    >
                      {gmail ? (
                        "Keshav@redcliffelabs.com"
                      ) : (
                        <span>
                          <EmailIcon />
                          Gmail
                        </span>
                      )}
                    </li>
                    <li
                      className="user-item"
                      onMouseEnter={() => setManager(true)}
                      onMouseLeave={() => setManager(false)}
                    >
                      {manager ? (
                        "Vivek Sharma"
                      ) : (
                        <span>
                          <ManageAccountsIcon /> Manager
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
                <div>
                  <AvatarGroup max={4} onClick={toggleDrawer(true)}>
                    <Avatar
                      style={{ backgroundColor: "grey", color: "white" }}
                      src="1"
                    />
                    <Avatar
                      style={{ backgroundColor: "grey", color: "white" }}
                      src="2"
                    />
                    <Avatar
                      style={{ backgroundColor: "grey", color: "white" }}
                      src="3"
                    />
                    <Avatar
                      style={{ backgroundColor: "grey", color: "white" }}
                      src="4"
                    />
                    <Avatar
                      style={{ backgroundColor: "grey", color: "white" }}
                      src="5"
                    />
                  </AvatarGroup>
                </div>
              </CardContent>
            </Card>

            <div className="cards-container">
              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "30px 30px",
                    padding: "15px 40px",
                    backgroundColor: "#e9ecef",
                  }}
                  className="hover-scrollbar"
                >
                  <Typography level="title-md" style={{ fontSize: "1.2rem" }}>
                    <CelebrationIcon /> Upcoming Celebrations
                  </Typography>
                  <CardContent>
                    <ul style={{ padding: 0 }}>
                      <li className="list-item">
                        <CakeIcon /> 16-02-2024 - Pradeep Tomar
                      </li>
                      <li className="list-item">
                        <CakeIcon /> 18-02-2024 - Vishak K  Gupta
                      </li>
                      <li className="list-item">
                        <CakeIcon /> 25-02-2024 - Abhishek Kumar
                      </li>
                      <li className="list-item">
                        <CakeIcon /> 05-03-2024 - Akash QA
                      </li>
                      <li className="list-item">
                        <CakeIcon /> 05-03-2024 - Kamal Kant
                      </li>

                      {/* Add more li items as needed */}
                    </ul>
                  </CardContent>
                </Card>
              </div>
              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "25px 30px",
                    padding: "10px 40px",
                    backgroundColor: "#e9ecef",
                  }}
                >
                  <Typography level="title-md" style={{ fontSize: "1.2rem" }}>
                    <FormatListBulletedIcon /> Attendance Records{" "}
                  </Typography>


                  <CardContent>
                    <ul>
                      <li>
                        <AccessAlarmIcon />
                        Total Hours: {attendanceInfo.totalHours}
                      </li>
                      <li>
                        <HourglassBottomIcon />
                        Overtime Hours: {attendanceInfo.overtimeHours}
                      </li>
                      <li>
                        <ArrowOutwardIcon />
                        Punch In Time : {attendanceInfo.punchInTime}
                      </li>
                      <li>
                        <CallReceivedIcon />
                        Punch Out Time: {attendanceInfo.punchOutTime}
                      </li>
                    </ul>

                    <Button
                      onClick={() => history.push(`/dashboard/payroll/Attendance-Master`)}
                      style={{
                        padding: "5px 6px",
                        backgroundColor: "rgb(14, 63, 108)",
                        color: "white",
                      }}
                    >
                      Show More
                    </Button>
                  </CardContent>
                </Card>
              </div>
              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "30px 30px",
                    padding: "10px 40px",
                    backgroundColor: "#e9ecef",
                  }}
                >
                  <Typography level="title-md" style={{ fontSize: "1.2rem" }}>
                    <PaymentsIcon /> Payroll
                  </Typography>
                  <CardContent>
                    <ul>
                      <li>
                        <AccountBalanceIcon />
                        Bank Account: {payrollInfo.bankAccount}
                      </li>
                      <li>
                        <PaidIcon />
                        Tax Rate: {payrollInfo.taxRate}
                      </li>
                      <li>
                        <CurrencyRupeeIcon /> Salary:{" "}
                        {showSalary ? payrollInfo.salary : "XXXXX"}
                        {showSalary && (
                          <span className="eye-icon" onClick={toggleShowSalary}>
                            <RemoveRedEyeIcon />
                          </span>
                        )}
                        {!showSalary && (
                          <span className="eye-icon" onClick={toggleShowSalary}>
                            <VisibilityOffIcon />
                          </span>
                        )}
                      </li>
                    </ul>
                    <Button
                      style={{
                        padding: "5px 6px",
                        backgroundColor: "rgb(14, 63, 108)",
                        color: "white",
                      }}
                    >
                      Show More
                    </Button>
                  </CardContent>
                </Card>
              </div>
              <div className="card">
                <Card
                  variant="outlined"
                  style={{
                    margin: "30px 30px",
                    padding: "10px 40px",

                    backgroundColor: "#e9ecef",
                    height: "227px",
                    overflowY: "scroll",
                    scrollbarWidth: "thin",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1.2rem",
                    }}
                  >
                    <EmojiEmotionsIcon style={{ marginRight: "10px" }} />
                    Upcoming Holidays
                  </Typography>
                  <CardContent>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {/* {upcomingHolidays.map((holiday: any, index: number) => (
                        <li key={index}>
                          {holiday.name} - {holiday.date}
                        </li>
                      ))} */}
                      {holidayList &&
                        holidayList.length > 0 &&
                        holidayList?.map((holiday: any, index: number) => (
                          <li key={index}>
                            {holiday.holiday_name} {holiday.is_optional ? "(Optional)" : ""} - {holiday.date}
                          </li>
                        ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <section className="user-sec">
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px ",
            }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <div className="user-Section">
                <div className="inner-cont">
                  <div className="user-info " onClick={handleUserData}>
                    <button id="info-btn">Personal Info</button>
                  </div>
                  <div className="user-work" onClick={handleProfileSection}>
                    <button id="work">Work Profile</button>
                  </div>
                  <div className="user-doc" onClick={handleDocuments}>
                    <button id="doc">Documents</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>

        {showSection.showUserdata && (
          <section id="user-details">
            <Grid
              container
              spacing={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className="ud-container"
                  style={{
                    display: "flex",
                    width: "initial",
                    margin: "3rem 3rem",
                  }}
                >
                  <div className="ud-left-outer">
                    <div className="ud-left-inner">
                      <div className="us-about" onClick={handleShowAbout}>
                        <PersonIcon />
                        <h6>About</h6>
                      </div>

                      <div className="us-address" onClick={handleShowAdd}>
                        <CallIcon />
                        <h6>Address</h6>
                      </div>

                      <div className="us-contact" onClick={handleContact}>
                        <PermContactCalendarIcon />
                        <h6>Contact no.</h6>
                      </div>

                      <div className="us-biodata" onClick={handleShowBiodata}>
                        <DescriptionIcon />
                        <h6>Biodata</h6>
                      </div>

                      <div className="us-dates" onClick={handleShowDoB}>
                        <CalendarMonthIcon />
                        <h6>Important dates</h6>
                      </div>

                      <div className="us-dependency" onClick={handleShowDep}>
                        <Groups2Icon />
                        <h6>Dependency</h6>
                      </div>
                    </div>
                  </div>

                  <div className="middle">
                    <div className="scroll-vertical"></div>
                  </div>

                  <div className="ud-right">
                    <div className="ud-right-inner">
                      {/* <div className="edit-btn">
                    <EditIcon/>
                </div> */}
                      <div className="edit-bio">
                        <div className="user-biodata">
                          {/* Show About */}
                          {showProperty.showAbout && (
                            <div
                              className="user-about"
                              onClick={handleShowAbout}
                            >
                              <div className="sltn">
                                <h6>Salutation</h6>
                                <p>-</p>
                              </div>
                              <div className="firstName">
                                <h6>First Name</h6>
                                <p>Akshat Verma </p>
                              </div>
                              <div className="middleName">
                                <h6> Middle Name</h6>
                                <p>-</p>
                              </div>
                              <div className="lastName">
                                <h6> Last Name</h6>
                                <p> -</p>
                              </div>
                              <div className="preferred">
                                <h6>Preffered Name (Optonal)</h6>
                                <p>-</p>
                              </div>

                              <div className="about">
                                <h6> About Yourself (Optonal)</h6>
                                <p>-</p>
                              </div>
                            </div>
                          )}

                          {/* Show Address  */}
                          {showProperty.showAdd && (
                            <div className="user-add" onClick={handleShowAdd}>
                              <div
                                className="add-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="add-details">
                                  <h3>Address Details</h3>
                                  <div className="add-head">
                                    <h6>Add your address!</h6>
                                    {showProperty.updateAdd ? (
                                      <div
                                        className="updateAdd"
                                        onClick={handleUpdateAdd}
                                      >
                                        <div className="edit-add-container">
                                          <div className="edit-add-body">
                                            <div className="add-type">
                                              <h6>Address</h6>
                                              {/* <MultipleSelectPlaceholder /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          className="add-btn"
                                          onClick={handleUpdateAdd}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="location"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="add-img"
                                      src="	https://redcliffelabs.sumhr.io/static/media/address.fa5f1936.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Show Contact */}
                          {showProperty.showContact && (
                            <div className="user-cont" onClick={handleContact}>
                              <div
                                className="cont-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="cont-dtl">
                                  <h3>Contact Details</h3>
                                  <div className="cont-head">
                                    <h6>Add your Contact number here!</h6>
                                    <button className="cont-btn">Add</button>
                                  </div>
                                </div>

                                <div
                                  className="cont-no"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="cont-img"
                                      src="	https://redcliffelabs.sumhr.io/static/media/contact.4c8fb414.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Show Biodata */}

                          {showProperty.showBiodata && (
                            <div
                              className="user-bio"
                              onClick={handleShowBiodata}
                            >
                              <div className="gender">
                                <h6>Gender</h6>
                                <p>Male</p>
                              </div>
                              <div className="bd-group">
                                <h6>Blood Group(Optional)</h6>
                                <p>-</p>
                              </div>
                              <div className="pronoun">
                                <h6> Gender pronoun (Optional)</h6>
                                <p>-</p>
                              </div>
                              <div className="challenge">
                                <h6>Challenged (Optional)</h6>
                                <p>-</p>
                              </div>
                              <div className="status">
                                <h6>Marital Status(Optonal)</h6>
                                <p>-</p>
                              </div>
                              <div className="spouse">
                                <h6>Your Spouse/Partner's Name(Optonal)</h6>
                                <p>-</p>
                              </div>
                            </div>
                          )}

                          {/* Show DoB */}
                          {showProperty.showDoB && (
                            <div className="user-dob" onClick={handleShowDoB}>
                              <div className="b-date">
                                <h6>Birth Date</h6>
                                <p>01-04-2003</p>
                              </div>
                              <div className="partner-dob">
                                <h6>Partner Birth Date(Optional)</h6>
                                <p>-</p>
                              </div>

                              <div className="anniversary">
                                <h6>Marriage Anniversary(Optional)</h6>
                                <p>-</p>
                              </div>
                            </div>
                          )}

                          {/* Show Dependency  */}
                          {showProperty.showDep && (
                            <div className="user-dep" onClick={handleShowDep}>
                              <div
                                className="dep-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="dep-details">
                                  <h3>Family Dependents!</h3>
                                  <div className="dep-head">
                                    <h6>A little about the people you love</h6>
                                    <button className="dep-btn">Add</button>
                                  </div>
                                </div>
                                <div
                                  className="dependency"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="dep-img"
                                      src="	https://redcliffelabs.sumhr.io/static/media/dependents.95c55efd.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        )}

        {showSection.showUserProfile && (
          <section id="user-profile, user-details">
            <Grid
              container
              spacing={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className="ud-container"
                  style={{
                    display: "flex",
                    width: "initial",
                    margin: "3rem 3rem",
                  }}
                >
                  <div className="ud-left-outer">
                    <div className="ud-left-inner">
                      <div
                        className="us-about work-details"
                        onClick={handleWorkDet}
                      >
                        <PersonIcon />
                        <h6>Work Details</h6>
                      </div>

                      <div
                        className="us-address rep-office"
                        onClick={handleRepOffice}
                      >
                        <CallIcon />
                        <h6>Reporting Office</h6>
                      </div>

                      <div
                        className="us-contact us-current-exp"
                        onClick={handleCurrExp}
                      >
                        <PermContactCalendarIcon />
                        <h6>Current Experience</h6>
                      </div>

                      <div
                        className="us-biodata past-exp"
                        onClick={handlePastExp}
                      >
                        <DescriptionIcon />
                        <h6>Past Experience</h6>
                      </div>
                    </div>
                  </div>
                  <div className="middle">
                    <div className="scroll-vertical"></div>
                  </div>

                  <div className="ud-right">
                    <div className="ud-right-inner">
                      <div className="edit-bio">
                        <div className="user-biodata">
                          {/* Show Work Profiles */}
                          {profileProperty.workDet && (
                            <div
                              className="user-about work-profile"
                              onClick={handleWorkDet}
                            >
                              <div className="sltn doj">
                                <h6>Date Of Joining</h6>
                                <p>-</p>
                              </div>
                              <div className="firstName emptStg">
                                <h6>Employment Stage</h6>
                                <p>-</p>
                              </div>
                              <div className="middleName emptype">
                                <h6>Employment Type</h6>
                                <p>-</p>
                              </div>
                              <div className="lastName emptGrade">
                                <h6> Employment Grade</h6>
                                <p> -</p>
                              </div>
                              <div className="preferred probEndDate">
                                <h6>Probation End Date</h6>
                                <p>04-08-2024</p>
                              </div>

                              <div className="about doConf">
                                <h6>Date Of Confirmation</h6>
                                <p>-</p>
                              </div>

                              <div className="npStart">
                                <h6>Notice Period Start Date</h6>
                                <p>-</p>
                              </div>

                              <div className="extDate">
                                <h6>Exit Date</h6>
                                <p>-</p>
                              </div>
                            </div>
                          )}

                          {/* Show Reporting Address  */}
                          {profileProperty.repOffice && (
                            <div className="user-add" onClick={handleRepOffice}>
                              <div
                                className="add-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="add-details rep-location-cont">
                                  <div
                                    className="add-head  rep-location "
                                    style={{ paddingTop: "0", marginTop: "0" }}
                                  >
                                    <h5>Here's Your address!</h5>

                                    <div className="rep-city-cont">
                                      <div className="rep-city">
                                        <div className="ofc-city">
                                          <p>Noida H-11</p>
                                        </div>
                                        <div className="ofc-street">
                                          <p>
                                            H-11, Electroniv City, Sector-63{" "}
                                          </p>
                                        </div>
                                        <div className="city-name">
                                          <p>Noida</p>
                                        </div>
                                        <div className="ofc-state">
                                          <p>Uttar Pradesh </p>
                                        </div>
                                        <div className="city-pin">
                                          <p>201301</p>
                                        </div>
                                        <div className="ofc-country">
                                          <p>India</p>
                                        </div>
                                        <div className="dep-head">
                                          <button className="dep-btn loc-btn">
                                            Current
                                          </button>
                                        </div>
                                        <hr />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="location"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="add-img"
                                      src="	https://redcliffelabs.sumhr.io/static/media/address.fa5f1936.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Show Contact */}
                          {profileProperty.currExp && (
                            <div className="user-cont " onClick={handleCurrExp}>
                              <div
                                className="cont-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="cont-dtl user-curr-exp">
                                  <h3>Current Experience!</h3>
                                  <div className="cont-head">
                                    <div className="user-exp">
                                      <div className="designation">
                                        <p>Intern</p>
                                      </div>
                                      <div className="ofc-name">
                                        <p>Red Cliffe Labs Private Limited</p>
                                      </div>
                                      <div
                                        className="user-duration"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p>06-02-2024</p>
                                        <p>to</p>
                                        <p>Present</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="cont-no"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="cont-img"
                                      src="	https://redcliffelabs.sumhr.io/static/media/contact.4c8fb414.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Show Past EXperience */}
                          {profileProperty.pastExp && (
                            <div className="user-dep" onClick={handlePastExp}>
                              <div
                                className="dep-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="dep-details">
                                  <h3>Your Past Experiences!</h3>
                                  <div className="dep-head">
                                    <h6 style={{ lineBreak: "auto" }}>
                                      Success and failure is nothing but
                                      experience. Tell us more about your
                                      experience below.
                                    </h6>
                                    <button className="dep-btn">Add</button>
                                  </div>
                                </div>
                                <div
                                  className="dependency"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="dep-img"
                                      src="https://redcliffelabs.sumhr.io/static/media/work-experience.e387d663.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        )}

        {showSection.showDocs && (
          <section id="user-profile, user-details">
            <Grid
              container
              spacing={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  className="ud-container"
                  style={{
                    display: "flex",
                    width: "initial",
                    margin: "3rem 3rem",
                  }}
                >
                  <div className="ud-left-outer">
                    <div className="ud-left-inner">
                      <div
                        className="us-about work-details"
                        onClick={handleIdentity}
                      >
                        <PersonIcon />
                        <h6>Identity</h6>
                      </div>

                      <div
                        className="us-address rep-office"
                        onClick={handleUsersOther}
                      >
                        <CallIcon />
                        <h6>others</h6>
                      </div>
                    </div>
                  </div>
                  <div className="middle">
                    <div className="scroll-vertical"></div>
                  </div>

                  <div className="ud-right">
                    <div className="ud-right-inner">
                      <div className="edit-bio">
                        <div className="user-biodata">
                          {showDocuments.userIdentity && (
                            <div className="user-dep" onClick={handleIdentity}>
                              <div
                                className="dep-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="dep-details">
                                  <h3>Identity Documents Details</h3>
                                  <div className="dep-head">
                                    <h6 style={{ lineBreak: "auto" }}>
                                      Add your documents.
                                    </h6>
                                    <button className="dep-btn">Add</button>
                                  </div>
                                </div>
                                <div
                                  className="dependency"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="dep-img"
                                      src="https://redcliffelabs.sumhr.io/static/media/education.2db0edb1.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {showDocuments.userOthers && (
                            <div
                              className="user-dep user-other-doc"
                              onClick={handleUsersOther}
                            >
                              <div
                                className="dep-div other-doc-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "2rem",
                                }}
                              >
                                <div className="dep-details other-doc">
                                  <h3>Other Documents!</h3>
                                  <div className="dep-head other-doc-head">
                                    <h6 style={{ lineBreak: "auto" }}>
                                      Start by uploading some of your personal
                                      documents
                                    </h6>
                                    <button className="dep-btn other-doc-btn">
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className="dependency"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10rem",
                                    paddingTop: "3rem",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <div>
                                    <img
                                      className="dep-img"
                                      src="https://redcliffelabs.sumhr.io/static/media/education.2db0edb1.svg"
                                      alt="Navigate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        )}

        {(["right"] as const).map((anchor) => (
          <>
            <Drawer
              anchor={anchor}
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box style={{ margin: "70px 20px", padding: "0" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <TextField

                    style={{ width: "280px" }}
                    placeholder="Search"
                  />
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      aria-hidden="true"
                      style={{ fontSize: "24px", color: "gray" }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Yash Gahlot
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      Frontend Intern
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Sumit Verma
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      Backend Developer
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Vinay Shastri
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      Software Engineer
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Kartik Shukla
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      QA Analysis
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Naresh Garg
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      Frontend Intern
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  style={{ margin: "10px 0" }}
                  variant="outlined"
                  orientation="horizontal"
                  sx={{
                    width: 320,
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                  }}
                >
                  <Avatar
                    src="/static/images/avatar/1.jpg"
                    sx={{ "--Avatar-size": "4rem" }}
                  />
                  <CardContent>
                    <Typography level="title-md" id="card-description">
                      Akash Jangid
                    </Typography>
                    <Chip
                      variant="outlined"
                      color="primary"
                      size="sm"
                      sx={{ pointerEvents: "none" }}
                    >
                      Frontend Intern
                    </Chip>
                    <Typography
                      level="body-sm"
                      aria-describedby="card-description"
                      mb={1}
                    >
                      <CallIcon style={{ width: "15px" }} />
                      7427004699
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Drawer>
          </>
        ))}
        {openPdf === true && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPdf}
            onClose={handlePdfModelClose}
            closeAfterTransition
          >
            <Fade in={openPdf}>
              {fileLink?.includes(".jpg") ||
                fileLink?.includes(".jpeg") ||
                fileLink?.includes(".png") ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${fileLink}`}
                    style={{ width: "95vh", height: "80%" }}
                    title="IMAGE Document"
                  />
                </div>
              ) : fileLink?.includes(".doc") || fileLink?.includes(".docx") ? (
                <div id="mera-doc-viewer">
                  <DocViewer
                    style={{ height: "-webkit-fill-available" }}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: fileLink, fileType: "doc" }]}
                  />
                </div>
              ) : (
                <iframe
                  src={`${fileLink}`}
                  style={{ width: "80%", height: "750px" }}
                  title="PDF Document"
                ></iframe>
              )}
            </Fade>
          </Modal>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  holidayList: state.PayrollReducer.holidayList,
});

export default connect(mapStateToProps, {
  getDisplayHolidaysList,
})(CustomerLeadFilterPage);
