import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Switch from "@mui/material/Switch";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
    Button,
} from "@material-ui/core";
import { useEffect } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DeleteModal from "./deleteModule";
import {  postUpdateModule } from "../../actions/EmployeeActions";
import { getCreateModule } from "../../../actions/loginActions";
import CreateModule from "./createModule";
import "./index.css"
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    setOpen: Function;
    getCreateModule: any;
    createModuleData: any;
    postUpdateModule: any;
};

const LearningModal: React.FC<ModalProps> = ({
    open,
    setOpen,
    getCreateModule,
    createModuleData,
    postUpdateModule,
}) => {
    const classes = useStyles();
    const [deleteId, setDeleteId] = useState<any>(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modulePercentage, setModulePassPercentage] = useState<any>(0);
    const [editButton, setEditButton] = useState<boolean>(false);
    const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false);
    const [moduleNames, setModuleName] = useState<String>("");
    const [videoUrl,setVideoUrl]=useState<any>("");
    const [moduleTimeMinutess, setModuleTimeMinutes] = useState<any>(0);
    const [moduleTimeHourss, setModuleTimeHours] = useState<any>(0);
    const [moduleIds,setModuleIds]=useState<any>("");
    const [moduleQuestion, setModuleQuestion] = useState<any>([])
    const [inputListOfQuestionss, setInputListOfQuestions] = useState<any>([
        {
            questionName: "",
            questionActive: true,
            option0: "",
            option0Check: false,
            option1: "",
            option1Check: false,
            option2: "",
            option2Check: false,
            option3: "",
            option3Check: false,
        },
    ]);
    const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const [checkedState, setCheckedState] = useState<any>(
        new Array(createModuleData?.count).fill(false)
    );
    const handleClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     getCreateModule();
    // }, [])


    const handleOpen = (data: any) => {
        setCreateModuleModalOpen(true);
        setEditButton(true);
        setModulePassPercentage(data?.minimum_score);
        setModuleName(data?.name);
        setModuleTimeHours(data?.completion_hours);
        setModuleTimeMinutes(data?.completion_minutes);
        setVideoUrl(data?.video_url);
        setModuleIds(data?.id);
        setModuleQuestion(data?.assignment_question_module)

        setInputListOfQuestions(data?.assignment_question_module?.map((value: any) => (
            {
                questionName: (value.name),
                questionActive: (value.status ==="active"?true:false),
                option0: (value.assignment_answer_question[0].name),
                option0Check: false,
                option1: (value.assignment_answer_question[1].name),
                option1Check: false,
                option2:(value.assignment_answer_question[2].name),
                option2Check: false,
                option3:(value.assignment_answer_question[3].name),
                option3Check: false,
            }
        )));

    };

    const handleChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        id: any
    ) => {
        setCheckedState(event.target.checked);
        let body = {
            status: event.target.checked ? "active" : "in_active",
            id: id,
        };
        await postUpdateModule(body);
       // await getCreateModule();
    };

    // useEffect(() => {
    //     if (open === true) {
    //         getCreateModule();
    //     }
    // }, [open]);


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div>
                            <div className="body" style={{ overflow: "scroll" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        
                                        <h2 style={{ fontSize: "16px", padding: "25px 0 0 15px" }}>Learning Modules</h2>
                                    </div>
                                   
                                    <HighlightOffIcon
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        style={{
                                            position: "relative",
                                            // left: "55%",
                                            height: "40px",
                                            width: "30px",
                                        }}
                                    />
                                </div>
                                <div className="startPageDiv">
                                    {
                                        createModuleData && createModuleData?.count > 0 && createModuleData?.results?.map((row: any, index: any) => (
                                            <div style={{ height: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "20px", marginRight: "5%", marginBottom: "20px" }}>
                                                <div
                                                    className="slide"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <DeleteTwoToneIcon
                                                        onClick={() => {
                                                            setDeleteId(row?.id);
                                                            setDeleteModalOpen(true);
                                                        }}
                                                    />


                                                </div>
                                                <div style={{ textAlign: "center" }}>
                                                    <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px", wordBreak:"break-word" }}>{row?.name !== null ? row?.name : "NO NAME"}</span>
                                                    <p style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>{`${(row?.completion_hours * 60) + row?.completion_minutes} min`}</p>
                                                    <Switch
                                                        checked={row?.status === "active" ? true : false}
                                                        onChange={(e) => handleChange(e, row?.id)}
                                                        color="success"
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                </div>
                                                <div style={{ textAlign: "center" }}>
                                                    <Button style={{
                                                        backgroundColor: "#c70e0ef7",
                                                        color: "white",
                                                        padding: "6px 16px",
                                                        borderRadius: "5px",
                                                        textTransform: "none",
                                                    }}
                                                      
                                                        onClick={() => {

                                                            handleOpen(row);
                                                        }}
                                                        size="small"
                                                    >
                                                        <p style={{ margin: "0", fontSize: "12px" }}> Edit Module</p>
                                                    </Button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <DeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                idToDelete={deleteId}
            />
            <CreateModule
                open={createModuleModalOpen}
                setOpen={setCreateModuleModalOpen}
                modulePercentage={modulePercentage}
                editButton={editButton}
                moduleNames={moduleNames}
                moduleTimeMinutess={moduleTimeMinutess}
                moduleTimeHourss={moduleTimeHourss}
                inputListOfQuestionss={inputListOfQuestionss}
                videoUrl={videoUrl}
                moduleIds={moduleIds}
                moduleQuestion={moduleQuestion}
            />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    createModuleData: state.loginReducer.createModuleData,
});

export default connect(mapStateToProps, {
    getCreateModule, postUpdateModule
})(LearningModal);