import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  TableContainer,
  withStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useHistory } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@mui/material/Avatar";
import "./index.sass";
import {
  getEmployeeList,
  editEmployeeById,
  getEmployeeDepartment,
  getOrganizationsList,
  getEmployeeMyTeamList,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import { generateEmployeeFilter } from "../../../helper/generate";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      position: "absolute",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      position: "absolute",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getEmployeeList: any;
  employeesList: any;
  createEmployee: any;
  editEmployeeById: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  empObj: any;
  getOrganizationsList: any;
  organizationsList: any;
  getEmployeeMyTeamList: any;
  employee_my_team_list: any;
  getEmployeeMode: any;
  get_Employee_Mode: any;
  getReportingManager: any;
  reporting_manager: any;
  loading: any;
  getShiftList: any;
  shiftList: any;
  getEmployeeSubDept: any;
  subDeptName: any;
  getDesignationtList: any;
  designationList: any;
  getExportEmployeeList: any;
  exportList: any;
  getUserProfileImage: any;
  getProfileImage: any;
  updateImage: any;
}
const CreateEmployeetTablePage: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  createEmployee,
  editEmployeeById,
  getEmployeeDepartment,
  departmentNames,
  getOrganizationsList,
  organizationsList,
  empObj,
  employee_my_team_list,
  getEmployeeMyTeamList,
  getEmployeeMode,
  get_Employee_Mode,
  getReportingManager,
  reporting_manager,
  loading,
  getShiftList,
  shiftList,
  getEmployeeSubDept,
  subDeptName,
  getDesignationtList,
  designationList,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [employeeEditopen, setEmployeeEditopen] = React.useState(false);
  const history = useHistory();
  ///Employee Create Page
  const [employeeId, setEmployeeId] = useState<any>("");
  const [employeeName, setEmployeeName] = useState<any>("");
  const [employeeEmail, setEmployeeEmail] = useState<any>("");
  const [employeeStatus, setEmployeeStatus] = useState<any>("");
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [employeeManagerName, setEmployeeManagerName] = useState<any>("");
  const [employeeManagerUUId, setEmployeeManagerUUId] = useState<any>("");
  const [employeeManagerId, setEmployeeManagerId] = useState<any>("");
  const [managerCode, setManagerCode] = useState<any>("");
  const [managerEmpNumber, setManagerEmpNumber] = useState<any>("");
  const [managerEmailId, setManagerEmailId] = useState<any>("");

  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [emplManagerId, setEmplManagerId] = useState<any>("");
 
  const [WeekOffDays, setWeekOffDays] = useState<any>("");
  const [PreviousWeekOffDays, setPreviousWeekOffDays] = useState<any>([]);
  const [shiftListData, setshiftListData] = useState<any>("");
  const [userShiftTime, setUserShiftTime] = useState<any>("");
  
  const [employeePhoneNumber, setEmployeePhoneNumber] = useState<any>("");
 
  const [FilterBtn, setFilterBtn] = useState<any>(false);
  const [EmployeePunchInPuchOutLocation, setEmployeePunchInPuchOutLocation] =useState<any>([]);

  useEffect(() => {
    getEmployeeList("my_team=true");
    getEmployeeMyTeamList("");
    getEmployeeDepartment("");
    getOrganizationsList("");
    getEmployeeMode();
    getReportingManager(`is_manager=true`);
    getShiftList();
    getEmployeeSubDept("");
    getDesignationtList();
  }, []);

  const handleCreateEmployee = async () => {
    const body = {
      // username: employeeEmail,
      employee_id: employeeId,
      manager: employeeManagerUUId,
      manager_email_id: managerEmailId,
      fullname: employeeName,
      
      weekly_off_days: WeekOffDays,
      shift: shiftListData,
      employee_work_location: EmployeePunchInPuchOutLocation,
    };

   
    if (shiftListData === "") {
      delete body.shift;
    }
    await editEmployeeById(body, employeeUUID);
    handleResetFunction();
    setEmployeeEditopen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = employeesList.links && employeesList.links.next.split("?")[1];

      getEmployeeList(`${url}`);
    } else if (newPage < page) {
      let url =
        employeesList.links && employeesList.links.previous.split("?")[1];

      getEmployeeList(`${url}`);
    }
    setPage(newPage as number);
  };

  const handleEditFunction = (data: any) => {
    setEmployeeName(data?.fullname);
    setEmployeeUUID(data.uuid);
    setEmployeeId(data?.employee_id);  
    setUserShiftTime(data?.shift);
    setWeekOffDays(data?.weekly_off_days);
    const formattedWeekOffDays = data.weekly_off_days.map((day: any) => ({
      text: day,
      value: day,
    }));
    setPreviousWeekOffDays(formattedWeekOffDays);
   
  };
  const handleResetFunction = () => {
    history.push("/dashboard/employee/my-team");
  };

  const handlFilterEmployee = () => {
    setFilterBtn(true);
    const body: any = {
      employee_id: employeeId,
      department: employeeDept != 240 ? employeeDept : "",
      manager: employeeManagerId,
      employee_status: employeeStatus,
    };
    const url = generateEmployeeFilter(body).substring(2);
    getEmployeeList(`my_team=true&${url}`);
    setPage(0);
  };

   
  const handleAutocompleteChange = (event: any, newValue: any) => {
    if (newValue) {
      let obj = JSON.parse(JSON.stringify(newValue, null, ""));
      setshiftListData(obj.id);
      setUserShiftTime(`Total Hours: ${obj.total_hour}`);
    }
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            backgroundColor: "rgb(28, 78, 128)",
            color: "#fff",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "10px",
            borderRadius: "15px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          Employee List
        </h4>
        <Grid
          container
          spacing={3}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeId(obj.employee_id);
                } else {
                  setEmployeeId("");
                }
              }}
              id="checkboxes-tags-demo"
              options={employee_my_team_list?.results || []}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  newInputValue &&
                    getEmployeeMyTeamList(`employee_id=${newInputValue}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Employee ID Or Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="ticket_status"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setEmployeeStatus(obj?.value);
                } else {
                  setEmployeeStatus("");
                }
              }}
              options={[
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Probation",
                  value: "probation",
                },
                {
                  text: "In Active",
                  value: "inactive",
                },
              ]}
              blurOnSelect
              aria-required
              freeSolo
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return employeeStatus.includes(option.value);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Employee Status "
                  variant="outlined"
                  value={employeeStatus}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              // disabled={employeeId === ""}
              style={{
                backgroundColor: "#1976d2",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onClick={handlFilterEmployee}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#1565c0";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#1976d2";
                e.currentTarget.style.boxShadow = "none";
              }}
              startIcon={<SearchIcon />}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<RotateLeftIcon />}
              style={{
                backgroundColor: "#9c27b0",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#7b1fa2";
                e.currentTarget.style.boxShadow =
                  "0px 2px 4px rgba(0, 0, 0, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#9c27b0";
                e.currentTarget.style.boxShadow = "none";
              }}
              onClick={() => handleResetFunction()}
            >
              Reset
            </Button>
          </Grid>
        </Grid>

        <TableContainer
          className={classes.tableContainer}
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  style={{ width: "fit-content" }}
                >
                  Profile
                </StyledTableCell>
                <StyledTableCell align="center">Edit Employee</StyledTableCell>
                <StyledTableCell align="center">Employee Name</StyledTableCell>
                <StyledTableCell align="center">Employee ID</StyledTableCell>
                <StyledTableCell align="center">Employee Age</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Gender
                </StyledTableCell>
                <StyledTableCell align="center">
                  Employee Designation
                </StyledTableCell>
                <StyledTableCell align="center">Employee email</StyledTableCell>
                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Department{" "}
                </StyledTableCell>
                <StyledTableCell align="center">Joining Date</StyledTableCell>
                <StyledTableCell align="center">
                  Employee Status
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody>
                  {employeesList &&
                    employeesList?.results?.length > 0 &&
                    employeesList?.results?.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            <div style={{ padding: "0px 60px" }}>
                              <Avatar
                                key={data.id}
                                // alt="User Avatar"
                                src={
                                  data.profile_picture
                                    ? data.profile_picture
                                    : "/static/images/avatar/1.jpg"
                                }
                                style={{
                                  margin: "2px auto",
                                  backgroundColor: "rgb(14, 63, 108)",
                                  cursor: "pointer",
                                  border: "1px solid black",
                                  height: "50px",
                                  width: "50px",
                                }}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleEditFunction(data);
                                  setEmployeeEditopen(true);
                                }}
                                startIcon={<EditLocationAltIcon />}
                                style={{
                                  borderRadius: "8px",
                                  padding: "8px 16px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "none",
                                  backgroundColor: "#155a8a", // Blue color
                                  color: "#ffffff",
                                  transition:
                                    "background-color 0.3s, box-shadow 0.3s",
                                  boxShadow: "none",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#1976d2"; // Darker blue on hover
                                  e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#155a8a";
                                  e.currentTarget.style.boxShadow = "none";
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.age ? data?.age : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.gender ? data?.gender : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.employee_designation
                              ? data?.employee_designation
                              : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {data?.email ? data?.email : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.phonenumber ? data?.phonenumber : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.department ? data?.department : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.date_of_joining
                              ? data?.date_of_joining
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.status ? data?.status : "NA"}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </>
            )}

            <TableFooter>
              <StyledTableRow style={{ textAlign: "center" }}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={6}
                  count={employeesList?.count || 0}
                  rowsPerPage={employeesList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={employeeEditopen}
          onClose={() => setEmployeeEditopen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={employeeEditopen}>
            <div className={classes.paper1}>
              <h3
                style={{
                  color: "#0e3f6c",
                }}
              >
                {" "}
                Edit Employee Master
              </h3>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="flex-start"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_id"
                    label="Employee Id"
                    disabled
                    value={employeeId}
                    onChange={(e) => {
                      setEmployeeId(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="employee_name"
                    type="text"
                    label="Employee Name "
                    value={employeeName}
                    required
                    disabled
                    variant="outlined"
                    onChange={(e) => setEmployeeName(e.target.value as string)}
                    inputProps={{ maxLength: 100 }}
                    style={{ width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        setManagerEmpNumber(obj?.employee_id);
                        setEmplManagerId(obj?.employee_id);
                        setEmployeeManagerName(obj?.name);
                        setEmployeeManagerUUId(obj?.uuid);
                      }
                    }}
                    id="reporting-manager"
                    options={reporting_manager?.results || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getReportingManager(`is_manager=true`);
                      }, 1000);
                    }}
                    disabled
                    getOptionLabel={(option: any) => {
                      return option
                        ? option?.employee_id + " " + option.name
                        : employeeManagerName && emplManagerId
                        ? emplManagerId + "" + employeeManagerName
                        : "";
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}>Manager Name:</b>
                          <strong>
                            {option?.name.toString()}
                            <br />
                          </strong>
                          <b style={{ color: "red" }}>Manager Id:</b>
                          {option?.employee_id?.toUpperCase()}
                        </p>
                      );
                    }}
                    defaultValue={
                      (reporting_manager &&
                        reporting_manager?.results?.map(
                          (opt: any) => opt?.employee_id === emplManagerId
                        )?.name) ||
                      ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        disabled
                        label="Manager Name"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="manager_emp_number"
                    type="text"
                    label=" Manager Employee ID"
                    disabled
                    value={managerEmpNumber}
                    onChange={(e) => {
                      setManagerEmpNumber(e.target.value as String);
                    }}
                    inputProps={{ maxlength: 10 }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="manager_email_id"
                    type="text"
                    label="Manager Email id"
                    value={managerEmailId}
                    disabled
                    onChange={(e) =>
                      setManagerEmailId(e.target.value as string)
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={handleAutocompleteChange}
                    id="shift-list"
                    options={shiftList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getShiftList(`name=${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return userShiftTime
                        ? userShiftTime
                        : option.start_time + " To " + option.end_time;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}> Shift :</b>
                          <strong>
                            {option?.start_time} To {option.end_time}
                          </strong>
                          <br />
                          <b style={{ color: "red" }}>Total Hours:</b>
                          {option?.total_hour}
                        </p>
                      );
                    }}
                    defaultValue={userShiftTime ? userShiftTime : null}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Shift Time"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="ticket_status"
                    onChange={(event, newValue) => {
                      let sourceIds: any = [];
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        sourceIds = obj.map((item: any) => item.value);
                      }
                      setWeekOffDays(sourceIds);
                    }}
                    options={[
                      {
                        text: "Monday",
                        value: "Monday",
                      },
                      {
                        text: "Tuesday",
                        value: "Tuesday",
                      },
                      {
                        text: "Wednesday",
                        value: "Wednesday",
                      },
                      {
                        text: "Thursday",
                        value: "Thursday",
                      },

                      {
                        text: "Friday",
                        value: "Friday",
                      },
                      {
                        text: "Saturday",
                        value: "Saturday",
                      },
                      {
                        text: "Sunday",
                        value: "Sunday",
                      },
                    ]}
                    freeSolo
                    defaultValue={PreviousWeekOffDays}
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    multiple
                    getOptionLabel={(option: any) => option.text}
                    getOptionDisabled={(option: any) => {
                      return WeekOffDays.includes(option.value);
                    }}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Week Off "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#5d4037" }}
                    fullWidth
                    startIcon={<SyncAltIcon />}
                    onClick={handleCreateEmployee}
                    disabled={
                      employeeName === "" &&
                      employeeId === "" &&
                      employeeDept === "" &&
                      employeeEmail === "" &&
                      employeePhoneNumber === ""
                    }
                  >
                    {"Update Employee"}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0e3f6c" }}
                    fullWidth
                    onClick={() => setEmployeeEditopen(false)}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  loading: state.loginReducer.loading,
  organizationsList: state.loginReducer.organizationsList,
  departmentNames: state.loginReducer.departmentNames,
  employee_my_team_list: state.loginReducer.employee_my_team_list,
  get_Employee_Mode: state.loginReducer.get_Employee_Mode,
  reporting_manager: state.loginReducer.reporting_manager,
  shiftList: state.loginReducer.shiftList,
  subDeptName: state.loginReducer.subDeptName,
  designationList: state.loginReducer.designationList,
  exportList: state.loginReducer.exportList,
  getProfileImage: state.PayrollReducer.getProfileImage,
  locationList: state.loginReducer.locationList,
});
export default connect(mapStateToProps, {
  getEmployeeList,
  getOrganizationsList,
  getEmployeeDepartment,
  editEmployeeById,
  getEmployeeMyTeamList,
  getEmployeeMode,
  getReportingManager,
  getShiftList,
  getDesignationtList,
  getEmployeeSubDept,
  getExportEmployeeList,
})(CreateEmployeetTablePage);
