import React from "react";
import loader from "../Loader2/loader.gif";

function Loader() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#4a4a4a",
        fontFamily: "'Roboto', sans-serif",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(5px)",
      }}
    >
      <img src={loader} alt="Loading" style={{ marginBottom: "20px" }} />
      <span style={{ fontSize: "24px", fontWeight: "600" }}>
        Preparing data...
      </span>
    </div>
  );
}

export default Loader;
