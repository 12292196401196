import React, { useEffect } from "react";
import { checkUser } from "../../actions/loginActions";
import { connect } from "react-redux";
import CheckRoles from "./checkRoles";
// import PushNotification from "../../newcomponents/notification";

interface Props {
  checkUser: Function;
  userProfile: any;
  user: any;
  path: any;
  query: any;

}

const Dash = ({ checkUser, user, path, query, userProfile }: Props) => {

  useEffect(() => {
    checkUser();
  }, []);
  const returnTOLogin = () => {
    window.location.replace("/");
  };
  return (
    <div>
      {user.user ? (
        <CheckRoles role={user.user.user_group} path={path} query={query} username={userProfile?.user?.username} />
      ) : (
        <div style={{ width: "32px", height: "32px" }}>
          <div style={{ width: "32px", height: "32px" }}>{returnTOLogin}</div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  user: state.loginReducer.user,
  userProfile: state.loginReducer.userProfile,
});
export default connect(mapStateToProps, { checkUser })(Dash);