import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { postRegularizationRequest } from "../../../actions/loginActions";
import { connect, useDispatch } from "react-redux";
import { getEmployeeList } from "../../../actions/loginActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@material-ui/core";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import Paper from "@mui/material/Paper";
// import { MapContainer, TileLayer, useMap,Marker,Popup } from 'react-leaflet'
// import {
//   MapContainer,
//   TileLayer,
//   useMap,
//   Marker,
//   Popup,
// } from 'https://cdn.esm.sh/react-leaflet'

import { TextField, Typography, withWidth } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgba(14, 63, 108, 0.1)",
      color: "rgba(42, 42, 42, 1)",
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 16,
      fontWeight: "bolder",
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
function fakeFetch(date: Dayjs, { signal }: { signal: AbortSignal }) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs("2022-04-17");

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      style={{ color: "Red" }}
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "A" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    rightContentHeader: {
      float: "right",
      textAlign: "right",
      paddingRight: "50px",
    },
    textField: {
      width: "340px",
      borderRadius: "15px",
      height: "50px",

      backgroundColor: "rgba(242, 242, 242, 1)",
      color: "rgba(167, 167, 167, 1)",
      "& .MuiOutlinedInput-root:invalid": {
        height: "55px",
        borderRadius: "15px",
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgba(167, 167, 167, 1)",
          height: "55px",
          borderRadius: "15px",
        },

        "&:hover fieldset": {
          borderColor: "rgba(167, 167, 167, 1)",
        },
      },
    },

    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
      background: "#0E3F6C",
    },
  })
);

interface Props {
  postRegularizationRequest: Function;
  getEmployeeList: Function;
  employeesList: any;
}

const Regularization: React.FC<Props> = ({
  postRegularizationRequest,
  getEmployeeList,
  employeesList,
}) => {
  const [regularizationCategory, setRegularizationCategory] =
    useState("work_from_home");
  const [durationtype, setDurationType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [overlap, setOverlap] = useState("");
  const [reason, setReason] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [employeeUUID, setEmployeeUUID] = useState<any>(employeesList[0]?.uuid);
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);
  const [value, setValue] = React.useState(0);
  const [attendanceOpen, setAttendanceOpen] = useState<any>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
  // const [selectedDateInfo, setSelectedDateInfo] = useState<{ [key: string]: { from: string; to: string; reason: string } }>({});
  // Other state variables...
  //  const handleDateSelect = (date: Dayjs | null) => {
  //   if (date) {
  //     // Prompt the user to fill in "from", "to", and "reason"
  //     const from = window.prompt("Enter 'from' time for this date (e.g., 09:00 AM):");
  //     const to = window.prompt("Enter 'to' time for this date (e.g., 05:00 PM):");
  //     const reason = window.prompt("Enter reason for this date:");

  //     // Add the selected date along with its values to the object
  //     setSelectedDateInfo(prev => ({
  //       ...prev,
  //       [date.format("DD-MM-YYYY")]: { from: from || "", to: to || "", reason: reason || "" }
  //     }));
  const fetchHighlightedDays = (date: Dayjs) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };
  const handleDateSelect = (date: Dayjs | null) => {
    if (date) {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleRemoveDate = (index: number) => {
    const updatedDates = [...selectedDates];
    updatedDates.splice(index, 1);
    setSelectedDates(updatedDates);
  };

  useEffect(() => {
    fetchHighlightedDays(initialValue);
    return () => requestAbortController.current?.abort();
  }, []);

  useEffect(() => {
    getEmployeeList("");
    setEmployeeUUID(employeesList.uuid);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      duration_type: durationtype,
      regularization_category: regularizationCategory,
      from_date: fromDate,
      to_date: toDate,
      comments: reason,
    };
    await postRegularizationRequest(data);
    setRegularizationCategory("");
    setDurationType("");
    setFromDate("");
    setToDate("");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      {/* <div>
      <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[51.505, -0.09]}>
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer>

      </div> */}

      
      <h1
        className="mainHeading"
        style={{
          width: "177px",
          height: "36px",
          top: "90px",
          left: "200px",
          color: "#2A2A2A",
          fontFamily: "Poppins",
        }}
      >
        Regularization
      </h1>
      <div style={{ marginTop: 30, display: "flex", padding: "0 16px" }}>
        <Paper
          elevation={3}
          style={{
            height: "374px",
            width: "449",
            padding: " 0 16px",
            border: "1px solid #d9dadb",
            backgroundColor: "#FFFFFF",
            borderRadius: "15px",
          }}
        >
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={selectedDate}
                onChange={handleDateSelect}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  } as any,
                }}
              />
            </LocalizationProvider>
          </div>
        </Paper>

        <div>
          <Paper
            elevation={3}
            style={{
              height: "227px",
              width: "673px",
              top: "148px",
              left: "710",
              backgroundColor: "#fff",
              borderRadius: "15px",
              margin: "0 px, 20px",
              marginLeft: "15px",
            }}
          >
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary="Rohit Kumar"
                secondary={
                  <Typography component="span" variant="body2">
                    <span>#123456 {}</span>
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Remarks"
                secondary={
                  <Typography component="span" variant="body2">
                    <TextField
                      className="input"
                      name="reason"
                      type="text"
                      placeholder="Type Here"
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                    />
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </Paper>

          {selectedDates.map((date, index) => (
            <Paper
              elevation={3}
              style={{
                height: "227px",
                width: "673px",
                top: "148px",
                left: "710",
                backgroundColor: "#fff",
                borderRadius: "15px",
                margin: "0 px, 20px",
                marginLeft: "15px",
                marginTop: "15px",
              }}
            >
              <h2
                style={{
                  marginLeft: "15px",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">
                  {date.format("DD-MMMM-YYYY")}
                </Typography>
                <IconButton onClick={() => handleRemoveDate(index)}>
                  <CloseIcon />
                </IconButton>
              </h2>
              <TableContainer>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>FROM</StyledTableCell>
                      <StyledTableCell>TO</StyledTableCell>
                      <StyledTableCell>REASON</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        {/* <Card
                        style={{
                          width: "130px",
                          borderRadius: "15px",
                          height: "66px",
                          padding: "0px 0px 0px 0px",
                          backgroundColor: "rgba(242, 242, 242, 1)",
                          color: "rgba(167, 167, 167, 1)",
                        }}
                      >
                        <CardContent>
                          <input
                            style={{
                              backgroundColor: "rgba(242, 242, 242, 1)",
                              color: "rgba(167, 167, 167, 1)",
                              width: "98px",
                              borderRadius: "15px",
                              height: "33px",
                              padding: "3px 7px 2px 12px",
                            }}
                            type="time"
                            value={fromTime}
                            onChange={(e) => setFromTime(e.target.value)}
                          />
                        </CardContent>
                      </Card> */}
                        <input
                          style={{
                            width: "110px",
                            borderRadius: "15px",
                            height: "50px",
                            padding: "10px 10px 10px 10px",
                            backgroundColor: "rgba(242, 242, 242, 1)",
                            color: "rgba(167, 167, 167, 1)",
                          }}
                          type="time"
                          value={fromTime}
                          onChange={(e) => setFromTime(e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <input
                          style={{
                            width: "110px",
                            borderRadius: "15px",
                            height: "50px",
                            padding: "10px 10px 10px 10px",
                            backgroundColor: "rgba(242, 242, 242, 1)",
                            color: "rgba(167, 167, 167, 1)",
                          }}
                          type="time"
                          value={toTime}
                          onChange={(e) => setToTime(e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* <Card
                        sx={{ width: 81 }}
                        style={{
                          width: "315px",
                          borderRadius: "15px",
                          height: "66px",
                          padding: "0px 0px 0px 0px",
                          backgroundColor: "rgba(242, 242, 242, 1)",
                          color: "rgba(167, 167, 167, 1)",
                        }}
                      >
                        <CardContent>
                          <TextField
                            style={{
                              backgroundColor: "rgba(242, 242, 242, 1)",
                              color: "rgba(167, 167, 167, 1)",
                              width: "285px",
                              borderRadius: "15px",
                              borderColor: "rgba(242, 242, 242, 1)",
                              height: "33px",
                              padding: "-1px 7px 2px 12px",
                            }}
                            className="input"
                            name="reason"
                            type="text"
                            required
                            placeholder="Type Here"
                            variant="outlined"
                          />
                        </CardContent>
                      </Card> */}
                        <textarea
                          className={classes.textField}
                          placeholder="    Type Here"
                          required
                          // style={{
                          //   width: "340px",
                          //   borderRadius: "15px",
                          //   height: "50px",
                          //   padding: "0px 0px 0px 0px",
                          //   backgroundColor: "rgba(242, 242, 242, 1)",
                          //   color: "rgba(167, 167, 167, 1)",
                          // }}
                          // value={reason}
                          // onChange={(e) => setReason(e.target.value)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ))}
        </div>
      </div>
    </main>
  );
};

// export default Regularization;
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
});

export default connect(mapStateToProps, {
  postRegularizationRequest,
  getEmployeeList,
})(Regularization);
