import React from "react";
import PayrollDashboardLeft from "../../PayrollManager-Panel/dashboard-left/index";
import PayrollDashboardRight from "../../PayrollManager-Panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const HRMSDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <PayrollDashboardLeft />
            <PayrollDashboardRight>{children && children}</PayrollDashboardRight>
        </div>
    );
};

export default HRMSDash;