import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid, TextField, MenuItem, Checkbox, Select, FormControl, InputLabel, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLocationList } from "../../../actions/loginActions";
import { AddEmploymentDetails, EditEmploymentDetails, getEmploymentDetails } from "../../actions/PayrollActions";
import { AnyObject } from "yup";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { CancelOutlined } from "@mui/icons-material";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid green",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "700px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    setOpenEditExpModel: Function;
    openEditExpModel: boolean;
    locationList:any;
    getLocationList:any;
    loading:boolean;
    getEmploymentDetails:any;
    selectedData:any;
    EditEmploymentDetails:any;
};

const EditExperienceModal: React.FC<ModalProps> = ({
    setOpenEditExpModel,
    openEditExpModel,
    locationList,
    getLocationList,
    loading,
    getEmploymentDetails,
    selectedData,
    EditEmploymentDetails,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");
    const [location, setLocation] = useState<any>();
    const [title, setTitle] = useState<any>("");
    const [employmentType, setEmploymentType] = useState<any>("none");
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState<any>(false);
    const [description, setDescription] = useState<any>("")
    const [companyName,setCompanyName] = useState<any>("")
    const [file, setFile] = React.useState<any>();
    const [view,setView] = useState<any>(false)
    const [edit,setEdit] = useState<any>(false)
    const [errors, setErrors] = useState({
        title: false,
        employmentType: false,
        location: false,
        startDate: false,
        endDate: false,
        companyName:false,
        fileSize: false,
        endDateBeforeStart: false,
    });
    const handleClose = () => {
        setOpenEditExpModel(false);
    };

    useEffect(()=>{
        if(selectedData){
            setCompanyName(selectedData?.company_name);
            setTitle(selectedData?.job_title);
            setEmploymentType(selectedData?.employment_type);
            setLocation(selectedData?.location);
            setIsCurrentlyWorking(selectedData?.is_currently_working);
            setStart_date(selectedData?.start_date?.slice(0, 7));
            !selectedData?.is_currently_working && setEnd_date(selectedData?.end_date !== null? selectedData?.end_date?.slice(0, 7) : "");
            setDescription(selectedData?.description);
        }

    },[selectedData])

    const fileSelectedHandler = async (e: React.ChangeEvent<{ files: any }>) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 10 * 1024 * 1024) {
            setErrors((prev) => ({ ...prev, fileSize: true }));
        } else {
            setFile(selectedFile);
            setErrors((prev) => ({ ...prev, fileSize: false }));
        }
    };

const validateForm = () => {
    const newErrors = {
        title: !title.trim(),
        employmentType: employmentType === "none",
        location: !location,
        startDate: !start_date,
        endDate: !isCurrentlyWorking && (!end_date || end_date <= start_date),
        companyName: !companyName.trim(),
        fileSize: file && file.size > 10 * 1024 * 1024,
        endDateBeforeStart: !isCurrentlyWorking && end_date < start_date,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).includes(true);
};
    const handleSubmit = async() =>{
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        const formattedStartDate = `${start_date}-01`;
        const formattedEndDate = (end_date !== "" && end_date !== null) ? `${end_date}-01` : "";
        const formData = new FormData();
        formData.append("job_title", title);
        formData.append("employment_type", employmentType);
        formData.append("company_name", companyName);
        formData.append("location", location);
        formData.append("is_currently_working", isCurrentlyWorking);
        formData.append("start_date", formattedStartDate);
        formData.append("end_date",formattedEndDate)
        formData.append("description", description);
        if(file){
            formData.append("document", file);
        }
        
       await EditEmploymentDetails(formData,selectedData?.id);
       setOpenEditExpModel(false);
       await getEmploymentDetails()
    }

    const handlePdfModelClose = () => {
        setView(false);
      };

    const blockCharInAutocompleteField = (e: React.KeyboardEvent<HTMLDivElement>) => {
        return ("~!@#$%^&*()_+{}|:<>?,./;'[]\=-0987654321`").includes(e.key) && e.preventDefault();
      }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openEditExpModel}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openEditExpModel}>
                <div className={classes.paper}>
                    <>
                        <h4 style={{ display: "flex", justifyContent: 'center' }}>Edit Experience</h4>
                        <Grid container spacing={2} direction="row" style={{ marginTop: "20px" }}>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="title"
                                    id="title"
                                    value={title}
                                    onChange={(e:any)=>{
                                        setTitle((e.target.value))
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, title:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, title:true
                                            })
                                        }
                                        
                                    }}
                                    required
                                    className="input"
                                    label="Title"
                                    variant="outlined"
                                    error={errors.title}
                                    helperText={errors.title && "Title is required"}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="company name"
                                    onChange={(e:any)=>{setCompanyName(e.target.value);
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, companyName:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, companyName:true
                                            })
                                        }}}
                                    id="company_name"
                                    value={companyName}
                                    required
                                    className="input"
                                    label="Company name"
                                    error={errors.companyName}
                                    helperText={errors.companyName && "Company name is required"}
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                            <Select
                                aria-required
                                className="input"
                                variant="outlined"
                                style={{ width: "100%", height:"40px"}}
                                label="Employment type"
                                value={employmentType}
                                required
                                onChange={(e:any) => {
                                    setEmploymentType(e.target.value);
                                    if(e.target.value !== "none"){
                                        setErrors({
                                            ...errors, employmentType:false
                                        })
                                    }else{
                                        setErrors({
                                            ...errors, employmentType:true
                                        })
                                    }
                                }}
                                error={errors.employmentType}
                                >
                                <MenuItem value={"none"} disabled>Employment type</MenuItem>
                                <MenuItem value={"full_time"}>Full Time </MenuItem>
                                <MenuItem value={"part_time"}>Part Time</MenuItem>
                                <MenuItem value={"contractor"}>Contractor</MenuItem>
                                <MenuItem value={"intern"}>Intern</MenuItem>
                            </Select>
                            {errors.employmentType && <p className="validationError">Employment type is required</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    onChange={(
                                        event,
                                        obj
                                    ) => {
                                        if (obj) {
                                        const loc =
                                            obj.location;
                                        setLocation(loc);
                                        setErrors({
                                            ...errors, location:false
                                        })
                                        
                                        }else{
                                            setLocation("");
                                            setErrors({
                                                ...errors, location:true
                                            })
                                        }
                                    }}
                                    id={"City"}
                                    options={
                                        locationList || []
                                    }
                                    
                                    onInputChange={(
                                        event,
                                        newInputValue
                                    ) => {
                                        clearTimeout(
                                        timer.current
                                        );
                                        timer.current =
                                        setTimeout(() => {
                                            getLocationList(
                                            `name=${newInputValue}`
                                            );
                                        }, 1000);
                                    }}
                                    
                                    value={location ? { location: location } : null}
                                    getOptionLabel={(
                                        option: any
                                    ) => {
                                        return option.location;
                                    }}
                                    renderOption={(
                                        option: any
                                    ) => {
                                        return option.location;
                                    }}
                                    renderInput={(
                                        params
                                    ) => (
                                        <TextField
                                        className="input"
                                        {...params}
                                        label="Location"
                                        required
                                        variant="outlined"
                                        error={errors.location}
                                        helperText={errors.location && "Location is required"}
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (
                                            e
                                            ) => {
                                            if (
                                                e.key ===
                                                "Enter"
                                            ) {
                                                e.stopPropagation();
                                            }
                                            },
                                        }}
                                        />
                                    )}
                                    />

                            </Grid>
                            <Grid item xs={12} >
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={isCurrentlyWorking}
                                    onChange={(e) =>
                                    {
                                        setIsCurrentlyWorking(e.target.checked);
                                        setEnd_date("")
                                        if(errors?.endDateBeforeStart){
                                            setErrors({...errors, endDateBeforeStart: false })
                                        }
                                    }
                                       
                                    }
                                    name="currentlyWorking"
                                    style={{
                                    margin: "0",
                                
                                    marginLeft: "10px",
                                    color:"#413c9f"
                                    }}
                                   
                                />
                                }
                                style={{ paddingTop: "7px" }}
                                label="I am currently working in this role"
                            />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    className="input"
                                    name="start_date"
                                    type="month"
                                    label="Start Date"
                                    required
                                    value={
                                        start_date
                                    }
                                    variant="outlined"
                                    onChange={(e) => {setStart_date(e.target.value as string);
                                        if(e.target.value && e.target.value !== null && e.target.value !== ""){
                                            setErrors({
                                                ...errors, startDate:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, startDate:true
                                            })
                                        }
                                        if(e.target.value <= end_date){
                                            setErrors({
                                                ...errors, endDateBeforeStart: false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, endDateBeforeStart: true
                                            })
                                        }
                                    }}
                                    error={errors.startDate}
                                    helperText={errors.startDate && "Start Date is required"}
                                    style={{ width: "100%" }}
                                    onKeyDown={blockCharInAutocompleteField}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="end_date"
                                    type="month"
                                    value={
                                        end_date
                                    }
                                    disabled={isCurrentlyWorking}
                                    className="input disableDate"
                                    required={!isCurrentlyWorking}
                                    error={(!isCurrentlyWorking && errors.endDateBeforeStart) || (!isCurrentlyWorking && errors.endDate)}
                                    helperText={
                                        !isCurrentlyWorking && errors.endDateBeforeStart
                                            ? "End date cannot be before the start date"
                                            : !isCurrentlyWorking && errors.endDate && "End Date is required"
                                    }
                                    label="End Date"
                                    variant="outlined"
                                    onKeyDown={blockCharInAutocompleteField}
                                    onChange={(e) => {
                                        setEnd_date(e.target.value as string);
                                        if (!isCurrentlyWorking && e.target.value && e.target.value >= start_date) {
                                            setErrors({ ...errors, endDate: false, endDateBeforeStart: false });
                                        } else if (!isCurrentlyWorking && e.target.value) {
                                            setErrors({ ...errors, endDateBeforeStart: true });
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            {!edit && selectedData?.document &&
                            <Grid item xs={12} className="centerItem">
                                <Grid container spacing={3}>
                                    <Grid item><button className="editButton" onClick={()=>setView(true)}>View Document</button></Grid>
                                    <Grid item><button className="editButton" onClick={()=>setEdit(true)}>Edit Document</button></Grid>
                                </Grid>  
                            </Grid>
                            }
                            {(edit || !selectedData?.document) &&
                            <Grid item xs={12} className="centerItem">
                            <FormControl style={{ width: "100%" }} className="uploadInput" >
                                <InputLabel><IconButton style={{padding:"0px", cursor:"pointer"}} onClick={()=>{setEdit(false); setFile(null)}}><CancelOutlined style={{height:"18px"}}/></IconButton> Upload Document</InputLabel>                                
                                <input
                                    className="uploadField"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={fileSelectedHandler}
                                />
                            {errors?.fileSize && <p className="validationError">File size should not exceed 10 MB</p>}  
                            </FormControl>
                            </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} className="centerItem">
                                <TextField
                                    name="Description"
                                    id="discription"
                                    value={description}
                                    onChange={(e:any)=>{
                                        setDescription((e.target.value))
                                    }}
                                    multiline
                                    rows={2}
                                    className="input"
                                    label="Description"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid container spacing={3} xs={12} style={{marginTop:"20px"}} className="centerItem">
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Edit Experience
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {view === true && (
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={view}
                            onClose={handlePdfModelClose}
                            closeAfterTransition
                            >
                            <Fade in={view}>
                                {selectedData?.document?.includes(".jpg") ||
                                selectedData?.document?.includes(".jpeg") ||
                                selectedData?.document?.includes(".png") ? (
                                <div
                                    style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    }}
                                >
                                    <img
                                    src={`${selectedData?.document}`}
                                    style={{ width: "95vh", height: "80%" }}
                                    title="IMAGE Document"
                                    />
                                </div>
                                ) : selectedData?.document?.includes(".doc") || selectedData?.document?.includes(".docx") ? (
                                <div id="mera-doc-viewer">
                                    <DocViewer
                                    style={{ height: "-webkit-fill-available" }}
                                    pluginRenderers={DocViewerRenderers}
                                    documents={[{ uri: selectedData?.document, fileType: "doc" }]}
                                    />
                                </div>
                                ) : (
                                <iframe
                                    src={`${selectedData?.document}`}
                                    style={{ width: "80%", height: "750px" }}
                                    title="PDF Document"
                                ></iframe>
                                )}
                            </Fade>
                            </Modal>
                        )}
                    </>

                </div>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.PayrollReducer.loading,
    locationList: state.loginReducer.locationList,
});

export default connect(mapStateToProps, {
    getLocationList,
    AddEmploymentDetails,
    getEmploymentDetails,
    EditEmploymentDetails
})(EditExperienceModal);