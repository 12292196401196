import React from "react";
import { useState } from "react";
import { Typography, Button, Paper, Grid, TextField, FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from "@mui/material";
import { DatePicker } from "@mui/lab";

const Holidays = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: React.SetStateAction<Date>) => {
    setSelectedDate(date);
  };

  return (
    <div style={{ padding: 16 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h6" gutterBottom>
              Holiday Calendar
            </Typography>

            <DatePicker
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => <TextField {...params} />}
            />

            <Typography variant="body1" style={{ marginTop: 16}}>
              Holidays on {selectedDate.toDateString()}:
              <ul>
                <li>New Year's Day</li>
                <li>Independence Day</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Holidays;
