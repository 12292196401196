import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Rating from "@mui/material/Rating";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import "./style.css";

import {
  getEmployeeList,
  PostEmployeeRating,
  getEmployeeForm,
} from "../../../actions/loginActions";
import { AnyMxRecord } from "dns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

const labels: { [index: string]: string } = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Nice",
  3: "NIce+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

interface Props {
  getEmployeeList: any;
  employeesList: any[];
  PostEmployeeRating: any;
  employee_rating: any;
  getEmployeeForm: any;
  get_employee_form: any;
}

const EmployeeFeedback: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  PostEmployeeRating,
  employee_rating,
  getEmployeeForm,
  get_employee_form,
}) => {
  const classes = useStyles();
  const [employeeName, setEmployeeName] = React.useState("Select Employee");
  const [showStar, setShowStar] = React.useState(false);
  const [value, setValue] = React.useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);
  const [showRating, setShowRating] = React.useState(false);
  const [employeeUUid, setEmployeeUUid] = React.useState<any>();
  const [employeeForm, setEmployeeForm] = React.useState<any>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setEmployeeName(event.target.value as string);
    setShowStar(true);
    const selectedEmployee = employeesList.find(
      (employee) => employee.fullname === event.target.value
    );
    if (selectedEmployee) {
      setEmployeeUUid(selectedEmployee.uuid);
      setEmployeeForm(true);
    } else {
      console.log("eror");
    }
  };



  useEffect(() => {
    getEmployeeList("");
  }, []);

//   useEffect(() => {
//     getEmployeeForm(employeeUUid);
//   }, [employeeUUid]);



  return (
    <main className={classes.content}>
      <div className={classes.toolbar}>
        <div className="container">
          <div className="contentHeader">
            <div className="header">
              <h2 style={{ textAlign: "center", color: "rgb(14, 63, 108)" }}>
                KRA-EMPLOYEE-RATING{" "}
              </h2>
            </div>
          </div>
          <div className="contentBody">
            <Box sx={{ width: "100%" }}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="feedback-outer">
                      <div className="feedback-inner">
                        <div className="emp-list-select">
                          <FormControl fullWidth style={{ width: "100%" }}>
                            <InputLabel id="empl-list-select">
                              Select Employee
                            </InputLabel>
                            <Select
                              labelId="emp-list"
                              id="emp-list"
                              value={employeeName}
                              label="Employee Name"
                              onChange={handleChange}
                              style={{
                                paddingTop: "20px",
                                fontSize: "1.5rem",
                                textAlign: "center",
                              }}
                            >
                              {employeesList &&
                                employeesList.length > 0 &&
                                employeesList.map(
                                  (employee: any, index: number) => {
                                    return (
                                      <MenuItem
                                        key={employee.id}
                                        value={employee.fullname}
                                      >
                                        {employee.fullname}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>
                        </div>
                        {showStar && (
                          <div>
                            <div className="emp-rating">
                              <div className="emp-rating-outer">
                                <div className="emp-rating-inner">
                                  <Rating
                                    className="star-icon"
                                    name="simple-controlled"
                                    value={value}
                                    precision={0.5}
                                    size="large"
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                      setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                      setHover(newHover);
                                    }}
                                    emptyIcon={
                                      <StarIcon
                                        id="star-icon"
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                </div>
                                <div id="rate-text-div">
                                  {value !== null && (
                                    <Box id="rate-text" sx={{ ml: 12 }}>
                                      {labels[hover !== -1 ? hover : value]}
                                    </Box>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="update-btn-div">
                              <Button
                                className="update-btn"
                                onClick={() => {
                                  setShowRating(true);
                                  // handleUpdateRating();
                                }}
                                variant="contained"
                              >
                                Update!
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </div>

          {/* {showRating &&
                        <div className="display-rating">
                            <Paper >
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className="show-cont">

                                            <div className="show-user-div">
                                                {employeesList && employeesList.length > 0 &&
                                                    employeesList.map((employee: any, index: number) => {
                                                        return (
                                                            <div>
                                                                <h5>{employee.fullname}</h5>
                                                                <h6>{employee.designation}</h6>

                                                            </div>

                                                        )
                                                    }
                                                    )}
                                            </div>
                                            <div className="show-user-rate">
                                                <Rating
                                                    className="star-icon"
                                                    name="simple-controlled"
                                                    value={value}
                                                    precision={0.5}
                                                    size="large"
                                                    getLabelText={getLabelText}

                                                    emptyIcon={<StarIcon id="star-icon" style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />
                                                {value !== null && (
                                                    <Box id='rate-text' sx={{ ml: 12, marginLeft: "30px" }}>{labels[hover !== -1 ? hover : value]}</Box>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    } */}
          {/* {employeeForm && employeeForm.length > 0 &&
                        employeeForm.map((empl: any, index: number) => {
                            { employeeForm }
                        })} */}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
  employee_rating: state.loginReducer.employee_rating,
  get_employee_form: state.loginReducer.get_employee_form,
});

export default connect(mapStateToProps, {
  getEmployeeList,
  PostEmployeeRating,
  getEmployeeForm,
})(EmployeeFeedback);
