import React from "react";
import EmployeeDashboardLeft from "../../Employee-Panel/dashboard-left/index";
import EmployeeDashboardRight from "../../Employee-Panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}
const EmployeeDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <EmployeeDashboardLeft />
            <EmployeeDashboardRight>{children && children}</EmployeeDashboardRight>
        </div>
    );
};

export default EmployeeDash;