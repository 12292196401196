import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField, Grid, Paper, Table, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles, Typography, Chip } from '@material-ui/core';
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    MenuItem,
    IconButton,
    Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { connect } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../Loader2';
import Modal from '@mui/material/Modal';
import Fade from '@material-ui/core/Fade';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import './index.sass';
import { Box, Button } from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { BorderTopRounded, Height } from '@mui/icons-material';
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    getEmployeeDepartment,
    postPolicyCreation,
    getPolicyCategory,
    createPolicyCategory,
    getPolicy,
    updatePolicy,
    getEmployeeList,
    postEmployeePolicyAck,
    getEmployeePolicyAck
} from "../../../actions/loginActions"
import { maxHeaderSize } from 'http';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        conlorChange: {
            background: "red",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "absolute",
            maxHeight: '90vh',
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid balck",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
            position: "absolute",
        },
        table: {
            maxWidth: "100%",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
        tableContainer: {
            position: "sticky",
            top: "200px",
            overflow: "scroll",
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
    })
);

const style = {
    position: 'absolute' as 'absolute',
    top: '55%',
    left: "50%",
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    loading: Boolean;
    getEmployeeDepartment: Function;
    departmentNames: any;
    postPolicyCreation: Function;
    policyCreation: any;
    getPolicyCategory: Function;
    get_Policy_Category: any;
    createPolicyCategory: Function;
    create_policy_category: any;
    getPolicy: Function;
    get_policy: any;
    updatePolicy: Function;
    update_policy: any;
    getEmployeeList: Function;
    employeesList: any;
    postEmployeePolicyAck: Function;
    postPolicyAck: any;
    getEmployeePolicyAck:Function;
    getPolicyAck:any;
}




const PolicyCenter: React.FC<Props> = ({
    loading,
    getEmployeeDepartment,
    departmentNames,
    postPolicyCreation,
    policyCreation,
    getPolicyCategory,
    get_Policy_Category,
    createPolicyCategory,
    create_policy_category,
    getPolicy,
    get_policy,
    updatePolicy,
    getEmployeeList,
    employeesList,
    postEmployeePolicyAck,
    postPolicyAck,
    getEmployeePolicyAck,
    getPolicyAck

}) => {
    const classes = useStyles()
    const timer = useRef<any>(0);
    const [policyCategoryData, setPolicyCategoryData] = useState<any>("");
    const [openPdf, setOpenPdf] = useState<boolean>(false)
    const [fileLink, setFileLink] = useState<any>("")
    const [employeeDepartment, setEmployeeDepartment] = useState<string>("");
    const [isRead, setIsRead] = useState<boolean>(false);
    const [policyName, setPolicyName] = useState<string>("");
    const [policyList, setPolicyList] = useState<any>([]);
    const [openNewModal, setOpenNewModal] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
    const [policyId, setPolicyId] = useState<any>("");
    const [employeeUuid, setEmployeeUuid] = useState<any>("");
    const [ackByEmp,setAckByEmp]=useState<boolean>(false);


    const handlePdfModelClose = () => {
        if (isAcknowledged) {
            if(!ackByEmp){
            setOpenConfirm(true);
            // if (isRead) {
            //     setOpenConfirm(true);
            // } else {
            //     alert("Please mark the checkbox below after reading the content before closing this window.");
            // }
        } else {
            setOpenPdf(false);
        }
    }else{
        setOpenPdf(false);
    }
    };

    const handleViewPdf = () => {
        setOpenPdf(true)
    }

    const handleOpenNew = () => {
        setOpenNewModal(true);
    }
    const handlePolicyType = (category_Id: any) => {
        // if (category !== "") {
        //     getPolicy(`?policy_name=${category}`)

        // }
        if (get_Policy_Category && get_Policy_Category.length > 0) {
            const data = get_Policy_Category.find((item: any) => item.id === category_Id);
            setPolicyList(data?.policy)
        }
    }

    useEffect(() => {
        getEmployeeDepartment();
        getEmployeeList()
    }, [])


    useEffect(() => {
        if (employeeDepartment) {
            getPolicyCategory(`?department_name=${employeeDepartment}`)
        }
    }, [employeeDepartment])


    useEffect(() => {
        if (employeesList) {
            const dept = employeesList?.results && employeesList?.results[0]?.department
            const uuid = employeesList?.results && employeesList?.results[0]?.uuid
            setEmployeeDepartment(dept);
            setEmployeeUuid(uuid);
        }
    }, [employeesList])

    useEffect(() => {
        if (get_policy) {
            setPolicyList(get_policy?.[0]?.policy)
        }
    }, [get_policy]);

    useEffect(() => {
        if (get_Policy_Category) {
            setPolicyCategoryData(get_Policy_Category);
            setPolicyList([]);
        }
    }, [get_Policy_Category])

    const DepartmentList = ({ departments }: { departments: any[] }) => {
        const deptNamelist = departmentNames
          .filter((dept: any) => departments?.includes(dept.id)) 
          .map((dept: any) => dept.name);

        return (
            <Grid container spacing={1}>
                {deptNamelist.length > 0 && (
                    deptNamelist.map((name: any, index: any) => (
                        <Grid item >
                            <Chip label={name} disabled color='primary' />
                        </Grid>
                    ))
                )}
            </Grid>
        );
    };

    const handleEmployeeAck = async () => {
        const formData = new FormData();
        formData.append("employee", employeeUuid)
        formData.append("policy", policyId)
        formData.append("is_acknowledge", 'true');
        await postEmployeePolicyAck(formData);

        setPolicyId("");
        setOpenConfirm(false)
        setOpenPdf(false)
        setIsRead(false)
    }

    useEffect(()=>{
        if(policyId){
            getEmployeePolicyAck(`employee=${employeeUuid}&policy=${policyId}`)
        }
    },[policyId])

    useEffect(()=>{
        if(getPolicyAck && getPolicyAck.length>0){
            setAckByEmp(getPolicyAck?.[0]?.is_acknowledge);
        }
    },[getPolicyAck])

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
                <h4
                    style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        backgroundColor: "rgb(28, 78, 128)",
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                        padding: "10px",
                        borderRadius: "15px",
                        letterSpacing: "0.5px",
                        textTransform: "capitalize",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    Policy center
                </h4>
                <Grid container spacing={3}>
                    <Grid item
                        xs={12}
                        sm={12}
                        md={5}>
                        <Paper className={classes.paper} style={{ background: "rgb(233, 236, 239)" }}>
                            <Grid container spacing={2} >
                                <Grid item
                                    xs={12}
                                    sm={12}
                                    md={12} style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "#0e3f6c", margin: "1rem", textAlign: "center", fontSize: "2rem" }}>Policy Categories</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {loading ? <Loader /> :
                                        (
                                            policyCategoryData &&
                                            policyCategoryData.length > 0 &&
                                            policyCategoryData?.map((policy: any) => (
                                                <Grid
                                                    xs={12} sm={12} md={12}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        textAlign: "left",
                                                        background: "white",
                                                        padding: "15px",
                                                        width: "100%",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        margin: "10px 0px",
                                                    }}
                                                    onClick={() => {
                                                        handlePolicyType(policy?.id);
                                                    }}
                                                    key={policy.id}
                                                // onMouseEnter={(e) => e.currentTarget.style.background = "#f0f0f0"}
                                                // onMouseLeave={(e) => e.currentTarget.style.background = "white"} 
                                                >
                                                    <Typography
                                                    >
                                                        {policy?.policy_name}
                                                    </Typography>
                                                </Grid>
                                            ))
                                        )}

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item
                        xs={12}
                        sm={12}
                        md={7}>
                        <Paper className={classes.paper} elevation={15} style={{ background: "rgb(233, 236, 239)" }}>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ margin: "20px 10px", }}
                            >
                                <h2>Policies</h2>
                            </Grid>
                            <Grid container>
                                {loading ? <Loader /> :
                                    (
                                        policyList &&
                                        policyList.length > 0 &&
                                        policyList.map((policy: any) => (
                                            (policy?.is_active === true &&
                                                <Grid item xs={12}
                                                    style={{
                                                        margin: "6px 10px",
                                                        background: "white",
                                                        padding: "30px 30px",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        borderRadius: "10px"
                                                    }}>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                                        <Grid item>
                                                            <h4>{policy.name}</h4>
                                                            <p> 👉{policy.description}</p>
                                                        </Grid>
                                                        <Grid item style={{ marginLeft: "auto" }}>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "bold",
                                                                    padding: "10px 20px",
                                                                    marginLeft: "10px"
                                                                }}
                                                                onClick={() => {
                                                                    // handleView(policy)
                                                                    setFileLink(policy.policy_file);
                                                                    setPolicyName(policy.name)
                                                                    setIsAcknowledged(policy.is_read)
                                                                    setPolicyId(policy?.id)
                                                                    handleViewPdf();
                                                                }}
                                                            >
                                                                VIEW
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Created At :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.created_at.slice(0, 10)}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Updated At :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.updated_at.slice(0, 10)}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Acknowledged By Employee :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.is_read === true ? "YES" : "NO"}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Department :</strong></p></Grid>
                                                        <Grid item xs={9}>
                                                        <p style={{ margin: "5px 0", fontSize: "1rem" }}>
                                                                {policy?.employee_department?.length > 0 && policy?.employee_department.includes(64)
                                                                ? "ALL"
                                                                : <DepartmentList departments={policy?.employee_department?.length > 0 ? policy?.employee_department : []} />
                                                                }
                                                            </p>
                                                                </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        ))

                                    )}
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
                {openPdf === true &&

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openPdf}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openPdf}>
                            <Box sx={{ ...style, borderRadius: "10px", maxHeight: "100%", overflow: "scroll" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                            <h4 style={{ margin: "1rem 3rem", textAlign: "center" }}>{policyName}</h4>
                                            <span style={{ marginLeft: "auto" }}>
                                                <HighlightOffIcon
                                                    onClick={handlePdfModelClose}
                                                    style={{ height: "30px", width: "30px" }} />
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fileLink?.includes(".jpg") || fileLink?.includes(".jpeg") || fileLink?.includes(".png") ? (
                                            <div style={{ display: "flex", justifyContent: "center", maxWidth: "100%", maxHeight: "700px", overflowY: "scroll", marginTop: "1rem" }}>
                                                <img src={fileLink} style={{ width: "100%", height: "100%" }} title="IMAGE Document" />
                                            </div>
                                        ) : fileLink?.includes(".doc") || fileLink?.includes(".docx") ? (
                                            <div id="mera-doc-viewer" style={{ maxWidth: "100%", maxHeight: "800px", marginTop: ".5rem" }}>
                                                <DocViewer
                                                    style={{ height: "780px", width: "100%" }}
                                                    pluginRenderers={DocViewerRenderers}
                                                    documents={[{ uri: fileLink, fileType: "doc" }]}
                                                />
                                            </div>
                                        ) : (
                                            <iframe src={fileLink} style={{ width: "100%", height: "700px" }} title="PDF Document"></iframe>
                                        )}
                                    </Grid>
                                    {/* {isAcknowledged &&
                                        <Grid item xs={12}>
                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                                <FormControl component="fieldset">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isRead}
                                                                onChange={(e) => setIsRead(e.target.checked)}
                                                            />
                                                        }
                                                        label="I have completed reading the content"
                                                    />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    } */}
                                </Grid>
                            </Box>

                        </Fade>
                    </Modal>
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openConfirm}
                    onClose={setOpenConfirm}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openConfirm}>
                        <Box sx={{ ...style, borderRadius: "10px", width: "450px" }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h5" component="h6" style={{ textAlign: "center", fontWeight: "600" }} >
                                        Have you read the policy?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        size="large"
                                        onClick={() => {
                                            handleEmployeeAck()

                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        size="large"
                                        onClick={() => { setOpenConfirm(false); setIsRead(false) }}
                                    >
                                        NO
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.EmployeeReducer.loading,
    departmentNames: state.loginReducer.departmentNames,
    policyCreation: state.loginReducer.policyCreation,
    get_Policy_Category: state.loginReducer.get_Policy_Category,
    create_policy_category: state.loginReducer.create_policy_category,
    get_policy: state.loginReducer.get_policy,
    employeesList: state.loginReducer.employeesList,
    postPolicyAck: state.loginReducer.postPolicyAck,
    getPolicyAck:state.loginReducer.getPolicyAck
})

export default connect(mapStateToProps, {

    getEmployeeDepartment,
    postPolicyCreation,
    getPolicyCategory,
    createPolicyCategory,
    getPolicy,
    updatePolicy,
    getEmployeeList,
    postEmployeePolicyAck,
    getEmployeePolicyAck
})(PolicyCenter);

