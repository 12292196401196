import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import BackupIcon from "@mui/icons-material/Backup";
import CreateIcon from "@mui/icons-material/Create";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import Loader from "../Loader2";
import { useHistory } from "react-router-dom";
import {
    getEmployeFilterList,
    multiManagerBulkUpload,
} from "../../actions/PayrollActions";
import { getEmployeeList, editEmployeeById, createEmployee } from "../../../actions/loginActions"
import { generateEmployeeFilter } from "../../../helper/generate";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TablePagination,
    TableFooter,
    TableCell,
    TableRow,
} from "@material-ui/core";
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 2,
};

const style1 = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        container: {
            position: "sticky",
            marginTop: 15,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        closeicon: {
            backgroundColor: "#d1ff33",
            width: '1.5em',
            height: '1.5em',
            borderRadius: "50%",
            border: "1px solid #c6ff00",
            cursor: "pointer",
            color: "#1a237e"
        },
        input: {
            padding: "1px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        blinker: {
            animation: `$blinker 1.5s linear infinite`,
        },
        "@keyframes blinker": {
            "50%": {
                opacity: 0.5,
            },
        },
    })
);

const ButtonDesign = styled(Button)({
    background: "#5B42F3",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px 20px",
    borderRadius: "20px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    transition: "all 0.3s ease",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        background: "linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB)",
    },
    "& .MuiButton-startIcon": {
        transition: "transform 0.3s ease",
    },
    "&:hover .MuiButton-startIcon": {
        transform: "rotate(15deg)",
    },
});

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#6c757d",
            color: theme.palette.common.white,
            fontSize: "16px",
        },
        body: {
            fontSize: 14,
            padding: "5px",
            textTransform: "capitalize",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loading: boolean;
    employeesFilterList: { results: { employee_id: any; name: string; uuid?: string }[] };
    getEmployeFilterList: any;
    multiManagerBulkUpload: any;
    employeesList: any;
    getEmployeeList: any;
    editEmployeeById: any;
    createEmployee: any;
    uuid: any;
}
const Forms: React.FC<Props> = ({
    loading,
    employeesFilterList,
    getEmployeFilterList,
    multiManagerBulkUpload,
    employeesList,
    getEmployeeList,
    editEmployeeById,
    createEmployee
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const history = useHistory();
    const [open, setOpen] = React.useState<boolean>(false);
    const handleClose = () => setOpen(false);
    const openBulkUpload = () => setOpen(true);
    const [page, setPage] = useState(0);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const handleOpenCreate = () => setOpenCreate(true);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const handleOpenFilter = () => setFilterOpen(true);
    const handleCloseFilter = () => setFilterOpen(false);
    const [employeeName, setEmployeeName] = useState<string>("");
    const [employeeId, setEmployeeId] = useState<any>("");
    const [l1Name, setL1Name] = useState<string>("");
    const [l1Id, setL1Id] = useState<any>("");
    const [l2Name, setL2Name] = useState<string>("");
    const [l2Id, setL2Id] = useState<any>("");
    const [l3Name, setL3Name] = useState<string>("");
    const [l3Id, setL3Id] = useState<any>("");
    const [employeeUUID, setEmployeeUUID] = useState<any>("");
    const [l1Uuid, setL1Uuid] = useState<any>("");
    const [l2Uuid, setL2Uuid] = useState<any>("");
    const [l3Uuid, setL3Uuid] = useState<any>("");
    const [empWorkLocation, setEmpWorkLocation] = useState<any>([]);
    const [buttonState, setButtonState] = useState<boolean>(false);
    const [fileTypeErrorLeave, setFileTypeLeaveError] = useState<any>("");
    const [leaveFileName, setLeaveFileName] = React.useState<string>("");
    const [leaveFile, setLeaveFile] = React.useState<any>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [editButton, setEditButton] = useState<boolean>(false);

    const handleLeaveFileChange = (e: React.ChangeEvent<{ files: any }>) => {
        setLeaveFile(e.target.files[0]);
        if (e.target.files[0]?.type === "text/csv") {
            setLeaveFileName(e.target.files[0].name);
            setFileTypeLeaveError("");
        } else {
            setFileTypeLeaveError("Please select csv file");
        }
    };

    useEffect(() => {
        getEmployeFilterList("get_all=true");
        getEmployeeList("get_all=true");
    }, []);

    const handleClose1 = () => {
        setOpenCreate(false);
        setEditButton(false);
        setEmployeeId("");
        setEmployeeName("");
        setL1Name("");
        setL1Id("");
        setL2Name("");
        setL2Id("");
        setL3Name("");
        setL3Id("");
        setEmployeeUUID("");
        setL1Uuid("");
        setL2Uuid("");
        setL3Uuid("");
        setEmpWorkLocation("");
    }

    const handleOpenEdit = (data: any) => {
        setOpenCreate(true);
        setEditButton(true);
        setEmployeeId(data?.employee_id || "");
        setEmployeeName(data?.name || "");
        setL1Name(data?.manager_name || "");
        setL1Id(data?.manager_id || "");
        setL2Name(data?.second_manager?.second_manager_name || "");
        setL2Id(data?.second_manager?.second_manager_rclid || "");
        setL3Name(data?.third_manager?.third_manager_name || "");
        setL3Id(data?.third_manager?.third_manager_rclid || "");
        setEmployeeUUID(data?.uuid || "");
        setL1Uuid(data?.manager || "");
        setL2Uuid(data?.second_manager?.second_manager_id || "");
        setL3Uuid(data?.third_manager?.third_manager_id || "");
        setEmpWorkLocation(
            data?.employee_work_location && data.employee_work_location.length > 0
                ? [data.employee_work_location[0].id]
                : []
        );
    };

    const submitLeaveFile = async () => {
        setIsButtonDisabled(true);
        const formData = new FormData();
        formData.append("file", leaveFile);
        await multiManagerBulkUpload(formData);
        setButtonState(true);
        setTimeout(() => {
            setButtonState(false);
        }, 1000 * 60 * 10);
        setLeaveFileName("");
        setLeaveFile("");
        setOpen(false);
    };

    const handleManagerUpdate = async () => {
        const body = {
            employee_work_location: empWorkLocation,
            manager: l1Uuid,
            second_manager: l2Uuid,
            third_manager: l3Uuid,
        };
        if (editButton) {
            await editEmployeeById(body, employeeUUID);
        }
        else {
            // await createEmployee(body);
            await editEmployeeById(body, employeeUUID);
        }
        setOpenCreate(false);
        getEmployeeList("get_all=true");
    };

    const filterEmp = () => {

        const body: any = {
            employee_id: employeeId,
        };
        const url = generateEmployeeFilter(body).substring(2);
        getEmployeeList(`get_all=true&${url}`);
        setPage(0);
        setFilterOpen(false);
        setEmployeeId("");

    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = employeesList.links && employeesList.links.next.split("?")[1];

            getEmployeeList(`${url}`);
        } else if (newPage < page) {
            let url =
                employeesList.links && employeesList.links.previous.split("?")[1];

            getEmployeeList(`${url}`);
        }
        setPage(newPage as number);
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
                direction="row"
            >
                <Grid item xs={12} sm={6} md={4}>
                    <h4
                        style={{
                            color: "rgb(14, 63, 108)",
                            display: "flex",
                            justifyContent: "left",
                            fontSize: "35px",
                            fontWeight: "bolder"
                        }}
                    >
                        Assign Manager Hierarchy
                    </h4>
                </Grid>
                <Grid item xs={12} sm={6} md={8} style={{ display: "flex", gap: "8px", justifyContent: "right" }}>
                    <ButtonDesign
                        variant="contained"
                        startIcon={<LibraryAddIcon />}
                        onClick={openBulkUpload}
                        style={{ width: "200px" }}
                    >
                        Bulk Create
                    </ButtonDesign>
                    <ButtonDesign
                        variant="contained"
                        startIcon={<CreateIcon />}
                        onClick={handleOpenCreate}
                        style={{ width: "200px" }}
                    >
                        Create
                    </ButtonDesign>
                    <ButtonDesign
                        variant="contained"
                        startIcon={<FilterAltIcon />}
                        onClick={handleOpenFilter}
                        style={{ width: "200px" }}
                    >
                        Filter
                    </ButtonDesign>
                </Grid>

            </Grid>
            <hr style={{ borderBottom: "3px solid #007BFF" }} />
            <TableContainer
                className={classes.container}
                style={{ maxHeight: "530px" }}
                component={Paper}
            >
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                <b>Sr. No.</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Name</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>L1</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>L2</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>L3</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Edit</b>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <Loader />
                        ) : (
                            employeesList &&
                            employeesList.results &&
                            employeesList.count > 0 &&
                            employeesList.results.map((data: any, index: any) => (
                                <StyledTableRow>
                                    <StyledTableCell align="center">
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data.fullname || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data.manager_name || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.second_manager?.second_manager_name || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.third_manager?.third_manager_name || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleOpenEdit(data)}
                                        >
                                            <EditIcon />
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow style={{ textAlign: "center" }}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={6}
                                count={employeesList?.count || 0}
                                rowsPerPage={employeesList?.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h2
                        style={{
                            color: "rgb(14, 63, 108)",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "35px",
                            fontWeight: "bolder"
                        }}
                    >
                        Manager Hierarchy Bulk Uploader
                    </h2>
                    <div style={{ marginTop: 10, width: "100%" }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={10} md={8}>
                                <div
                                    style={{
                                        position: "relative",
                                        left: "0",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        className="upload-inner"
                                        style={{
                                            width: "100%",
                                            maxWidth: "800px",
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            padding: "30px 10px",
                                        }}
                                    >
                                        <div className="files-first">
                                            <BackupIcon
                                                style={{
                                                    margin: "0 5px",
                                                    width: "100px",
                                                    color: "blue",
                                                }}
                                            />
                                            <h5>Upload File</h5>
                                        </div>
                                        <Grid item xs={12}>
                                            <input
                                                className="button-17"
                                                type="text/csv"
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "500px",
                                                    height: "50px",
                                                    padding: "6px 10px",
                                                    margin: "10px auto",
                                                }}
                                                accept=".csv"
                                                placeholder="File Name"
                                                value={leaveFileName}
                                                disabled
                                            />
                                            <p style={{ color: "red", textAlign: "center" }}>
                                                {fileTypeErrorLeave}
                                            </p>
                                        </Grid>

                                        <div className="upload-btn" style={{ margin: "10px 0" }}>
                                            <Button
                                                className="button-17"
                                                style={{ height: "56px" }}
                                                component="label"
                                                id="upload-btn"
                                            >
                                                Select CSV File
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    hidden
                                                    onChange={handleLeaveFileChange}
                                                />
                                            </Button>
                                        </div>

                                        <div className="file-det" style={{ margin: "10px 0" }}>
                                            <span>Supported file type: </span>
                                            <strong style={{ color: "red" }}>Note : Only CSV File Can Be uploaded</strong>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            {loading ? (
                                                <Loader />
                                            ) : (
                                                <Grid container spacing={3} justifyContent="center">
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Button
                                                            className="button-17"
                                                            startIcon={<CloudUploadIcon />}
                                                            style={{
                                                                height: "56px",
                                                                width: "100%",
                                                                marginTop: "10px",
                                                            }}
                                                            onClick={submitLeaveFile}
                                                            disabled={
                                                                buttonState ||
                                                                leaveFileName === "" ||
                                                                fileTypeErrorLeave !== "" ||
                                                                loading
                                                            }
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <a
                                                            href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/b41a939d-c61e-4727-9456-e7cb0c734f33.csv"
                                                            target=""
                                                        >
                                                            <Button
                                                                className="button-17"
                                                                fullWidth
                                                                style={{
                                                                    height: "56px",
                                                                    width: "100%",
                                                                    marginTop: "10px",
                                                                }}
                                                                startIcon={<CloudDownloadIcon />}
                                                                component="label"
                                                            >
                                                                Download Sample CSV File
                                                            </Button>
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </div>
                                    </div>
                                    {loading && (
                                        <div
                                            className={classes.blinker}
                                            style={{
                                                color: "#007BFF",
                                                fontSize: "1.5em",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                margin: "20px auto",
                                                padding: "10px",
                                                border: "2px solid #007BFF",
                                                borderRadius: "10px",
                                                backgroundColor: "#f0f8ff",
                                                boxShadow: "0 4px 8px rgba(0, 123, 255, 0.2)",
                                                animation: "blinker 1.5s linear infinite",
                                                maxWidth: "800px",
                                                width: "100%",
                                            }}
                                        >
                                            Please wait, we are uploading Leave file Data. It will take a
                                            little bit of time...
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openCreate}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    {
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            style={{ alignItems: "left" }}
                        >
                            <Grid item xs={12} sm={4} md={12}>
                                <h2
                                    style={{
                                        color: "rgb(14, 63, 108)",
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "35px",
                                        fontWeight: "bolder"
                                    }}
                                >
                                    Manager Hierarchy {editButton ? "Update" : "Create"}
                                </h2>
                            </Grid>

                            <Grid item xs={12} sm={4} md={6}>
                                <Autocomplete
                                    disabled={editButton}
                                    onChange={(event, obj) => {
                                        if (obj) {
                                            setEmployeeId(obj.employee_id);
                                            setEmployeeUUID(obj.uuid);
                                        }
                                    }}
                                    id="checkboxes-tags-demo"
                                    options={employeesFilterList?.results || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            newInputValue &&
                                                getEmployeFilterList(
                                                    `get_all=true&employee_id=${newInputValue}`
                                                );
                                        }, 1000);
                                    }}
                                    defaultValue={{
                                        employee_id: employeeId,
                                        name: employeeName,
                                    }}
                                    getOptionLabel={(option: any) => {
                                        return option?.employee_id + " " + option.name;
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <p>
                                                <b style={{ color: "blue" }}>Employee Name:</b>
                                                <strong>
                                                    {option?.name.toString()}
                                                    <br />
                                                </strong>
                                                <b style={{ color: "red" }}>Employee Id:</b>
                                                {option?.employee_id?.toUpperCase()}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            label="Employee Name"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={6}>
                                <Autocomplete
                                    onChange={(event, obj) => {
                                        if (obj) {
                                            setL1Id(obj.employee_id);
                                            setL1Uuid(obj.uuid);
                                        } else {
                                            setL1Id("");
                                            setL1Uuid("");
                                        }
                                    }}
                                    id="checkboxes-tags-demo"
                                    options={employeesFilterList?.results || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            newInputValue &&
                                                getEmployeFilterList(
                                                    `get_all=true&employee_id=${newInputValue}`
                                                );
                                        }, 1000);
                                    }}
                                    getOptionLabel={(option: any) => {
                                        return option?.employee_id + " " + option.name;
                                    }}
                                    defaultValue={{
                                        employee_id: l1Id,
                                        name: l1Name,
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <p>
                                                <b style={{ color: "blue" }}>Employee Name:</b>
                                                <strong>
                                                    {option?.name.toString()}
                                                    <br />
                                                </strong>
                                                <b style={{ color: "red" }}>Employee Id:</b>
                                                {option?.employee_id?.toUpperCase()}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            label="L1"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={6}>
                                <Autocomplete
                                    onChange={(event, obj) => {
                                        if (obj) {
                                            setL2Id(obj.employee_id);
                                            setL2Uuid(obj.uuid);
                                        } else {
                                            setL2Id("");
                                            setL2Uuid("");
                                        }
                                    }}
                                    id="checkboxes-tags-demo"
                                    options={employeesFilterList?.results || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            newInputValue &&
                                                getEmployeFilterList(
                                                    `get_all=true&employee_id=${newInputValue}`
                                                );
                                        }, 1000);
                                    }}
                                    defaultValue={{
                                        employee_id: l2Id,
                                        name: l2Name,
                                    }}
                                    getOptionLabel={(option: any) => {
                                        return option?.employee_id + " " + option.name;
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <p>
                                                <b style={{ color: "blue" }}>Employee Name:</b>
                                                <strong>
                                                    {option?.name.toString()}
                                                    <br />
                                                </strong>
                                                <b style={{ color: "red" }}>Employee Id:</b>
                                                {option?.employee_id?.toUpperCase()}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            label="L2"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={6}>
                                <Autocomplete
                                    onChange={(event, obj) => {
                                        if (obj) {
                                            setL3Id(obj.employee_id);
                                            setL3Uuid(obj.uuid);
                                        } else {
                                            setL3Id("");
                                            setL3Uuid("");
                                        }
                                    }}
                                    id="checkboxes-tags-demo"
                                    options={employeesFilterList?.results || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            newInputValue &&
                                                getEmployeFilterList(
                                                    `get_all=true&employee_id=${newInputValue}`
                                                );
                                        }, 1000);
                                    }}
                                    defaultValue={{
                                        employee_id: l3Id,
                                        name: l3Name,
                                    }}
                                    getOptionLabel={(option: any) => {
                                        return option?.employee_id + " " + option.name;
                                    }}
                                    renderOption={(option: any) => {
                                        return (
                                            <p>
                                                <b style={{ color: "blue" }}>Employee Name:</b>
                                                <strong>
                                                    {option?.name.toString()}
                                                    <br />
                                                </strong>
                                                <b style={{ color: "red" }}>Employee Id:</b>
                                                {option?.employee_id?.toUpperCase()}
                                            </p>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            label="L3"
                                            variant="outlined"
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item style={{ margin: "0 auto" }} xs={12} sm={4} md={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    onClick={handleManagerUpdate}
                                >
                                    {editButton ? "Update" : "Create"}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Modal>
            <Modal
                open={filterOpen}
                onClose={handleCloseFilter}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <h3 style={{ color: "#0e3f6c" }}> Filter Manager Hierarchy </h3>
                        <CloseIcon
                            onClick={handleCloseFilter}
                            className={classes.closeicon}
                        />
                    </div>


                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        style={{ alignItems: "left" }}
                    >

                        <Grid item xs={12} sm={4} md={4}>
                            <Autocomplete
                                onChange={(event, obj) => {
                                    if (obj) {
                                        setEmployeeId(obj.employee_id);
                                    } else {
                                        setEmployeeId("");
                                    }
                                }}
                                id="checkboxes-tags-demo"
                                options={employeesFilterList?.results || []}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        newInputValue &&
                                            getEmployeFilterList(
                                                `get_all=true&employee_id=${newInputValue}`
                                            );
                                    }, 1000);
                                }}
                                defaultValue={{
                                    employee_id: employeeId,
                                    name: employeeName,
                                }}
                                getOptionLabel={(option: any) => {
                                    return option?.employee_id + " " + option.name;
                                }}
                                renderOption={(option: any) => {
                                    return (
                                        <p>
                                            <b style={{ color: "blue" }}>Employee Name:</b>
                                            <strong>
                                                {option?.name.toString()}
                                                <br />
                                            </strong>
                                            <b style={{ color: "red" }}>Employee Id:</b>
                                            {option?.employee_id?.toUpperCase()}
                                        </p>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="input"
                                        {...params}
                                        label="Employee Name"

                                        disabled={editButton}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                                if (e.key === "Enter") {
                                                    e.stopPropagation();
                                                }
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonPrimary"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={filterEmp}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonSecondary"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                    history.push("/dashboard/payrollmanager/assign-manager-hierarchy")
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    employeesFilterList: state.PayrollReducer.employeesFilterList,
    employeesList: state.loginReducer.employeesList,
    loading: state.loginReducer.loading,
});
export default connect(mapStateToProps, {
    getEmployeFilterList,
    multiManagerBulkUpload,
    editEmployeeById,
    getEmployeeList,
    createEmployee
})(Forms);