import React, { useEffect, useState, useRef } from "react";
import { Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  getEmployeeList,
  getEmployeeDepartment,
  getattendancerecord,
  getAttendanceMasterPro,
} from "../../../actions/loginActions";
import { getAttendanceMaster } from "../../actions/PayrollActions";
import Loader from "../Loader2";
import moment from "moment";
import { generateAttendanceFilter } from "../../../helper/generate";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    rightContentHeader: {
      float: "right",
      textAlign: "right",
      paddingRight: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    blinker: {
      animation: `$blinker 1.5s linear infinite`,
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0.5,
      },
    },
  })
);
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getAttendanceMaster: Function;
  attendanceData: any;
  getEmployeeList: any;
  employeesList: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  getattendancerecord: any;
  attendancerecord: any;
  getAttendanceMasterPro: any;
  attendanceMaster: any;
  attendanceMasterLoading: any;
}

const MyAttendance: React.FC<Props> = ({
  getAttendanceMaster,
  attendanceData,
  getEmployeeList,
  employeesList,
  getEmployeeDepartment,
  departmentNames,
  getattendancerecord,
  attendancerecord,
  getAttendanceMasterPro,
  attendanceMaster,
  attendanceMasterLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [FilterBtn, setFilterBtn] = useState<any>(false);
  const [page, setPage] = useState(0);
  const [employeeDept, setEmployeeDept] = useState<any>("");
  const [departmentHead, setDepartmentHead] = useState<any>("");
  const tableRef = useRef(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [datesData, setDatesData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [EmployeeType, setEmployeeType] = useState<any>("");
  const MyCurrenttoday = moment().format("YYYY-MM-DD");

  useEffect(() => {
    getEmployeeList("get_all=true");
    getAttendanceMaster("get_all=true");
    getEmployeeDepartment();
    getattendancerecord("month=6&year=2024");
    getAttendanceMasterPro();
  }, []);

  const generateMonthDates = (
    startDateStr: string,
    endDateStr: string
  ): string[] => {
    const dates: string[] = [];
    let currentDate = new Date(startDateStr);

    const endDate = new Date(endDateStr);

    while (currentDate <= endDate) {
      const formattedDate = currentDate.toISOString().split("T")[0];
      dates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const handleFilter = () => {
    setFilterBtn(true);
    const body: any = {
      start_date: startDate,
      end_date: endDate,
      department: employeeDept !== 240 ? employeeDept : "",
      employee_type: EmployeeType,
    };
    const url = generateAttendanceFilter(body).substring(2);
    getAttendanceMasterPro(`?${url}`);
    const dateData = generateMonthDates(startDate, endDate);
    setDatesData(dateData);
    setOpenForm(true);
    setPage(0);
    setPage(0);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <h4
        style={{
          fontSize: "24px",
          fontWeight: "600",
          backgroundColor: "rgb(28, 78, 128)",
          color: "#fff",
          textAlign: "center",
          fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
          padding: "10px",
          borderRadius: "15px",
          letterSpacing: "0.5px",
          textTransform: "capitalize",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        Attendance Master
      </h4>
      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left", marginTop: "1rem" }}
      >
        <Grid item xs={12} sm={2} md={2}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeDept(obj.id);
                setDepartmentHead(obj.department_head_name);
              } else {
                setEmployeeDept("");
              }
            }}
            id="department-name"
            options={departmentNames || []}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                newInputValue && getEmployeeDepartment(`name=${newInputValue}`);
              }, 1000);
            }}
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            renderOption={(option: any) => {
              return option?.name;
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Department Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="StartDate"
            value={startDate}
            variant="outlined"
            onChange={(e) => setStartDate(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <TextField
            name="End_date"
            type="date"
            value={endDate}
            className="input"
            label="End Date"
            variant="outlined"
            inputProps={{
              max:MyCurrenttoday,
            }}
            onChange={(e) => setEndDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Autocomplete
            id="gender"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setEmployeeType(obj?.value);
              } else {
                setEmployeeType("");
              }
            }}
            options={[
              {
                text: "All",
                value: "all",
              },
              {
                text: "Active",
                value: "active",
              },
              {
                text: "InActive",
                value: "inactive",
              },
            ]}
            blurOnSelect
            aria-required
            freeSolo
            getOptionLabel={(option: any) => option.text}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Employee Type"
                required
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              attendanceMasterLoading && (
                <CircularProgress size={20} color="primary" />
              )
            }
            fullWidth
            onClick={handleFilter}
            disabled={startDate === "" || endDate === "" || employeeDept === ""}
            style={{
              backgroundColor:
                startDate === "" || endDate === "" || employeeDept === ""
                  ? "gray"
                  : " #1976d2",
              color: "#ffffff",
              borderRadius: "8px",
              padding: "8px 16px",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
              transition: "background-color 0.3s, box-shadow 0.3s",
              boxShadow: "none",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#1565c0";
              e.currentTarget.style.boxShadow =
                "0px 2px 4px rgba(0, 0, 0, 0.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#1976d2";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<RotateLeftIcon />}
            style={{
              backgroundColor: "#9c27b0",
              color: "#ffffff",
              borderRadius: "8px",
              padding: "8px 16px",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
              transition: "background-color 0.3s, box-shadow 0.3s",
              boxShadow: "none",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#7b1fa2";
              e.currentTarget.style.boxShadow =
                "0px 2px 4px rgba(0, 0, 0, 0.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#9c27b0";
              e.currentTarget.style.boxShadow = "none";
            }}
            onClick={() =>
              history.push("/dashboard/payrollManager/Attendance-Master")
            }
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <DownloadTableExcel
            filename="Attendence Record"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <LoadingButton
              component="label"
              role={undefined}
              color="secondary"
              style={{
                backgroundColor:
                  !FilterBtn || attendanceMasterLoading ? "gray" : "#009688",
                color: "#ffffff",
                borderRadius: "8px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                boxShadow: "none",
              }}
              onClick={attendanceMasterLoading}
              disabled={!FilterBtn || attendanceMasterLoading}
              
              onMouseOver={(e) => {
                if (!attendanceMasterLoading) {
                  e.currentTarget.style.backgroundColor = "#7b1fa2";
                  e.currentTarget.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.1)";
                }
              }}
              onMouseOut={(e) => {
                if (!attendanceMasterLoading) {
                  e.currentTarget.style.backgroundColor = !FilterBtn || attendanceMasterLoading ? "gray" : "#9c27b0";
                  e.currentTarget.style.boxShadow = "none";
                }
              }}
              loadingPosition="start"
              variant="contained"
              tabIndex={-1}
              loading={attendanceMasterLoading}
              startIcon={attendanceMasterLoading ? null : <CloudDownloadIcon />}
            >
              {attendanceMasterLoading ? (
                <span>
                  Preparing data... <br />
                  <small>Please wait, we are preparing data for you.</small>
                </span>
              ) : (
                "Export excel"
              )}
              <VisuallyHiddenInput type="file" />
            </LoadingButton>
          </DownloadTableExcel>
        </Grid>
      </Grid>
      <div
        style={{
          padding: "12px",
          marginTop: "12px",
          border: "1px solid #ccc",
          borderRadius: "6px",
          backgroundColor: "#e3f2fd",
          display: "inline-block",
          fontSize: "15px",
          color: "#333",
        }}
      >
        <span style={{ color: "#c62828" }}>
          📅 Please select <strong>Department</strong> and both the{" "}
          <strong>Start Date</strong> and <strong>End Date</strong> to generate
          the Employee Attendance.
        </span>
        <br />
      </div>
      <div style={{ marginTop: 10, width: "100%" }}>
        <div>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
          >
            <Table stickyHeader aria-label="simple table" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Code</StyledTableCell>
                  <StyledTableCell align="center">
                    Employee Name
                  </StyledTableCell>
                  <StyledTableCell align="center">Company Name</StyledTableCell>
                  <StyledTableCell align="center">Department</StyledTableCell>
                  <StyledTableCell align="center">
                    Location Name
                  </StyledTableCell>
                  <StyledTableCell align="center">Designation</StyledTableCell>
                  <StyledTableCell align="center">Domain</StyledTableCell>
                  <StyledTableCell align="center">Mgr Code</StyledTableCell>
                  <StyledTableCell align="center">Manager Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Date of Joining
                  </StyledTableCell>

                  {datesData?.map((date: any) => (
                    <StyledTableCell align="center">{date}</StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    Present Count
                  </StyledTableCell>
                  <StyledTableCell align="center">Absent Count</StyledTableCell>
                  <StyledTableCell align="center">Leave Count</StyledTableCell>
                  <StyledTableCell align="center">Paid Leave</StyledTableCell>
                  <StyledTableCell align="center">unpaid Count</StyledTableCell>
                  <StyledTableCell align="center">WeekOff Days</StyledTableCell>
                  <StyledTableCell align="center">Holidays</StyledTableCell>
                  <StyledTableCell align="center">Working Days</StyledTableCell>
                  <StyledTableCell align="center">Total Days</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              {attendanceMasterLoading ? (
                <Loader />
              ) : (
                <TableBody>
                  {attendanceMaster &&
                    attendanceMaster?.data &&
                    attendanceMaster?.data?.map((user: any) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {user.code || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.employee_name || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.company_name || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.department || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.location || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.designation || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.domain || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.mgr_code || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.manager_name || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user.dateof_joining || "NA"}
                          </StyledTableCell>
                          {datesData?.map((date: any) => (
                            <StyledTableCell align="center" key={date}>
                              {user?.attendance?.find(
                                (data: any) => data.date === date
                              )?.status || (user.dateof_joining&&("A")||"NJ")}
                            </StyledTableCell>
                          ))}

                          <StyledTableCell align="center">
                            {user?.present_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.absent_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.leave_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.paid_leave_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.unpaid_leave_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.weekly_off}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.holidays}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.working_days}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {user?.total_days}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {attendanceMaster &&
            attendanceMaster?.error === "Please select start and  end date" && (
              <div
                style={{
                  padding: "15px",
                  margin: "20px 0",
                  borderRadius: "8px",
                  backgroundColor: "#fddede",
                  border: "1px solid #f5c6c6",
                  color: "#721c24",
                  fontFamily: "'Roboto', sans-serif",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Please select start and end date
                </span>
              </div>
            )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  attendanceData: state.PayrollReducer.attendanceData,
  employeesList: state.loginReducer.employeesList,
  departmentNames: state.loginReducer.departmentNames,
  attendancerecord: state.loginReducer.attendancerecord,
  attendanceMaster: state.loginReducer.attendanceMaster,
  attendanceMasterLoading: state.loginReducer.attendanceMasterLoading,
});
export default connect(mapStateToProps, {
  getAttendanceMaster,
  getEmployeeList,
  getEmployeeDepartment,
  getattendancerecord,
  getAttendanceMasterPro,
})(MyAttendance);
