import React, { useState, useEffect } from "react";
import "./attendancestyle.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { postRegularizationRequest } from "../../../actions/loginActions";
import { connect, useDispatch } from "react-redux";
import { getEmployeeList } from "../../../actions/loginActions";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    rightContentHeader: {
      float: "right",
      textAlign: "right",
      paddingRight: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  })
);

interface Props {
  postRegularizationRequest: Function;
  getEmployeeList: Function;
  employeesList: any;
}

const Attendancehome: React.FC<Props> = ({
  postRegularizationRequest,
  getEmployeeList,
  employeesList,
}) => {
  const [regularizationCategory, setRegularizationCategory] =
    useState("work_from_home");
  const [durationtype, setDurationType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [overlap, setOverlap] = useState("");
  const [reason, setReason] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [employeeUUID, setEmployeeUUID] = useState<any>(employeesList[0]?.uuid);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = yesterday.toISOString().split('T')[0];

  useEffect(() => {
    getEmployeeList("");
    setEmployeeUUID(employeesList.uuid);
  }, []);
  const combineDateTime = (date: string, time: string) => {
    const [year, month, day] = date.split("-");
    const [hours, minutes] = time.split(":");
    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours),
      Number(minutes)
    ).toISOString();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const fromDateTime = combineDateTime(fromDate, fromTime);
    // const toDateTime = combineDateTime(toDate, toTime);
    const data = {
      // duration_type: durationtype,
      regularization_category: regularizationCategory,
      from_date: fromDate,
      // to_date: toDate,
      comments: reason,
      check_in: fromDate + ":" + fromTime,
      check_out: fromDate + ":" + toTime,
    };
    await postRegularizationRequest(data);
    setRegularizationCategory("");
    setDurationType("");
    setFromDate("");
    setToDate("");
    setToTime("");
    setFromTime("");
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          width: "100%",
          textAlign: "center",
          color: "rgb(14, 63, 108)",
        }}
      >
        REGULARIZATION REQUEST
      </h2>
      <div style={{ marginTop: 70, width: "100%" }}>
        <Grid
          container
          spacing={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <form className="attendhello" onSubmit={handleSubmit}>
            <label>
              Location
              <select
                className="tex"
                value={regularizationCategory}
                onChange={(e) => setRegularizationCategory(e.target.value)}
              >
                <option value="work_from_home">Work From Home</option>
                <option value="work_from_office">Work From Office</option>
                <option value="on_duty">On Duty</option>
                {/* <option value="forgot_to_punch">Forgot to Punch</option> */}
              </select>
            </label>
            {/* <label>
              Duration Type:
              <select
                className="tex"
                value={durationtype}
                onChange={(e) => setDurationType(e.target.value)}
              >
                <option value="day_based">Day Based</option>
                <option value="night_based">Night based</option>
              </select>
            </label> */}

            <label>
              Date
              <br />
              <input
                className="tex"
                type="date"
                value={fromDate}
                max={formattedYesterday} // 
                onChange={(e) => setFromDate(e.target.value)}
              />
            </label>
            {/* <label>
              To Date
              <br />
              <input
                className="tex"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </label> */}
            <label>
              In Time
              <br />
              <input
                className="tex"
                type="time"
                value={fromTime}
                onChange={(e) => setFromTime(e.target.value)}
              />
            </label>
            <label>
              Out Time
              <br />
              <input
                className="tex"
                type="time"
                value={toTime}
                onChange={(e) => setToTime(e.target.value)}
              />
            </label>

            {/* <label>
              Overlap Holiday?
              <input
                type="checkbox"
                value={overlap}
                onChange={() => setOverlap}
              />
            </label> */}
            <label>
              Reason
              <textarea
                className="tex"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </label>
            <button
              style={{ backgroundColor: "rgb(14, 63, 108)" }}
              className="but"
              type="submit"
            // disabled={
            //   fromDate === "" ||
            //   toDate === "" ||
            //   fromTime === "" ||
            //   toTime === "" ||
            //   reason === ""
            // }
            >
              Apply
            </button>
          </form>
        </Grid>
      </div>
    </main>
  );
};

// export default Attendancehome;
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
});

export default connect(mapStateToProps, {
  postRegularizationRequest,
  getEmployeeList,
})(Attendancehome);
