import React, { useEffect, useRef, useState } from "react";
import {
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { connect } from "react-redux";
import {
} from "../../actions/PayrollActions";
import "./style.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        doctor__cards: {
            display: "flex",
            flexWrap: "wrap",
            width: "94%",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "monospace",
        },
        inner__Cards: {
            boxShadow: "0 8px 10px 0px rgba(16, 16, 17, 0.5)",
            height: "50px",
            border: "5px",
            width: "46%",
            margin: "10px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        inner__doctor__cards: {
            boxShadow: "0 8px 10px 0px rgba(16, 16, 17, 0.5)",
            border: "15px",
            height: "50px",
            width: "46%",
            margin: "10px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: 'scroll',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid green",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
        },

        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            // width: auto,

        },
    })
);

interface Props {

    loading: boolean;

}

const Dashboard: React.FC<Props> = ({

    loading,

}) => {
    const classes = useStyles();
    const timer = useRef(0);
    const [selectedContent, setSelectedContent] = useState('address');

    const showContent = (contentId: any) => {
        setSelectedContent(contentId);
    };


    return (
        <div >

            <div className="sidebar">
                <div
                    className="content-item"
                    onClick={() => showContent('about')}>
                    <PersonIcon />
                    About
                </div>
                <div
                    className="content-item"
                    onClick={() => showContent('address')}>
                    <LocationOnIcon /> Address
                </div>
                <div
                    className="content-item"
                    onClick={() => showContent('contact')}>
                    <LocalPhoneIcon />
                    Contact
                </div>
                <div
                    className="content-item"
                    onClick={() => showContent('biodata')}>
                    <ArticleIcon />
                    Biodata

                </div>
                <div
                    className="content-item"
                    onClick={() => showContent('dates')}>
                    <CalendarMonthIcon />
                    Dates
                </div>
                <div
                    className="content-item"
                    onClick={() => showContent('dependency')}>
                    <FamilyRestroomIcon />
                    Dependents
                </div>
            </div>

             <div className="right-content">
                <div className={`content-details ${selectedContent === 'address' && 'active'}`}>
                    <h2>Address</h2>
                    <p>Insert address details here.</p>
                </div>
                <div className={`content-details ${selectedContent === 'about' && 'active'}`}>
                    <h2>About</h2>
                    <p>Insert about details here.</p>
                </div>
                <div className={`content-details ${selectedContent === 'contact' && 'active'}`}>
                    <h2>Contact</h2>
                    <p>Insert contact details here.</p>
                </div>
                <div className={`content-details ${selectedContent === 'biodata' && 'active'}`}>
                    <h2>Biodata</h2>
                    <p>Insert biodata details here.</p>
                </div>
                <div className={`content-details ${selectedContent === 'dates' && 'active'}`}>
                    <h2>Dates</h2>
                    <p>Insert dates details here.</p>
                </div>
                <div className={`content-details ${selectedContent === 'dependency' && 'active'}`}>
                    <h2>Dependency</h2>
                    <p>Insert dependency details here.</p>
                </div>
            </div> 

        </div>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.PayrollReducer.loading,

});

export default connect(mapStateToProps, {

})(Dashboard);