import {
  combineReducers
} from 'redux';

import snackBarReducer from './snackBarReducer';
import loginReducer from './loginReducer';
import PayrollReducer from "../PayrollManager-Panel/reducers/PayrollReducer";
import EmployeeReducer from "../PayrollManager-Panel/reducers/PayrollReducer";
import customBarReducer from './customSnackBar';



const rootReducer = combineReducers({
  customBarReducer,
  snackBarReducer,
  loginReducer,
  PayrollReducer,
  EmployeeReducer
});

export default rootReducer;