import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import App from './notification'
import moment from 'moment';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),

            ...theme.mixins.toolbar,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            overflow: "scroll",
        },
    })
);



interface Props {


}

const MessageTable: React.FC<Props> = ({


}) => {
    const classes = useStyles()


    const [messageType, setMessageType] = useState<any>(false);
    useEffect(() => {

    }, [messageType])
    const handleUnReadMessage = () => {
        setMessageType(false)
    }
    const handleReadMessage = () => {
        setMessageType(true)
    }

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            <h1 style={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0rem",
                display: "block",
                width: "100%",
                color: "rgb(14, 63, 108)"
            }}>
                UPDATES
            </h1>
            <App />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    notificationMessage: state.PayrollReducer.notificationMessage,
})

export default connect(mapStateToProps, {
})(MessageTable);

