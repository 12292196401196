
export const arr = [
  {
    role: "PayrollManager",
    url: "payrollManager",
  },
  {
    role: "Employee",
    url: "employee",
  },
  {
    role: "Admin",
    url: "admin",
  },
  {
    role: "HRManager",
    url: "hrmanager",
  },
];

