import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import Modal from "@mui/material/Modal";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
  TableCell,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  getLmsCourses,
  createLMSCourses,
  updateLMSCourse,
  bulkCourseActions,
  postCreateBatch,
  updateLmsBatch,
  getLmsBatches,
} from "../../actions/PayrollActions";
import {
  getEmployeeDepartment,
  getEmployeeALlData,
} from "../../../actions/loginActions";
import {
  generateLmsCourseFilter,
  generateUserFilter,
} from "../../../helper/generate";
import { cursorTo } from "readline";
import { filter } from "jszip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      //   marginTop: "1rem",
      padding: "-1rem 2rem 2rem 2rem",
      width: "100%",
      borderRadius: "10px",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      maxHeight: "600px",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    container: {
      position: "sticky",
      marginTop: 30,
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputField: {
      backgroundColor: "rgb(230 242 255)",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#1C4E80",
      color: theme.palette.common.white,
      fontSize: "16px",
    },
    body: {
      fontSize: 14,
      padding: "5px",
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  createLMSCourses: Function;
  getLmsCourses: Function;
  lmsCourses: any;
  getEmployeeDepartment: Function;
  departmentNames: any;
  getEmployeeALlData: Function;
  employee_all_data: any;
  bulkCourseActions: Function;
  updateLMSCourse: Function;
  postCreateBatch: any;
  getLmsBatches: any;
  batches: any;
  updateLmsBatch: any;
}
const Courses: React.FC<Props> = ({
  createLMSCourses,
  getLmsCourses,
  lmsCourses,
  getEmployeeDepartment,
  departmentNames,
  getEmployeeALlData,
  employee_all_data,
  bulkCourseActions,
  updateLMSCourse,
  postCreateBatch,
  getLmsBatches,
  batches,
  updateLmsBatch,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
  const [openCreateCourseDrawer, setOpenCreateCourseDrawer] =
    React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [openAction, setOpenAction] = React.useState(false);
  const [mapping, setMapping] = React.useState("departments");
  const [updateMode, setUpdateMode] = React.useState(false);
  const [updateCourseId, setUpdateCourseId] = React.useState(0);
  const [expandSummary, setExpandSummary] = useState<number[]>([]);
  const [expandInstruction, setExpandInstruction] = useState<number[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<any>([]);
  const [selectedEmployess, setSelectedEmployees] = useState<any>([]);
  const [actionType, setActionType] = useState("");
  const [openImage, setOpenImage] = React.useState(false);
  const [fileChanged, setFileChanged] = React.useState(false);
  const [openBatch, setOpenBatch] = useState(false);
  const [openCreateBatch, setCreateBatch] = useState(false);
  const [openAssignUsers, setOpenAssignUsers] = useState(false);
  const [assignBatchId, setAssignBatchId] = useState(0);
  const [page, setPage] = React.useState<any>(0);
  const [departmentIds, setDepartmentIds] = React.useState<any>([]);
  const [openEmployeeDetails, setOpenEmployeeDetails] = React.useState(false);
  const [disabledButton, setDisableButton] = React.useState(false);
  const [clickedEmployeeData, setClickedEmployeeData] = React.useState<any>([]);
  const [filterClickedEmployeeData, setFilterClickedEmployeeData] =
    React.useState<any>([]);
  const [key, setKey] = useState<any>(false);
  const [courseData, setCourseData] = React.useState<any>({
    course_id: "",
    name: "",
    batch_name: "",
    start_date: "",
    end_date: "",
    summary: "",
    instruction: "",
    thumbnail: "",
    course_status: "",
    modules: [],
    department: [],
    with_certificate: false,
    assigned_users: [],
    start_joining: "",
    end_joining: "",
    employee_id: "",
  });

  useEffect(() => {
    getLmsCourses();
    getEmployeeDepartment();
    getLmsBatches();
  }, []);
  const handleSummaryClick = (rowIndex: number) => {
    setExpandSummary((prev: any) =>
      prev.includes(rowIndex)
        ? prev.filter((index: any) => index !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  const handleInstructionClick = (rowIndex: number) => {
    setExpandInstruction((prev: any) =>
      prev.includes(rowIndex)
        ? prev.filter((index: any) => index !== rowIndex)
        : [...prev, rowIndex]
    );
  };
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenFilterDrawer(open);
    };

  const toggleDrawer2 =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenCreateCourseDrawer(open);
    };

  const handleCourseChange = async (value: any, key: string) => {
    setCourseData((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUpdateCourse = (courseData: any) => {
    setOpenCreateCourseDrawer(true);
    setUpdateMode(true);
    setCourseData({
      name: courseData.name || "",
      summary: courseData.summary || "",
      instruction: courseData.instruction || "",
      days: courseData.days || null,
      start_date: courseData.start_date || "",
      end_date: courseData.end_date || "",
      course_status: courseData.course_status || "",
      thumbnail: courseData.thumbnail,
      modules: courseData.modules || [],
      department: courseData.department.map((item: any) => item.id) || [],
      with_certificate: courseData.with_certificate || false,
      assigned_users: courseData.assigned_users || [],
    });

    setUpdateCourseId(courseData.id);
  };

  const handleCourseFilter = async () => {
    const body: any = {
      startDate: courseData.start_date,
      endDate: courseData.end_date,
      courseName: courseData.name,
      courseStatus: courseData.course_status,
      courseId: courseData.course_id,
    };

    const url = generateLmsCourseFilter(body);
    await getLmsCourses(url ? `?${url.slice(1)}` : "");
    setOpenFilterDrawer(false);
  };

  const handleUserFilter = () => {
    const body: any = {
      start_date: courseData.start_joining,
      end_date: courseData.end_joining,
      employee_id: courseData.employee_id,
    };
    const url = generateUserFilter(body);
    getEmployeeALlData(
      url ? `department=${departmentIds}&${url.slice(1)}` : ""
    );
  };

  const handleEmployeeFilter = () => {
    const employee_id = courseData.employee_id;
    const filterData = clickedEmployeeData.filter(
      (employeeClicked: any) => employeeClicked.employee_id === employee_id
    );
    setFilterClickedEmployeeData(filterData);
  };

  const handleEmployeeReset = () => {
    setFilterClickedEmployeeData(clickedEmployeeData);
  };

  const handleBulkActions = async () => {
    const allCourses = lmsCourses?.results.filter((course: any) =>
      selectedCourses.includes(course.id)
    );
    const checkPublishedStatus = allCourses.some(
      (oneCourse: any) => oneCourse.course_status === "published"
    );
    const checkUnpublishedStatus = allCourses.some(
      (oneCourse: any) => oneCourse.course_status === "draft"
    );
    const isNotContainModules = allCourses.some(
      (oneCourse: any) => oneCourse.modules === null
    );
    const isNotContainBatch = allCourses.some(
      (oneCourse: any) => oneCourse.batch.length === 0
    );
    if (isNotContainBatch && actionType === "published") {
      alert("Please select the batch before publish the course");
      return;
    } else if (checkPublishedStatus && actionType === "published") {
      alert("You can not published already published course");
      return;
    } else if (checkUnpublishedStatus && actionType === "draft") {
      alert("You can not unpublished already unpublished course");
      return;
    } else if (isNotContainModules && actionType === "published") {
      alert("You can not published course with 0 module");
      return;
    }

    const data = {
      action_type: actionType === "delete" ? "delete" : "update",
      course_ids: selectedCourses,
      status: actionType === "delete" ? "" : actionType,
    };

    await bulkCourseActions(data);
    getLmsCourses();
    setOpenAction(false);
    setSelectedCourses([]);
  };

  const handleEmployeeData = (course: any, key: any) => {
    if (
      key === "invited-users" &&
      course?.no_of_users_invited?.length > 0
    ) {
      setClickedEmployeeData(course?.no_of_users_invited);
      setOpenEmployeeDetails(true);
    } else if (
      key === "attempted-users" &&
      course?.no_of_users_attempted?.length > 0
    ) {
      setClickedEmployeeData(course?.no_of_users_attempted);
      setOpenEmployeeDetails(true);
    } else if (
      key === "not-attempted-users" &&
      (course?.no_of_users_invited?.length - course?.no_of_users_attempted?.length) > 0
    ) {
      const attemptedUsersId = course?.no_of_users_attempted?.map(
        (attempted: any) => attempted.employee_id
      );
      const notAttemptedUsers = course?.no_of_users_invited?.filter(
        (invited: any) => !attemptedUsersId.includes(invited.employee_id)
      );
      setClickedEmployeeData(notAttemptedUsers);
      setOpenEmployeeDetails(true);
    } else if (
      key === "completed-users" &&
      course?.no_of_users_completed?.length > 0
    ) {
      setClickedEmployeeData(course?.no_of_users_completed);
      setOpenEmployeeDetails(true);
    } else if (
      key === "failed-users" &&
      (course?.no_of_users_attempted?.length - course?.no_of_users_completed?.length)  > 0
    ) {
      const passedUsers = course?.no_of_users_completed?.map(
        (passed: any) => passed.employee_id
      );
      const failedUsers = course?.no_of_users_attempted?.filter(
        (attempt: any) => !passedUsers.includes(attempt.employee_id)
      );
      setClickedEmployeeData(failedUsers);
      setOpenEmployeeDetails(true);
    }

  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setDisableButton(true);

    const formData = new FormData();
    formData.append("name", courseData.name);
    formData.append("summary", courseData.summary);
    formData.append("instruction", courseData.instruction);
    formData.append("course_status", courseData.course_status);
    formData.append(
      "with_certificate",
      courseData.with_certificate ? "true" : "false"
    );
    formData.append("department", courseData.department);

    if (!updateMode) {
      formData.append("thumbnail", courseData.thumbnail);
      await createLMSCourses(formData);
      getLmsCourses();
      setOpenCreateCourseDrawer(false);
    } else if (updateMode) {
      if (fileChanged) {
        formData.append("thumbnail", courseData.thumbnail);
      }
      await updateLMSCourse({ updateCourseId, formData });
      getLmsCourses();
      setFileChanged(false);
      setOpenCreateCourseDrawer(false);
    }
  };

  useEffect(() => {
    if (disabledButton) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setDisableButton(false);
      }, 15000);
    }
  }, [disabledButton]);

  const handleCharacterLimit = (
    inputText: string,
    updateModule: (text: string, field: string) => void
  ) => {
    if (inputText.length <= 30) {
      updateModule(inputText, "name");
    } else {
      const truncatedText = inputText.slice(0, 30);
      updateModule(truncatedText, "name");
    }
  };

  const checkCourseId = (event: any, courseId: number) => {
    if (selectedCourses?.length < 100) {
      !event.target.checked
        ? setSelectedCourses(
            selectedCourses.filter((course_id: any) => course_id !== courseId)
          )
        : setSelectedCourses((prev: any) => [...prev, courseId]);
    } else {
      return false;
    }
  };

  const checkEmployeeId = (event: any, employeeId: number) => {
    if (selectedEmployess?.length < 100) {
      !event.target.checked
        ? setSelectedEmployees(
            selectedEmployess.filter(
              (employee_id: any) => employee_id !== employeeId
            )
          )
        : setSelectedEmployees((prev: any) => [...prev, employeeId]);
    } else {
      return false;
    }
  };

  const handleAssignUsers = () => {
    setOpenAssignUsers(true);
    const myCourse = lmsCourses?.results?.filter((course: any) =>
      selectedCourses.includes(course.id)
    );
    const uniqueDepartmentIds = Array.from(
      new Set(
        myCourse.flatMap((item: any) =>
          item.department.map((dept: any) => dept.id)
        )
      )
    );
    setDepartmentIds(uniqueDepartmentIds);
    const departmentParam = uniqueDepartmentIds?.join(",");
    getEmployeeALlData(`department=${departmentParam}`);
  };

  const handleAssignCourse = async () => {
    let updateCourseId = 0;
    if (selectedCourses.length > 1) {
      alert("You can assign users for one course at a time only");
      return;
    } else {
      updateCourseId = selectedCourses[0];
    }

    const allSelectedEmployees = selectedEmployess?.join(",");
    const formData = new FormData();
    formData.append("assigned_users", allSelectedEmployees);
    await updateLMSCourse({ updateCourseId, formData });
    getLmsCourses();
    setOpenAssignUsers(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        employee_all_data.links && employee_all_data.links.next.split("?")[1];
      getEmployeeALlData(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        employee_all_data.links &&
        employee_all_data.links.previous.split("?")[1];
      getEmployeeALlData(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };

  const handleCreateBatch = async () => {
    if (openCreateBatch === true) {
      const coursesId = selectedCourses?.join(",");

      const data = {
        batch_name: courseData.batch_name,
        start_date: courseData.start_date,
        end_date: courseData.end_date,
        courses: coursesId,
      };
      await postCreateBatch(data);
      getLmsCourses();
      setOpenBatch(false);
    } else if (openCreateBatch === false) {
      const coursesId = selectedCourses?.join(",");
      const data = {
        courses: coursesId,
      };

      updateLmsBatch({ assignBatchId, data });
      getLmsCourses();
      setOpenBatch(false);
    }
  };

  const handleResetFilter = () => {
    getEmployeeALlData(`department=${departmentIds}`);
    setCourseData({
      course_id: "",
      name: "",
      batch_name: "",
      start_date: "",
      end_date: "",
      summary: "",
      instruction: "",
      thumbnail: "",
      course_status: "",
      modules: [],
      department: [],
      with_certificate: false,
      assigned_users: [],
      start_joining: "",
      end_joining: "",
      employee_id: "",
    })
    setKey(!key)
  };

  useEffect(() => {
    if (clickedEmployeeData) {
      setFilterClickedEmployeeData(clickedEmployeeData);
    }
  }, [clickedEmployeeData, lmsCourses]);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4
            style={{
              fontWeight: "bold",
              textAlign: "left",

              color: "#2d148a",
              fontSize: "2rem",
              fontFamily: "'Arial Black', sans-serif",
              marginTop: "20px",
              display: "inline-block",
            }}
          >
            LMS Courses
          </h4>
        </div>
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ModeEditOutlineIcon />}
              onClick={handleAssignUsers}
              disabled={selectedCourses?.length === 0}
            >
              Assign
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ModeEditOutlineIcon />}
              onClick={() => setOpenBatch(true)}
              disabled={selectedCourses?.length === 0}
            >
              Batch
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ModeEditOutlineIcon />}
              onClick={() => setOpenAction(true)}
              disabled={selectedCourses?.length === 0}
            >
              Action
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer(true)(event);
              }}
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                toggleDrawer2(true)(event);
                setUpdateMode(false);
                setCourseData({
                  name: "",
                  summary: "",
                  instruction: "",
                  days: null,
                  start_date: "",
                  end_date: "",
                  course_status: "",
                  success_percentage: "",
                  modules: [],
                  department: [],
                  with_certificate: false,
                  assigned_users: [],
                });
              }}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </div>
        </div>
      </div>

      {(["right"] as const).map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
          >
            <Box sx={{ width: 450, margin: "70px 40px", padding: "0" }}>
              <div>
                <h3
                  style={{
                    marginTop: "10px",
                    color: "#1C4E80",
                    fontWeight: 900,
                    letterSpacing: "0.3px",
                    textAlign: "center",
                  }}
                >
                  Apply Filters
                </h3>
              </div>
              <hr style={{ borderBottom: "3px solid #007BFF" }} />
              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Course Id
                </label>
                <div>
                  <TextField
                    className="input"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e: any) =>
                      handleCourseChange(e.target.value, "course_id")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="employee" style={{ fontWeight: "bold" }}>
                  Course Status
                </label>
                <div>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e: any) =>
                      handleCourseChange(e.target.value, "course_status")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="published">Published</MenuItem>
                    <MenuItem value="unpublished">Unpublished</MenuItem>
                  </TextField>
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label style={{ fontWeight: "bold" }}>Date</label>
              </div>
              <div style={{ display: "flex" }}>
                <TextField
                  className="input"
                  name="start_date"
                  id="noTyping"
                  type="date"
                  label="Start Date"
                  variant="outlined"
                  onChange={(e) =>
                    handleCourseChange(e.target.value, "start_date")
                  }
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className="input"
                  name="end_date"
                  id="noTyping"
                  type="date"
                  label="End Date"
                  variant="outlined"
                  onChange={(e) =>
                    handleCourseChange(e.target.value, "end_date")
                  }
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Course Name
                </label>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      handleCourseChange(obj.name, "name");
                    }
                  }}
                  id="employee"
                  options={lmsCourses?.results || []}
                  getOptionLabel={(option: any) => {
                    return option?.name;
                  }}
                  renderOption={(option: any) => {
                    return <p>{option.name}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Course"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button variant="contained" onClick={handleCourseFilter}>
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    setCourseData({
                      course_id: "",
                      name: "",
                      summary: "",
                      instruction: "",
                      days: null,
                      start_date: "",
                      end_date: "",
                      course_status: "",
                      success_percentage: "",
                      modules: [],
                      department: [],
                      with_certificate: false,
                      assigned_users: [],
                    })
                  }
                >
                  Reset
                </Button>
              </div>
            </Box>
          </Drawer>
        </>
      ))}
      {(["right"] as const).map((anchor) => (
        <>
          <Drawer
            anchor={anchor}
            open={openCreateCourseDrawer}
            onClose={() => setOpenCreateCourseDrawer(false)}
          >
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: 450, margin: "50px 40px", padding: "0" }}>
                <div>
                  <h3
                    style={{
                      marginTop: "10px",
                      color: "#1C4E80",
                      fontWeight: 900,
                      letterSpacing: "0.3px",
                      textAlign: "center",
                    }}
                  >
                    {updateMode ? "Update Course" : "Create Course"}
                  </h3>
                </div>
                <hr style={{ borderBottom: "3px solid #007BFF" }} />
                <div>
                  <label htmlFor="image" style={{ fontWeight: "bold" }}>
                    Thumbnail (Image 200 x 100 recommended) *
                  </label>
                  {!updateMode ? (
                    <div>
                      <input
                        id="image"
                        type="file"
                        accept=".jpeg, .png,.jpg"
                        required
                        onChange={(e: any) =>
                          handleCourseChange(e.target.files[0], "thumbnail")
                        }
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div>
                        <input
                          id="image"
                          type="file"
                          accept=".jpeg, .png,.jpg"
                          onChange={(e: any) => {
                            handleCourseChange(e.target.files[0], "thumbnail");
                            setFileChanged(true);
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => setOpenImage(true)}
                          disabled={courseData.thumbnail === null}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="department-name"
                    style={{ fontWeight: "bold" }}
                  >
                    Course title *
                  </label>
                  <div>
                    <TextField
                      className={"input"}
                      fullWidth
                      name="name"
                      type="text"
                      size="small"
                      variant="outlined"
                      required
                      value={courseData.name}
                      onChange={(e: any) =>
                        handleCharacterLimit(e.target.value, handleCourseChange)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "gray",
                      marginTop: "5px",
                    }}
                  >
                    {courseData?.name?.length || 0} / 30 characters
                  </p>
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="course-summary"
                    style={{ fontWeight: "bold" }}
                  >
                    Course Summary (Optional)
                  </label>
                  <div>
                    <TextareaAutosize
                      className={"input"}
                      value={courseData.summary}
                      onChange={(e) =>
                        handleCourseChange(e.target.value, "summary")
                      }
                      style={{ width: "100%", borderRadius: "4px" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Type summary here..."
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label
                    htmlFor="course-instruction"
                    style={{ fontWeight: "bold" }}
                  >
                    Course Instruction (Optional)
                  </label>
                  <div>
                    <TextareaAutosize
                      className="input"
                      value={courseData.instruction}
                      onChange={(e) =>
                        handleCourseChange(e.target.value, "instruction")
                      }
                      style={{ width: "100%", borderRadius: "4px" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder=" Type instruction here..."
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label htmlFor="module-status" style={{ fontWeight: "bold" }}>
                    Course Status *
                  </label>
                  <div>
                    <TextField
                      className="input"
                      type="text"
                      select
                      fullWidth
                      size="small"
                      required
                      variant="outlined"
                      value={courseData.course_status}
                      onChange={(e: any) =>
                        handleCourseChange(e.target.value, "course_status")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="draft">(Unpublished) Draft</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div>
                  <label htmlFor="module-status" style={{ fontWeight: "bold" }}>
                    Mapping
                  </label>
                  <div>
                    <TextField
                      className="input"
                      type="text"
                      select
                      fullWidth
                      size="small"
                      required
                      variant="outlined"
                      value={mapping}
                      onChange={(e) => setMapping(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="all" disabled>
                        All
                      </MenuItem>
                      <MenuItem value="departments">Departments</MenuItem>
                      <MenuItem value="employees" disabled>
                        Employess
                      </MenuItem>
                    </TextField>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                {mapping === "departments" && (
                  <div>
                    <label
                      htmlFor="reporting-manager"
                      style={{ fontWeight: "bold" }}
                    >
                      Select Departments *
                    </label>
                    <div>
                      <Autocomplete
                        multiple
                        limitTags={0}
                        value={
                          departmentNames?.filter((dept: any) =>
                            courseData?.department?.includes(dept.id)
                          ) || []
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let packs: any = [];
                            newValue.map((val: any) => {
                              let obj = JSON.parse(
                                JSON.stringify(val, null, " ")
                              );
                              packs.push(obj.id);
                            });
                            handleCourseChange(packs, "department");
                          }
                        }}
                        options={departmentNames || []}
                        getOptionLabel={(option: any) => {
                          return option.name;
                        }}
                        renderOption={(option: any) => {
                          return option?.name;
                        }}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            newInputValue &&
                              getEmployeeDepartment(`name=${newInputValue}`);
                          }, 1000);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Select Departments"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            inputProps={{
                              ...params.inputProps,
                              onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                }
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div>
                    <label
                      htmlFor="department-name"
                      style={{ marginTop: "3px", fontWeight: "bold" }}
                    >
                      Check for enable Certificate:
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="with_certificate"
                      checked={courseData.with_certificate}
                      onChange={(e) =>
                        handleCourseChange(e.target.checked, "with_certificate")
                      }
                      id=""
                      style={{ width: "15px", height: "15px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
                {/* {checked && (
                  <>
                    <div>
                      <label
                        htmlFor="department-name"
                        style={{ fontWeight: "bold" }}
                      >
                        Select Certificates
                      </label>
                      <Autocomplete
                        multiple
                        limitTags={0}
                        // onChange={(event, newValue) => {
                        //   if (newValue) {
                        //     let packs: any = [];
                        //     newValue.map((val: any) => {
                        //       let obj = JSON.parse(
                        //         JSON.stringify(val, null, " ")
                        //       );
                        //       packs.push(obj);
                        //     });
                        //     setEmployeeList(packs);
                        //   }
                        // }}

                        id="checkboxes-tags-demo"
                        options={[
                          {
                            text: "center",
                            value: "value",
                          },
                        ]}
                        getOptionLabel={(option: any) => {
                          return option?.text;
                        }}
                        renderOption={(option: any) => {
                          return (
                            <p style={{ margin: "0 10px" }}>{option.text}</p>
                          );
                        }}
                        // onInputChange={(event, newInputValue) => {
                        //   clearTimeout(timer.current);
                        //   timer.current = setTimeout(() => {
                        //     newInputValue &&
                        //       getMyTeamDetails(`employee_id=${newInputValue}`);
                        //   }, 1000);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></div>
                  </>
                )} */}

                <div
                  style={{
                    display: "flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={disabledButton || courseData.department.length === 0}
                  >
                    {updateMode ? "Update Course" : "Save & Published"}
                  </Button>
                  <p>{disabledButton ? "Please wait course is creating..." :""}</p>
                </div>
              </Box>
            </form>
          </Drawer>
        </>
      ))}

      <hr style={{ borderBottom: "3px solid #007BFF" }} />
      <TableContainer
        className={classes.container}
        style={{ maxHeight: "540px" }}
        component={Paper}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">
                <input
                  type="checkbox"
                  className="input"
                  name="selectall"
                  id="selectall"
                  style={{ transform: "scale(1.5)" }}
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedCourses(
                          lmsCourses?.results?.map(
                            (data: any, index: number) => {
                              return data.id;
                            }
                          )
                        )
                      : setSelectedCourses([])
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 120 }}>
                <b>Course Id</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Course Title</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 400 }}>
                <b>Course Summary</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 400 }}>
                <b>Course Instruction</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 350 }}>
                <b>Mapping</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 350 }}>
                <b>Batch</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 350 }}>
                <b>Course Status</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Batch Start Date</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Batch End Date</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Modules</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Invited users</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Attempted</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Not Attempted</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Completed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Passed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>No. of Users Failed</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Success status</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Course Created By</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Course Created Timestamp</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Course Update By</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Course Updated Timestamp</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Certificate</b>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 150 }}>
                <b>Completion Status</b>
              </StyledTableCell>
              <StyledTableCell align="center">
                <b>Click To Action</b>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {lmsCourses?.results?.map((course: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  <input
                    type="checkbox"
                    name="selectall"
                    id="selectall"
                    checked={selectedCourses.find(
                      (qa: any) => qa === course.id
                    )}
                    onChange={(e) => checkCourseId(e, course.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.id || "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.name || "No Name"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    style={{
                      display: "inline-block",
                      width: "300px",
                      textAlign: "justify",
                    }}
                  >
                    {expandSummary.includes(index)
                      ? course?.summary || "No summary available"
                      : `${course?.summary?.slice(0, 50) || ""}... `}
                    <Typography
                      onClick={() => handleSummaryClick(index)}
                      style={{
                        cursor: "pointer",
                        color: "black",
                        display: "inline",
                        fontWeight: "bold",
                      }}
                    >
                      {expandSummary.includes(index) ? "see less" : "see more"}
                    </Typography>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    style={{
                      display: "inline-block",
                      width: "300px",
                      textAlign: "justify",
                    }}
                  >
                    {expandInstruction.includes(index)
                      ? course?.instruction || "No instructions available"
                      : `${course?.instruction?.slice(0, 50) || ""}... `}
                    <Typography
                      onClick={() => handleInstructionClick(index)}
                      style={{
                        cursor: "pointer",
                        color: "black",
                        display: "inline",
                        fontWeight: "bold",
                      }}
                    >
                      {expandInstruction.includes(index)
                        ? "see less"
                        : "see more"}
                    </Typography>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {Array.isArray(course?.department)
                    ? course?.department
                        .map((dept: any) => dept.name)
                        .join(", ")
                    : "No departments"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {Array.isArray(course?.batch)
                    ? course?.batch
                        ?.map((batch_data: any) => batch_data.name)
                        .join(", ") || "N/A"
                    : ""}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.course_status || "Pending"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(course?.start_date).isValid()
                    ? moment(course.start_date).format("YYYY-MM-DD")
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(course?.end_date).isValid()
                    ? moment(course.end_date).format("YYYY-MM-DD")
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.modules?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEmployeeData(course, "invited-users")}
                >
                  {course?.no_of_users_invited?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleEmployeeData(course, "attempted-users")
                  }
                >
                  {course?.no_of_users_attempted?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleEmployeeData(course, "not-attempted-users")
                  }
                >
                  {course?.no_of_users_invited?.length -
                    course?.no_of_users_attempted?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleEmployeeData(course, "completed-users")
                  }
                >
                  {course?.no_of_users_completed?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleEmployeeData(course, "completed-users")
                  }
                >
                  {course?.no_of_users_completed?.length || 0}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEmployeeData(course, "failed-users")}
                >
                  {course?.no_of_users_attempted?.length -
                    course?.no_of_users_completed?.length || 0}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.no_of_users_invited?.length
                    ? `${(
                        (course?.no_of_users_attempted?.length /
                          course?.no_of_users_invited?.length) *
                        100
                      ).toFixed(2)}%`
                    : "0.00%"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.created_by}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(course?.created_at).format("HH:mm")},{" "}
                  {moment(course?.created_at).format("YYYY-MM-DD")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.updated_by}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(course?.updated_at).format("HH:mm")},{" "}
                  {moment(course?.updated_at).format("YYYY-MM-DD")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.with_certificate === true ? "True" : "False"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {course?.completion_status || "Incomplete"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => handleUpdateCourse(course)}
                  >
                    Edit
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openAction}
        onClose={() => setOpenAction(false)}
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
      >
        <DialogTitle style={{ fontWeight: "600" }}>ACTIONS</DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              className="input"
              select
              fullWidth
              size="small"
              variant="outlined"
              onChange={(e: any) => setActionType(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="draft">Unpublished</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
            </TextField>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="small"
              disabled={actionType === ""}
              onClick={handleBulkActions}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openImage}
        onClose={() => setOpenImage(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ borderRadius: "10px" }}>
          <img
            src={courseData.thumbnail}
            style={{ width: "500px", height: "300px" }}
            title="IMAGE Document"
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBatch}
        onClose={() => setOpenBatch(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Paper style={{ width: "490px", padding: "20px" }}>
          <div style={{ width: "100%" }}>
            <label htmlFor="batch-name" style={{ fontWeight: "bold" }}>
              Select Batch
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "80%" }}>
                <Autocomplete
                  onChange={(event, obj) => {
                    if (obj) {
                      handleCourseChange(obj?.batch_name, "batch_name");
                      setAssignBatchId(obj.id);
                    }
                  }}
                  id="batch"
                  disabled={openCreateBatch === true}
                  options={batches?.results || []}
                  getOptionLabel={(option: any) => {
                    return option?.batch_name || "";
                  }}
                  renderOption={(option: any) => {
                    return <p>{option?.batch_name || "Unnamed Batch"}</p>;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      placeholder="Select Batch"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{ padding: "5px" }}
                  onClick={() => setCreateBatch(!openCreateBatch)}
                >
                  {!openCreateBatch ? "+" : "-"}
                </Button>
              </div>
            </div>
          </div>
          {openCreateBatch && (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  gap: "10px",
                  width: "100%",
                }}
              ></div>
              <div>
                <label htmlFor="department-name" style={{ fontWeight: "bold" }}>
                  Batch Name
                </label>
                <div>
                  <TextField
                    className={"input"}
                    fullWidth
                    name="batch_name"
                    type="text"
                    size="small"
                    variant="outlined"
                    required
                    value={courseData.batch_name}
                    onChange={(e) =>
                      handleCourseChange(e.target.value, "batch_name")
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <div>
                <label htmlFor="module-status" style={{ fontWeight: "bold" }}>
                  Batch Duration *
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div>
                    <TextField
                      className="input"
                      name="start_date"
                      label="Start Date"
                      type="date"
                      required
                      value={courseData.start_date}
                      onChange={(e) =>
                        handleCourseChange(e.target.value, "start_date")
                      }
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "220px", margin: "0" }}
                    />
                  </div>
                  <div>
                    <TextField
                      className="input"
                      name="end_date"
                      label="End Date"
                      type="date"
                      required
                      value={courseData.end_date}
                      onChange={(e) =>
                        handleCourseChange(e.target.value, "end_date")
                      }
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "220px", margin: "0" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button variant="contained" onClick={handleCreateBatch}>
              {openCreateBatch ? "Create Batch" : "Assign Batch"}
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAssignUsers}
        onClose={() => setOpenAssignUsers(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Paper style={{ width: "70%", padding: "20px" }}>
          <h2>Select employees to assign course</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div style={{ width: "300px" }}>
              <Autocomplete
                key={key}
                onChange={(event, obj) => {
                  if (obj) {
                    handleCourseChange(obj.employee_id, "employee_id");
                  }
                }}
                id="employee_ids"
                options={employee_all_data?.results || []}
                getOptionLabel={(option: any) => {
                  return option?.employee_id + " " + option.name;
                }}
                renderOption={(option: any) => {
                  return (
                    <p>
                      <b style={{ color: "blue" }}>Employee Name:</b>
                      <strong>{option?.name.toString()}</strong>
                      <br />
                      <b style={{ color: "red" }}>Employee Id:</b>
                      {option?.employee_id?.toUpperCase()}
                    </p>
                  );
                }}
                // onInputChange={(event, newInputValue) => {
                //   clearTimeout(timer.current);
                //   timer.current = setTimeout(() => {
                //     newInputValue &&
                //       getEmployeeALlData(`department=${departmentIds}&employee_id=${newInputValue}`);
                //   }, 1000);
                // }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Employee ID Or Name"
                    variant="outlined"
                    style={{ width: "100%" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </div>
            <div style={{ width: "200px" }}>
              <TextField
                className="input"
                name="start_joining"
                label="Start Date"
                type="date"
                value={courseData.start_joining}
                onChange={(e) =>
                  handleCourseChange(e.target.value, "start_joining")
                }
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ width: "200px" }}>
              <TextField
                className="input"
                name="end_joining"
                label="End Date"
                type="date"
                value={courseData.end_joining}
                onChange={(e) =>
                  handleCourseChange(e.target.value, "end_joining")
                }
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <Button variant="contained" onClick={handleUserFilter}>
                Filter
              </Button>
            </div>
            <div>
              <Button variant="contained" onClick={handleResetFilter}>
                Reset
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={handleAssignCourse}
                disabled={selectedEmployess?.length === 0}
              >
                Assign Users
              </Button>
            </div>
          </div>

          <TableContainer
            className={classes.container}
            style={{ maxHeight: "400px", marginTop: "20px" }}
            component={Paper}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <input
                      type="checkbox"
                      className="input"
                      name="selectall"
                      id="selectall"
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedEmployees(
                              employee_all_data?.results?.map(
                                (employee: any, index: number) => {
                                  return employee.employee_id;
                                }
                              )
                            )
                          : setSelectedEmployees([])
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Id</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 150 }}>
                    <b>Date of Joining</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employee_all_data?.results?.map(
                  (employee: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          checked={selectedEmployess.find(
                            (employeeId: any) =>
                              employeeId === employee.employee_id
                          )}
                          onChange={(e) =>
                            checkEmployeeId(e, employee.employee_id)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.employee_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.fullname || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.date_of_joining || "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={3}
                    count={employee_all_data.count || 0}
                    rowsPerPage={
                      employee_all_data && employee_all_data?.page_size
                    }
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEmployeeDetails}
        onClose={() => setOpenEmployeeDetails(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Paper style={{ width: "70%", padding: "20px" }}>
          <h2>Employees</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div style={{ width: "300px" }}>
              <Autocomplete
                onChange={(event, obj) => {
                  if (obj) {
                    handleCourseChange(obj.employee_id, "employee_id");
                  }
                }}
                id="employee_ids"
                options={clickedEmployeeData || []}
                getOptionLabel={(option: any) => {
                  return option?.employee_id + " " + option.fullname;
                }}
                renderOption={(option: any) => {
                  return (
                    <p>
                      <b style={{ color: "blue" }}>Employee Name:</b>
                      <strong>{option?.fullname}</strong>
                      <br />
                      <b style={{ color: "red" }}>Employee Id:</b>
                      {option?.employee_id?.toUpperCase()}
                    </p>
                  );
                }}
                // onInputChange={(event, newInputValue) => {
                //   clearTimeout(timer.current);
                //   timer.current = setTimeout(() => {
                //     newInputValue &&
                //       getEmployeeALlData(`department=${departmentIds}&employee_id=${newInputValue}`);
                //   }, 1000);
                // }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Employee ID Or Name"
                    variant="outlined"
                    style={{ width: "100%" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                        }
                      },
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Button variant="contained" onClick={handleEmployeeFilter}>
                Filter
              </Button>
            </div>
            <div>
              <Button variant="contained" onClick={handleEmployeeReset}>
                Reset
              </Button>
            </div>
          </div>

          <TableContainer
            className={classes.container}
            style={{ maxHeight: "400px", marginTop: "20px" }}
            component={Paper}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <input
                      type="checkbox"
                      className="input"
                      name="selectall"
                      id="selectall"
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedEmployees(
                              filterClickedEmployeeData?.map(
                                (employee: any, index: number) => {
                                  return employee.employee_id;
                                }
                              )
                            )
                          : setSelectedEmployees([])
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Id</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 120 }}>
                    <b>Employee Name</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ minWidth: 150 }}>
                    <b>Department</b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filterClickedEmployeeData?.map(
                  (employee: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          checked={selectedEmployess.find(
                            (employeeId: any) =>
                              employeeId === employee.employee_id
                          )}
                          onChange={(e) =>
                            checkEmployeeId(e, employee.employee_id)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.employee_id || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.fullname || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {employee?.department || "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  lmsCourses: state.PayrollReducer.lmsCourses,
  batches: state.PayrollReducer.batches,
  departmentNames: state.loginReducer.departmentNames,
  employee_all_data: state.loginReducer.employee_all_data,
});
export default connect(mapStateToProps, {
  createLMSCourses,
  getLmsCourses,
  getEmployeeDepartment,
  getEmployeeALlData,
  bulkCourseActions,
  updateLMSCourse,
  getLmsBatches,
  postCreateBatch,
  updateLmsBatch,
})(Courses);
