import React from "react";
import HRMangerDashboardLeft from "../../HrManager-Panel/dashboard-left/index";
import HRMangerDashboardRight from "../../HrManager-Panel/dashboard-right/index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}
const HRMangerDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <HRMangerDashboardLeft />
            <HRMangerDashboardRight>{children && children}</HRMangerDashboardRight>
        </div>
    );
};

export default HRMangerDash;