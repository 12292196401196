import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Slide from '@mui/material/Slide';
import { getAttendancesList } from "../../../actions/loginActions"
import { TransitionProps } from '@mui/material/transitions';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "70%",
      float: "left",
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      minHeight: "400px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  getAttendancesList: any
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  children,
  getAttendancesList
}) => {
  const classes = useStyles();
  const history = useHistory();


  useEffect(() => {
    getAttendancesList()
  }, [])

  let[handleClockIn, sethandleClockIn]=useState()
  let [attendanceupdate,setattendanceupdate]=useState()
  let [advancedoptions,setadvancedoptions]=useState();
  let[presentdays,setpresentdays]=useState(0);
  let [absentdays,setabsentdays]=useState(0)

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Date', width: 300 },
    { field: 'firstName', headerName: 'Status', width: 300 },
    { field: 'lastName', headerName: 'In Time', width: 300 },
    { field: 'age', headerName: 'Out Time', type: 'number',width: 100, align:'center'},
    // { field: 'fullName',headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false, width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];
  
  const rows = [
    { id: 2024, lastName: 'Present', firstName: 'Jon', age: 1035 },
    { id: 2024, lastName: 'Present', firstName: 'Cersei', age: 42 },
    { id: 2024, lastName: 'Present', firstName: 'Jaime', age: 45 },
    { id: 2024, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 2024, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 2024, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 2024, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 2024, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 2024, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  // function createData(
  //   date: any,
  //   status: number,
  //   intime: number,
  //   outtime: number,
    
  // ) {
  //   return { date, status, intime, outtime  };
  // }
  
  // const rows = [
  //   createData(2021, 159, 6.0, 24 ),
  //   createData(2024, 237, 9.0, 37),
  //   createData(2024, 262, 16.0, 24,),
  //   createData(2024, 305, 3.7, 67),
    
  // ];


  

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>

          </div>

        </div>
        <div className={classes.contentFilter}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "left" }}
          >
            <h3>My Attendance</h3>

          <Grid/>

          {/* <Grid item xs={12} sm={4} md={2} style={{ marginLeft: 'auto' }}>
          <TextField
            name="end_date"
            type="date"
            id="noTyping"
            value={end_date}
            className="input"
            label="Select Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid> */}

      <div style={{display:"flex", justifyContent:'space-between'}}>
        <button  className="button1" onClick={handleClockIn}>CLOCK-IN REQUEST</button>

        <button  className="button1" onClick={attendanceupdate}>ATTENDANCE UPDATE</button>

        <button  className="button1" onClick={advancedoptions}>Advanced options</button>
      </div>
      
     
      <section className="user-sec" >
               <div className="user-section" >
                 <div className="inner-cont"  >
                   <div className="user-info">
                   <h4>Present Days:{presentdays}</h4>
                    </div>
                   <div className="user-work">
                   <h4>Absent Days:{absentdays}</h4>
                   </div>
                   
                 </div>
               </div>
        </section>

        {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Attendance Status</TableCell>
            <TableCell align="center">In Time</TableCell>
            <TableCell align="center">Out Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.status}</TableCell>
              <TableCell align="center">{row.intime}</TableCell>
              <TableCell align="center">{row.outtime}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}

      <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        // pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div>
      
            
    

          </Grid>
        </div>
        

      </main>
      
      
      
      
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attendancesList: state.PayrollReducer.attendancesList,
});

export default connect(mapStateToProps, {
  getAttendancesList

})(CustomerLeadFilterPage);







