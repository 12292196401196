import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./style.css";
import {
  Table,
  TableBody,
  TextareaAutosize,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
  Button,
} from "@material-ui/core";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid } from "@material-ui/core";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontWeight: "bolder",
    },
    body: {
      fontSize: 14,
      fontWeight: "bolder",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      margin: "0 auto"
    },
    content: {
      width: "100%",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    textarea: {
      padding: "2px 4px",
      borderRadius: "8px",
      backgroundColor: "##E0E0E0",
      lineHeight: "1.5rem",
      fontSize: "1rem",
    },

    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },

  })
);


const TaxDeclaration = () => {
  const classes = useStyles();
  // const [taxDeclaration, setTaxDeclaration] = useState<any>([
  //   {
  //     rentAmout: "",
  //     childrenTutionFees: "",
  //     lifeInsurancePremium: "",
  //     pf: "",
  //     repaymentOfHousingLoan: "",
  //     suknaynaSamridhiYojana: "",
  //     vpf: "",
  //     newPensionScheme: "",
  //     medicalBills: "",
  //     medicalInsurancePremium: "",
  //     preventiveHealthCheckup: "",
  //     pHCDependentParents: "",
  //     additionalInterestOnHousingLoan: "",
  //     donation1: "",
  //     donation2: "",
  //     deductionToFamiliesOfDisabledPerson: "",
  //     medicalExpenses: "",
  //     interestOnEducationLoan: "",
  //     section80BE: "",
  //   },
  // ]);

  const values = [
    "Rent Amount",
    "Children Tuition Fees",
    "Life Insurance Premium",
    "PF",
    "Repayment of Housing loan(Principal amount)",
    "Sukanya Samriddhi Yojana",
    "VPF",
    "New Pension Schem",
    "Medical Bills - Senior Citizen (>60)",
    "Medical Insurance Premium",
    "Preventive Health Check-up",
    "Preventive Health Checkup - Dependant Parents",
    "Additional Interest on Housing loan borrowed as on1st Apr 2019",
    "Donation",
    "Donation",
    "Deduction To Families Of Disabled Persons For The Purpose Of Caring",
    "Medical Expenses Incurred For The Treatment ",
    "Interest on Higher Education Loan",
    "Section 80EE",
  ];



  const handleInputChange = (e: any) => {
    // code here
    e.preventDefault();
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          width: "100%",
          textAlign: "center",
          color: "rgb(14, 63, 108)",

        }}
      >
        Upload Tax Declaration Proffs
      </h2>

      <div style={{ marginTop: 50, width: "100%" }}>
        <Grid
          container
          spacing={4}
          style={{ display: "flex", justifyContent: "center", padding: "5px", boxShadow: "3px 3px 10px grey", borderRadius: "10px " }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer style={{ minHeight: "100px" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      Particulars
                    </StyledTableCell>
                    <StyledTableCell align="center">Section</StyledTableCell>

                    <StyledTableCell align="center">Declare</StyledTableCell>
                    <StyledTableCell align="center">
                      Actual Upload
                    </StyledTableCell>
                    <StyledTableCell align="center">Qualifying</StyledTableCell>
                    <StyledTableCell align="center">Upload</StyledTableCell>
                    <StyledTableCell align="center">Remarks</StyledTableCell>
                    <StyledTableCell align="center">Save</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {values.map((item: any, index: number) => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell>
                          <TextField
                            className="input"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={item}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                            name="keyResult"
                            className={classes.textarea}
                            value={""}
                            onChange={handleInputChange}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                            name="keyResult"
                            className={classes.textarea}
                            value={""}
                            onChange={handleInputChange}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                            name="keyResult"
                            className={classes.textarea}
                            value={""}
                            onChange={handleInputChange}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                            name="keyResult"
                            className={classes.textarea}
                            value={""}
                            onChange={handleInputChange}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon style={{ color: "rgb(14, 63, 108)" }} />}
                            style={{ backgroundColor: "#AEAEAE" }}
                          >
                            Upload file
                            <VisuallyHiddenInput type="file" style={{ color: "white" }} />
                          </Button>
                        </StyledTableCell>

                        <StyledTableCell>
                          <TextareaAutosize
                            name="keyResult"
                            className={classes.textarea}
                            value={""}
                            onChange={handleInputChange}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            style={{ backgroundColor: "#AEAEAE" }}
                          // startIcon={<SaveAsIcon />}
                          >
                            Save file

                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default TaxDeclaration;