import React, { useState, useEffect, useRef, FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    withStyles,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Grid, Paper, Tooltip } from "@material-ui/core";
import Button from "@mui/material/Button"
import { Block } from "@material-ui/icons";
import './IncomeTaxDeclaration.css'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),

            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        rightContentHeader: {
            float: "right",
            textAlign: "right",
            paddingRight: "50px",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props { }

const IncomeTaxDeclaration: FC<Props> = () => {
    const history = useHistory();
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [kra, setKra] = useState<any>([
        {
            keyResult: "",
            targetLevel: "",
            KpiWeightage: "",
            KraWeightage: "",
            selfAchievement: "",
            selfWtScore: "",
            reportingManagerAchievement: "",
            reportingMangerWtScore: "",
        },
    ]);

    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const style = {
        color: isHovered ? 'rgb(103, 37, 103)' : '#EA4C89',
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h2 className="taxHeading" style={{
            }}>Income Tax Declaration : 2023-24</h2>
            <div style={{ marginTop: 50, width: "100%" }}>
                <Grid
                    container
                    spacing={4}
                    style={{ display: "flex", justifyContent: "center", padding: "5px", boxShadow: "3px 3px 10px grey", borderRadius: "10px " }}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <TableContainer style={{ minHeight: "100px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>

                                    <StyledTableRow>
                                        <StyledTableCell align="center" style={{ width: "30%", fontSize: "1.2rem" }}>Particulars</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: "15%", fontSize: "1.2rem" }}> Section</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: "15%", fontSize: "1.2rem" }}>Declare</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: "15%", fontSize: "1.2rem" }}>Qualifying</StyledTableCell>
                                        <StyledTableCell align="center" style={{ width: "25%", fontSize: "1.2rem" }}>Remarks</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>

                                    <StyledTableRow></StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center"><textarea style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }} name="sec01" id="section1" value="Rent Amount"></textarea></StyledTableCell>
                                        <StyledTableCell align="center"><textarea style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }} name="sec01" id="section1"  ></textarea></StyledTableCell>
                                        <StyledTableCell align="center"><textarea style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }} name="sec01" id="section1"  ></textarea></StyledTableCell>
                                        <StyledTableCell align="center"><textarea style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }} name="sec01" id="section1"  ></textarea></StyledTableCell>
                                        <StyledTableCell align="center"> <textarea style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }} name="sec01" id="section1" cols={30}
                                            value="Actual rent paid minus 10% of the basic salary, or.
                                            Actual HRA offered by the employer, or.
                                            50% of salary when residential house is situated in Mumbai, Delhi, Chennai or Kolkata; 40% of salary when residential house is situated elsewhere"></textarea>   </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Land Load Name Mandatory in rent greater then 8333/-">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}>
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={""} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Land Load Pan Mandatory in rent greater then 8333/-">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}
                                            >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={""} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Children Tuition Fees">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"}></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Life Insurance Premium">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="PF">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}>
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Repayment of Housing loan(Principla Amount)">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Sukanya Samiridhi Yojana">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="VPF">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"15000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="New Pension Yojana">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"5000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Medical Bills-Senior Citizens(>60)">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"25000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Medical Insurance Premium">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}
                                            >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"25000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Preventive Health checkup">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80C">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={"5000Max"} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%", color: 'black', fontSize: "1rem" }}

                                                value="Additional Interest onHousing Loan Borrowed as on 1Apr 2019">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value="80EEA">
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""}  >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1"
                                                value={""} >
                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <textarea
                                                style={{ border: ".5px solid grey", borderRadius: "10px ", textAlign: "center", width: "100%" }}
                                                name="sec01" id="section1" cols={30}
                                                value={""} ></textarea>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>


                            </Table>
                        </TableContainer>
                    </Grid>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            // onSubmit={submitForm}
                            style={{
                                color: "white",
                                margin: "20px 0",
                                marginTop: "10px",
                                backgroundColor: "rgb(14, 63, 108)",
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>

            </div>


        </main>
    );
};

const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, {})(IncomeTaxDeclaration);