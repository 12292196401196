import {
  ACTIONS,
  LoginActionTypes,
} from "../interfaces/actionTypes/loginTypes";
import SecureStorage from "../config/SecureStorage";
import { Action } from "history";

type LoginInitialState = {
  user: object;
  status: boolean;
  success: boolean;
  otp: any;
  passwordStatus: boolean;
  loading: boolean;
  loadingOTP: boolean;
  attendancesList: Array<any>;
  attendancesListById: Array<any>;
  employeesList: Array<any>;
  employeesById: Array<any>;
  organizationsList: Array<any>;
  payrollList: Array<any>;
  payrollById: Array<any>;
  employeeLeaveLedgersList: Array<any>;
  employeeLeaveLedgersById: Array<any>;
  employeeLeaveMaster: Array<any>;
  employeeLeaveMasterById: Array<any>;
  componentsList: Array<any>;
  employeeComponentList: Array<any>;
  editKraDetails: object;
  departmentNames: any;
  employeeSalary: Array<any>;
  team_details: Array<any>;
  attendanceData: any;
  birthday_reminder: Array<any>;
  holidayList: Array<any>;
  leaveComponents: Array<any>;
  payroll_aggregate: Array<any>;
  payroll_data: Array<any>;
  leave_application: Array<any>;
  employee_rating: Array<any>;
  get_employee_from: Array<any>;
  employee_Salary: Array<any>;
  update_salary: Array<any>;
  post_salary: Array<any>;
  subDeptName: Array<any>;
  employeeStateName: Array<any>;
  get_employee_form: Array<any>;
  shiftList: Array<any>;
  designationList: Array<any>;
  locationList: Array<any>;
  attendancerecord: Array<any>;
  attendanceMaster: Array<any>;
  attendanceMasterLoading: boolean;
  manageAttendance: Array<any>;
  manageAttendanceLoading: boolean;
  employee_by_id: Array<any>;
  employee_all_data: Array<any>;
  employee_my_team_list: Array<any>;
  bulk_employee: Array<any>;
  leaveLedger: Array<any>;
  get_Employee_Mode: Array<any>;
  update_employee: Array<any>;
  reporting_manager: Array<any>;
  get_notification: Array<any>;
  exportList: Array<any>;
  exportListLoading: boolean;
  postProfileImage: Array<any>;
  getProfileImage: Array<any>;
  attendanceDataWithourPage: Array<any>;
  medicalData: Array<any>;
  attendanceDataWithourPageloading: boolean;
  geoFenceCordinate: Array<any>;
  userReport: Array<any>;
  userReportloading: boolean;
  userReportExport: Array<any>;
  userReportloadingExport: boolean;
  userRegularizationReport: Array<any>;
  userRegularizationReportloading: boolean;
  userRegularizationReportExport: Array<any>;
  userRegularizationReportExportLoading: boolean;
  lockDetails: Array<any>;
  createModuleData: object;
  assignmentQuestionMasterData: Array<any>;
  assignmentAnswerMasterPutData: Array<any>;
  assignmentAnswerMasterPostData: Array<any>;
  empLeaveCount: Array<any>;
  empLeaveCountLoader: boolean;
  empLeaveCountExport: Array<any>;
  empLeaveExportCountLoader: boolean;
  performanceTableData: object;
  policyCreation: object;
  get_Policy_Category: object;
  create_policy_category: object;
  get_policy: object;
  update_policy:object;
  updateBiometric:Array<any>;
  policyLoading:boolean;
  createCategoryLoading: boolean;
  update_policy_category: object;
  postPolicyAck:object;
  getPolicyAck:object;
  getWeeklyShift:Array<any>;
  loadingForRegularization:boolean;
  employeeListForRegularization:Array<any>;
};

const initialState: LoginInitialState = {
  user: {
    username: "",
    email: "",
    age: 0,
  },
  status: false,
  success: false,
  otp: false,
  passwordStatus: true,
  loading: false,
  loadingOTP: false,
  attendancesList: [],
  attendancesListById: [],
  employeesList: [],
  employeesById: [],
  organizationsList: [],
  payrollList: [],
  payrollById: [],
  employeeLeaveLedgersList: [],
  employeeLeaveLedgersById: [],
  employeeLeaveMaster: [],
  employeeLeaveMasterById: [],
  componentsList: [],
  employeeComponentList: [],
  editKraDetails: {},
  departmentNames: null,
  employeeSalary: [],
  attendanceData: [],
  birthday_reminder: [],
  holidayList: [],
  team_details: [],
  leaveComponents: [],
  payroll_aggregate: [],
  payroll_data: [],
  leave_application: [],
  employee_rating: [],
  get_employee_from: [],
  employee_Salary: [],
  update_salary: [],
  post_salary: [],
  subDeptName: [],
  employeeStateName: [],
  get_employee_form: [],
  shiftList: [],
  designationList: [],
  locationList: [],
  attendancerecord: [],
  attendanceMaster: [],
  attendanceMasterLoading: false,
  manageAttendance: [],
  manageAttendanceLoading: false,
  employee_by_id: [],
  employee_all_data: [],
  employee_my_team_list: [],
  bulk_employee: [],
  leaveLedger: [],
  get_Employee_Mode: [],
  update_employee: [],
  reporting_manager: [],
  get_notification: [],
  exportList: [],
  exportListLoading: false,
  postProfileImage: [],
  getProfileImage: [],
  attendanceDataWithourPage: [],
  geoFenceCordinate: [],
  userReport: [],
  userReportloading: false,
  userReportExport: [],
  userReportloadingExport: false,
  userRegularizationReport: [],
  userRegularizationReportloading: false,
  userRegularizationReportExport: [],
  userRegularizationReportExportLoading: false,
  lockDetails: [],
  createModuleData: {},
  assignmentQuestionMasterData: [],
  assignmentAnswerMasterPutData: [],
  assignmentAnswerMasterPostData: [],
  empLeaveCount: [],
  empLeaveCountLoader: false,
  empLeaveCountExport: [],
  empLeaveExportCountLoader: false,
  performanceTableData: {},
  medicalData: [],
  attendanceDataWithourPageloading: false,
  policyCreation: {},
  get_Policy_Category: {},
  create_policy_category: {},
  get_policy: {},
  update_policy:{},
  updateBiometric:[],
  policyLoading:false,
  createCategoryLoading: false,
  update_policy_category:{},
  postPolicyAck:{},
  getPolicyAck:{},
  getWeeklyShift:[],
  loadingForRegularization:false,
  employeeListForRegularization:[],
};

const userReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
        status: true,
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        success: action.payload,
      };
    case ACTIONS.SEND_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case ACTIONS.LOGOUT:
      SecureStorage.removeItem("token");
      window.location.href = "/";
      return {
        ...state,
        ...initialState,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_LOADING_OTP:
      return {
        ...state,
        loadingOTP: action.payload,
      };
    case ACTIONS.GET_ATTENDANCE_LIST:
      return {
        ...state,
        attendancesList: action.payload,
      };
    case ACTIONS.GET_ATTENDANCE_BY_ID:
      return {
        ...state,
        attendancesListById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_LIST:
      return {
        ...state,
        employeesList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYROLLS_LIST:
      return {
        ...state,
        payrollList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYROLL_BY_ID:
      return {
        ...state,
        payrollById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_LIST:
      return {
        ...state,
        employeeLeaveLedgersList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_BY_ID:
      return {
        ...state,
        employeeLeaveLedgersById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_LIST:
      return {
        ...state,
        employeeLeaveMaster: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_BY_ID:
      return {
        ...state,
        employeeLeaveMasterById: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CONPONENTS_LIST:
      return {
        ...state,
        componentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_CONPONENTS_LIST:
      return {
        ...state,
        employeeComponentList: action.payload,
      };
    case ACTIONS.EDIT_KRA_DETAILS:
      return {
        ...state,
        editKraDetails: action.payload,
      };
    case ACTIONS.GET_EMPLOYEE_DEPARTMENT:
      return {
        ...state,
        departmentNames: action.payload,
      };
    case ACTIONS.POST_EMPLOYEE_SALARY:
      return {
        ...state,
        employeeSalary: action.payload,
      };
    case ACTIONS.GET_MY_TEAM_DETAILS:
      return {
        ...state,
        team_details: action.payload,
      };
    case ACTIONS.DISPLAY_HOLIDAYS:
      return {
        ...state,
        holidayList: action.payload,
      };

    case ACTIONS.BIRTHDAY_REMINDER:
      return {
        ...state,
        birthday_reminder: action.payload,
      };
    case ACTIONS.ATTENDANCE_MASTER:
      return {
        ...state,
        attendanceData: action.payload,
      };
    case ACTIONS.GET_LEAVE_COMPONENTS:
      return {
        ...state,
        leaveComponents: action.payload,
      };
    case ACTIONS.GET_PAYROLL_AGGREGATE:
      return {
        ...state,
        payroll_aggregate: action.payload,
      };
    case ACTIONS.GET_PAYROLL_DATA:
      return {
        ...state,
        payroll_data: action.payload,
      };
    case ACTIONS.LEAVE_APPLICATION:
      return {
        ...state,
        leave_application: action.payload,
      };

    case ACTIONS.EMPLOYEE_RATING:
      return {
        ...state,
        employee_rating: action.payload,
      };
    case ACTIONS.GET_EMPLOYEE_FORM:
      return {
        ...state,
        get_employee_form: action.payload,
      };

    case ACTIONS.GET_EMPLOYEE_SALARY:
      return {
        ...state,
        employee_Salary: action.payload,
      };
    case ACTIONS.UPDATE_EMPLOYEE_SALARY:
      return {
        ...state,
        update_salary: action.payload,
      };
    case ACTIONS.Post_Employee_Salary:
      return {
        ...state,
        post_salary: action.payload,
      };
    case ACTIONS.GET_EMPLOYEE_SUB_DEPARTMENT:
      return {
        ...state,
        subDeptName: action.payload,
      };
    case ACTIONS.GET_EMPLOYEE_STATE_NAME:
      return {
        ...state,
        employeeStateName: action.payload,
      };
    case ACTIONS.GET_SHIFT_LIST:
      return {
        ...state,
        shiftList: action.payload,
      };
    case ACTIONS.GET_DESIGNATION_LIST:
      return {
        ...state,
        designationList: action.payload,
      };
    case ACTIONS.GET_LOCATION_LIST:
      return {
        ...state,
        locationList: action.payload,
      };
    case ACTIONS.GET_ATTENDANCE_RECORD:
      return {
        ...state,
        attendancerecord: action.payload,
      };
    case ACTIONS.GET_ATTENDANCE_MASTER:
      return {
        ...state,
        attendanceMaster: action.payload,
        attendanceMasterLoading: false,
      };
    case ACTIONS.SET_ATTENDANCE_MASTER_LOADING:
      return {
        ...state,
        attendanceMasterLoading: action.payload,
      };
    case ACTIONS.SET_MANAGE_ATTENDANCE_LOADING:
      return {
        ...state,
        manageAttendanceLoading: action.payload,
      };
    case ACTIONS.GET_MANAGE_ATTENDANCE:
      return {
        ...state,
        manageAttendance: action.payload,
        manageAttendanceLoading: false,
      };
    case ACTIONS.GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        employee_by_id: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_ALL_DATA:
      return {
        ...state,
        employee_all_data: action.payload,
      };
    case ACTIONS.GET_EMPLOYEE_MY_TEAM_LIST:
      return {
        ...state,
        employee_my_team_list: action.payload,
      };
    case ACTIONS.POST_EMPLOYEE_BULK_UPLOAD:
      return {
        ...state,
        bulk_employee: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAVE_LEDGER:
      return {
        ...state,
        leaveLedger: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMPLOYEE_MODE:
      return {
        ...state,
        get_Employee_Mode: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_EMPLOYEE_BULK_UPLOAD:
      return {
        ...state,
        update_employee: action.payload,
        loading: false,
      };

    case ACTIONS.GET_REPORTING_MANAGER:
      return {
        ...state,
        reporting_manager: action.payload,
        loading: false,
      };

    case ACTIONS.GET_NOTIFICATION:
      return {
        ...state,
        get_notification: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EXPORT_EMPLOYEE_LIST:
      return {
        ...state,
        exportList: action.payload,
        exportListLoading: false,
      };
    case ACTIONS.SET_EXPORT_EMPLOYEE_LIST_LOADING:
      return {
        ...state,
        exportListLoading: action.payload,
      };

    case ACTIONS.GET_PROFILE_IMAGE:
      return {
        ...state,
        getProfileImage: action.payload,
        loading: false,
      };

    case ACTIONS.POST_UPDATE_IMAGE:
      return {
        ...state,
        postProfileImage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION:
      return {
        ...state,
        attendanceDataWithourPage: action.payload,
        attendanceDataWithourPageloading: false,
      };
    case ACTIONS.SET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION_LOADING:
      return {
        ...state,
        attendanceDataWithourPageloading: action.payload,
      };
    case ACTIONS.GET_GEO_FENCE_CORDINATE_LIST:
      return {
        ...state,
        geoFenceCordinate: action.payload,
      };
    case ACTIONS.GET_USER_REPORT:
      return {
        ...state,
        userReport: action.payload,
        userReportloading: false,
      };
    case ACTIONS.SET_USER_REPORT_LOADING:
      return {
        ...state,
        userReportloading: action.payload,
      };
    case ACTIONS.GET_USER_REPORT_EXPORT:
      return {
        ...state,
        userReportExport: action.payload,
        userReportloadingExport: false,
      };
    case ACTIONS.SET_USER_REPORT_LOADING_EXPORT:
      return {
        ...state,
        userReportloadingExport: action.payload,
      };
    case ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING:
      return {
        ...state,
        userReportExport: action.payload,
      };
    case ACTIONS.GET_USER_REGULARIZATION_REPORT:
      return {
        ...state,
        userRegularizationReport: action.payload,
        userRegularizationReportloading: false,
      };
    case ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING:
      return {
        ...state,
        userRegularizationReportloading: action.payload,
      };
    case ACTIONS.GET_USER_REGULARIZATION_REPORT_EXPORT:
      return {
        ...state,
        userRegularizationReportExport: action.payload,
        userRegularizationReportExportLoading: false,
      };
    case ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING_EXPORT:
      return {
        ...state,
        userRegularizationReportExportLoading: action.payload,
      };
    case ACTIONS.GET_USER_LOCK_ACTION:
      return {
        ...state,
        lockDetails: action.payload,
      };

    case ACTIONS.CREATE_MODULE:
      return {
        ...state,
        createModuleData: action.payload,
      };

    case ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPutData: action.payload,
      };
    case ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER:
      return {
        ...state,
        assignmentAnswerMasterPostData: action.payload,
      };

    case ACTIONS.EMPLOYEE_LEAVE_COUNT_LOADING:
      return {
        ...state,
        empLeaveCountLoader: action.payload,
      };

    case ACTIONS.EMPLOYEE_LEAVE_COUNT:
      return {
        ...state,
        empLeaveCount: action.payload,
        empLeaveCountLoader: false,
      };

    case ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT_LOADING:
      return {
        ...state,
        empLeaveExportCountLoader: action.payload,
      };

    case ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT:
      return {
        ...state,
        empLeaveCountExport: action.payload,
        empLeaveExportCountLoader: false,
      };

    case ACTIONS.PERFORMANCE_TABLE_NEW:
      return {
        ...state,
        performanceTableData: action.payload,
      };
    case ACTIONS.GET_MEDICAL_INFO:
      return {
        ...state,
        medicalData: action.payload,
        loading: false,
      };
    case ACTIONS.POLICY_CREATE:
      return {
        ...state,
        policyCreation: action.payload,
        policyLoading: false,
      }
    case ACTIONS.GET_POLICY_CATEGORY:
      return {
        ...state,
        get_Policy_Category: action.payload,
        loading: false,
      }
    case ACTIONS.CREATE_POLICY_CATEGORY:
      return {
        ...state,
        create_policy_category: action.payload,
        createCategoryLoading: false,
      }
    case ACTIONS.GET_POLICY:
      return {
        ...state,
        get_policy: action.payload,
        loading: false,
      }
    case ACTIONS.POLICY_UPDATE:
      return {
        ...state,
        get_policy: action.payload,
        policyLoading: false,
      }
      case ACTIONS.SET_LOADING_POLICY:
        return {
          ...state,
          policyLoading: action.payload,
        }
      case ACTIONS.GET_MEDICAL_INFO:
        return{
          ...state,
          medicalData:action.payload,
          loading:false,
        };
      case ACTIONS.GET_UPDATE_BIOMETRIC:
        return{
          ...state,
          updateBiometric:action.payload,
        };
      case ACTIONS.SET_CREATE_CATEGORY_LOADING:
        return{
          ...state,
          createCategoryLoading:action.payload,
        }
      case ACTIONS.POLICY_CATEGORY_UPDATE:
        return {
          ...state,
          update_policy_category: action.payload,
          createCategoryLoading: false,
        }
        case ACTIONS.POST_POLICY_ACK:
        return {
          ...state,
          postPolicyAck: action.payload,
          createCategoryLoading: false,
        }
        case ACTIONS.GET_POLICY_ACK:
          return {
            ...state,
            getPolicyAck: action.payload,
            createCategoryLoading: false,
          }
          case ACTIONS.GET_WEEKLY_ROSTER:
            return {
              ...state,
              getWeeklyShift: action.payload,
              loading:false,
            }
        case ACTIONS.SET_LOADING_FOR_REGULARIZATION:
          return {
            ...state,
            loadingForRegularization: action.payload,
          }
        case ACTIONS.GET_EMPLOYEE_LIST_FOR_REGULARIZATION:
          return {
            ...state,
            employeeListForRegularization: action.payload,
            loadingForRegularization: false,
          }
    default:
      return state;
  }
};

export default userReducer;
