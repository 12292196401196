import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Slider,
  Chip,
  Switch,
  withStyles,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import Loader from "../../Components/loader/index";
import Radio from "@material-ui/core/Radio";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
// import { ACTIONS } from "../../interfaces/actionTypes/ImagingAdminType";
// import {
//   updatedPackageAlias,
//   updatePackage,
// } from "../../actions/ImagingAdminAction";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PrettoSlider: any = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getCodeGenData: any;
  codeGenrator: any;
  createImagingPackage: any;
  getPackage: any;
  packageList: any;
  loading: any;
  setValue: any;
  updatedPackageAlias: any;
  getImagingPackageAlias: any;
  updatePackage: any;
}
const CreateCentrePage: React.FC<Props> = ({
  getCodeGenData,
  codeGenrator,
  createImagingPackage,
  getPackage,
  packageList,
  loading,
  updatedPackageAlias,
  getImagingPackageAlias,
  updatePackage,
  setValue,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  let dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [testName, setTestName] = useState<any>("");
  const [testNameEdit, setTestNameEdit] = useState<any>("");
  const [testCode, setTestCode] = useState<any>("");
  const [createTestCode, setCreateTestCode] = useState<any>("");
  const [createTestCodeEdit, setCreateTestCodeEdit] = useState<any>("");
  const [sellingPrice, setSellingPrice] = useState<any>("");
  const [mrpPrice, setMrpPrice] = useState<any>("");
  const [testType, setTestType] = useState<string>("");
  const [testTypeEdit, setTestTypeEdit] = useState<string>("");
  const [description, setDescription] = useState<any>("");
  const [descriptionEdit, setDescriptionEdit] = useState<any>("");
  const [typeKey, setTypeKey] = useState<any>(0);
  const [packageName, setPackageName] = useState<any>("");
  const [testKey, setTestKey] = useState<any>(0);
  const [editPackage, setEditPackage] = useState<any>({});
  const [packageId, setpackageId] = useState<any>("");
  const [isActive, setIsActive] = useState(false);
  // useEffect(() => {
  //   if (testType === "") {
  //     dispatch({ type: ACTIONS.GET_CODE_GENRATOR_DATA, payload: [] });
  //   }
  // }, [testType]);

  useEffect(() => {
    if (testType !== "") {
      getCodeGenData(`?type=${testType}`);
    }
  }, [testType]);

  useEffect(() => {
    codeGenrator?.code && setTestCode(codeGenrator?.code);
  }, [codeGenrator, testType]);

  const handleSubmitTest = async () => {
    const body = {
      name: testName,
      code: testCode,
      type: testType,
      offer_price: sellingPrice,
      package_price: mrpPrice,
      description: description,
      org_type: "imaging",
    };
    await createImagingPackage(body);
    setTypeKey(typeKey + 2);
    setCreateTestCode("");
    setTestType("");
    setDescription("");
    setSellingPrice("");
    setTestName("");
    setMrpPrice("");
    getPackage(`?org_type=imaging`);
  };
  const handleEditTest = (data: any) => {
    setCreateTestCodeEdit(data.code);
    setTestTypeEdit(data.type);
    setTestNameEdit(data.name);
    setDescriptionEdit(data.description);
    //     setSellingPrice(data.offer_price)
    //     setMrpPrice(data.package_price)
    //     setpackageId(data.id)
    setIsActive(data?.is_active);
    setEditPackage(data);
  };
  const handleTestUpdate = async (pack: any) => {
    const body: any = {
      description: descriptionEdit,
      type: testTypeEdit,
      name: testNameEdit,
      code: createTestCodeEdit,
      is_active: isActive,
    };
    await updatePackage(pack.slug, body);
    setEditPackage({});
    getPackage(`?org_type=imaging`);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = packageList.links.next;
      getPackage(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = packageList.links.previous;
      getPackage(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };
  const handleRestTest = () => {
    setPage(0);
    setTestKey(testKey + 2);
    getPackage(`?org_type=imaging`);
    setTestType("");
  };
  const handleEditPackage = (data: any) => {
    setDescription(data?.description);
    setTestType(data?.type);
    setTestName(data?.name);
    setCreateTestCode(data?.code);
    setpackageId(data.id);
    setIsActive(data?.is_active);
    setEditPackage(data);
  };

  const handleUser = async () => {
    const body: any = {
      description: description,
      type: testType,
      name: testName,
      code: createTestCode,
      is_active: isActive,
    };
    await updatedPackageAlias(packageId, body);
    setEditPackage({});
    // getImagingPackageAlias(`?org_type=imaging`);
  };
  const handleRestTestData = () => {
    setCreateTestCodeEdit("");
    setTestTypeEdit("");
    setTestNameEdit("");
    setDescriptionEdit("");
    setSellingPrice("");
    setMrpPrice("");
    setpackageId("");
    setIsActive(false);
    setDescription("");
    setTestType("");
    setTestName("");
    setTestCode("");
    setTypeKey(typeKey + 2);
    setEditPackage({});
    // dispatch({ type: ACTIONS.GET_CODE_GENRATOR_DATA, payload: [] });
  };
  return (
    <main className={classes.content}>
      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="colony-area-sector"
            key={typeKey}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setTestType(obj.value);
              }
            }}
            options={[
              {
                text: "X-Ray",
                value: "XRay",
              },
              {
                text: "MRI",
                value: "MRI",
              },
              {
                text: "USG",
                value: "USG",
              },
              {
                text: "PET SCAN",
                value: "PET",
              },
              {
                text: "NUCLEAR",
                value: "NUC",
              },
              {
                text: "DEXA",
                value: "DEXA",
              },
              {
                text: "Mammography",
                value: "MAM",
              },
              {
                text: "Cardio",
                value: "CAR",
              },
              {
                text: "Neurology",
                value: "NEU",
              },
              {
                text: "CT",
                value: "CT",
              },
              {
                text: "CBCT",
                value: "CBCT",
              },
              {
                text: "PFT",
                value: "PFT",
              },
              {
                text: "Audiology",
                value: "Audiology",
              },
              {
                text: "Gastroenterology",
                value: "Gastroenterology",
              },
              {
                text: "Gyne",
                value: "Gyne",
              },
              {
                text: "Path at Centre",
                value: "PAC",
              },
            ]}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.text}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="TYPE"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="testcode"
            type="text"
            label="TEST CODE"
            value={testCode}
            required
            disabled
            variant="outlined"
            onChange={(e) => setTestCode(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="testname"
            type="text"
            label="TEST NAME"
            value={testName}
            required
            variant="outlined"
            onChange={(e) => setTestName(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="sellingprice"
            label="SELLING PRICE"
            onWheel={(e: any) => e.target.blur()}
            value={sellingPrice}
            required
            variant="outlined"
            onChange={(e) =>
              setSellingPrice(
                e.target.value.replace(/[^0-9\s]/, "").trimStart()
              )
            }
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="mrpprice"
            onWheel={(e: any) => e.target.blur()}
            label="MRP"
            value={mrpPrice}
            required
            variant="outlined"
            onChange={(e) =>
              setMrpPrice(e.target.value.replace(/[^0-9\s]/, "").trimStart())
            }
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="input"
            name="description"
            type="text"
            label="DESCRIPTION"
            value={description}
            required
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            onClick={handleSubmitTest}
            disabled={
              description === "" ||
              testType === "" ||
              mrpPrice === "" ||
              sellingPrice === ""
            }
          >
            Create Test
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button variant="contained" fullWidth onClick={handleRestTestData}>
            Reset Test
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Autocomplete
            id="test"
            key={testKey}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPackageName(obj.name);
              }
            }}
            options={packageList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            getOptionLabel={(option: any) => option?.name}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPackage(`?org_type=imaging&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPackageName("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="TEST NAME"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={packageName === ""}
            onClick={handleRestTest}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div className="data-table" style={{ width: "100%" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "560px" }}
        >
          { (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">TEST CODE</StyledTableCell>
                  <StyledTableCell align="center">TEST NAME</StyledTableCell>
                  <StyledTableCell align="center">Type</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  {/*<StyledTableCell align="center">Action </StyledTableCell>*/}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {packageList &&
                  packageList.results &&
                  packageList.results.length > 0 &&
                  packageList.results.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        {/* <StyledTableCell align="center">{data?.code}</StyledTableCell> */}
                        <StyledTableCell align="center">
                          {editPackage?.id === data?.id ? (
                            <TextField
                              name="subject"
                              type="text"
                              value={createTestCodeEdit}
                              disabled
                              className="input"
                              variant="outlined"
                              label="Test Code"
                              placeholder="Test Code"
                              onChange={(e) =>
                                setCreateTestCode(e.target.value as string)
                              }
                              style={{ width: "100%" }}
                              required
                            />
                          ) : (
                            data?.code
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">{data?.name}</StyledTableCell> */}
                        <StyledTableCell align="center">
                          {editPackage?.id === data?.id ? (
                            <TextField
                              name="subject"
                              type="text"
                              value={testNameEdit}
                              className="input"
                              variant="outlined"
                              label="Test Name"
                              placeholder="Test Name"
                              onChange={(e) =>
                                setTestNameEdit(e.target.value as string)
                              }
                              style={{ width: "100%" }}
                              required
                            />
                          ) : (
                            data?.name
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">{data?.type}</StyledTableCell> */}
                        <StyledTableCell align="center">
                          {editPackage?.id === data?.id ? (
                            <TextField
                              name="subject"
                              type="text"
                              disabled
                              value={testTypeEdit}
                              className="input"
                              variant="outlined"
                              label="Type"
                              placeholder="Type"
                              onChange={(e) =>
                                setTestType(e.target.value as string)
                              }
                              style={{ width: "100%" }}
                              required
                            />
                          ) : (
                            data?.type
                          )}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">{data?.description}</StyledTableCell> */}
                        <StyledTableCell align="center">
                          {editPackage?.id === data?.id ? (
                            <TextField
                              name="subject"
                              type="text"
                              value={descriptionEdit}
                              className="input"
                              variant="outlined"
                              label="Prerequisite"
                              placeholder="Prerequisite"
                              onChange={(e) =>
                                setDescriptionEdit(e.target.value as string)
                              }
                              style={{ width: "100%" }}
                              required
                            />
                          ) : (
                            data?.description
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {editPackage?.id === data?.id ? (
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => handleTestUpdate(data)}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => handleEditTest(data)}
                            >
                              <EditIcon />
                            </Button>
                          )}
                        </StyledTableCell>

                        {/* <StyledTableCell align="center">
                                                {editPackage?.id === data?.id? (
                                                        <Switch
                                                            defaultChecked={data?.is_active}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={data?.is_active}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell> */}

                        {/* <StyledTableCell align="center">
                                                {editPackage?.id === data?.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleEditTest(data)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell> */}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  colSpan={6}
                  count={ 0}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({});
export default connect(mapStateToProps, {
  // updatedPackageAlias,
  // updatePackage,
})(CreateCentrePage);
