import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import {

    getEmployeeList,
    getLocationList,
    PostPunchInOut
} from "../../../actions/loginActions";
import { FormControl, FormLabel, FormControlLabel, Checkbox, keyframes } from '@mui/material';
import { useHistory } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        input: {
            padding: "1px",
        },

    })
);
interface Props {
    getEmployeeList: any;
    employeesList: any;
    locationList: any
    getLocationList: any;
    PostPunchInOut: any;
    punchRequest: any;
}

const EmployeePunch: React.FC<Props> = ({

    getEmployeeList,
    employeesList,
    locationList,
    getLocationList,
    PostPunchInOut,
    punchRequest
}) => {

    let currentDate = new Date();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthArr = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]


    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const dayOfMonth = currentDate.getDate();

    const date = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    // const currentTime = `${hours}:${minutes}:${seconds}`;
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const classes = useStyles();
    const [punch, setPunch] = useState<any>(true);
    const [employeePunchIN, setEmployeePunchIn] = useState<any>();
    const [employeePunchOUT, setEmployeePunchOut] = useState<any>();
    const [position, setPosition] = useState({ latitude: '', longitude: '' });

    const [currentTime, setCurrentTime] = useState<any>();
    useEffect(() => {
        setTimeout(() => {
            const currTime = `${hours}:${minutes}:${seconds}`;
            setCurrentTime(currTime);
        }, 1000);
    })

    useEffect(() => {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position) => {
                setPosition({
                    latitude: position.coords.latitude.toString(),
                    longitude: position.coords.longitude.toString(),
                })
            })
        }

        const savedPunchIN = localStorage.getItem('employeePunchIN');
        const savedPunchOUT = localStorage.getItem('employeePunchOUT');
        const savedPunch = localStorage.getItem('punch');
        if (savedPunchIN) setEmployeePunchIn(savedPunchIN);
        if (savedPunchOUT) setEmployeePunchOut(savedPunchOUT);
        if (savedPunch) setPunch(savedPunch === 'true');
    }, []);

    const handlePunching = () => {

        if (position.latitude === "" && position.longitude === "") {
            alert("Please enable location service to continue")
            return;
        } else {
            setPunch(!punch)
            if (punch) {
                setEmployeePunchIn(currentTime);
                localStorage.setItem('employeePunchIN', currentTime);
                localStorage.setItem('punch', 'false');
                PostPunchInOut(position);
            }
            else {
                setEmployeePunchOut(currentTime);
                localStorage.setItem('employeePunchOUT', currentTime);
                localStorage.setItem('punch', 'true');
                PostPunchInOut(position);
            }
        }




    }



    return (
        <main className={classes.content}>
            <div className={classes.toolbar} >
                <div style={{
                    display: "flex",
                    marginTop: "100px",
                    justifyContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ background: "white", padding: "30px 30px ", borderRadius: "10px", boxShadow: "10px 10px 35px grey", width: "500px", }}>
                        <h1 style={{ fontSize: "2.5rem", border: "rgb(14, 63, 108)", color: "#0e3f6c", width: "100%", textAlign: "center" }}>Employee Punch</h1>
                        <div className="employee-punch-header" style={{ marginTop: "50px" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <p style={{ fontSize: "1.5rem", fontWeight: "500", color: "grey" }}>{dayOfWeek}</p>
                                <p style={{ fontSize: "1.5rem", fontWeight: "500", color: "grey", marginLeft: "5px" }}>{`${monthArr[month]}  ${date}  ${year}  `}</p>
                            </div>
                            <div style={{ fontSize: "2rem", marginLeft: "30%", fontWeight: "500", }}>
                                {`${currentTime}  ${ampm}`}
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: "40px",
                                marginLeft: "20%",
                                display: "flex",
                                alignItems: "center",


                            }}>
                            <div style={{
                                height: '254px',
                                width: '254px',
                                borderRadius: "50%",
                                backgroundColor: punch ? '#2e9797' : '#cd1b1bd1',
                                paddingTop: "5px",
                                paddingLeft: "5px",
                            }}  >
                                <div
                                    style={{
                                        height: '245px',
                                        width: '245px',
                                        borderRadius: "50%",
                                        background: "white",
                                        position: "absolute",
                                        paddingTop: "20px",
                                        paddingLeft: "20px",
                                    }}>
                                    <div

                                        onClick={() => {

                                            handlePunching()
                                        }}
                                        style={{
                                            height: '200px',
                                            width: '200px',
                                            backgroundColor: punch ? '#2e9797' : '#cd1b1bd1',
                                            textAlign: "center",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            color: "rgb(14, 63, 108)",
                                            boxShadow: "5px 5px 10px grey",
                                            border: "2px solid lightgrey",
                                        }}>
                                        {
                                            punch ?
                                                <button

                                                    style={{
                                                        fontSize: "2.3rem", border: "none", background: "transparent", fontWeight: "500",
                                                        color: "white"

                                                    }}>Punch In</button>
                                                :
                                                <button
                                                    style={{
                                                        fontSize: "2.3rem", border: "none", background: "transparent", color: "white", fontWeight: "500px"

                                                    }}>Punch Out</button>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "30px", marginLeft: "16%" }}>
                            <p>Your Shift Timing is: <b>9:30 - 18:30 </b></p>
                            <p>Your Punch-In time is : <b style={{ color: "#2e9797", fontSize: "1.2rem" }}> {employeePunchIN ? `${employeePunchIN} ${ampm}` : " "} </b></p>
                            <p>Your Punch-Out time is : <b style={{ color: "#cd1b1bd1", fontSize: "1.2rem" }}>{employeePunchOUT ? `${employeePunchOUT} ${ampm}` : ""}</b> </p>
                        </div>
                    </div>
                </div>
            </div>
        </main >

    )
};

const mapStateToProps = (state: any) => ({
    employeesList: state.loginReducer.employeesList,
    locationList: state.loginReducer.locationList,
    punchRequest: state.loginReducer.punchRequest,

});
export default connect(mapStateToProps, {

    getEmployeeList,
    getLocationList,
    PostPunchInOut,
})(EmployeePunch);