import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  TableBody,
  Select,
  MenuItem
} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Checkbox, FormControlLabel } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
 
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  // uploadZipFileReportId: any;
  // zipReoprtUrl: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  // uploadZipFileReportId,
  // zipReoprtUrl,
}) => {
  const classes = useStyles();
  const [file, setFile] = React.useState<any>([]);
  const [value, setValue] = React.useState(0);
  const [file_name, setFileName] = React.useState<string>("");
  const [withoutHeader, setWithoutHeader] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<any>(false);
  const [urlFile, setUrlFile] = useState<any>([]);
  const [withoutUrlReport, setWithoutUrlList] = useState<any>([]);
  const [withUrlReport, setWithUrlList] = useState<any>([]);
  const [incorrectBookings, setIncorrectBooking] = useState<any>([]);
  const [file1, setFile1] = React.useState<any>("");
  const [DocumentType, setDocumentType] = React.useState<any>("");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (zipReoprtUrl) {
  //     const tempUrlList = new Array();
  //     const tempUithouturlList = new Array();
  //     const tempUithurlList = new Array();
  //     setUrlFile(zipReoprtUrl);
  //     zipReoprtUrl &&
  //       zipReoprtUrl.map((data: any) => {
  //         if (data[withoutHeader?"no_header_report":"booking_report"] && data[withoutHeader?"no_header_report":"booking_report"] !== "") {
  //           tempUrlList.push(data[withoutHeader?"no_header_report":"booking_report"]);
  //           tempUithurlList.push(data);
  //         } else {
  //           tempUithouturlList.push(data);
  //         }
  //       });
  //     let storeCorrectData: any = [];
  //     let storeIncorrectData: any = [];
  //     tempUithurlList?.map((list: any) => {
  //       if (list[withoutHeader?"no_header_report":"booking_report"]?.search("redcliffelabsbackend") > 1) {
  //         storeIncorrectData.push({
  //           ...list,
  //         });
  //       } else {
  //         storeCorrectData.push({
  //           ...list,
  //         });
  //       }
  //     });
  //     setWithUrlList(storeCorrectData);
  //     setIncorrectBooking(storeIncorrectData);
  //     setWithoutUrlList(tempUithouturlList);
  //   }
  // }, [zipReoprtUrl]);

  const handleFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setIncorrectBooking([]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
  };

  const handleUploadCsvFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    // await uploadZipFileReportId(formData);
    setButtonState(true);
  };

  const DownloadZipFile = async () => {
    let filename = "Redcliffe bulk report";
    const zip = new JSZip();
    var photoZip = zip.folder("Redcliffe Bulk report");
    withUrlReport.forEach((url: any) => {
      const blobPromise = fetch(url[withoutHeader ? "no_header_report" : "booking_report"]).then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      });
      const name = url?.id + "-" + url?.customer_name + ".pdf";
      if (photoZip) {
        photoZip.file(name, blobPromise);
      } else {
        console.log("something went wrong");
      }
    });
    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, filename))
      .catch((e) => console.log(e));
  };

  const documentUploadFunction = async () => {
    const formData = new FormData();
    // formData.append("booking", bookingId);
    // formData.append("remarks", remarks);
    // formData.append("file", file1);
    // setOpenUploadModal(false);
    setFile1("");
    // setRemarks("");
    // await postUploadBookingDocument(formData);
  }
  const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0])
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="main-right">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              style={{ justifyContent: "space-around" }}>
              <Tab
                style={{
                  background: value === 0 ? "#00bfa5" : "#80cbc4", color: "black"
                }}
                label="ZIP UPLOADER"
                {...a11yProps(0)} />

              <Tab
                style={{
                  background: value === 1 ? "#00bfa5" : "#80cbc4", color: "black"
                }}
                label="FILE  UPLOADER"
                {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </div>
        {value === 0 ?
          <>
            <h4 className="mainHeading"
              style={{
                width: "100%",
                textAlign: "center",
                color: "#0e3f6c",
              }}>BULK  ZIP UPLOADER</h4>
            <div className="main-file-upload">
              <div className="file-upload-wrapper">
                <div className="file-uploader">
                  <input
                    type="text/csv"
                    style={{
                      height: "100%",
                      padding: "10px 16px",
                      marginRight: "5px",
                    }}
                    value={file_name}
                    disabled
                  />
                  <Button variant="contained" component="label" color="secondary">
                    Select File
                    <input
                      type="file"
                      name="file"
                      id="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                  {file_name !== "" ? (
                    <Button
                      variant="contained"
                      component="label"
                      color="secondary"
                      onClick={handleUploadCsvFile}
                      style={{ marginLeft: "1rem" }}
                    >
                      Upload csv file
                    </Button>
                  ) : (
                    ""
                  )}

                </div>
                <FormControlLabel
                  control={<Checkbox name="is_active" checked={withoutHeader} />}
                  onChange={() => setWithoutHeader(!withoutHeader)}
                  label="Download Without Header"
                />
                <p style={{ color: "green" }}>
                  {urlFile && urlFile.length > 0
                    ? "Booking id Updated Successfully now you can download zip file"
                    : ""}
                </p>
                {urlFile && urlFile.length > 0 ? (
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: "#924A91",
                      color: "#fff",
                      width: "100%",
                      marginTop: "20px",
                    }}
                    onClick={DownloadZipFile}
                  // disabled={buttonState}
                  >
                    Download Zip File
                  </Button>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={12}>
                  <p
                    style={
                      incorrectBookings?.length > 0
                        ? { display: "block", color: "red", textAlign: "center" }
                        : { display: "none", color: "red", textAlign: "center" }
                    }
                  >
                    {incorrectBookings &&
                      incorrectBookings?.length > 0 &&
                      incorrectBookings?.map((list: any) => {
                        return list?.id + ",";
                      })}{" "}
                    &nbsp; doesn't consolidate!
                  </p>
                </Grid>
              </div>
            </div>
          </>
          : <div style={{ backgroundColor: "whitesmoke", padding: "20px" }}>
            <h4 className="mainHeading"
              style={{
                width: "100%",
                textAlign: "center",
                color: "#0e3f6c",
              }}>Upload Documents</h4>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ margin: "1rem auto" }}
            >
              <Grid item xs={12} >
                <p>Document Type</p>
                <Select className="input"
                  name="action"
                  variant="outlined"
                  value={DocumentType}
                  style={{ width: "100%" }}
                  onChange={(e) => setDocumentType(e.target.value as string)}

                >
                  <MenuItem value={"phlebo_delay"}>Company Incorporation Documents</MenuItem>
                  <MenuItem value={"phlebo_behavior"}>Financial Documents</MenuItem>
                  <MenuItem value={"report_not_delivered"}>Legal Documents</MenuItem>
                  <MenuItem value={"fault_report"}>Human Resources Documents</MenuItem>
                  <MenuItem value={"payment_related_issue"}> Corporate Governance Documents</MenuItem>
                  <MenuItem value={"refund_query"}>Insurance Documents</MenuItem>
                  <MenuItem value={"doctor_consultation"}>Compliance Document</MenuItem>
                  <MenuItem value={"social_media_ticket"}>Marketing and Sales Documents</MenuItem>
                  <MenuItem value={"social_media_ticket"}>IT and Data Security Documents</MenuItem>
                  <MenuItem value={"social_media_ticket"}>Training and Development Document</MenuItem>
                  <MenuItem value={"social_media_ticket"}>Miscellaneous Document</MenuItem>
                  <MenuItem value={"others"}>Others</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <sup
                  style={{
                    fontSize: "11px",
                    marginLeft: "15px",
                    marginTop: "15px",
                    background: "#fff",
                    color: "rgba(0, 0, 0, 0.54)",
                    letterSpacing: "0.00938em",
                    height: "40px",
                  }}
                >
                  Upload Document
                </sup>
                <input
                  className="input"
                  style={{
                    width: "100%",
                    marginTop: "-15px",
                    marginBottom: "10px",
                    padding: "18.5px 14px",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "5px",
                  }}
                  accept=".pdf,.doc,.jpg,.jpeg,.png"
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={fileSelectedHandler1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={documentUploadFunction}
                  disabled={file1 === ""}
                  style={{ height: "56px", paddingTop: "6px", marginTop: "7px", color: (file1 === "") ? "gray" : "white" }}
                >
                  UPLOAD
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <a
                  href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/ddc2b305-8a86-4e00-9fb3-628f7aa8ff67.csv"
                  target=""
                >
                  <Button
                    fullWidth
                    style={{ height: "56px", background: "#4caf50", marginTop: "8px" }}
                    variant="contained"
                    component="label"
                  >
                    Download  Sample CSV  File
                  </Button>
                </a>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
            >
              <Grid item xs={12} md={8}>
                <small style={{ color: 'rgb(24 53 96)', fontSize: '16px' }}>
                  <ul style={{ paddingLeft: '15px', marginTop: '20px', listStyleType: 'none' }}>
                    <li style={{ marginBottom: '8px' }}>
                      <span style={{ backgroundColor: '#f0f0f0', padding: '5px 10px', borderRadius: '5px' }}>
                        <strong>Note 1:</strong> You can upload images, CSV files, PDFs, or any other type of file.
                      </span>
                    </li>
                    <li style={{ marginBottom: '8px' }}>
                      <span style={{ backgroundColor: '#f0f0f0', padding: '5px 10px', borderRadius: '5px' }}>
                        <strong>Note 2:</strong> You can select more than one at a time.
                      </span>
                    </li>
                  </ul>
                </small>

              </Grid>

            </Grid>
          </div>}

      </div>
    </main>

  );
};


const mapStateToProps = (state: any) => ({
  // zipReoprtUrl: state.CorAdminReducer.zipReoprtUrl,
});

export default connect(mapStateToProps, {
  // uploadZipFileReportId,
})(CustomUploader);
