import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, withWidth } from "@material-ui/core";
import DatePicker, { DateObject } from "react-multi-date-picker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { } from "../../actions/PayrollActions";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import WatchIcon from "@mui/icons-material/Watch";
import SpeedIcon from "@mui/icons-material/Speed";
import "./style.css";
import { CenterFocusStrong } from "@material-ui/icons";

import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";
// import ".newAttendance.css"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    doctor__cards: {
      display: "flex",
      flexWrap: "wrap",
      width: "94%",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "monospace",
    },
    inner__Cards: {
      boxShadow: "0 8px 10px 0px rgba(16, 16, 17, 0.5)",
      height: "50px",
      border: "5px",
      width: "46%",
      margin: "10px 0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    inner__doctor__cards: {
      boxShadow: "0 8px 10px 0px rgba(16, 16, 17, 0.5)",
      border: "15px",
      height: "50px",
      width: "46%",
      margin: "10px 0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // width: auto,
    },
  })
);

interface Props {
  loading: boolean;
}

const Dashboard: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();
  const timer = useRef(0);
  const [end_date, setEnd_date] = useState("");
  const [dates, setDates] = useState([]);
  const [key, setKey] = useState(false);
  const [openAttendance, setopenAttendanceOpen] = useState(false);
  const [phlebo, setPhlebo] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [phleboRemarks, setPhleboRemarks] = useState<any>();
  const [inTime, setInTime] = useState("");
  const [OutTime, setOutTime] = useState("");

  const [clockInOpen, setClockInOpen] = useState(false);
  const [attendance, setopenAttendance] = useState(false);

  // const [inTime, setInTime] = useState('');

  const [inDate, setInDate] = useState("");

  const [outDate, setOutDate] = useState("");

  const [location, setLocation] = useState("");
  const [reason, setReason] = useState("");

  const handleCancelClick = () => {
    // Handle cancel action here, for example:
    setopenAttendance(false);
  };


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <div style={{ marginTop: 40, width: "100%" }}>
      <div style={{ margin: "10px 0px 40px 0px" }}>
        <h5
          style={{
            display: "flex",
            padding: "20px",
            fontWeight: "bolder",
            color: "#cc24af",
          }}
        >
          My Attendance
        </h5>
        <Grid item xs={12} sm={4} md={2} style={{ marginLeft: "auto" }}>
          <TextField
            name="end_date"
            type="date"
            id="noTyping"
            value={end_date}
            className="input"
            label="Select Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            marginTop: "10px",
            padding: "10px",
            background: "#f3f3f3",
            borderRadius: 8,
          }}
        >
          <Grid item xs={6} sm={4} md={6}>
            <span
              style={{
                color: "#827d7d",
                padding: "0px 10px 0px 10px",
                fontSize: "20px",
              }}
            >
              Present Days: {" "}
              <b
                style={{ color: "black", fontWeight: "700", fontSize: "20px" }}
              >
                {20}
              </b>
            </span>
          </Grid>
          <Grid item xs={6} sm={4} md={6}>
            <span
              style={{
                color: "#827d7d",
                padding: "0px 10px 0px 10px",
                fontSize: "20px",
              }}
            >
              Absent Days:{" "}
              <b
                style={{ color: "black", fontWeight: "700", fontSize: "20px" }}
              >
                {20}
              </b>
            </span>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            marginTop: "20px",
            padding: "10px",
            background: "#f3f3f3",
            borderRadius: 8,
          }}
        >
          <Grid item xs={6} sm={4} md={3}>
            <Button
              variant="contained"
              style={{ background: "#006064", color: "white" }}
              fullWidth
              onClick={() => setClockInOpen(true)}
            >
              Clock-in Request
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button
              variant="contained"
              style={{ background: "#1b5e20" }}
              fullWidth
              onClick={() => setopenAttendanceOpen(true)}
            >
              Attendance Update
            </Button>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Button
              style={{ background: "rgb(40 128 163)" }}
              variant="contained"
              startIcon={<SettingsIcon />}
              fullWidth
            // onClick={() => handleUpdateRefund(data)}
            >
              Advanced Options
            </Button>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Button
              variant="contained"
              style={{ background: "#1b5e20" }}
              fullWidth
              // onClick={() => setopenAttendance(true)}
              onClick={() => setIsDrawerOpen(true)}
            >
              Attendance
            </Button>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Typography variant="h6" component="div"></Typography>
            </Drawer>
          </Grid>
          <>
            {/* <button onClick={()=>setIsDrawerOpen(true)}>hello</button> */}

            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <Box p={2} width={410} textAlign={"left"} role="presentation">
                <Typography variant="h6" component="div">
                  <Grid
                    container
                    spacing={4}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <form className="view">
                      <h2
                        className="mainHeading"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "rgb(103, 37, 103)",
                          marginTop: "30px",
                        }}
                      >
                        Recipients
                      </h2>

                      <label>
                        Select shift date
                        <br />
                        <input className="tex"
                          type="date"
                          value={inDate}
                          onChange={(event) => setInDate(event.target.value)}
                        />
                        {/* 
              <label>
                Shift
              </label> */}
                      </label>
                      <label>
                        Select in date:
                        <br />
                        <input className="tex"
                          type="date"
                          value={inDate}
                          onChange={(event) => setInDate(event.target.value)}
                        />
                      </label>
                      <label>
                        In time:
                        <br />
                        <input className="tex"
                          type="time"
                          value={inTime}
                          onChange={(event) => setInTime(event.target.value)}
                        />
                      </label>
                      <label>
                        Select out date:
                        <br />
                        <input className="tex"
                          type="date"
                          value={outDate}
                          onChange={(event) => setOutDate(event.target.value)}
                        />
                      </label>
                      <label>
                        Select out time
                        <br />
                        <input className="tex"
                          type="time"
                          value={OutTime}
                          onChange={(event) => setOutTime(event.target.value)}
                        />
                      </label>
                      <label>
                        Where were you working from?
                        <br />
                        <select className="tex">
                          <option></option>
                          placeholder="Enter"
                          <option value="someOption">Work from office</option>
                          <option value="Work from home">Work from home</option>
                          {/* value={location}
                onChange={(event) => setLocation(event.target.value)} */}
                        </select>
                        {/* <input
                // type="text"
                // placeholder="Enter"
                value={location}
                onChange={(event) => setLocation(event.target.value)}
              /> */}
                      </label>

                      <label >
                        Reason for request
                        <br />
                        {/* {textarea style={{ width: "300px", height: "6em" }} 
                // placeholder="Enter"
                value={reason}
                onChange={(event) => setReason(event.target.value)}
                /> } */}
                        <textarea className="tex"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          style={{ width: "310px" }}
                        />
                      </label>
                      <div>
                        <button className="button-style" type="submit">
                          Add
                        </button>
                        <button
                          className="button-style"
                          type="button"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Grid>
                </Typography>
              </Box>
            </Drawer>
          </>
        </Grid>
      </div>
      <div style={{ margin: "40px 0px 40px 0px" }}>
        <div className="cardsAnalytics">
          <div
            style={{
              height: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <SpeedIcon style={{ color: "rgb(6 136 204)" }} />
              <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>
                Overview
              </span>
            </div>
            <div>
              <span className="card-data">
                Present Days:<span className="right-align">10</span>
              </span>
              <span className="card-data">
                Leave Days:<span className="right-align">2</span>
              </span>
              <span className="card-data">
                Absent Days:<span className="right-align">13</span>
              </span>
              <span className="card-data">
                Leave Hours:<span className="right-align">01:30:15</span>
              </span>
            </div>
          </div>
          <div
            style={{
              height: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <QueryBuilderIcon style={{ color: "rgb(6 136 204)" }} />
              <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>
                Total Duration
              </span>
            </div>
            <div>
              <div>
                <span className="card-data">
                  Work Duration:<span className="right-align">10</span>
                </span>
                <span className="card-data">
                  Late by:<span className="right-align">2</span>
                </span>
                <span className="card-data">
                  Overtimes:<span className="right-align">13</span>
                </span>
                <span className="card-data">
                  Working Hours:<span className="right-align">10:02:55</span>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <WatchIcon style={{ color: "rgb(6 136 204)" }} />
              <span style={{ color: "#827d7d", padding: "0px 10px 0px 10px" }}>
                Average Duration
              </span>
            </div>
            <div>
              <div>
                <span className="card-data">
                  Work Duration:<span className="right-align">10</span>
                </span>
                <span className="card-data">
                  Late by:<span className="right-align">2</span>
                </span>
                <span className="card-data">
                  Overtimes:<span className="right-align">13</span>
                </span>
                <span className="card-data">
                  Working Hours:<span className="right-align">10:02:55</span>
                </span>
              </div>
            </div>
          </div>
          <div style={{ opacity: "0" }}></div>
          <div style={{ opacity: "0" }}></div>
        </div>
      </div>

      <div>
        <Modal
          style={{ width: "500px", top: "1%", right: "100", padding: "1px" }}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={attendance}
          onClose={() => setopenAttendance(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={attendance}>
            <div className={classes.paper}>
              <h4>Recipients</h4>

              <form style={{ width: "90px" }}>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  style={{
                    alignItems: "right",
                    marginTop: "10px",
                    margin: "1rem auto",
                  }}
                >
                  <label>
                    Select shift date
                    <br />
                    <input className="tex"
                      type="date"
                      value={inDate}
                      onChange={(event) => setInDate(event.target.value)}
                    />
                    {/* 
        <label>
          Shift
        </label> */}
                  </label>
                  <label>
                    Select in date:
                    <br />
                    <input className="tex"
                      type="date"
                      value={inDate}
                      onChange={(event) => setInDate(event.target.value)}
                    />
                  </label>
                  <label>
                    In time:
                    <br />
                    <input className="tex"
                      type="time"
                      value={inTime}
                      onChange={(event) => setInTime(event.target.value)}
                    />
                  </label>
                  <label>
                    Select out date:
                    <br />
                    <input
                      type="date"
                      value={outDate}
                      onChange={(event) => setOutDate(event.target.value)}
                    />
                  </label>
                  <label>
                    Select out time
                    <br />
                    <input
                      type="time"
                      value={OutTime}
                      onChange={(event) => setOutTime(event.target.value)}
                    />
                  </label>
                  <label>
                    Where were you working from?
                    <br />
                    <select style={{ width: "97%", height: "3em" }}>
                      <option></option>
                      placeholder="Enter"
                      <option value="someOption">Work from office</option>
                      <option value="Work from home">Work from home</option>
                      {/* value={location}
          onChange={(event) => setLocation(event.target.value)} */}
                    </select>
                    {/* <input
          // type="text"
          // placeholder="Enter"
          value={location}
          onChange={(event) => setLocation(event.target.value)}
        /> */}
                  </label>

                  <label>
                    Reason for request
                    <br />
                    <input
                      type="textarea"
                      style={{ width: "22em", height: "7em" }}
                      // placeholder="Enter"
                      value={reason}
                      onChange={(event) => setReason(event.target.value)}
                    />
                  </label>
                  <div className="diplayflex">
                    <button className="button-style" type="submit">
                      Add
                    </button>
                    <button
                      className="button-style"
                      type="button"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </Grid>
              </form>
            </div>
          </Fade>
        </Modal>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAttendance}
        onClose={() => setopenAttendanceOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAttendance}>
          <div className={classes.paper}>
            <h4>Recipients</h4>

            <form >
              <Grid
                container
                spacing={3}
                direction="row"
                style={{
                  alignItems: "left",
                  marginTop: "20px",
                  margin: "1rem auto",
                }}
              >
                <Grid item xs={12} sm={4} md={2}>
                  <p>Date</p>
                  <DatePicker
                    value={dates}
                    onChange={() => setDates}
                    multiple
                    sort
                    maxDate={new DateObject().add(37, "days")}
                    style={{ height: "48px", width: "auto" }}
                    calendarPosition="bottom-center"
                    plugins={[<DatePanel />]}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <p>Date</p>
                  <TextField
                    id="time"
                    label={"in Time"}
                    type="time"
                    value={inTime}
                    defaultValue="07:30"
                    className={classes.textField}
                    onChange={(e) => setInTime(e.target.value as string)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <p>Date</p>
                  <TextField
                    id="time"
                    label={"Out Time"}
                    type="time"
                    value={OutTime}
                    defaultValue="07:30"
                    className={classes.textField}
                    onChange={(e) => setOutTime(e.target.value as string)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p>Where were you working from?</p>
                  <Autocomplete
                    id="city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setLeaveReason(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "Work From office ",
                        value: "CO",
                      },
                      {
                        text: "Work From Home",
                        value: "WO",
                      },
                    ]}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    getOptionLabel={(option) => option.text}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Where are you working from ?"
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p style={{ fontWeight: "bold" }}>Reason of Request</p>
                  <textarea
                    className="input"
                    name="phlebo_remarks"
                    placeholder="Enter Phlebo Remarks"
                    value={phleboRemarks}
                    style={{ width: "100%", margin: "0", padding: "4px" }}
                    onChange={(e: any) => setPhleboRemarks(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "2.2rem", height: "48px" }}
                  >
                    Regularize
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "2.2rem", height: "48px" }}
                    startIcon={<RestartAltIcon />}
                    onClick={() => {
                      setLeaveReason("");
                      setDates([]);
                      setPhlebo("");
                      setKey(!key);
                      // history.push("/dashboard/sla/holiday")
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
      {/* ****** */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={clockInOpen}
        onClose={() => setClockInOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={clockInOpen}>
          <div className={classes.paper}>
            <h4>Clock in request</h4>

            <form >
              <Grid
                container
                spacing={3}
                direction="row"
                style={{
                  alignItems: "left",
                  marginTop: "20px",
                  margin: "1rem auto",
                }}
              >
                <Grid item xs={12} sm={4} md={2}>
                  <p>Date</p>
                  <TextField
                    id="time"
                    label={"Clock in time"}
                    type="time"
                    value={inTime}
                    defaultValue="07:30"
                    className={classes.textField}
                    onChange={(e) => setInTime(e.target.value as string)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <p>Where were you working from?</p>
                  <Autocomplete
                    id="city"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setLeaveReason(obj?.value);
                      }
                    }}
                    options={[
                      {
                        text: "Work From office ",
                        value: "CO",
                      },
                      {
                        text: "Work From Home",
                        value: "WO",
                      },
                    ]}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    getOptionLabel={(option) => option.text}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Where are you working from ?"
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <p style={{ fontWeight: "bold" }}>Reason of Request</p>
                  <textarea
                    className="input"
                    name="phlebo_remarks"
                    placeholder="Enter Phlebo Remarks"
                    value={phleboRemarks}
                    style={{ width: "100%", margin: "0", padding: "4px" }}
                    onChange={(e: any) => setPhleboRemarks(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "2.2rem", height: "48px" }}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "2.2rem", height: "48px" }}
                    startIcon={<RestartAltIcon />}
                    onClick={() => {
                      setLeaveReason("");
                      setDates([]);
                      setPhlebo("");
                      setKey(!key);
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.PayrollReducer.loading,
});

export default connect(mapStateToProps, {})(Dashboard);
