import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField, Button, MenuItem,  Select, } from "@material-ui/core";
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import FormControl from "@material-ui/core/FormControl";
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PersonalData from "./Personal-data";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        contentTable: {
            width: "100%",
            padding: "0px 0px 10px 0px",
            marginTop: "40px",
            minHeight: "300px",
        },
    })
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    style: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


interface Props {

    loading: any;

}

const PackageMaster: React.FC<Props> = ({

    loading,



}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [openModal, setopenModal] = useState<boolean>(false)
    const [packId, setpackId] = useState<any>("")
    const [file1, setFile1] = React.useState<any>("");
    const [remarks, setRemarks] = useState<any>("")
    const [documentType, setDocumentType] = useState<any>("");

    const documentUploadFunction = async () => {
        const formData = new FormData();

        setFile1("");
        setRemarks("");

    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {

        setValue(newValue);
    };
    const fileSelectedHandler1 = async (e: React.ChangeEvent<{ files: any }>) => {
        setFile1(e.target.files[0])
    };


    return (
        <>

            <div className={classes.root} style={{ marginTop: "3.5rem" }}>
                <AppBar position="static" >
                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example" style={{ justifyContent: "space-around" }}>
                        <Tab style={{ background: value === 0 ? "#a7d1b7" : "#20B2AA", color: "black" }} label="PERSONAL DATA" {...a11yProps(0)} />
                        <Tab style={{ background: value === 1 ? "#a7d1b7" : "#20B2AA", color: "black" }} label="WORK PROFILE" {...a11yProps(1)} />
                        <Tab style={{ background: value === 2 ? "#a7d1b7" : "#20B2AA", color: "black" }} label="DOCUMENT" {...a11yProps(2)} />

                    </Tabs>
                </AppBar>

                <TabPanel style={{ width: "100%" }} value={value} index={0}  >
                 <PersonalData/>
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={1}>
                    {/* <BulkUpload /> */}
                </TabPanel>

                <TabPanel style={{ width: "100%" }} value={value} index={2}>
                    <div style={{ backgroundColor: "whitesmoke", padding: "20px" }}>
                        <h6 style={{ fontWeight: "700", fontSize: "22px" }}>Upload Documents</h6>
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                            style={{ margin: "1rem auto", background: "#e1f5fe" }}
                        >
                            <Grid item xs={12} md={12}>
                                <FormControl style={{ width: "100%" }} className="input">
                                    <p>Document Type</p>
                                    <Select
                                        name="DocumentType"
                                        label="Document Type"
                                        value={documentType}
                                        className="input"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", height: "40px" }}
                                        onChange={(e) => setDocumentType(e.target.value as string)}
                                        required
                                    >
                                        <MenuItem value="aadhaar_card">Aadhaar Card</MenuItem>
                                        <MenuItem value="pan_card">Pan Card</MenuItem>
                                        <MenuItem value="voter_id">Voter ID</MenuItem>
                                        <MenuItem value="usg_ultrasound_report">Driving Licence</MenuItem>
                                        <MenuItem value="others">Others</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}>
                                
                                <TextField
                                    name="remarks"
                                    type="text"
                                    placeholder="Enter Remarks"
                                    value={remarks}
                                    className="input"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: "100%", margin: "0" }}
                                    onChange={(e) =>
                                        setRemarks(e.target.value as String)
                                    }
                                    required
                                />
                            </Grid>
                            <Grid item md={12}>
                                <sup
                                    style={{
                                        fontSize: "11px",
                                        marginLeft: "15px",
                                        marginTop: "15px",
                                        background: "#fff",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        letterSpacing: "0.00938em",
                                        height: "40px",
                                    }}
                                >
                                    Upload Document
                                </sup>
                                <input
                                    className="input"
                                    style={{
                                        width: "100%",
                                        marginTop: "-15px",
                                        marginBottom: "10px",
                                        padding: "18.5px 14px",
                                        border: "1px solid rgba(0, 0, 0, 0.23)",
                                        borderRadius: "5px",
                                    }}
                                    accept=".pdf,.doc,.jpg,.jpeg,.png"
                                    type="file"
                                    name="file"
                                    id="file"
                                    multiple
                                    onChange={fileSelectedHandler1}
                                />
                            </Grid>
                            <Grid item md={12} style={{ paddingTop: "27px", display: "flex", justifyContent: "center" }}>
                                <Button
                                    variant="contained"
                                    onClick={documentUploadFunction}
                                    disabled={remarks === "" || file1 === ""}
                                    style={{ height: "40px", paddingTop: "4px", color: (remarks === "" || file1 === "") ? "gray" : "white" }}
                                >
                                    UPLOAD
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>

            </div>

        </>
    );
};
const mapStateToProps = (state: any) => ({

    loading: state.PayrollReducer.loading,

});
export default connect(mapStateToProps, {
})(PackageMaster);