import React, { useEffect, useState, useRef } from "react";
import "./leavestyle.css";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { leaveApplication } from "../../actions/PayrollActions";
import { connect, useDispatch } from "react-redux";
import dayjs from 'dayjs';

import {
  getEmployeeList,
  getLeaveComponents
} from "../../../actions/loginActions"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    rightContentHeader: {
      float: "right",
      textAlign: "right",
      paddingRight: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
  })
);

interface Props {
  leaveApplication: Function;
  leave_application: any;

  getEmployeeList: any;
  employeesList: any;
  
  getLeaveComponents: any;
  leaveComponents: any;
}

const LeaveApplication: React.FC<Props> = ({
  leaveApplication,
  leave_application,
  getEmployeeList,
  employeesList,
  getLeaveComponents,
  leaveComponents,

}) => {
  const timer = useRef<any>(0);
  const [leaveType, setLeaveType] = useState();
  const [firstHalf, setFirstHalf] = useState('first_half');
  const [secondHalf, setSecondHalf] = useState('first_half');
  const [medicalCertificate, setMedicalCertificate] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [employeeUUID, setEmployeeUUID] = useState<any>("");
  const [LeaveTypeId, setLeaveTypeId] = useState<any>("")
  const diffDays = dayjs(toDate).diff(dayjs(fromDate), 'days');

  useEffect(() => {
    if (employeesList && employeesList.length > 0) {
      setEmployeeUUID(employeesList[0]?.uuid)
    }
  }, [employeesList])



  const handleLeaveTypeChange = (e: any) => {
    setLeaveType(e.target.value);
    setMedicalCertificate(null);
  };

  const handleMedicalCertificateChange = (e: any) => {
    setMedicalCertificate(e.target.files[0]);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data: any = {
      reason_for_leave: reason,
      from_date: fromDate,
      to_date: toDate,
      leave_from_session: firstHalf,
      leave_to_session: secondHalf,
      medical_certificate: medicalCertificate,
      employee_id: employeeUUID,
      component: leaveType,
      component_id: LeaveTypeId,
    };
    if (medicalCertificate == null) {
      delete data.medical_certificate;
    }

    leaveApplication(data)
  };

  function SubmitButton() {
    if (leaveType && firstHalf && secondHalf && fromDate && toDate && reason) {

    } else {
      return <button type="button" disabled>Button</button>
    };
  };


  useEffect(() => {
    getEmployeeList("")
    getLeaveComponents("")
    // setEmployeeUUID(employeesList.uuid)

  }, [])


  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          width: "100%",
          textAlign: "center",
          color: "rgb(14, 63, 108)",
        }}
      >
        LEAVE APPLICATION
      </h2>
      <div style={{ marginTop: 70, width: "100%" }}>
        <Grid
          container
          spacing={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <form className="hello" onSubmit={handleSubmit}>

            <Autocomplete
              onChange={(event, obj) => {
                if (obj) {
                  setLeaveTypeId(obj.id)
                }
              }}
              id="checkboxes-tags-demo"
              options={leaveComponents || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => { }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option.name;
              }}
              renderOption={(option: any) => {
                return (<p>{option?.name.toString()}</p>)
              }}
              renderInput={(params) => (
                <TextField
                
                  className="input"
                  {...params}
                  label="Leave Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />

            <label>
              From:
              <section style={{ display: "flex" }}>
                <select
                  className="tex"
                  value={firstHalf}
                  onChange={(e) => setFirstHalf(e.target.value)}
                  style={{ borderRadius: "10px 0px 0px 10px" }}
                >
                  <option value="first_half">First Half</option>
                  <option value="second_half">Second Half</option>
                </select>
                <input
                  className="tex"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  style={{ borderRadius: "0px 10px 10px 0px" }}
                />
              </section>
            </label>
            <label>
              To:
              <section style={{ display: "flex" }}>
                <select
                  className="tex"
                  value={secondHalf}
                  onChange={(e) => setSecondHalf(e.target.value)}
                  style={{ borderRadius: "10px 0px 0px 10px" }}
                >
                  <option value="first_half">First Half</option>
                  <option value="second_half">Second Half</option>
                </select>
                <input
                  className="tex"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  style={{ borderRadius: "0px 10px 10px 0px" }}
                />
              </section>
            </label>
            <label>
              Reason for Leave:
              <textarea
                className="tex"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </label>
            {(leaveType === "Medical" && diffDays >= 3) && (
              <label>
                Medical Certificate:
                <br />
                <input
                  className="medical"
                  type="file"
                  onChange={handleMedicalCertificateChange}
                />
              </label>
            )}

            <button
              style={{ backgroundColor: "rgb(14, 63, 108)" }}
              className="but"
              type="submit"
              disabled={
                fromDate === ""
                || toDate === ""
                || reason === ""
              }
            >
              Apply
            </button>

          </form>
        </Grid>
      </div>
      :
    </main>
  );
};



const mapStateToProps = (state: any) => ({
  leave_application: state.PayrollReducer.leave_application,
  employeesList: state.loginReducer.employeesList,
  leaveComponents: state.loginReducer.leaveComponents,
});

export default connect(mapStateToProps, {
  leaveApplication,
  getEmployeeList,
  getLeaveComponents,
})(LeaveApplication);