import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { MessageRounded } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import CommentModal from "./CommentModal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import { Switch } from "@material-ui/core";
import Loader from "../Loader2";
import { useHistory } from "react-router-dom";
import {
    getEmployeFilterList,
    absenceNotificationPost,
} from "../../actions/PayrollActions";
import { getEmployeeList } from "../../../actions/loginActions"
import { generateEmployeeFilter } from "../../../helper/generate";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TablePagination,
    TableFooter,
    TableCell,
    TableRow,
} from "@material-ui/core";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const DefaultSwitch: any = withStyles({
    switchBase: {
        color: "red",
        "&$checked": {
            color: "green",
        },
        "&$checked + $track": {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red",
    },
})(Switch);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        container: {
            position: "sticky",
            marginTop: 15,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },

        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },

        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        closeicon: {
            backgroundColor: "#d1ff33",
            width: '1.5em',
            height: '1.5em',
            borderRadius: "50%",
            border: "1px solid #c6ff00",
            cursor: "pointer",
            color: "#1a237e"
        },
        input: {
            padding: "1px",
        },
        blinker: {
            animation: `$blinker 1.5s linear infinite`,
        },
        "@keyframes blinker": {
            "50%": {
                opacity: 0.5,
            },
        },
    })
);

const ButtonDesign = styled(Button)({
    background: "#5B42F3",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px 20px",
    borderRadius: "20px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    transition: "all 0.3s ease",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        background: "linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB)",
    },
    "& .MuiButton-startIcon": {
        transition: "transform 0.3s ease",
    },
    "&:hover .MuiButton-startIcon": {
        transform: "rotate(15deg)",
    },
});

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#6c757d",
            color: theme.palette.common.white,
            fontSize: "16px",
        },
        body: {
            fontSize: 14,
            padding: "5px",
            textTransform: "capitalize",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loading: boolean;
    employeesFilterList: { results: { employee_id: any; name: string; uuid?: string }[] };
    getEmployeFilterList: any;
    employeesList: any;
    getEmployeeList: any;
    uuid: any;
    absenceNotificationPost: any;
}
const DailyAbsence: React.FC<Props> = ({
    loading,
    employeesFilterList,
    getEmployeFilterList,
    employeesList,
    getEmployeeList,
    absenceNotificationPost,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const handleOpenFilter = () => setFilterOpen(true);
    const handleCloseFilter = () => setFilterOpen(false);
    const [employeeName, setEmployeeName] = useState<string>("");
    const [employeeId, setEmployeeId] = useState<any>("");
    const [openCommentModal, setOpenCommentModal] = React.useState(false);
    const [empId, setEmpId] = useState<any>("");

    useEffect(() => {
        getEmployeFilterList("get_all=true");
        getEmployeeList("get_all=true");
    }, []);

    const filterEmp = () => {
        const body: any = {
            employee_id: employeeId,
        };
        const url = generateEmployeeFilter(body).substring(2);
        getEmployeeList(`get_all=true&${url}`);
        setPage(0);
        setFilterOpen(false);
        setEmployeeId("");

    };

    const handleActiveDaily = async (data: any) => {
        const body: any = {
            employee_id: data.employee_id,
            daily_absence_notification: !data?.absence_notification?.daily_absence_notification,
            three_days_absence_notification: data?.absence_notification?.three_days_absence_notification,
        };
        await absenceNotificationPost(body);
        getEmployeeList("get_all=true");
    };

    const handleActiveThreeDay = async (data: any) => {
        const body: any = {
            employee_id: data.employee_id,
            daily_absence_notification: data?.absence_notification?.daily_absence_notification,
            three_days_absence_notification: !data?.absence_notification?.three_days_absence_notification,
        };
        await absenceNotificationPost(body);
        getEmployeeList("get_all=true");
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = employeesList.links && employeesList.links.next.split("?")[1];

            getEmployeeList(`${url}`);
        } else if (newPage < page) {
            let url =
                employeesList.links && employeesList.links.previous.split("?")[1];

            getEmployeeList(`${url}`);
        }
        setPage(newPage as number);
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
                direction="row"
            >
                <Grid item xs={12} sm={6} md={6}>
                    <h4
                        style={{
                            color: "rgb(14, 63, 108)",
                            display: "flex",
                            justifyContent: "left",
                            fontSize: "35px",
                            fontWeight: "bolder"
                        }}
                    >
                        Daily Absence Notificaion ON/Off
                    </h4>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ display: "flex", gap: "8px", justifyContent: "right" }}>
                    <ButtonDesign
                        variant="contained"
                        startIcon={<FilterAltIcon />}
                        onClick={handleOpenFilter}
                        style={{ width: "200px" }}
                    >
                        Filter
                    </ButtonDesign>
                </Grid>

            </Grid>
            <hr style={{ borderBottom: "3px solid #007BFF" }} />
            <TableContainer
                className={classes.container}
                style={{ maxHeight: "530px" }}
                component={Paper}
            >
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                <b>Sr. No.</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Name</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Employee ID</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Employee Department</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Comment</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Daily Absence</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Three Day Absence</b>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <Loader />
                        ) : (
                            employeesList &&
                            employeesList.results &&
                            employeesList.count > 0 &&
                            employeesList.results.map((data: any, index: any) => (
                                <StyledTableRow>
                                    <StyledTableCell align="center">
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.fullname || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.employee_id || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.department || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <MessageRounded
                                            onClick={() => {
                                                setOpenCommentModal(true)
                                                setEmpId(data?.employee_id)
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <DefaultSwitch
                                            name="checked"
                                            inputProps={{
                                                "aria-label": "secondary checkbox",
                                            }}
                                            disableRipple
                                            checked={data?.absence_notification?.daily_absence_notification}

                                            onClick={() => handleActiveDaily(data)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <DefaultSwitch
                                            name="checked"
                                            inputProps={{
                                                "aria-label": "secondary checkbox",
                                            }}
                                            disableRipple
                                            checked={data?.absence_notification?.three_days_absence_notification}
                                            onClick={() => handleActiveThreeDay(data)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow style={{ textAlign: "center" }}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={6}
                                count={employeesList?.count || 0}
                                rowsPerPage={employeesList?.page_size}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Modal
                open={filterOpen}
                onClose={handleCloseFilter}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <h3 style={{ color: "#0e3f6c" }}> Filter Daily Absence </h3>
                        <CloseIcon
                            onClick={handleCloseFilter}
                            className={classes.closeicon}
                        />
                    </div>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        style={{ alignItems: "left" }}
                    >
                        <Grid item xs={12} sm={4} md={4}>
                            <Autocomplete
                                onChange={(event, obj) => {
                                    if (obj) {
                                        setEmployeeId(obj.employee_id);
                                    } else {
                                        setEmployeeId("");
                                    }
                                }}
                                id="checkboxes-tags-demo"
                                options={employeesFilterList?.results || []}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timer.current);
                                    timer.current = setTimeout(() => {
                                        newInputValue &&
                                        getEmployeFilterList(
                                                `get_all=true&employee_id=${newInputValue}`
                                            );
                                    }, 1000);
                                }}
                                defaultValue={{
                                    employee_id: employeeId,
                                    name: employeeName,
                                }}
                                getOptionLabel={(option: any) => {
                                    return option?.employee_id + " " + option.name;
                                }}
                                renderOption={(option: any) => {
                                    return (
                                        <p>
                                            <b style={{ color: "blue" }}>Employee Name:</b>
                                            <strong>
                                                {option?.name.toString()}
                                                <br />
                                            </strong>
                                            <b style={{ color: "red" }}>Employee Id:</b>
                                            {option?.employee_id?.toUpperCase()}
                                        </p>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        className="input"
                                        {...params}
                                        label="Employee Name"
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                                if (e.key === "Enter") {
                                                    e.stopPropagation();
                                                }
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonPrimary"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={filterEmp}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Button
                                className="buttonSecondary"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                    history.push("/dashboard/payrollmanager/daily-absence")
                                }
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <CommentModal
                openCommentModal={openCommentModal}
                setOpenCommentModal={setOpenCommentModal}
                empId={empId}
            />
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    loading: state.loginReducer.loading,
    employeesFilterList: state.PayrollReducer.employeesFilterList,
    employeesList: state.loginReducer.employeesList,
});
export default connect(mapStateToProps, {
    getEmployeFilterList,
    getEmployeeList,
    absenceNotificationPost,
})(DailyAbsence);