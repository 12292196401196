import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, styled } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from '@mui/material/Button';
import Fade from "@material-ui/core/Fade";
import FormControlLabel from '@mui/material/FormControlLabel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, TextField, MenuItem, Checkbox, Select, FormControl, InputLabel, IconButton } from "@material-ui/core";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { CancelOutlined } from "@mui/icons-material";
import { EditCertificate, getCertificates } from "../../actions/EmployeeActions";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid green",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "700px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    setOpenModal: Function;
    openModal: boolean;
    employeesList:any;
    getCertificates:any;
    EditCertificate:any;
    selectedData:any;
};

const EditCertificationModal: React.FC<ModalProps> = ({
    setOpenModal,
    openModal,
    employeesList,
    getCertificates,
    EditCertificate,
    selectedData,

}) => {
    const classes = useStyles();
    const [issueDate, setIssueDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [name, setName] = useState<any>("");
    const [file, setFile] = React.useState<any>();
    const [credentialUrl, setCredentialUrl] = useState<any>("");
    const [companyName,setCompanyName] = useState<any>("");
    const [isLifeTimeVaild, setIsLifeTimeValid] = useState<any>(false);
    const [view,setView] = useState<any>(false)
    const [edit,setEdit] = useState<any>(false)
    const [errors, setErrors] = useState({
        name: false,
        startDate: false,
        endDate: false,
        companyName: false,
        fileSize: false,
        invalidUrl: false,
        endDateBeforeStart: false,
    });
    const handleClose = () => {
        setOpenModal(false);
    };

    const fileSelectedHandler = async (e: React.ChangeEvent<{ files: any }>) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 10 * 1024 * 1024) {
            setErrors((prev) => ({ ...prev, fileSize: true }));
        } else {
            setFile(selectedFile);
            setErrors((prev) => ({ ...prev, fileSize: false }));
        }
    };

    const blockCharInAutocompleteField = (e: React.KeyboardEvent<HTMLDivElement>) => {
        return ("~!@#$%^&*()_+{}|:<>?,./;'[]\\=-0987654321`").includes(e.key) && e.preventDefault();
    };
    
    const isValidURL = (url: string) => {
        const urlPattern = new RegExp(
           /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g
        );
        return !!urlPattern.test(url);
    };

      const validateForm = () => {
        const newErrors = {
            name: !name.trim(),
            startDate: !issueDate,
            endDate: !isLifeTimeVaild && (!expirationDate || expirationDate <= issueDate),
            companyName: !companyName.trim(),
            fileSize: file && file.size > 10 * 1024 * 1024,
            invalidUrl: credentialUrl && !isValidURL(credentialUrl),
            endDateBeforeStart: !isLifeTimeVaild && expirationDate < issueDate,
        };
    
        setErrors(newErrors);
    
        return !Object.values(newErrors).includes(true);
    };
        const handleSubmit = async() =>{
            const isValid = validateForm();
            if (!isValid) {
                return;
            }
            const formattedIssueDate = `${issueDate}-01`;
            const formattedExpirationDate = (expirationDate !== "" && expirationDate !== null) ? `${expirationDate}-01` : "";    
            const formData = new FormData();
            const empId = employeesList?.results &&
            employeesList?.results?.length > 0 &&
            employeesList?.results[0]?.uuid;
            formData.append("employee", empId);
            formData.append("certificate_name", name);
            formData.append("issued_by_organization", companyName);
            formData.append("is_lifetime_valid", isLifeTimeVaild);
            formData.append("issue_date", formattedIssueDate);
            formData.append("expiration_date",formattedExpirationDate)
            formData.append("certificate_url", credentialUrl);
            if(file){
                formData.append("document", file);
            }
           await EditCertificate(formData, selectedData?.id);
            setOpenModal(false);
           await getCertificates()
        }

        useEffect(()=>{
            if(selectedData){
                setName(selectedData?.certificate_name)
                setCompanyName(selectedData?.issued_by_organization)
                setIsLifeTimeValid(selectedData?.is_lifetime_valid)
                !selectedData?.is_lifetime_valid && setIssueDate(selectedData?.issue_date?.slice(0, 7));
                setExpirationDate(selectedData?.expiration_date?.slice(0,7))
                setCredentialUrl(selectedData?.certificate_url)
            }

        },[selectedData])

        const handlePdfModelClose = () => {
            setView(false);
          };
        

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <div className={classes.paper}>
                    <>
                        <h4 style={{ display: "flex", justifyContent: 'center' }}>Edit Certification</h4>
                        <Grid container spacing={2} direction="row" style={{ marginTop: "20px" }}>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="name"
                                    id="name"
                                    value={name}
                                    onChange={(e:any)=>{
                                        setName((e.target.value))
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, name:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, name:true
                                            })
                                        }
                                        
                                    }}
                                    required
                                    className="input"
                                    label="Certificate name"
                                    variant="outlined"
                                    error={errors.name}
                                    helperText={errors.name && "Name is required"}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                            <TextField
                                    name="company name"
                                    onChange={(e:any)=>{setCompanyName(e.target.value);
                                        if(e.target.value !== ""){
                                            setErrors({
                                                ...errors, companyName:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, companyName:true
                                            })
                                        }}}
                                    id="company_name"
                                    value={companyName}
                                    required
                                    className="input"
                                    label="Company name"
                                    error={errors.companyName}
                                    helperText={errors.companyName && "Company name is required"}
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={isLifeTimeVaild}
                                    onChange={(e) =>
                                    {
                                        setIsLifeTimeValid(e.target.checked);
                                        setExpirationDate("")
                                        if(errors?.endDateBeforeStart){
                                            setErrors({...errors, endDateBeforeStart: false })
                                        }
                                    }
                                       
                                    }
                                    name="isLifeTimeValid"
                                    style={{
                                    margin: "0",
                                
                                    marginLeft: "10px",
                                    color:"#413c9f"
                                    }}
                                   
                                />
                                }
                                style={{ paddingTop: "7px" }}
                                label="Lifetime Valid."
                            />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                            <TextField
                                    className="input"
                                    name="start_date"
                                    type="month"
                                    label="Issue Date"
                                    required
                                    value={
                                        issueDate
                                    }
                                    variant="outlined"
                                    onChange={(e) => {setIssueDate(e.target.value as string);
                                        if(e.target.value && e.target.value !== null && e.target.value !== ""){
                                            setErrors({
                                                ...errors, startDate:false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, startDate:true
                                            })
                                        }
                                        if(e.target.value <= expirationDate){
                                            setErrors({
                                                ...errors, endDateBeforeStart: false
                                            })
                                        }else{
                                            setErrors({
                                                ...errors, endDateBeforeStart: true
                                            })
                                        }
                                    }}
                                    error={errors.startDate}
                                    helperText={errors.startDate && "Issue Date is required"}
                                    style={{ width: "100%" }}
                                    onKeyDown={blockCharInAutocompleteField}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                                <TextField
                                    name="end_date"
                                    type="month"
                                    value={
                                        expirationDate
                                    }
                                    disabled={isLifeTimeVaild}
                                    className="input disableDate"
                                    required={!isLifeTimeVaild}
                                    error={(!isLifeTimeVaild && errors.endDateBeforeStart) || (!isLifeTimeVaild && errors.endDate)}
                                    helperText={
                                        !isLifeTimeVaild && errors.endDateBeforeStart
                                            ? "Expiration date cannot be before the issue date"
                                            : !isLifeTimeVaild && errors.endDate && "Expiration Date is required"
                                    }
                                    label="Expiration Date"
                                    variant="outlined"
                                    onKeyDown={blockCharInAutocompleteField}
                                    onChange={(e) => {
                                        setExpirationDate(e.target.value as string);
                                        if (!isLifeTimeVaild && e.target.value && e.target.value >= issueDate) {
                                            setErrors({ ...errors, endDate: false, endDateBeforeStart: false });
                                        } else if (!isLifeTimeVaild && e.target.value) {
                                            setErrors({ ...errors, endDateBeforeStart: true });
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} className="centerItem">
                            <TextField
                                name="credentialUrl"
                                id="credentialUrl"
                                value={credentialUrl}
                                onChange={(e: any) => {
                                    setCredentialUrl(e.target.value);
                                    if (!e.target.value || isValidURL(e.target.value)) {
                                        setErrors({ ...errors, invalidUrl: false });
                                    } else {
                                        setErrors({ ...errors, invalidUrl: true });
                                    }
                                }}
                                className="input"
                                label="Credential URL"
                                variant="outlined"
                                error={errors.invalidUrl}
                                helperText={errors.invalidUrl && "Invalid URL"}
                                style={{ width: "100%" }}
                            />
                            </Grid>
                            {!edit && selectedData?.document &&
                            <Grid item xs={12} className="centerItem">
                                <Grid container spacing={3}>
                                    <Grid item><button className="editButton" onClick={()=>setView(true)}>View Document</button></Grid>
                                    <Grid item><button className="editButton" onClick={()=>setEdit(true)}>Edit Document</button></Grid>
                                </Grid>  
                            </Grid>
                            }
                            {(edit || !selectedData?.document) &&
                            <Grid item xs={12} className="centerItem">
                            <FormControl style={{ width: "100%" }} className="uploadInput" >
                                <InputLabel><IconButton style={{padding:"0px", cursor:"pointer"}} onClick={()=>{setEdit(false); setFile(null)}}><CancelOutlined style={{height:"18px"}}/></IconButton> Upload Document</InputLabel>                                
                                <input
                                    className="uploadField"
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={fileSelectedHandler}
                                />
                             {errors?.fileSize && <p className="validationError">File size should not exceed 10 MB</p>}
                            </FormControl>
                            
                            </Grid>
                            }
                            <Grid container spacing={3} xs={12} style={{marginTop:"20px"}} className="centerItem">
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Edit Certificate
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                                </Grid>
                           
                           
                            </Grid>
                        </Grid>
                       
                        {view === true && (
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={view}
                            onClose={handlePdfModelClose}
                            closeAfterTransition
                            >
                            <Fade in={view}>
                                {selectedData?.document?.includes(".jpg") ||
                                selectedData?.document?.includes(".jpeg") ||
                                selectedData?.document?.includes(".png") ? (
                                <div
                                    style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    }}
                                >
                                    <img
                                    src={`${selectedData?.document}`}
                                    style={{ width: "95vh", height: "80%" }}
                                    title="IMAGE Document"
                                    />
                                </div>
                                ) : selectedData?.document?.includes(".doc") || selectedData?.document?.includes(".docx") ? (
                                <div id="mera-doc-viewer">
                                    <DocViewer
                                    style={{ height: "-webkit-fill-available" }}
                                    pluginRenderers={DocViewerRenderers}
                                    documents={[{ uri: selectedData?.document, fileType: "doc" }]}
                                    />
                                </div>
                                ) : (
                                <iframe
                                    src={`${selectedData?.document}`}
                                    style={{ width: "80%", height: "750px" }}
                                    title="PDF Document"
                                ></iframe>
                                )}
                            </Fade>
                            </Modal>
                        )}
                    </>

                </div>
            </Fade>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.EmployeeReducer.loading,
    employeesList: state.loginReducer.employeesList,
});

export default connect(mapStateToProps, {
    getCertificates,
    EditCertificate,
})(EditCertificationModal);