export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_LOADING: "hrms/set_loading",
  DISPLAY_HOLIDAYS: "national-holidays-list/",
  LEAVE_APPLICATION: "hrms/LEAVE_APPLICATION",
  REGULARIZATION: "hrms/REGULARIZATION",
  BIRTHDAY_REMINDER: "hrms/BIRTHDAY_REMINDER",
  ATTENDANCE_MASTER: "hrms/attendance-master",
  EMPLOYEE_SALARY: "hrms/employeeSalary",
  GET_EMPLOYEE_SALARY: "hrms/getEmployeeSalry",
  GET_LEAVE_APPLICATION: "hrms/leaveApplication",
  GET_PENDING_REGULARIZATION: "hrms/pendingRegularization",
  LEAVE_APPLICATION_REVIEW: "hrms/LeaveReview",
  GET_EMPLOYEE_DATA: "hrms/employye_data",
  CREATE_LEAVE_REQUESTS: "hrms/create_leave_requests",
  GET_LEAVE_REQUESTS: "hrms/get_leave_requests",
  GET_ALL_EMPLOYEE_DATA: "hrms/employees",
  UPDATE_REGULARIZE: "hrms/upadte_regularize",
  POST_CREATE_MODULE : "post-create-module",
  UPDATE_LMS:"update-lms",
  DELETE_CREATE_MODULE:"delete-module",
  POST_UPDATE_MODULE:"post-update-module",
  ADD_EMPLOYMENT_DETAILS:"hrms/add-employment-details",
  EDIT_EMPLOYMENT_DETAILS:"hrms/edit-employment-details",
  GET_EMPLOYMENT_DETAILS:"hrms/get-employment-details",
  DELETE_EMPLOYMENT_DETAIL:"hrms/delete-employment-detail",
  ADD_CERTIFICATE:"hrms/add-certificate",
  EDIT_CERTIFICATE:"hrms/edit-certificate",
  GET_CERTIFICATE:"hrms/get-certificate",
  DELETE_CERTIFICATE:"hrms/delete-certificate",
  ADD_MEDICAL_INFO:"hrms/add-medical-info",
  EDIT_MEDICAL_INFO:"hrms/edit-medical-info",
  DELETE_MEDICAL_DETAIL:"hrms/delete-medical-info",
  ADD_EDUCATION:"hrms/add-education",
  EDIT_EDUCATION:"hrms/edit-education",
  GET_EDUCATION:"hrms/get-education",
  DELETE_EDUCATION:"hrms/delete-education",
  GET_LMS_COURSES:"hrms/get-lms-courses",
};
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface LeaveApplication {
  type: typeof ACTIONS.LEAVE_APPLICATION;
  payload: Array<any>;
}
interface Regularization {
  type: typeof ACTIONS.REGULARIZATION;
  payload: Array<any>;
}
interface AttendanceMaster {
  type: typeof ACTIONS.ATTENDANCE_MASTER;
  payload: Array<any>;
}
interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING;
  payload: Array<string | number>;
}

interface DisplayHolidays {
  type: typeof ACTIONS.DISPLAY_HOLIDAYS;
  payload: Array<any>;
}
interface Regularization {
  type: typeof ACTIONS.REGULARIZATION;
  payload: Array<any>;
}

interface BirthdayReminder {
  type: typeof ACTIONS.BIRTHDAY_REMINDER;
  payload: Array<any>;
}
interface EmployeeSalary {
  type: typeof ACTIONS.EMPLOYEE_SALARY;
  payload: Array<any>;
}

interface GetEmployeeSalary {
  type: typeof ACTIONS.GET_EMPLOYEE_SALARY;
  payload: Array<any>;
}
interface GetLeaveApplication {
  type: typeof ACTIONS.GET_LEAVE_APPLICATION;
  payload: Array<any>;
}
interface GetPendingRegularization {
  type: typeof ACTIONS.GET_PENDING_REGULARIZATION;
  payload: Array<any>;
}
interface leaveApplicationReview {
  type: typeof ACTIONS.LEAVE_APPLICATION_REVIEW;
  payload: Array<any>;
}

interface GetEmployeeData {
  type: typeof ACTIONS.GET_EMPLOYEE_DATA;
  payload: Array<any>;
}

interface CreateLeaveRequests {
  type: typeof ACTIONS.CREATE_LEAVE_REQUESTS;
  payload: Array<any>;
}

interface GetLeaveRequests {
  type: typeof ACTIONS.GET_LEAVE_REQUESTS;
  payload: Array<any>;
}

interface GetAllEmployeeData {
  type: typeof ACTIONS.GET_ALL_EMPLOYEE_DATA;
  payload: Array<any>;
}
interface UpdateRegularization {
  type: typeof ACTIONS.UPDATE_REGULARIZE;
  payload: Array<any>;
}
interface addEmploymentDetails {
  type: typeof ACTIONS.ADD_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface editEmploymentDetails {
  type: typeof ACTIONS.EDIT_EMPLOYMENT_DETAILS;
  payload: Object;
}
interface getEmploymentDetails {
  type: typeof ACTIONS.GET_EMPLOYMENT_DETAILS;
  payload: Array<any>;
}
interface deleteEmploymentDetail {
  type: typeof ACTIONS.DELETE_EMPLOYMENT_DETAIL;
  payload: boolean;
}

interface Postcreate {
  type: typeof ACTIONS.POST_CREATE_MODULE;
  payload: Array<any>;
}

interface UpdateLmss {
  type: typeof ACTIONS.UPDATE_LMS;
  payload: Array<any>;
}
interface DeleteModule {
  type: typeof ACTIONS.DELETE_CREATE_MODULE;
  payload: Object;
}
interface PostUpdateModule {
  type: typeof ACTIONS.POST_UPDATE_MODULE;
  payload: Object;
}


interface addCertificate {
  type: typeof ACTIONS.ADD_CERTIFICATE;
  payload: Object;
}
interface editCertificate {
  type: typeof ACTIONS.EDIT_CERTIFICATE;
  payload: Object;
}
interface getCertificate {
  type: typeof ACTIONS.GET_CERTIFICATE;
  payload: Array<any>;
}
interface deleteCertificate {
  type: typeof ACTIONS.DELETE_CERTIFICATE;
  payload: boolean;
}

interface AddMedicalInfo {
  type: typeof ACTIONS.ADD_MEDICAL_INFO;
  payload: object;
}
interface EditMedicalInfo {
  type: typeof ACTIONS.EDIT_MEDICAL_INFO;
  payload: object;
}
interface deleteMedicalInfo {
  type: typeof ACTIONS.DELETE_MEDICAL_DETAIL;
  payload: boolean;
}
interface AddEducation {
  type: typeof ACTIONS.ADD_EDUCATION;
  payload: Object;
}
interface EditEducation {
  type: typeof ACTIONS.EDIT_EDUCATION;
  payload: Object;
}
interface getEducationDetails {
  type: typeof ACTIONS.GET_EDUCATION;
  payload: Array<any>;
}
interface deleteEducation {
  type: typeof ACTIONS.DELETE_EDUCATION;
  payload: boolean;
}

interface GetLmsCourses {
  type: typeof ACTIONS.GET_LMS_COURSES;
  payload: object;
}

export type HRMSTypes =
  | ChangePassword
  | SetLoadng
  | DisplayHolidays
  | LeaveApplication
  | Regularization
  | AttendanceMaster
  | BirthdayReminder
  | EmployeeSalary
  | GetEmployeeSalary
  | GetLeaveApplication
  | GetPendingRegularization
  | leaveApplicationReview
  | GetEmployeeData
  | CreateLeaveRequests
  | GetAllEmployeeData
  | UpdateRegularization
  | GetLeaveRequests
  | Postcreate
  | UpdateLmss
  | DeleteModule
  | PostUpdateModule
  | addEmploymentDetails
  | editEmploymentDetails
  | getEmploymentDetails
  | deleteEmploymentDetail
  | addCertificate
  | getCertificate
  | editCertificate
  | deleteCertificate
  | AddMedicalInfo
  | EditMedicalInfo
  | deleteMedicalInfo
  | AddEducation
  | getEducationDetails
  | EditEducation
  | deleteEducation
  | GetLmsCourses;
