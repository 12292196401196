import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import BulkUpload from './bulkupload'
import {
//   getImagingTestList,
//   getSubCenterInfo,
//   getCenterAddressPrice,
//   getDayWiseTimeSlotList,
//   getTimeslots,
//   updateDayWiseimeSlots,
//   updatedCenterPackage,
//   createCenterPackage,
//   getDays,
//   getCodeGenData,
//   createImagingPackage,
//   getPackage,
//   getCentrePackageComments,
//   getImagingPackageAlias
} from '../../actions/PayrollActions'
import CreateTest from './CreateNewTest'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style: any;
  getImagingPackageAlias: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, getImagingPackageAlias, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface Props {
  getImagingTestList: any;
  imagingTestList: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  loading: any;
  getCenterAddressPrice: any;
  centerAddressPrice: any;
  getDayWiseTimeSlotList: any;
  daywisetimeslot: any;
  getTimeslots: any;
  timeSlots: any;
  updateDayWiseimeSlots: any;
  updatedCenterPackage: any;
  createCenterPackage: any;
  getDays: any;
  dayList: any
  getCodeGenData:any;
  codeGenrator:any;
  createImagingPackage:any;
  getPackage:any;
  packageList:any;
  getCentrePackageComments:any;
  centrePackageComments:any
}

const PackageMaster: React.FC<Props> = ({
  getImagingTestList,
  imagingTestList,
  getSubCenterInfo,
  subCenterInfor,
  loading,
  getCenterAddressPrice,
  centerAddressPrice,
  getDayWiseTimeSlotList,
  daywisetimeslot,
  timeSlots,
  getTimeslots,
  updateDayWiseimeSlots,
  updatedCenterPackage,
  createCenterPackage,
  getDays,
  dayList,
  getCodeGenData,
  codeGenrator,
  createImagingPackage,
  getPackage,
  packageList,
  getCentrePackageComments,
  centrePackageComments
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openModal,setopenModal]=useState<boolean>(false)
  const [packId,setpackId]=useState<any>("")
  

  useEffect(() => {
    if (value === 0) {
    //   getDays()
    }
    if (value === 1) {
      // getSubCenterInfo(`?imaging=true`)

    }
    if (value === 2) {
    //   getImagingTestList()
    }
    if (value === 3) {
    //   getPackage(`?org_type=imaging`)
    }


  }, [value])



  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  
    setValue(newValue);
  };


  return (
    <>

      <div className={classes.root} style={{ marginTop: "3rem" }}>
        <AppBar position="static" >
          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example" style={{ justifyContent: "space-around" }}>
            <Tab style={{ background: value === 0 ? "#3f51b5" : "#f3e5f5", color: "black" }} label="SINGLE TEST UPLOAD" {...a11yProps(0)} />
            <Tab style={{ background: value === 1 ? "#3f51b5" : "#f3e5f5", color: "black" }} label="BULK TEST UPLOAD" {...a11yProps(1)} />
            <Tab style={{ background: value === 2 ? "#3f51b5" : "#f3e5f5", color: "black" }} label="PREVIEW TEST" {...a11yProps(2)} />
            <Tab style={{ background: value === 3 ? "#3f51b5" : "#f3e5f5", color: "black" }} label="CREATE NEW TEST" {...a11yProps(3)} />
          </Tabs>
        </AppBar>

        <TabPanel style={{ width: "100%" }} value={value} index={0} getImagingPackageAlias={""} >
         
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={1} getImagingPackageAlias={""}>
          <BulkUpload getImagingTestList={undefined} getSubCenterInfo={undefined} getCities={undefined} uploadBulkPackage={undefined} getPincode={undefined} />
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={2} getImagingPackageAlias={""}>
        
        </TabPanel>
        <TabPanel style={{ width: "100%" }} value={value} index={3} getImagingPackageAlias={""}>
          <CreateTest 
                      getCodeGenData={getCodeGenData}
                      codeGenrator={codeGenrator}
                      createImagingPackage={createImagingPackage}
                      getPackage={getPackage}
                      packageList={packageList}
                      loading={loading}
                      setValue={setValue}
                      getImagingPackageAlias={""} updatedPackageAlias={undefined} updatePackage={undefined}            />
        </TabPanel>
      
      </div>

    </>
  );
};
const mapStateToProps = (state: any) => ({
  imagingTestList: state.PayrollReducer.imagingTestList,
  subCenterInfor: state.PayrollReducer.subCenterInfor,
  centerAddressPrice: state.PayrollReducer.centerAddressPrice,
  daywisetimeslot: state.PayrollReducer.daywisetimeslot,
  timeSlots: state.PayrollReducer.timeSlots,
  dayList: state.PayrollReducer.dayList,
  codeGenrator: state.PayrollReducer.codeGenrator,
  packageList: state.PayrollReducer.packageList,
  centrePackageComments: state.PayrollReducer.centrePackageComments,
  loading: state.PayrollReducer.loading,

});
export default connect(mapStateToProps, {
//   getImagingTestList,
//   getSubCenterInfo,
//   getCenterAddressPrice,
//   getDayWiseTimeSlotList,
//   getTimeslots,
//   updateDayWiseimeSlots,
//   updatedCenterPackage,
//   createCenterPackage,
//   getDays,
//   getCodeGenData,
//   createImagingPackage,
//   getPackage,
//   getCentrePackageComments
})(PackageMaster);