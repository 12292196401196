export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_LOADING: "hrms/set_loading",
  DISPLAY_HOLIDAYS:"national-holidays-list/",
  LEAVE_APPLICATION: "hrms/LEAVE_APPLICATION",
  REGULARIZATION:"hrms/REGULARIZATION"
  
};
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: Array<string | number>
}

interface DisplayHolidays {
  type: typeof ACTIONS.DISPLAY_HOLIDAYS;
  payload:Array<any>

}

interface LeaveApplication {
  type: typeof ACTIONS.LEAVE_APPLICATION
  payload: Array<any>;
}
interface Regularization{
  type: typeof ACTIONS.REGULARIZATION
  payload:Array<any>;
}


// interface Regularization{
//   type: typeof ACTIONS.REGULARIZATION;
//   payload: Array<any>;

export type HRMSTypes =
  | ChangePassword
  | ChangePassword
  | SetLoadng
  | DisplayHolidays
  | LeaveApplication
  | Regularization