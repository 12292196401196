import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Paper, TextField, Grid } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import CreateIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from "@mui/material/Box";
import { withStyles } from "@material-ui/core/styles";
import CreateModule from "./createModule";
import SeeLearningModule from "./seeLearningModule";
import { styled, keyframes } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import { getPerformanceTablenew } from "../../../actions/loginActions";
import { generateLmsFilter } from "../../../helper/generate";
import Loader from "../Loader2";
import "./button.css";
import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TablePagination,
    TableFooter,
    TableCell,
    TableRow,
} from "@material-ui/core";

const style1 = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    border: "2px solid #2d148a",
    bgcolor: "#fafafa",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
};
const ButtonDesign = styled(Button)({
    background: "#2d148a",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px 20px",
    borderRadius: "25px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    transition: "all 0.3s ease",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    "&:hover": {
        transform: "scale(1.03)",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.4)",
        transition:"transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        background: "radial-gradient(circle, rgba(131,58,180,1) 29%, rgba(112,49,187,1) 62%, rgba(83,28,193,1) 97%)",
    },
    "& .MuiButton-startIcon": {
        transition: "transform 0.3s ease",
    },
    "&:hover .MuiButton-startIcon": {
        transform: "rotate(15deg)",
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "-1rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "10px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        container: {
            position: "sticky",
            marginTop: 30,
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#1C4E80",
            color: theme.palette.common.white,
            fontSize: "16px",
        },
        body: {
            fontSize: 14,
            padding: "5px",
            textTransform: "capitalize",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loading: boolean;
    open: boolean;
    setOpen: any;
    getPerformanceTablenew: any;
    performanceTableData: any;
}
const Forms: React.FC<Props> = ({ loading, open, setOpen, getPerformanceTablenew, performanceTableData }) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [open1, setOpen1] = React.useState(false);
    const handleClose = () => setOpen1(false);
    const handleOpen = () => setOpen1(true);

    useEffect(() => {
        getPerformanceTablenew();
    }, []);

      const filterBookings = () => {
        const body: any = {
            name: filterName,
        };
        const url = generateLmsFilter(body).substring(2);
        getPerformanceTablenew(`?${url}`);
        setOpen1(false);
        setPage(0);
      };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url =
                performanceTableData?.links && performanceTableData?.links?.next?.split("?")[1];
            getPerformanceTablenew(url ? `?${url}` : "");
        } else if (newPage < page) {
            let url =
                performanceTableData?.links && performanceTableData?.links?.previous?.split("?")[1];
            getPerformanceTablenew(url ? `?${url}` : "");
        }
        setPage(newPage as number);
    };
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
                direction="row"
                style={{ alignItems: "left" }}

            >
                <Grid item xs={12} sm={3} md={6}>
                    <h4 style={{
                        fontWeight: "bold",
                        textAlign: "left",

                        color: "#2d148a",
                        fontSize: "2rem",
                        fontFamily: "'Arial Black', sans-serif",
                        marginTop: "20px",
                        display: "inline-block"
                    }}>
                        LMS
                    </h4>
                </Grid>
                <Grid item xs={12} sm={3} md={6}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "8px",
                            borderRadius: "10px",
                            padding: "10px",
                            alignItems: "right",
                        }}

                    >
                        <ButtonDesign
                            variant="contained"
                            startIcon={<RemoveRedEyeIcon />}
                            onClick={() => {
                                setModalOpen(true);
                            }}
                            style={{ width: "228px" }}
                        >
                            See Module
                        </ButtonDesign>
                        <ButtonDesign
                            variant="contained"
                            startIcon={<CreateIcon />}
                            onClick={() => {
                                setCreateModuleModalOpen(true);
                            }}
                            style={{ width: "228px" }}
                        >
                            Create
                        </ButtonDesign>
                        <ButtonDesign
                            variant="contained"
                            startIcon={<FilterAltIcon />}
                            onClick={handleOpen}
                            style={{ width: "228px" }}
                        >
                            Filter
                        </ButtonDesign>
                    </div>
                </Grid>
            </Grid>
            <hr style={{ borderBottom: "3px solid #007BFF" }} />
            <TableContainer
                className={classes.container}
                style={{ maxHeight: "540px" }}
                component={Paper}
            >
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                <b>Sr. No.</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Module Name</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Username</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Video Status</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Assessment Status</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Score</b>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <b>Result</b>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                           <Loader />
                        ) : (
                            performanceTableData &&
                            performanceTableData.results &&
                            performanceTableData.count > 0 &&
                            performanceTableData.results.map((data: any, index: any) => (
                                <StyledTableRow>
                                     <StyledTableCell align="center">
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data.module_name || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data.username || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      
                                        {data.watched_video !== undefined ? data.watched_video.toString() : "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data.asignment_status || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {data?.final_score || "NA"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    {data.asignment_status || "NA"}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={3}
                            count={performanceTableData.count || 0}
                            rowsPerPage={performanceTableData.page_size}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableFooter>
                </Table>
            </TableContainer>

            <CreateModule
                open={createModuleModalOpen}
                setOpen={setCreateModuleModalOpen} modulePercentage={undefined} editButton={undefined} moduleNames={undefined} moduleTimeMinutess={undefined} moduleTimeHourss={undefined} inputListOfQuestionss={undefined} videoUrl={undefined} moduleIds={undefined} moduleQuestion={undefined} />

            <SeeLearningModule
                open={modalOpen}
                setOpen={setModalOpen}
            />

            <Modal
                open={open1}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style1}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div>
                            <h4 style={{ marginTop: "-10px", fontSize: "25px", textAlign: "center", fontWeight: "bold" }}>FILTER</h4>
                        </div>
                        <div>
                        </div>
                        <CloseIcon
                            onClick={handleClose}
                            style={{
                                position: "relative",
                                left: "45%",
                                height: "40px",
                                width: "30px",
                                marginTop: "-50px",
                                color: "red",
                            }}
                        />
                    </div>
                    {
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            style={{ alignItems: "left" }}
                        >

                            <Grid item xs={12} sm={4} md={4}>
                                <TextField
                                    className="input"
                                    name="name"
                                    type="text"
                                    label="Module Name"
                                    value={filterName}
                                    variant="outlined"
                                    onChange={(e) => setFilterName(e.target.value as string)}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Button
                                    className="buttonPrimary"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={filterBookings}
                                >
                                    Filter
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Button
                                    className="buttonSecondary"
                                    variant="outlined"
                                    color="error"
                                    fullWidth
                                    onClick={() =>
                                        setFilterName("")
                                    }
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Modal>

        </main>
    );
};
const mapStateToProps = (state: any) =>
    ({ performanceTableData: state.loginReducer.performanceTableData, });
export default connect(mapStateToProps, { getPerformanceTablenew })(Forms);
