import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.sass';
import * as serviceWorker from './serviceWorker';
import CreateStore from './store';
import Routes from './config/Routes';

const store = CreateStore();
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
ReactDOM.render(<Provider store={store}><Routes /></Provider>, document.getElementById('root'));
serviceWorker.unregister();
