import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tab from '@mui/material/Tab';
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import {
  getComponentsList,
  getEmployeeList,
  getEmployeeComponentsList,
  PostEmployeeSalary,
  getEmployeeSalary,
  UpdateEmployeeSalary,

} from "../../../actions/loginActions";
import "./index.sass";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    input: {
      padding: "1px",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      minWidth: 700,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function a11yProps(index: any) {
  return {
    id: `simple - tab - ${index}`,
    "aria-controls": `simple - tabpanel - ${index}`,
  };
}

interface Props {
  getComponentsList: Function;
  componentsList: any;
  getEmployeeList: Function;
  employeesList: any;
  getEmployeeComponentsList: Function;
  employeeComponentList: any;
  PostEmployeeSalary: Function;
  getEmployeeSalary: Function;
  employee_Salary: any;
  UpdateEmployeeSalary: Function;
  update_salary: any;
}
const CreatePartnerPage: React.FC<Props> = ({
  getComponentsList,
  componentsList,
  getEmployeeList,
  employeesList,
  getEmployeeComponentsList,
  employeeComponentList,
  PostEmployeeSalary,
  getEmployeeSalary,
  employee_Salary,
  UpdateEmployeeSalary,
  update_salary,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [value, setValue] = React.useState(0);
  const [employeeUUid, setEmployeeUuid] = useState('');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getEmployeeList("get_all=true");
  }, [])


  const [formData, setFormData] = useState<any[]>([]);

  const handleInputChange = (e: any, index: number, fieldName: string, dataId: string) => {
    const { value } = e.target;
    const createFormData = [...formData];
    createFormData[index] = {
      ...createFormData[index],
      [fieldName]: value,
    };
    setFormData(createFormData);
  };
  const calculateYearlyAmount = (monthlyAmount: number): number => {
    return monthlyAmount * 12;
  }

  const handleSaveData = () => {
    const createDataArr = employeeComponentList.map((data: any) => ({
      id: data.id,
      // component: employeeComponent.id,
      monthly_amount: data.monthly_amount,
      yearly_amount: data.monthly_amount * 12,
      employee: employeeUUid,
    }));
    PostEmployeeSalary(createDataArr);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h4
        className="mainHeading"
        style={{
          width: "100%",
          textAlign: "center",
          color: "#0e3f6c",
          textShadow: "4px 4px 10px grey",
        }}
      >
        {" "}
        EMPLOYEE SALARY MASTER
      </h4>


      {/* <div style={{ width: "100%" }} className="data-table">
        <AppBar position="static" style={{ marginTop: "20px" }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ justifyContent: "space-around", width: "100%" }}
          >
            <Tab
              style={{
                background: value === 0 ? "rgb(14, 63, 108)" : "white",

                color: value === 0 ? "white" : "black",
              }}
              label="Create"
              {...a11yProps(0)} />

            <Tab
              style={{
                background: value === 1 ? "rgb(14, 63, 108)" : "white",

                color: value === 1 ? "white" : "black",
              }}
              label="Update"
              {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div> */}

      <Grid
        container
        spacing={3}
        direction="row"
        style={{ alignItems: "left", marginTop: "1.5rem" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            onChange={(event, obj) => {
              if (obj) {
                setEmployeeUuid(obj.uuid)
                getEmployeeComponentsList(`employee = ${obj.uuid}`);
                getEmployeeSalary(obj.uuid);
                getEmployeeList(`uuid=${obj.uuid}`)
              }
            }}
            id="checkboxes-tags-demo"
            options={employeesList || []}
            getOptionLabel={(option: any) => {
              return option?.employee_id + " " + option.name;
            }}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getEmployeeList(`get_all=true&name= ${newInputValue}`);
              }, 1000);
            }}
            renderOption={(option: any) => {
              return (
                <p>
                  <b style={{ color: "blue" }}>Employee Name:</b>
                  <strong>
                    {option?.name.toString()}
                    <br />
                  </strong>
                  <b style={{ color: "red" }}>Employee Id:</b>
                  {option?.employee_id?.toUpperCase()}
                </p>
              );
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Search By Code Or Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                    }
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <div>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Particular</StyledTableCell>
                <StyledTableCell align="center">Monthly</StyledTableCell>
                <StyledTableCell align="center">Yearly</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {employeeComponentList &&
                employeeComponentList.length > 0 &&
                employeeComponentList.map((data: any, index: number) => (
                  <TableRow key={index}>
                    <StyledTableCell align="center">{data.component.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        className="input"
                        name={`monthly_amount_${index}`}
                        type="number"
                        variant="outlined"
                        value={data.monthly_amount}
                        style={{ width: "75%" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        className="input"
                        name={`yearly_amount_${index}`}
                        type="number"
                        label=""
                        variant="outlined"
                        style={{ width: "75%" }}
                        value={data.monthly_amount * 12}
                        disabled
                      />
                    </StyledTableCell>

                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ position: "absolute", width: "100%" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ alignItems: "left", marginTop: "1.5rem" }}
          >
            <Grid item xs={12} sm={6} md={3} style={{ margin: "20px auto" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(9 69 72)", color: "white", cursor: "pointer" }}
                fullWidth
                onClick={handleSaveData}

              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ margin: "20px auto" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#ad7575", color: "white", cursor: "pointer" }}
                fullWidth
                onClick={() => history.push("/dashboard/payrollManager/salary-details")
                }
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <div>
              <Grid
                container
                spacing={3}
                direction="row"
                style={{ alignItems: "left", marginTop: "1.5rem" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        getEmployeeComponentsList(`employee = ${obj.uuid}`);
                        getEmployeeSalary(obj.uuid);
                        getComponentsList();
                      }
                    }}
                    id="checkboxes-tags-demo"
                    options={employeesList || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeList(`get_all = true & name= ${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option?.employee_id + " " + option.name;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}>Employee Name:</b>
                          <strong>
                            {option?.name.toString()}
                            <br />
                          </strong>
                          <b style={{ color: "red" }}>Employee Id:</b>
                          {option?.employee_id?.toUpperCase()}
                        </p>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Search By Code Or Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="center">Particular</StyledTableCell>
                      <StyledTableCell align="center">Monthly</StyledTableCell>
                      <StyledTableCell align="center">Yearly</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {employeeComponentList &&
                      employeeComponentList.length > 0 &&
                      employeeComponentList?.map((data: any, index: number) => (
                        <>
                          <StyledTableRow>
                            <StyledTableCell align="center">
                              {data.component.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                className="input"
                                name="monthly_amount"
                                type="number"
                                id="salary-enabled"
                                variant="outlined"
                                value={data.monthly_amount}
                                style={{ width: "75%" }}
                                onChange={(e) => {
                                  handleInputChange(
                                    e,
                                    index,
                                    "monthly_amount",
                                    data.id
                                  );
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                className="input"
                                name="phone"
                                type="number"
                                label=""
                                value={data.monthly_amount * 12}
                                id="salary-disabled"
                                variant="outlined"
                                style={{ width: "75%" }}
                                disabled
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ position: "absolute", width: "100%" }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  style={{ alignItems: "left", marginTop: "1.5rem" }}
                >
                  <Grid item xs={12} sm={6} md={3} style={{ margin: "20px auto" }}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "rgb(9 69 72)", color: "white", cursor: "pointer" }}
                      fullWidth
                      onClick={() => handleSaveData()}
                    >
                      Update
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ margin: "20px auto" }}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ad7575", color: "white", cursor: "pointer" }}
                      fullWidth
                      onClick={() => history.push("/dashboard/payrollManager/salary-details")
                      }
                    >
                      Reset
                    </Button>
                  </Grid>

                </Grid>
              </div>
            </div> */}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  componentsList: state.loginReducer.componentsList,
  employeesList: state.loginReducer.employeesList,
  employeeComponentList: state.loginReducer.employeeComponentList,
  employee_Salary: state.loginReducer.employee_Salary,
  update_salary: state.loginReducer.update_salary,
});
export default connect(mapStateToProps, {
  getComponentsList,
  getEmployeeList,
  getEmployeeComponentsList,
  PostEmployeeSalary,
  getEmployeeSalary,
  UpdateEmployeeSalary,
})(CreatePartnerPage);