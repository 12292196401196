export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_USER: "login/set_user",
  LOGOUT: "login/logout",
  SET_PROFILE: "login/set_profile",
  RESET_PASSWORD: "login/reset_password",
  SEND_OTP: "login/send_otp",
  SET_LOADING: "login/set_loading",
  SET_LOADING_OTP: "login/set_loading_otp",
  PANELUSER_LOGOUT: "login/paneluser_logout",
  GET_ATTENDANCE_LIST: "login/get_attendances",
  
  CREATE_ATTENDANCE: "login/post_attendances",
  GET_ATTENDANCE_BY_ID: "login/get_attendances_by_id",
  EDIT_ATTENDANCE_BY_ID: "login/edit_attendances_by_id",
  DELETE_EMPLOYEE_ATTENDANCE_BY_ID: "login/delete_attendances_by_id",
  CREATE_EMPLOYEE: "login/create_employees",
  GET_EMPLOYEE_LIST: "login/get_employees",
  EDIT_EMPLOYEE_BY_ID: "login/edit/employees/uuid",
  DELETE_EMPLOYEE_ACCOUNT_BY_ID: "login/delete/employees/uuid",
  GET_ORGANIZATION_LIST: "login/get_organizations",
  CREATE_ORGANIZATIONS: "login/create_organizations",
  CREATE_PAYROLLS: "login/create_payrolls",
  GET_PAYROLLS_LIST: "login/get_payrolls",
  GET_PAYROLL_BY_ID: "login/get_payrolls_by_id",
  EDIT_PAYROLL_BY_ID: "login/edit_payrolls_by_id",
  DELETE_PAYROLL_BY_ID: "login/delete_payrolls_by_id",
  CREATE_EMPLOYEE_LEAVE_LEADGERS: "login/create_employee-leave-ledgers",
  GET_EMPLOYEE_LEAVE_LEADGERS_LIST: "login/get_employee-leave-ledgers",
  GET_EMPLOYEE_LEAVE_LEADGERS_BY_ID: "login/get_employee-leave-ledgers_by_id",
  EDIT_EMPLOYEE_LEAVE_LEADGERS_BY_ID: "login/edit_employee-leave-ledgers",
  DELETE_EMPLOYEE_LEAVE_LEADGERS_BY_ID: "login/edit_employee-leave-ledgers",
  CREATE_EMPLOYEE_LEAVE_MASTER: "login/create_employee-leave-master",
  GET_EMPLOYEE_LEAVE_MASTER_LIST: "login/get_employee-leave-master_list",
  GET_EMPLOYEE_LEAVE_MASTER_BY_ID: "login/get_employee-leave-master_list_id",
  EDIT_EMPLOYEE_LEAVE_MASTER_BY_ID: "login/edit_employee-leave-master_list_id",
  DELETE_EMPLOYEE_LEAVE_MASTER_BY_ID:
    "login/delete_employee-leave-master_list_id",
  GET_CONPONENTS_LIST: "login/get-components-list",
  GET_EMPLOYEE_CONPONENTS_LIST: "login/get-employee-components-list",
  UPDATE_EMPLOYEE_COMPONENTS: "login/update-employee-components",
  EDIT_KRA_DETAILS: "login/kra-details",
  CREATE_LEAVE_APPLOCATION: "login/leave-application",
  GET_EMPLOYEE_DEPARTMENT: "login/department",
  POST_EMPLOYEE_SALARY: "login/employee-salary-master",
  GET_MY_TEAM_DETAILS: "login/my-team-details",
  POST_DEPARTMENT_BULK_UPLOAD: "login/users/bulk_upload_department/csv",
  POST_EMPLOYEE_BULK_UPLOAD: "login/bulk_upload_employee/csv",
  POST_PAYSHLIP_BULK_UPLOAD: "login/bulk_upload_payslip/csv",
  BIRTHDAY_REMINDER: "hrms/BIRTHDAY_REMINDER",
  ATTENDANCE_MASTER: "hrms/attendance-master",
  DISPLAY_HOLIDAYS: "national-holidays-list/",
  GET_LEAVE_COMPONENTS: "login/leave_components",
  GET_REGULARIZATION_REQUEST: "login/regularization_components",
  GET_PAYROLL_AGGREGATE: "login/payroll_aggregate",
  POST_BULK_REGULARIZATION_UPLOAD:"login/bulk-regularization-limit/",
  GET_PAYROLL_DATA: "login/payroll_components",
  LEAVE_APPLICATION: "hrms/LEAVE_APPLICATION",
  EMPLOYEE_RATING: "login/Employee_rating",
  GET_EMPLOYEE_FORM: "login/employee_form",
  GET_EMPLOYEE_SALARY: "login/employee_salary",
  UPDATE_EMPLOYEE_SALARY: "login/update_employee_salary",
  Post_Employee_Salary: "login/post_employee_salary",
  GET_EMPLOYEE_SUB_DEPARTMENT: "login/sub-department",
  SEND_OTP_NUMBER: "login/sendOtpNumber",
  RESET_PASSWORD_NEW: "login/resetPasswordNew",
  GET_EMPLOYEE_STATE_NAME: "login/states",
  GET_SHIFT_LIST: "login/shift-list",
  GET_DESIGNATION_LIST: "login/api/designation",
  GET_LOCATION_LIST: "login/api/location",
  GET_ATTENDANCE_RECORD: "login/attendancerecord",
  GET_ATTENDANCE_MASTER: "login/attendance-record",
  SET_ATTENDANCE_MASTER_LOADING: "login/attendance-record/loading",
  GET_MANAGE_ATTENDANCE: "login/manageAttendance",
  SET_MANAGE_ATTENDANCE_LOADING: "login/manageAttendance-loading",
  GET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION: "login/attendance-list",
  SET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION_LOADING:
    "login/attendance-list-loading",
  POST_ASSIGN_MANAGE: "login/assign-manager",
  POST_PUNCH_IN_OUT: "login/assign-punch",
  GET_EMPLOYEE_BY_ID: "login/employees",
  GET_EMPLOYEE_ALL_DATA: "login/employees_all",
  GET_EMPLOYEE_MY_TEAM_LIST: "login/employees_all_my_team",
  GET_LEAVE_LEDGER: "login/leave_ledger",
  GET_EMPLOYEE_MODE: "login/employee_mode",
  UPDATE_EMPLOYEE_BULK_UPLOAD: "login/update_employee",
  GET_REPORTING_MANAGER: "login/reporting-manager",
  GET_NOTIFICATION: "login/notification",
  BULK_LEAVE_UPLOADS: "bulk-leave-uploads",
  GET_EXPORT_EMPLOYEE_LIST: "bulk-leave-uploads",
  SET_EXPORT_EMPLOYEE_LIST_LOADING: "bulk-leave-uploads-loading",
  POST_UPDATE_IMAGE: "post-upload-image",
  GET_PROFILE_IMAGE: "get-profile-image",
  POST_BULK_REGULARIZATION: "hrms/api/regularization/bulk",
  UPDATE_EMPLOYEE_WORK_LOCATION: "hrms/api/update/employee-work-location",
  GET_GEO_FENCE_CORDINATE_LIST: "hrms/api/geo-fence-cordinate",
  CREATE_GEO_FENCE_CORINATE: "hrms/api/create_geo-fence-cordinate",
  GET_USER_REPORT: "hrms/api/users-report",
  SET_USER_REPORT_LOADING: "hrms/api/users-report-loading",
  GET_USER_REPORT_EXPORT: "hrms/api/users-report_export",
  SET_USER_REPORT_LOADING_EXPORT: "hrms/api/users-report-export-loading",
  CREATE_LOCK_ACTION: "hrms/api/lock-action",
  GET_USER_REGULARIZATION_REPORT: "hrms/regularization-users-report",
  SET_USER_REGULARIZATION_REPORT_LOADING:
    "hrms/regularization-users-report-loading",
  GET_USER_REGULARIZATION_REPORT_EXPORT:
    "hrms/regularization-users-report-export",
  SET_USER_REGULARIZATION_REPORT_LOADING_EXPORT:
    "hrms/regularization-users-report-export-loading",
  GET_USER_LOCK_ACTION: "hrms/lock-action",
  UPDATE_USERS_LOCK_ACTION: "hrms/update/lock-action",
  RESET_PASSWORD_BY_PAYROLL: "hrms/users/users/reset-password",
  CREATE_MODULE: "hrms/learning-module",
  POST_ASSIGNMENT_ANSWER_MASTER: "login/post_assignment_answer_master",
  PUT_ASSIGNMENT_ANSWER_MASTER: "login/put_assignment_answer_master",
  POST_MODULE_PERFORMANCE: "login/post-module-performance",
  EMPLOYEE_LEAVE_COUNT: "login/employee-leave-count",
  EMPLOYEE_LEAVE_COUNT_LOADING:"login/employee-leave-count-loader",
  EMPLOYEE_LEAVE_COUNT_EXPORT:"login/employee-leave-count-export",
  EMPLOYEE_LEAVE_COUNT_EXPORT_LOADING:"login/employee-leave-count-export-loading",
  UPDATE_GEO_LOCATION:"login/location_update",
  UPDATE_DESIGNAION:"login/update-destignation",
  SEND_EMPLOYEE_EMAIL:"login/send-employee-email",
  UPDATE_DEPARTMENT:"login/update-department",
  PERFORMANCE_TABLE_NEW:"login/performance-table-new",
  GET_MEDICAL_INFO:"hrms/medical-details",
  ASSIGN_WEEKLY_SHIFT:"hrms/assign-weekly-shift",
  POLICY_CREATE:"hrms/policy_center_create",
  GET_POLICY_CATEGORY:"hrms/get_policy_category",
  CREATE_POLICY_CATEGORY:"hrms/create_policy_category",
  GET_POLICY:"hrms/get_policy",
  POLICY_UPDATE:"hrms/update_policy",
  SET_LOADING_POLICY:"hrms/set_Loding_policy",
  GET_UPDATE_BIOMETRIC:"hrms/update-biometric",
  SET_CREATE_CATEGORY_LOADING: "hrms/create_categry_loading",
  POLICY_CATEGORY_UPDATE: "hrms/update_category_policy",
  POST_POLICY_ACK: "hrms/ack_employee_policy",
  GET_POLICY_ACK: "hrms/get_employee_ack",
  POST_DESIGNATION_BULK_UPLOAD: "hrms/post_designation_bulk_upload",
  UPDATE_CATEGORY: "hrms/update-category",
  GET_WEEKLY_ROSTER: "hrms/get-weekly-roster",
  GET_EMPLOYEE_LIST_FOR_REGULARIZATION: "hrms/employee",
  SET_LOADING_FOR_REGULARIZATION: "hrms/employee_loading",
  WEEKLY_ROSTER_BULK_UPLOAD:"HRMS/weekly-roster-bulk-upload",
};

interface SetUser {
  type: typeof ACTIONS.SET_USER;
  payload: Array<string | number>;
}

interface Logout {
  type: typeof ACTIONS.LOGOUT;
  payload: any;
}
interface PanelUserLogout {
  type: typeof ACTIONS.PANELUSER_LOGOUT;
  payload: any;
}
interface SetProfile {
  type: typeof ACTIONS.SET_PROFILE;
  payload: object;
}

interface ResetPassword {
  type: typeof ACTIONS.RESET_PASSWORD;
  payload: object;
}
interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetLoadngOTP {
  type: typeof ACTIONS.SET_LOADING_OTP;
  payload: boolean;
}

interface GetAttendancesList {
  type: typeof ACTIONS.GET_ATTENDANCE_LIST;
  payload: Array<any>;
}
interface CreateAttendances {
  type: typeof ACTIONS.CREATE_ATTENDANCE;
  payload: Array<any>;
}
interface GetAttendanceById {
  type: typeof ACTIONS.GET_ATTENDANCE_BY_ID;
  payload: Array<any>;
}
interface EditAttendanceById {
  type: typeof ACTIONS.EDIT_ATTENDANCE_BY_ID;
  payload: Array<any>;
}
interface DeleteAttendanceById {
  type: typeof ACTIONS.DELETE_EMPLOYEE_ATTENDANCE_BY_ID;
  payload: Array<any>;
}
interface CreateEmployee {
  type: typeof ACTIONS.CREATE_EMPLOYEE;
  payload: Array<any>;
}
interface GetEmployeeList {
  type: typeof ACTIONS.GET_EMPLOYEE_LIST;
  payload: Array<any>;
}
interface EditEmployeeById {
  type: typeof ACTIONS.EDIT_EMPLOYEE_BY_ID;
  payload: Array<any>;
}
interface DeleteEmployeeById {
  type: typeof ACTIONS.DELETE_EMPLOYEE_ACCOUNT_BY_ID;
  payload: Array<any>;
}
interface GetOrganizationsList {
  type: typeof ACTIONS.GET_ORGANIZATION_LIST;
  payload: Array<any>;
}
interface CreateOrganizations {
  type: typeof ACTIONS.CREATE_ORGANIZATIONS;
  payload: Array<any>;
}
interface GetPayrollsList {
  type: typeof ACTIONS.GET_PAYROLLS_LIST;
  payload: Array<any>;
}
interface GetPayrollById {
  type: typeof ACTIONS.GET_PAYROLL_BY_ID;
  payload: Array<any>;
}
interface EditPayrollById {
  type: typeof ACTIONS.EDIT_PAYROLL_BY_ID;
  payload: Array<any>;
}
interface DeletePayrollById {
  type: typeof ACTIONS.DELETE_PAYROLL_BY_ID;
  payload: Array<any>;
}
interface CreateEmployeeLeaveLedgers {
  type: typeof ACTIONS.CREATE_EMPLOYEE_LEAVE_LEADGERS;
  payload: Array<any>;
}
interface GetEmployeeLeaveLedgersList {
  type: typeof ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_LIST;
  payload: Array<any>;
}
interface GetEmployeeLeaveLedgersById {
  type: typeof ACTIONS.GET_EMPLOYEE_LEAVE_LEADGERS_BY_ID;
  payload: Array<any>;
}
interface EditEmployeeLeaveLedgersById {
  type: typeof ACTIONS.EDIT_EMPLOYEE_LEAVE_LEADGERS_BY_ID;
  payload: Array<any>;
}
interface DeleteEmployeeLeaveLedgersById {
  type: typeof ACTIONS.DELETE_EMPLOYEE_LEAVE_LEADGERS_BY_ID;
  payload: Array<any>;
}
interface CreateEmployeeLeaveMasters {
  type: typeof ACTIONS.CREATE_EMPLOYEE_LEAVE_MASTER;
  payload: Array<any>;
}
interface GetEmployeeLeaveMastersList {
  type: typeof ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_LIST;
  payload: Array<any>;
}
interface GetEmployeeLeaveMastersById {
  type: typeof ACTIONS.GET_EMPLOYEE_LEAVE_MASTER_BY_ID;
  payload: Array<any>;
}
interface EditEmployeeLeaveMastersById {
  type: typeof ACTIONS.EDIT_EMPLOYEE_LEAVE_MASTER_BY_ID;
  payload: Array<any>;
}
interface DeleteEmployeeLeaveMastersById {
  type: typeof ACTIONS.DELETE_EMPLOYEE_LEAVE_MASTER_BY_ID;
  payload: Array<any>;
}
interface GetComponentsList {
  type: typeof ACTIONS.GET_CONPONENTS_LIST;
  payload: Array<any>;
}
interface GetEmployeeComponentsList {
  type: typeof ACTIONS.GET_EMPLOYEE_CONPONENTS_LIST;
  payload: Array<any>;
}
interface UpdateEmployeeComponents {
  type: typeof ACTIONS.UPDATE_EMPLOYEE_COMPONENTS;
  payload: Array<any>;
}

interface EditKraDetails {
  type: typeof ACTIONS.EDIT_KRA_DETAILS;
  payload: object;
}
interface CreateLeaveApplication {
  type: typeof ACTIONS.CREATE_LEAVE_APPLOCATION;
  payload: Array<any>;
}

interface GetEmployeeDepartment {
  type: typeof ACTIONS.GET_EMPLOYEE_DEPARTMENT;
  payload: Array<any>;
}

interface PostEmployeeSalary {
  type: typeof ACTIONS.POST_EMPLOYEE_SALARY;
  payload: Array<any>;
}
interface GetMyTeamDetails {
  type: typeof ACTIONS.GET_MY_TEAM_DETAILS;
  payload: Array<any>;
}
interface PostDepartmentBulkUploader {
  type: typeof ACTIONS.POST_DEPARTMENT_BULK_UPLOAD;
  payload: Array<any>;
}
interface PostBulkUploaderEmployee {
  type: typeof ACTIONS.POST_EMPLOYEE_BULK_UPLOAD;
  payload: Array<any>;
}
interface PostBulkUploaderPayslip {
  type: typeof ACTIONS.POST_PAYSHLIP_BULK_UPLOAD;
  payload: Array<any>;
}

interface BirthdayReminder {
  type: typeof ACTIONS.BIRTHDAY_REMINDER;
  payload: Array<any>;
}
interface GetLeaveComponents {
  type: typeof ACTIONS.GET_LEAVE_COMPONENTS;
  payload: Array<any>;
}
interface GetRegularizationRequest {
  type: typeof ACTIONS.GET_REGULARIZATION_REQUEST;
  payload: Array<any>;
}

interface AttendanceMaster {
  type: typeof ACTIONS.ATTENDANCE_MASTER;
  payload: Array<any>;
}

interface DisplayHolidays {
  type: typeof ACTIONS.DISPLAY_HOLIDAYS;
  payload: Array<any>;
}
interface GetPayrollAggregate {
  type: typeof ACTIONS.GET_PAYROLL_AGGREGATE;
  payload: Array<any>;
}
interface GetPayrollData {
  type: typeof ACTIONS.GET_PAYROLL_DATA;
  payload: Array<any>;
}
interface LeaveApplication {
  type: typeof ACTIONS.LEAVE_APPLICATION;
  payload: Array<any>;
}

interface EmployeeRating {
  type: typeof ACTIONS.EMPLOYEE_RATING;
  payload: Array<any>;
}

interface EmployeeForm {
  type: typeof ACTIONS.GET_EMPLOYEE_FORM;
  payload: Array<any>;
}
interface GetEmployeeSalary {
  type: typeof ACTIONS.GET_EMPLOYEE_SALARY;
  payload: Array<any>;
}

interface UpdateEmployeeSalary {
  type: typeof ACTIONS.UPDATE_EMPLOYEE_SALARY;
  payload: Array<any>;
}

interface PostEmployeeSalary {
  type: typeof ACTIONS.Post_Employee_Salary;
  payload: Array<any>;
}
interface GetEmployeeSubDept {
  type: typeof ACTIONS.GET_EMPLOYEE_SUB_DEPARTMENT;
  payload: Array<any>;
}
interface PostSendOtpNumber {
  type: typeof ACTIONS.SEND_OTP_NUMBER;
  payload: Array<any>;
}

interface postresetpassword {
  type: typeof ACTIONS.RESET_PASSWORD_NEW;
  payload: Array<any>;
}
interface GetEmployeeStateName {
  type: typeof ACTIONS.GET_EMPLOYEE_STATE_NAME;
  payload: Array<any>;
}
interface GetShiftList {
  type: typeof ACTIONS.GET_SHIFT_LIST;
  payload: Array<any>;
}
interface GetDesignationtList {
  type: typeof ACTIONS.GET_DESIGNATION_LIST;
  payload: Array<any>;
}
interface GetLocationList {
  type: typeof ACTIONS.GET_LOCATION_LIST;
  payload: Array<any>;
}
interface GetAttendancerecord {
  type: typeof ACTIONS.GET_ATTENDANCE_RECORD;
  payload: Array<any>;
}
interface getmaster {
  type: typeof ACTIONS.GET_ATTENDANCE_MASTER;
  payload: Array<any>;
}
interface SetAttendanceMasterProLoading {
  type: typeof ACTIONS.SET_ATTENDANCE_MASTER_LOADING;
  payload: Array<any>;
}
interface getmanage {
  type: typeof ACTIONS.GET_MANAGE_ATTENDANCE;
  payload: Array<any>;
}
interface SetManageAttendanceLoading {
  type: typeof ACTIONS.SET_MANAGE_ATTENDANCE_LOADING;
  payload: Array<any>;
}
interface GetManageAttendanceWithOutPagination {
  type: typeof ACTIONS.GET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION;
  payload: Array<any>;
}
interface SetManageAttendanceWithOutPagination {
  type: typeof ACTIONS.SET_MANAGE_ATTENDANCE_WITHOUT_PAGINATION_LOADING;
  payload: Array<any>;
}
interface PostAssignManage {
  type: typeof ACTIONS.POST_ASSIGN_MANAGE;
  payload: Array<any>;
}
interface PostPunchInOut {
  type: typeof ACTIONS.POST_PUNCH_IN_OUT;
  payload: Array<any>;
}
interface GetEmployeeById {
  type: typeof ACTIONS.GET_EMPLOYEE_BY_ID;
  payload: Array<any>;
}
interface GetEmployeeALlData {
  type: typeof ACTIONS.GET_EMPLOYEE_ALL_DATA;
  payload: Array<any>;
}
interface GetEmployeeMyTeamList {
  type: typeof ACTIONS.GET_EMPLOYEE_MY_TEAM_LIST;
  payload: Array<any>;
}

interface GetLeaveLedger {
  type: typeof ACTIONS.GET_LEAVE_LEDGER;
  payload: Array<any>;
}
interface GetEMployeeMode {
  type: typeof ACTIONS.GET_EMPLOYEE_MODE;
  payload: Array<any>;
}

interface updateEmployee {
  type: typeof ACTIONS.UPDATE_EMPLOYEE_BULK_UPLOAD;
  payload: Array<any>;
}

interface reportingManager {
  type: typeof ACTIONS.GET_REPORTING_MANAGER;
  payload: Array<any>;
}

interface notificationUpdates {
  type: typeof ACTIONS.GET_NOTIFICATION;
  payload: Array<any>;
}

interface bulkLeaves {
  type: typeof ACTIONS.BULK_LEAVE_UPLOADS;
  payload: Array<any>;
}
interface GetExportEmployeeList {
  type: typeof ACTIONS.GET_EXPORT_EMPLOYEE_LIST;
  payload: Array<any>;
}
interface SetExportEmployeeList {
  type: typeof ACTIONS.SET_EXPORT_EMPLOYEE_LIST_LOADING;
  payload: Array<any>;
}
interface PostUpdateImage {
  type: typeof ACTIONS.POST_UPDATE_IMAGE;
  payload: Array<any>;
}
interface PostBulkRegularization {
  type: typeof ACTIONS.POST_BULK_REGULARIZATION;
  payload: Array<any>;
}

interface GetProfileImage {
  type: typeof ACTIONS.GET_PROFILE_IMAGE;
  payload: Array<any>;
}
interface UpdateEmployeeWorkLocation {
  type: typeof ACTIONS.UPDATE_EMPLOYEE_WORK_LOCATION;
  payload: Array<any>;
}
interface GetGeoFenceCordinateList {
  type: typeof ACTIONS.GET_GEO_FENCE_CORDINATE_LIST;
  payload: Array<any>;
}
interface CreateGeoFenceCordinate {
  type: typeof ACTIONS.CREATE_GEO_FENCE_CORINATE;
  payload: Array<any>;
}
interface GetUsersReport {
  type: typeof ACTIONS.GET_USER_REPORT;
  payload: Array<any>;
}
interface SetUsersReport {
  type: typeof ACTIONS.SET_USER_REPORT_LOADING;
  payload: Array<any>;
}
interface GetUsersReportExport {
  type: typeof ACTIONS.GET_USER_REPORT_EXPORT;
  payload: Array<any>;
}
interface SetUsersReportExport {
  type: typeof ACTIONS.SET_USER_REPORT_LOADING_EXPORT;
  payload: Array<any>;
}
interface CreateLockAction {
  type: typeof ACTIONS.CREATE_LOCK_ACTION;
  payload: Array<any>;
}
interface GetUsersRegularizationReport {
  type: typeof ACTIONS.GET_USER_REGULARIZATION_REPORT;
  payload: Array<any>;
}
interface SetUsersRegularizationReport {
  type: typeof ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING;
  payload: boolean;
}
interface GetUsersRegularizationReportExport {
  type: typeof ACTIONS.GET_USER_REGULARIZATION_REPORT_EXPORT;
  payload: Array<any>;
}
interface SetUsersRegularizationReportExport {
  type: typeof ACTIONS.SET_USER_REGULARIZATION_REPORT_LOADING_EXPORT;
  payload: Array<any>;
}
interface GetUsersLockAction {
  type: typeof ACTIONS.GET_USER_LOCK_ACTION;
  payload: Array<any>;
}
interface UpdateUsersLockAction {
  type: typeof ACTIONS.UPDATE_USERS_LOCK_ACTION;
  payload: Array<any>;
}
interface ResetPasswordByPayroll {
  type: typeof ACTIONS.RESET_PASSWORD_BY_PAYROLL;
  payload: Array<any>;
}

interface CreateModule {
  type: typeof ACTIONS.CREATE_MODULE;
  payload: object;
}

interface PostAssignmentAnswerMaster {
  type: typeof ACTIONS.POST_ASSIGNMENT_ANSWER_MASTER;
  payload: boolean;
}

interface PutAssignmentAnswerMaster {
  type: typeof ACTIONS.PUT_ASSIGNMENT_ANSWER_MASTER;
  payload: boolean;
}

interface PostModulePerformanceData {
  type: typeof ACTIONS.POST_MODULE_PERFORMANCE;
  payload: object;
}

interface EmployeeLeaveCountLoader {
  type: typeof ACTIONS.EMPLOYEE_LEAVE_COUNT_LOADING;
  payload: boolean;
}
interface EmployeeLeaveCount {
  type: typeof ACTIONS.EMPLOYEE_LEAVE_COUNT;
  payload: object;
}

interface EmployeeLeaveCountExportLoader {
  type: typeof ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT_LOADING;
  payload: boolean;
}
interface EmployeeLeaveCountExport {
  type: typeof ACTIONS.EMPLOYEE_LEAVE_COUNT_EXPORT;
  payload: object;
}
interface UpdateGeoLocation {
  type: typeof ACTIONS.UPDATE_GEO_LOCATION;
  payload: object;
}

interface updateDesignation {
  type: typeof ACTIONS.UPDATE_DESIGNAION;
  payload: object;
}
interface sendTemplate {
  type : typeof ACTIONS.SEND_EMPLOYEE_EMAIL;
  payload: object;
}
interface updateDepartment{
  type : typeof ACTIONS.UPDATE_DEPARTMENT;
  payload: object;
}
interface updateDepartment {
  type: typeof ACTIONS.UPDATE_DEPARTMENT;
  payload: object;
}
interface PerformanceTableNew {
  type: typeof ACTIONS.PERFORMANCE_TABLE_NEW;
  payload: object;
}
interface GetMedicalData {
  type: typeof ACTIONS.GET_MEDICAL_INFO;
  payload: Array<any>;
}

interface WeeklyRoster {
  type: typeof ACTIONS.ASSIGN_WEEKLY_SHIFT;
  payload: object;
}
interface PostPolicyCreation {
  type: typeof ACTIONS.POLICY_CREATE;
  payload: Array<any>;
}
interface GetPolicyCategory {
  type: typeof ACTIONS.GET_POLICY_CATEGORY;
  payload: Array<any>;
}

interface CreatePolicyCategory {
  type: typeof ACTIONS.CREATE_POLICY_CATEGORY;
  payload: Array<any>;
}

interface GetPolicy {
  type: typeof ACTIONS.GET_POLICY;
  payload: Array<any>;
}

interface UpdatePolicy {
  type: typeof ACTIONS.POLICY_UPDATE;
  payload: Array<any>;
}

interface GetUpdateBiometric {
  type: typeof ACTIONS.GET_UPDATE_BIOMETRIC;
  payload: Array<any>;
}
interface setLoadindPolicy {
  type: typeof ACTIONS.SET_LOADING_POLICY;
  payload: boolean;
}

interface createCategoryLoading {
  type: typeof ACTIONS.SET_CREATE_CATEGORY_LOADING;
  payload: boolean;
}

interface UpdateCategoryPolicy {
  type: typeof ACTIONS.POLICY_CATEGORY_UPDATE;
  payload: Array<any>;
}

interface postPOlicyAck {
  type: typeof ACTIONS.POST_POLICY_ACK;
  payload: Array<any>;
}
interface getPOlicyAck {
  type: typeof ACTIONS.GET_POLICY_ACK;
  payload: Array<any>;
}
interface postDesignaion {
  type: typeof ACTIONS.POST_DESIGNATION_BULK_UPLOAD;
  payload: Array<any>;
}

interface UpdateCategory {
  type: typeof ACTIONS.UPDATE_CATEGORY;
  payload: Array<any>;
}

interface assignWeeklyShift {
  type: typeof ACTIONS.ASSIGN_WEEKLY_SHIFT;
  payload: Array<any>;
}

interface getWeeklyShift {
  type: typeof ACTIONS.GET_WEEKLY_ROSTER;
  payload: Array<any>;
}

interface GetEmployeeListForReglarization {
  type: typeof ACTIONS.GET_EMPLOYEE_LIST_FOR_REGULARIZATION;
  payload: Array<any>;
}
interface SetEmployeeListForLoading {
  type: typeof ACTIONS.SET_LOADING_FOR_REGULARIZATION;
  payload: Array<any>;
}
interface PostBulkRegularizationLimitUploader {
  type: typeof ACTIONS.POST_BULK_REGULARIZATION_UPLOAD;
  payload: Array<any>;
}

interface bulkuploadWeeklyRoster{
  type: typeof ACTIONS.WEEKLY_ROSTER_BULK_UPLOAD;
  payload: Array<any>;
}
export type LoginActionTypes =
  | SetUser
  | Logout
  | SetProfile
  | SetLoadng
  | SetLoadngOTP
  | ResetPassword
  | PanelUserLogout
  | GetAttendancesList
  | CreateAttendances
  | GetAttendanceById
  | EditAttendanceById
  | DeleteAttendanceById
  | CreateEmployee
  | GetEmployeeList
  | EditEmployeeById
  | DeleteEmployeeById
  | GetOrganizationsList
  | CreateOrganizations
  | GetPayrollsList
  | GetPayrollById
  | EditPayrollById
  | DeletePayrollById
  | CreateEmployeeLeaveLedgers
  | GetEmployeeLeaveLedgersList
  | GetEmployeeLeaveLedgersById
  | EditEmployeeLeaveLedgersById
  | DeleteEmployeeLeaveLedgersById
  | CreateEmployeeLeaveMasters
  | GetEmployeeLeaveMastersList
  | GetEmployeeLeaveMastersById
  | EditEmployeeLeaveMastersById
  | DeleteEmployeeLeaveMastersById
  | GetComponentsList
  | GetEmployeeComponentsList
  | UpdateEmployeeComponents
  | EditKraDetails
  | CreateLeaveApplication
  | GetEmployeeDepartment
  | PostEmployeeSalary
  | GetMyTeamDetails
  | PostDepartmentBulkUploader
  | PostBulkUploaderEmployee
  | PostBulkUploaderPayslip
  | BirthdayReminder
  | AttendanceMaster
  | DisplayHolidays
  | GetLeaveComponents
  | GetRegularizationRequest
  | GetPayrollAggregate
  | GetPayrollData
  | LeaveApplication
  | EmployeeRating
  | EmployeeForm
  | GetEmployeeSalary
  | UpdateEmployeeSalary
  | PostEmployeeSalary
  | GetEmployeeSubDept
  | PostSendOtpNumber
  | postresetpassword
  | GetEmployeeStateName
  | GetShiftList
  | GetDesignationtList
  | GetLocationList
  | GetAttendancerecord
  | getmaster
  | getmanage
  | SetManageAttendanceLoading
  | PostAssignManage
  | PostPunchInOut
  | GetEmployeeById
  | GetEmployeeALlData
  | GetEmployeeMyTeamList
  | GetLeaveLedger
  | GetEMployeeMode
  | updateEmployee
  | reportingManager
  | notificationUpdates
  | GetExportEmployeeList
  | bulkLeaves
  | PostUpdateImage
  | SetAttendanceMasterProLoading
  | PostBulkRegularization
  | GetManageAttendanceWithOutPagination
  | SetManageAttendanceWithOutPagination
  | UpdateEmployeeWorkLocation
  | GetGeoFenceCordinateList
  | CreateGeoFenceCordinate
  | GetUsersReport
  | SetUsersReport
  | GetUsersReportExport
  | SetUsersReportExport
  | CreateLockAction
  | SetUsersRegularizationReport
  | GetUsersRegularizationReport
  | GetUsersRegularizationReportExport
  | SetUsersRegularizationReportExport
  | SetExportEmployeeList
  | GetUsersLockAction
  | UpdateUsersLockAction
  | ResetPasswordByPayroll
  | GetProfileImage
  | CreateModule
  | PostAssignmentAnswerMaster
  | PutAssignmentAnswerMaster
  | PostModulePerformanceData
  | EmployeeLeaveCount
  | EmployeeLeaveCountLoader
  | EmployeeLeaveCountExport
  | UpdateGeoLocation
  | EmployeeLeaveCountExportLoader
  | updateDesignation
  | updateDepartment
  | PerformanceTableNew
  | SetManageAttendanceWithOutPagination
  | GetMedicalData
  | GetProfileImage
  | WeeklyRoster
  | SetManageAttendanceWithOutPagination
  | GetMedicalData
  | GetProfileImage
  | PostPolicyCreation
  | GetPolicyCategory
  | CreatePolicyCategory
  | GetPolicy
  | UpdatePolicy
  | GetUpdateBiometric
  | GetProfileImage
  | setLoadindPolicy
  | createCategoryLoading
  | UpdateCategoryPolicy
  |postPOlicyAck
  |getPOlicyAck
  |sendTemplate
  | postDesignaion
  | UpdateCategory
  | assignWeeklyShift
  | getWeeklyShift
  | GetEmployeeListForReglarization
  | SetEmployeeListForLoading
  |PostBulkRegularizationLimitUploader
  | UpdateCategory
  | bulkuploadWeeklyRoster;
