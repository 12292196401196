import React, { FunctionComponent, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@mui/material/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  resetPasswordReset,
  sendOtp,
  sendOtp1,
  resetPassword1,
} from "../../actions/loginActions";
import { useEffect } from "react";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  loginForm: {
    border: "1px solid gainsboro",
    background: " #fff",
    padding: "2rem",
    borderRadius: "15px",
    position: "absolute",
    top: " 25%",
    right: "7%",
    height: " auto",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: "relative",
    margin: "2px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -9,
    marginLeft: -9,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type FormData = {
  email: string;
  password: string;
};

type LoginFormProps = {
  handleForm: any;
  loading: boolean;
  loadingOTP: boolean;
};

const LoginForm: FunctionComponent<LoginFormProps> = ({
  handleForm,
  loading,
  loadingOTP,
}) => {
  const classes = useStyles();
  const { handleSubmit, register, errors } = useForm<FormData>();

  const history = useHistory();

  const dispatch = useDispatch();

  const loginReducer = useSelector((state: any) => state.loginReducer);
  const {
    otp: otpSent,
    success,
    loading: loadingReset,
    loadingOTP: loadingResetOTP,
  } = loginReducer;

  const [forgot, setForgot] = useState<any>(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [phone, setphone] = useState("");
  const [clickOtp, setClickOtp] = useState<boolean>(false);
  const [showpopup, setShowpopup] = useState<boolean>(false);
  const sendOtpHandler = () => {
    dispatch(sendOtp1({ phone }));
  };

  const resetPasswordHandler = async () => {
    const body: any = {
      otp: otp,
      password: newPassword,
      mobile: phone,
    };
    if (otp && newPassword && phone) {
      dispatch(resetPassword1(body));
    }
    setphone("");
    setPassword("");
    setNewPassword("");
    setOtp("");
    setForgot(false)
  };


  const handleLogin = (data: FormData) => {
    const { email , password} = data
    if(email === password){
      setShowpopup(true)
      handleForm(data);
    }else{
      handleForm(data);
    }
  }

  useEffect(() => {
    if (success) {
      history.push({ pathname:"/", state: showpopup});
    }
  }, [history, success]);

  useEffect(() => {
    dispatch(resetPasswordReset());
  }, [dispatch]);



  const resetDetails = () => {
    setForgot(false);
    setEmail("");
    setOtp("");
    setPassword("");
    setNewPassword("");
    setphone("");

    dispatch(resetPasswordReset());
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.loginForm}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} style={{ background: "black" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {forgot ? "Reset Password" : "Login"}
        </Typography>
        {!forgot ? (
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleLogin)}
            noValidate
          >
            <TextField
              className="input"
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label=" User Id"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: "Required",
              })}
              required={errors.email ? true : false}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              InputProps={{
                style: {
                  height: "3.5rem", 
                },
              }}
            />
            <TextField
              className="input"
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              inputRef={register({
                required: "Required",
              })}
              required={errors.password ? true : false}
              error={errors.password ? true : false}
              helperText={errors.password && errors.password.message}
              InputProps={{
                style: {
                    height: "3.5rem", 
                  },
                endAdornment: (
                  <div
                    onClick={() =>
                      setShowPassword((previousState: any) => !previousState)
                    }
                  >
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </InputAdornment>
                  </div>
                ),
              }}
            />
            <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#0e3f6c" }}
                disabled={loading}
                className={classes.submit}
              >
                Sign In
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disabled={loading}
                style={{ backgroundColor: "#865e5e" }}
                className={classes.submit}
                onClick={() => setForgot(true)}
              >
                Forgot Password
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        ) : (
          <>
            <div className={classes.form}>
              <TextField
                inputProps={{
                  style: {
                    height: "2.4375em",
                  },
                }}
                id="outlined-number"
                name="phone"
                label="Phone Number"
                type="number"
                value={phone}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={(e) => setphone(e.target.value)}
                style={{
                  width: "100%",
                  margin: "1rem auto",

                  padding: "0px 8px 0px 0px",
                  height: "2.4375em",
                }}
              />



              {clickOtp && (
                <>

                  <TextField
                    inputProps={{
                      style: {
                        height: "2.4375em",
                      },
                    }}
                    id="outlined-number"
                    label="OTP"
                    name="otp"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{
                      width: "100%",
                      margin: "1rem auto",

                      padding: "0px 8px 0px 0px",
                      height: "3em;",
                    }}
                    disabled={(otpSent && otpSent.status)}
                  />
                  <div style={{ height: "4.4375em" }}>

                    <TextField
                      disabled={!otp || !phone}
                      inputProps={{
                        style: {
                          height: "2.4375em",
                        },
                      }}
                      id="outlined-password-input"
                      label="Set Password"
                      autoComplete="current-password"
                      value={newPassword}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <div
                            onClick={() =>
                              setShowPassword(
                                (previousState: any) => !previousState
                              )
                            }
                          >
                            <InputAdornment position="end">
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </InputAdornment>
                          </div>
                        ),
                      }}
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{
                        width: "100%",
                        margin: "1rem auto",
                        padding: "0px 8px 0px 0px",
                        height: "2.4375em",
                      }}
                    // disabled={!(otpSent && otpSent.status)}
                    />
                  </div>

                </>
              )}
              <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loadingResetOTP || !phone}
                  className={classes.submit}
                  onClick={() => {
                    sendOtpHandler();
                    setClickOtp(true);
                  }}
                  style={{
                    backgroundColor:
                      loadingResetOTP || !phone ? "#7b8794" : "#525354",
                    color: "white",
                  }}
                >
                  Send OTP
                </Button>
              </div>
              <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!otp || !newPassword}
                  className={classes.submit}
                  onClick={() => resetPasswordHandler()}
                  style={{ fontWeight: "500" }}
                >
                  Update Password
                </Button>
                {loadingReset && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              <div className={classes.wrapper} style={{ marginTop: "1rem" }}>
                <Button
                  fullWidth
                  variant="contained"
                  // disabled={!otp || !newPassword}
                  style={{
                    backgroundColor: "#d82032",
                    color: 'white',
                    fontWeight: "500"
                  }}

                  className={classes.submit}
                  onClick={() => resetDetails()}
                >
                  Login
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(LoginForm);
