import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Loader from "../Loader2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Box from "@mui/material/Box";
import BackupIcon from "@mui/icons-material/Backup";
import { bulkLeaveUploads } from "../../../actions/loginActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    item: {},
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    blinker: {
      animation: `$blinker 1.5s linear infinite`,
    },
    "@keyframes blinker": {
      "50%": {
        opacity: 0.5,
      },
    },
  })
);

interface Props {
  bulkLeaveUploads: any;
  loading: any;
}
const LeaveBulkUploader: React.FC<Props> = ({ bulkLeaveUploads, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const [buttonState, setButtonState] = useState<boolean>(false);


  const [fileTypeErrorLeave, setFileTypeLeaveError] = useState<any>("");
  const [leaveFileName, setLeaveFileName] = React.useState<string>("");
  const [leaveFile, setLeaveFile] = React.useState<any>([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleLeaveFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setLeaveFile(e.target.files[0]);
    if (e.target.files[0]?.type === "text/csv") {
      setLeaveFileName(e.target.files[0].name);
      setFileTypeLeaveError("");
    } else {
      setFileTypeLeaveError("Please select csv file");
    }
  };

  const submitLeaveFile = async () => {
    setIsButtonDisabled(true);
    const formData = new FormData();
    formData.append("file", leaveFile);
    await bulkLeaveUploads(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
    setLeaveFileName("");
    setLeaveFile("");
    history.push("/dashboard/payrollManager/bulk-leave-uploader");
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        className="mainHeading"
        style={{
          position: "relative",
          left: "-5%",
          color: "rgb(14, 63, 108)",
          marginTop: "10px",
        }}
      >
        Leave Bulk Uploader
      </h2>
      <div style={{ marginTop: 50, width: "100%" }}>
        <Grid container spacing={4}>
          {" "}
        </Grid>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <div
                style={{
                  position: "relative",
                  left: "30%",
                  marginTop: "30px",
                }}
              >
                <div
                  className="uplaod-inner"
                  style={{
                    height: "100%",
                    width: "800px",
                    border: "2px dashed grey",
                    borderRadius: "10px",
                    textAlign: "center",
                    padding: "30px 10px",
                  }}
                >
                  <div className="files-first" style={{}}>
                    <BackupIcon
                      style={{
                        margin: "0 5px",
                        width: "100px",
                        color: "blue",
                      }}
                    />
                    <h5>Upload File</h5>
                  </div>
                  <Grid item xs={12} sm={6} md={8}>
                    <input
                      className="button-17"
                      type="text/csv"
                      style={{
                        height: "50px",
                        padding: "6px 10px",
                        marginLeft: "255px",
                      }}
                      accept=".csv"
                      placeholder="File Name"
                      value={leaveFileName}
                      disabled
                    />
                    <p style={{ color: "red", marginLeft: "255px" }}>
                      {fileTypeErrorLeave}
                    </p>
                  </Grid>

                  <div className="upload-btn">
                    <Button
                      className="button-17"
                      style={{ height: "56px" }}
                      component="label"
                      id="upload-btn"
                    >
                      Select CSV File
                      <input
                        type="file"
                        name="file"
                        id="file"
                        hidden
                        onChange={handleLeaveFileChange}
                      />
                    </Button>
                  </div>

                  <div className="file-det">
                    <span style={{ marginRight: "10px" }}>
                      Supported file type:{" "}
                    </span>
                    <strong>Note : Only CSV File Can Be upload</strong>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <Grid item xs={12} sm={6} md={4}>
                          <Button
                            className="button-17"
                            startIcon={<CloudUploadIcon />}
                            component="label"
                            style={{
                              height: "56px",
                              width: "100%",
                              marginLeft: "-50px",
                              marginTop: "10px",
                            }}
                            onClick={submitLeaveFile}
                            disabled={
                              buttonState ||
                              leaveFileName === "" ||
                              fileTypeErrorLeave !== "" ||
                              loading
                            }
                          >
                            Upload
                          </Button>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <a
                            href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/1cbb2116-a23b-4fd3-ba1a-dc2bec9ea50a.csv"
                            target=""
                          >
                            <Button
                              className="button-17"
                              fullWidth
                              style={{
                                height: "56px",
                                width: "100",
                                marginTop: "10px",
                              }}
                              startIcon={<CloudDownloadIcon />}
                              component="label"
                            >
                              Download Sample CSV File
                            </Button>
                          </a>
                        </Grid>
                      </>
                    )}
                  </div>
                </div>
                {loading && (
                  <div
                    className={classes.blinker}
                    style={{
                      color: "#007BFF",
                      fontSize: "1.5em",
                      fontWeight: "bold",
                      textAlign: "center",
                      margin: "20px auto",
                      marginLeft: "20px",
                      padding: "10px",
                      border: "2px solid #007BFF",
                      borderRadius: "10px",
                      backgroundColor: "#f0f8ff",
                      boxShadow: "0 4px 8px rgba(0, 123, 255, 0.2)",
                      animation: "blinker 1.5s linear infinite",
                      maxWidth: "800px",
                      width: "100%",
                    }}
                  >
                    Please wait, we are uploading Leave file Data. It will take a
                    little bit of time...
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.loginReducer.loading,
});
export default connect(mapStateToProps, {
  bulkLeaveUploads,
})(LeaveBulkUploader);
