import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { createStyles, makeStyles, useTheme, Theme, fade } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyIcon from '@mui/icons-material/Key';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import HailIcon from '@mui/icons-material/Hail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Avatar from "@mui/material/Avatar";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { logout, panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from '@material-ui/core';
import { changePassword, } from '../actions/AdminActions';
import Popover from '@material-ui/core/Popover';
import { useHistory } from 'react-router';
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { onMessageListener } from "../../firebase";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PaymentIcon from '@mui/icons-material/Payment';

import "./style.css"
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import { TimeToLeave } from '@material-ui/icons';
import CoPresentIcon from '@mui/icons-material/CoPresent';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    menuButton: {
      marginRight: 0,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "rgb(14, 63, 108)",
      padding: theme.spacing(0, 1),

      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    menuItem: {
      width: "100%",
    },
    menuItemIcon: {
      color: "#97c05c",
    },
  }),
);

interface Props {
  notificationMessage: any;
  passwordStatus: boolean;
}
const PhleboAdmin: React.FC<Props> = ({
  notificationMessage,
  passwordStatus,
}
) => {

  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory()
  const [open, setOpen] = React.useState(true);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false)
  const [changeEmailModal, setChangeEmailModal] = React.useState(false);
  const [changeMobileNumber, setChangeMobileNumber] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);
  const id = open ? 'transitions-popper' : undefined;



  onMessageListener()
    .then((payload) => {

      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

    })
    .catch((err) => console.log("failed: ", err));


  const handleClose = () => {
    setOpenRightMenu(false);
  };

  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true)
    }
  }, [passwordStatus])

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    }
    await dispatch(changePassword(body))
    setchangePasswordModal(false)
    dispatch(logout())

  }

  const updateEmail = async () => {
    // code here
  }

  const updateMobileNumber = async () => {
    // code here
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openProfileDialog, setOpenProfileDialog] = useState(false);

  const handleClickAvatar = () => {
    setOpenProfileDialog(true);
  };

  const handleCloseProfileDialog = () => {
    setOpenProfileDialog(false);
  };

  const handleLogout = () => {
    dispatch(panelUserLogout())
    dispatch(logout())
  }
  const handleNotification = () => {
    history.push("/dashboard/employee/inbox");
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "rgb(14, 63, 108)" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Celara
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-owns={openPopover ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleNotification}
              color="inherit"
            >
              <CircleNotificationsIcon
                style={{
                  width: 40,
                  height: 40,
                  margin: "0 auto",
                }} />
            </IconButton>
            <Button onClick={handleClickAvatar}>
              <Avatar
                alt="User Avatar"
                style={{
                  color: "#fff",
                }}
              />
            </Button>
            <Dialog open={openProfileDialog} onClose={handleCloseProfileDialog} style={{ padding: "20px 30px" }}>
              <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
                <span style={{ fontWeight: "bold" }}>My Profile</span>
              </DialogTitle>
              <DialogContent>
                <div style={{ padding: "0px 60px" }}>
                  <Avatar
                    alt="User Avatar"
                    style={{
                      backgroundColor: "rgb(14, 63, 108)",
                      width: 110,
                      height: 110,
                    }}
                  ></Avatar>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.2rem", margin: "2px 0px" }}>
                  <span>Hello, Keshav</span>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>

                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >

                  <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setchangePasswordModal(true)}
                  >
                    Change Password
                  </Button>
                  <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setChangeEmailModal(true)}
                  >
                    Change Email Id
                  </Button>
                  <Button className='profile-button' style={{ width: "100%", padding: "2px", margin: "5px", border: "2px solid black" }}
                    onClick={() => setChangeMobileNumber(true)}
                  >
                    Update Mobile Number
                  </Button>
                  <Button
                    onClick={() => handleLogout()}
                    style={{ background: "rgb(14, 63, 108)", color: "white", width: "100%", margin: "10px 0px" }}
                  >
                    Logout
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)} >
                Change Password
              </MenuItem>
              <MenuItem onClick={() => {

                handleLogout()
                // }
              }}>Logout</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changePasswordModal}
            onClose={() => setchangePasswordModal(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",
                maxWidth: "none",
              },
            }}
          >
            <DialogTitle id="form-dialog-title" style={{ display: "flex", justifyContent: "space-around" }}>Change Password</DialogTitle>
            <DialogContent>
              <TextField
                id="name"
                placeholder="Old Password"
                type={showOldPassword ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  padding: "14px !important",
                }}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowOldPassword((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
              <TextField
                id="name"
                placeholder="New Password"
                type={showNewPassword ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  // padding: "14px !important",
                }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowNewPassword((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
              <TextField
                id="name"
                placeholder="Confirm Password"
                type={showNewPasswordRepeat ? "text" : "password"}
                fullWidth
                style={{
                  width: "100%",
                  margin: "0.3rem auto",
                  padding: "14px !important",
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div onClick={() => setShowNewPasswordRepeat((previousState: any) => !previousState)}>
                      <InputAdornment position="end">
                        {showNewPasswordRepeat ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </InputAdornment>
                    </div>
                  )
                }}
              />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updatePassword()} disabled={oldPassword?.length === 0 || newPassword?.length < 7 || newPassword !== confirmPassword} variant="contained" color="primary"  >
                Update
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changeEmailModal}
            onClose={() => setChangeEmailModal(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",

                maxWidth: "none",
              },
            }}
          >
            <DialogTitle >Update Email</DialogTitle>
            <DialogContent>
              <div >
                <TextField
                  placeholder='Email address'
                />
              </div>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updateEmail()} variant="contained" color="primary" style={{ backgroundColor: "#EA4C89" }}  >
                Update Email
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Dialog
            open={changeMobileNumber}
            onClose={() => setChangeMobileNumber(false)}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                padding: "10px 10px",
                alignItems: "center",
                width: "25%",
                maxWidth: "none",
              },
            }}
          >
            <DialogTitle >Update Mobile Number</DialogTitle>
            <DialogContent>
              <TextField style={{ padding: "5px 15px" }}
                placeholder='Enter Mobile Number'
              />
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => updateMobileNumber()} variant="contained" color="primary" style={{ backgroundColor: "#EA4C89" }}  >
                Update Mobile Number
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/admin">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#413c9f" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#413c9f" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage && notificationMessage?.results && notificationMessage?.results?.length > 0 ? notificationMessage?.results.map((data: any, index: any) => {
        return (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{index + 1 + "." + data?.notification}</Typography>
          </Popover>

        )
      }) : null}

    </div>
  );
}
const mapStateToProps = (state: any) => ({
  userDetails: state.EmployeeReducer.userDetails,
  notificationMessage: state.EmployeeReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
})


export default connect(mapStateToProps, {
})(PhleboAdmin);
