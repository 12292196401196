import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
} from "@material-ui/core";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LockImage from "./lock (1).png";
import { Theme, createStyles } from "@material-ui/core/styles";
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Chip from "@mui/material/Chip";
import { getCreateModule } from "../../../actions/loginActions";
import AttemptModule from "./AttemptModule";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getLmsCourses } from "../../actions/EmployeeActions";
import Certificate from "./certificate";
import createModule from "../LMS/createModule";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);



interface Props {
  getCreateModule: Function,
    createModuleData:any;
    getLmsCourses:Function,
    lmsCourses:any,

    
}

const LmsModules: React.FC<Props> = ({
  getCreateModule,
    createModuleData,
    getLmsCourses,
    lmsCourses,

}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [openModule, setOpenModule] = useState(false);
  const [myModule, setMyModule] = useState<any>();
  const [openCertificate, setOpenCertificate] = useState<any>(false);
  const { id } = useParams<any>(); 

  useEffect(()=>{
    getCreateModule(`?course_id=${id}&module_status=published`);
    getLmsCourses(`?id=${id}`);
  },[]); 


  const handleQuizModule  = (results:any, moduleData:any) =>{
     if(results !== "Fail" && moduleData?.with_certificate === true){
      setOpenCertificate(true);
      setMyModule(moduleData)
     }else if(results === "Fail"){
      setOpenModule(true)
      setMyModule(moduleData)
     }
  }


 
  
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        style={{
          fontWeight: "bold",
          color: "rgb(28, 78, 128)",
          textAlign: "center",
        }}
      >
        My Modules
      </h2>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          onInput={(e:any)=> getCreateModule(`?course_id=${id}&module_status=published&${e.target.value}`)}
          sx={{
            width: "400px",
            marginBottom: "22px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              "& fieldset": {
                borderColor: "rgb(28, 78, 128)", 
              },
              "&:hover fieldset": {
                borderColor: "darkblue", 
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div> */}
      <div style={{ display:"flex", justifyContent:"center", gap:"20px", flexWrap:"wrap"}}>
       {
        createModuleData && createModuleData?.count > 0 ? createModuleData?.results?.map((moduleData:any, index:number)=>(
            <div style={{ position: "relative", display: "inline-block", margin:"15px" }}>
            {
               moduleData.is_locked === true && <img
                src={LockImage}
                style={{
                  position: "absolute",
                  top: "30%",
                  left: "35%",
                  zIndex: 10,
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  padding: "5px",
                }}
                width={100}
                height={100}
                alt=""
              />
            }
            <Card
              variant="outlined"
              sx={{
                width: 320,
                filter: moduleData.is_locked === true ? "blur(1.5px)" : "",
              }}
            >
              <CardOverflow>
              <AspectRatio ratio="2">
                <img
                  src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
                  srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
                  loading="lazy"
                  alt=""
                />
                <Chip
                  label={moduleData.score_by_user === "Pass" ? "Completed": "Pending"}
                  size="small"
                  variant="outlined"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: moduleData?.score_by_user === "Fail" ? "#ff9a00" : "rgb(64 145 64)",
                    color: "#fff",
                    zIndex: 5,
                  }}
                />
              </AspectRatio>
            </CardOverflow>
              <CardContent>
                <Typography level="title-md">
                  Module-{index + 1} {moduleData.name}
                </Typography>
              </CardContent>
              <Button
                variant="solid"
                size="md"
                aria-label="Explore Bahamas Islands"
                color="primary"
                sx={{
                  ml: "auto",
                  alignSelf: "center",
                  fontWeight: 600,
                  color:"#fff",
                  width: "100%",
                  border: moduleData.score_by_user !== "Fail" ? "1px solid black" : "",
                }}
                onClick={()=>{
                    handleQuizModule(moduleData.score_by_user, moduleData)
                }}
                disabled={moduleData.score_by_user !== "Fail" && moduleData?.with_certificate === false}

              >
                {moduleData?.score_by_user === "Fail" ? "Start Module" : moduleData?.with_certificate === true ? "Download Certificate" : "Completed" }
              </Button>
              <CardOverflow
                variant="soft"
                sx={{ bgcolor: "background.level1" }}
              >
                <Divider inset="context" />
                <CardContent
                  orientation="horizontal"
                  style={{ margin: "auto" }}
                >
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                   Passing Score: {moduleData.module_weightage} {moduleData?.weightage_type === "percentage" ? "%": "marks"}
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    level="body-xs"
                    textColor="text.secondary"
                    sx={{ fontWeight: "md" }}
                  >
                    Total Question: {moduleData.assignment_question_module.length} 
                  </Typography>
                </CardContent>
              </CardOverflow>
            </Card>
          </div>
        )) : <div style={{ display:"flex", justifyContent:"center", alignItems:"center", marginTop:"50px", fontSize:"20px", fontWeight:"bold"}}> No Modules Found</div>
       }
      </div>
      {
        openModule && <AttemptModule
        open={openModule}
        setOpen={setOpenModule} 
        myModule={myModule}       
        />
      }
      {
        openCertificate && <Certificate open={openCertificate} setOpen={setOpenCertificate} myModule={myModule} />
      }

    </main>
  );
};

const mapStateToProps = (state: any) => ({
  createModuleData: state.loginReducer.createModuleData,
  lmsCourses: state.EmployeeReducer.lmsCourses,
});

export default connect(mapStateToProps, {
    getCreateModule,
    getLmsCourses,
})(LmsModules);
