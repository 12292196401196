import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

interface JobCardProps {
    title: string;
    designation: string;
    location: string;
}

const JobCard: React.FC<JobCardProps> = ({ title, designation, location }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        // Handle form submission logic here
        handleClose(); // Close the dialog after submission
    };

    return (
        <>
            <Card sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <CardContent>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography color="text.secondary" gutterBottom>
                        {designation}
                    </Typography>
                    <Typography color="text.secondary">
                        {location}
                    </Typography>
                </CardContent>
                <Button variant="contained" onClick={handleClickOpen}>Apply</Button>
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Apply for {title}</DialogTitle>
                <DialogContent>
                    {/* Form fields */}
                    <TextField autoFocus margin="dense" id="name" label="Name" fullWidth />
                    <TextField margin="dense" id="email" label="Email Address" type="email" fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default JobCard;
