import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { TextField, Grid,InputAdornment,IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { AppBar, Tabs, Tab } from "@mui/material";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { ACTIONS } from "../../../interfaces/actionTypes/loginTypes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import "./index.sass";
import {
  getEmployeeList,
  getEmployeeALlData,
  resetPasswordByPayroll,
} from "../../../actions/loginActions";
import Loader from "../Loader2";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { generateEmployeeFilter } from "../../../helper/generate";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      position: "absolute",
    },
    paper1: {
      backgroundColor: theme.palette.background.paper,
      border: "3px solid green",
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "1200px",
      position: "absolute",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const validatePassword = (password: any) => {
  const minLength = 6;
  const maxLength = 12;
  const hasNumber = /\d/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

  return (
    password.length >= minLength &&
    password.length <= maxLength &&
    hasNumber.test(password) &&
    hasSpecialChar.test(password)
  );
};

interface Props {
  getEmployeeList: any;
  employeesList: any;
  resetPasswordByPayroll: any;
}
const CreateEmployeetTablePage: React.FC<Props> = ({
  getEmployeeList,
  employeesList,
  resetPasswordByPayroll,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0);
  const [employeeUUid, setEmployeeUuid] = useState("");
  const [password, setPassword] = useState("");
  const [key1, setKey1] = useState<any>(false);
  const [passwordError, setPasswordError] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getEmployeeList("get_all=true");
  }, []);

  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword(value);

    // Validate password
    if (!validatePassword(value)) {
      setPasswordError(
        "Password must be between 6-12 characters, include a number, and a special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data: any = {
      employee_id: employeeUUid,
      password: password,
    };

    await resetPasswordByPayroll(data);
    setEmployeeUuid("");
    setPassword("");
    setKey1(!key1);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            backgroundColor: "rgb(28, 78, 128)",
            color: "#fff",
            textAlign: "center",
            fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
            padding: "12px 20px",
            borderRadius: "12px",
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
          }}
        >
          Employee Password Reset
        </h4>

        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            alignItems: "flex-start",
            padding: "20px",
            backgroundColor: "#f5f5f5",
            borderRadius: "10px",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)",
            marginTop: "10px",
          }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              key={key1}
              onChange={(event, obj) => {
                if (obj) {
                  setEmployeeUuid(obj.employee_id);
                }
              }}
              id="checkboxes-tags-demo"
              options={employeesList?.results || []}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getEmployeeList(`get_all=true&employee_id=${newInputValue}`);
                }, 1000);
              }}
              getOptionLabel={(option: any) => {
                return option?.employee_id + " " + option.name;
              }}
              renderOption={(option: any) => {
                return (
                  <p>
                    <b style={{ color: "blue" }}>Employee Name:</b>
                    <strong>
                      {option?.name.toString()}
                      <br />
                    </strong>
                    <b style={{ color: "red" }}>Employee Id:</b>
                    {option?.employee_id?.toUpperCase()}
                  </p>
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Search By Code Or Name"
                  variant="outlined"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "8px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              value={password}
              className="input"
              label="Password "
              variant="outlined"
              onChange={handlePasswordChange}
              error={!!passwordError}
              helperText={passwordError}
              style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "10px 12px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              fullWidth
              style={{
                backgroundColor:
                  password === "" || employeeUUid === "" ? "gray" : "#1E3A8A",
                color:
                  password === "" || employeeUUid === "" ? "black" : "white",
                borderRadius: "8px",
                padding: "12px 24px",
                border: "none",
                cursor:
                  password === "" || employeeUUid === ""
                    ? "not-allowed"
                    : "pointer",
                transition:
                  "transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out",
                boxShadow:
                  password === "" || employeeUUid === ""
                    ? "none"
                    : "0px 4px 8px rgba(0, 0, 0, 0.2)",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={handleSubmit}
              disabled={password === "" || employeeUUid === ""}
              onMouseEnter={(e) => {
                if (!(password === "" || employeeUUid === "")) {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0px 6px 12px rgba(0, 0, 0, 0.3)";
                }
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow =
                  "0px 4px 8px rgba(0, 0, 0, 0.2)";
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  employeesList: state.loginReducer.employeesList,
});
export default connect(mapStateToProps, {
  getEmployeeList,
  resetPasswordByPayroll,
})(CreateEmployeetTablePage);
