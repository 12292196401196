import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Checkbox, TableFooter } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, TextField, Grid } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import { generateLeaveFilter } from "../../../helper/generate";
import { useRef } from "react";
import {
  getLeaveRequests,
  createLeaveRequests,
} from "../../actions/EmployeeActions";

import Loader from "../Loader2";
import { getEmployeeList, getEmployeeById } from "../../../actions/loginActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "rgb(14, 63, 108)",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const checkboxStyle = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
  accentColor: "#924A91",
  borderRadius: "4px",
  border: "1px solid #ccc",
  transition: "all 0.3s ease",
};

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getLeaveRequests: any;
  createLeaveRequests: any;
  leaveRequests: any;
  getEmployeeList: any;
  employeesList: any;
  loading: any;
  getEmployeeById: any;
  employee_by_id: any;
}

const LeadsTable: React.FC<Props> = ({
  createLeaveRequests,
  getLeaveRequests,
  leaveRequests,
  getEmployeeList,
  employeesList,
  loading,
  getEmployeeById,
  employee_by_id,

}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [employeeUUid, setEmployeeUuid] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [openLeave, setOpenLeave] = useState(false);

  useEffect(() => {
    getLeaveRequests("?status=pending");
    getEmployeeById("");
  }, []);

  const checkedLead = (event: any, leadId: number) => {
    if (selectedLeads?.length < 10) {
      !event.target.checked
        ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== leadId))
        : setSelectedLeads((prev: any) => [...prev, leadId]);
    } else {
      return false;
    }
  };

  const handleTabChange = (event: any, newValue: any) => {
    if (newValue === 0) {
      getLeaveRequests("?status=pending");
    } else if (newValue === 1) {
      getLeaveRequests("?status=approved");
    }
    setValue(newValue);
  };

  const handleFilter = () => {
    const body: any = {
      employee_id: employeeUUid,
      date: leaveDate,
    };
    const url = generateLeaveFilter(body).substring(2);

    {
      value === 0
        ? getLeaveRequests(`?status=pending&${url}`)
        : getLeaveRequests(`?status=approved&${url}`);
    }
    setPage(0);
  };

  const handleRequest = async (request: string) => {
    const requestIds: [number, string][] = [];

    if (request === "Accept") {
      selectedLeads.forEach((lead: any) => {
        requestIds.push([lead, "approve"]);
      });
    } else if (request === "Reject") {
      selectedLeads.forEach((lead: any) => {
        requestIds.push([lead, "reject"]);
      });
    }
    const body: any = {
      status: requestIds,
    };
    await createLeaveRequests(body);
    getLeaveRequests("?status=pending");
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = leaveRequests.links && leaveRequests.links.next.split("?")[1];

      getLeaveRequests(`?${url}`);
    } else if (newPage < page) {
      let url =
        leaveRequests.links && leaveRequests.links.previous.split("?")[1];

      getLeaveRequests(`?${url}`);
    }
    setPage(newPage as number);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2
            style={{
              marginTop: "1rem",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Leave Requests
          </h2>
        </div>
        {/* )} */}

        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            centered
            sx={{
              padding: "10px",
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#e0e0e0",
                height: "4px",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "#757575",
                minWidth: "300px",
                margin: "0 10px",
                padding: "12px 24px",
                borderRadius: "8px",
                transition: "all 0.3s ease",
                backgroundColor: "#e0e0e0",
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "rgb(14, 63, 108)",
                },
                "&:hover": {
                  backgroundColor: "#a1a1a1",
                },
              },
            }}
          >
            <Tab label="Pending" />
            <Tab label="Approved" />
          </Tabs>
          {employee_by_id?.results &&
            employee_by_id?.results?.length > 0 &&
            employee_by_id?.results[0]?.is_manager === true && (
              <Grid container spacing={3} style={{ marginTop: "15px" }}>
                <Grid item xs={12} sm={3} md={3}>
                  <Autocomplete
                    onChange={(event, obj) => {
                      if (obj) {
                        setEmployeeUuid(obj.employee_id);
                      }
                    }}
                    id="checkboxes-tags-demo"
                    options={employeesList?.results || []}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getEmployeeList(`get_all=true&name= ${newInputValue}`);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => {
                      return option?.employee_id + " " + option.name;
                    }}
                    renderOption={(option: any) => {
                      return (
                        <p>
                          <b style={{ color: "blue" }}>Employee Name:</b>
                          <strong>
                            {option?.name.toString()}
                            <br />
                          </strong>
                          <b style={{ color: "red" }}>Employee Id:</b>
                          {option?.employee_id?.toUpperCase()}
                        </p>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Search By Employee Code Or Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    className="input"
                    name="start_date"
                    id="noTyping"
                    type="date"
                    label="Date"
                    value={leaveDate}
                    variant="outlined"
                    onChange={(e) =>
                      String(e.target.value).length <= 10
                        ? setLeaveDate(e.target.value as string)
                        : ""
                    }
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleFilter}
                  // disabled={
                  //   selectedMonth === "" || selectedYear === "" || employeeDept === ""
                  // }
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={() =>
                      history.push("/dashboard/employee/manage-employee")
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>)}

          {selectedLeads.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    backgroundColor: "#4CAF50",
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#45A049",
                    },
                  }}
                  onClick={() => handleRequest("Accept")}
                >
                  Accept
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    backgroundColor: "#F44336",
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                    "&:hover": {
                      backgroundColor: "#D32F2F",
                    },
                  }}
                  onClick={() => handleRequest("Reject")}
                >
                  Reject
                </Button>
              </div>
            </div>
          )}

          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            <Table
              className={classes.table}
              stickyHeader
              aria-label="customized table"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    {value === 0 && (
                      <input
                        type="checkbox"
                        className="input"
                        name="selectall"
                        style={checkboxStyle}
                        id="selectall"
                        onChange={(e) =>
                          e.target.checked
                            ? setSelectedLeads(
                              leaveRequests?.results &&
                              leaveRequests?.results.map(
                                (lead: any, index: number) => {
                                  if (index < 100) {
                                    return lead.id && lead.id;
                                  }
                                }
                              )
                            )
                            : setSelectedLeads([])
                        }
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="center">Leave from</StyledTableCell>
                  <StyledTableCell align="center">Leave to</StyledTableCell>
                  <StyledTableCell align="center">Reason</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {leaveRequests &&
                      leaveRequests?.results &&
                      leaveRequests?.results?.length > 0 ? (
                      leaveRequests?.results?.map((leave: any, index: any) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {value === 0 && (
                                <input
                                  type="checkbox"
                                  name="selectall"
                                  style={checkboxStyle}
                                  id="selectall"
                                  checked={selectedLeads.some(
                                    (lead: any) => lead === leave.id
                                  )}
                                  onChange={(e: any) =>
                                    checkedLead(e, leave.id)
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {typeof leave.employee_name === "string"
                                ? leave.employee_name
                                : JSON.stringify(leave.employee_name)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof leave.from_date === "string"
                                ? leave.from_date
                                : JSON.stringify(leave.from_date)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof leave.to_date === "string"
                                ? leave.to_date
                                : JSON.stringify(leave.to_date)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof leave.reason_for_leave === "string"
                                ? leave.reason_for_leave
                                : JSON.stringify(leave.reason_for_leave)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {typeof leave.status === "string"
                                ? leave.status
                                : JSON.stringify(leave.status)}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={6} align="center">
                          No leave requests available.
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <StyledTableRow style={{ textAlign: "center" }}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={5}
                    count={leaveRequests?.count || 0}
                    rowsPerPage={leaveRequests?.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Dialog
            open={openLeave}
            onClose={() => setOpenLeave(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Approve Leave Request ?
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => handleRequest("Accept")}>Accept</Button>
              <Button onClick={() => handleRequest("Reject")} autoFocus>
                Reject
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  leaveRequests: state.EmployeeReducer.leaveRequests,
  loading: state.EmployeeReducer.loading,
  employeesList: state.loginReducer.employeesList,
  employee_by_id: state.loginReducer.employee_by_id,
});

export default connect(mapStateToProps, {
  createLeaveRequests,
  getLeaveRequests,
  getEmployeeList,
  getEmployeeById,
})(LeadsTable);
