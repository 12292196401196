import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Theme, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import LockImage from "./lock (1).png";
import Chip from "@mui/material/Chip";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { TextField, InputAdornment } from "@mui/material";
import { getLmsCourses } from "../../actions/EmployeeActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #b435a3 ",
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      opacity: "0.8",
      width: "1400px",
      height: "auto%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
  })
);

interface Props {
  getLmsCourses: Function;
  lmsCourses: any;
  employeeData: any;
}

const LmsCourses: React.FC<Props> = ({
  getLmsCourses,
  lmsCourses,
  employeeData,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const history = useHistory();

  function calculateDaysBetweenDates(date1: string, date2: string): string {
    if (!date1 || !date2) {
      return "N/A"; // Handle missing dates
    }

    const startDate = moment(date1, "YYYY-MM-DD", true); // Parse in strict mode
    const endDate = moment(date2, "YYYY-MM-DD", true);

    if (!startDate.isValid() || !endDate.isValid()) {
      return "N/A"; // Handle invalid date formats
    }

    const daysDifference = endDate.diff(startDate, "days");
    return daysDifference >= 0 ? daysDifference.toString() : "Expired";
  }

  useEffect(() => {
    getLmsCourses();
  }, []);

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2
        style={{
          fontWeight: "bold",
          color: "rgb(28, 78, 128)",
          textAlign: "center",
        }}
      >
        My Courses
      </h2>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          onChange={(e: any) => getLmsCourses(`?name=${e.target.value}`)}
          sx={{
            width: "400px",
            marginBottom: "22px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              "& fieldset": {
                borderColor: "rgb(28, 78, 128)",
              },
              "&:hover fieldset": {
                borderColor: "darkblue",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {lmsCourses?.results &&
          lmsCourses.results?.map((course: any, index: number) => (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                margin: "15px",
              }}
            >
              {course.is_locked && (
                <img
                  src={LockImage}
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "35%",
                    zIndex: 10,
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    padding: "5px",
                  }}
                  width={100}
                  height={100}
                  alt=""
                />
              )}
              <Card
                variant="outlined"
                sx={{
                  width: 320,
                  filter: course.is_locked ? "blur(1.5px)" : "",
                }}
              >
                <CardOverflow>
                  <AspectRatio ratio="2">
                    <img
                      src={course.thumbnail || "default-thumbnail.png"}
                      loading="lazy"
                      width="200"
                      height="100"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt={course.name || "Course Thumbnail"}
                    />
                    <Chip
                      label={
                        course?.completion_status === "Pending"
                          ? "Pending"
                          : course?.completion_status === "InProgress" ? "In-progress" : "Completed"
                      }
                      size="small"
                      variant="outlined"
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: course.completion_status === "Pending" ? "#ff9a00" : course.completion_status === "InProgress" ? "rgb(64 145 64)" : "#2196F3",
                        color: "#fff",
                        zIndex: 5,
                      }}
                    />
                  </AspectRatio>
                </CardOverflow>
                <CardContent>
                  <Typography level="title-md">
                    {`Course : ${course.name || "Untitled Course"}`}
                  </Typography>
                  <Typography level="title-md">
                    {`Batch : ${
                      course.batch
                        .map((course_data: any) => course_data.name)
                        .join(", ") || "Untitled Course"
                    }`}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                      label={`Course Id: ${course?.id}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />

                    <Chip
                      label={`No. of Modules: ${
                        Array.isArray(course?.modules)
                          ? course?.modules?.filter(
                              (moduleStatus: any) =>
                                moduleStatus.status === "published"
                            )?.length
                          : 0
                      }`}
                      color="success"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Chip
                      label={`Expire in (Days): ${
                        course.start_date && course.end_date
                          ? calculateDaysBetweenDates(
                              course.start_date,
                              course.end_date
                            )
                          : "N/A"
                      }`}
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                    <Chip
                      label={`No of Questions: ${course?.no_of_question}`}
                      color="primary"
                      size="small"
                      variant="outlined"
                      style={{ width: "150px" }}
                    />
                  </div>
                </CardContent>
                <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Explore Bahamas Islands"
                  sx={{
                    ml: "auto",
                    alignSelf: "center",
                    fontWeight: 600,
                    width: "100%",
                  }}
                  onClick={() =>
                    history.push(
                      `/dashboard/employee/employee-courses/${course.id}`
                    )
                  }
                >
                  { course?.completion_status === "Pending" ? "Start Course"  : course?.completion_status === "InProgress" ? "Resume" : "Completed"}
                </Button>
                <CardOverflow
                  variant="soft"
                  sx={{ bgcolor: "background.level1" }}
                >
                  <Divider inset="context" />
                  <CardContent
                    orientation="horizontal"
                    style={{ margin: "auto" }}
                  >
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Assigned: {course.start_date}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography
                      level="body-xs"
                      textColor="text.secondary"
                      sx={{ fontWeight: "md" }}
                    >
                      Expire: {course.end_date}
                    </Typography>
                  </CardContent>
                </CardOverflow>
              </Card>
            </div>
          ))}
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  lmsCourses: state.EmployeeReducer.lmsCourses,
  employeeData: state.EmployeeReducer.employeeData,
});

export default connect(mapStateToProps, {
  getLmsCourses,
})(LmsCourses);
