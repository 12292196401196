import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField, Grid, Paper, Table, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles, Typography, Chip } from '@material-ui/core';
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    MenuItem,
    IconButton,
    Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../Loader2';
import Modal from '@mui/material/Modal';
import Fade from '@material-ui/core/Fade';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import './index.sass';
import { Box, Button } from "@mui/material";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { BorderTopRounded, Height } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
    getEmployeeDepartment,
    postPolicyCreation,
    getPolicyCategory,
    createPolicyCategory,
    getPolicy,
    updatePolicy,
    updatePolicyCategory,
    updateCategory,
} from "../../../actions/loginActions"
import { maxHeaderSize } from 'http';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        paper3: {
            padding: "30px",
            width: "100%",
            borderRadius: "10px",
        },
        conlorChange: {
            background: "red",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "absolute",
            maxHeight: '90vh',
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid balck",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
            position: "absolute",
        },
        table: {
            maxWidth: "100%",
        },
        tableHead: {
            background: "rgb(146, 74, 145)!important",
        },
        tableHeadCell: {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
        },
        tableContainer: {
            position: "sticky",
            top: "200px",
            overflow: "scroll",
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
    })
);

const style = {
    position: 'absolute' as 'absolute',
    top: '55%',
    left: "50%",
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    loading: boolean;
    getEmployeeDepartment: Function;
    departmentNames: any;
    postPolicyCreation: Function;
    policyCreation: any;
    getPolicyCategory: Function;
    get_Policy_Category: any;
    createPolicyCategory: Function;
    create_policy_category: any;
    getPolicy: Function;
    get_policy: any;
    updatePolicy: Function;
    update_policy: any;
    policyLoading: any;
    createCategoryLoading: any;
    updatePolicyCategory: any;
    updateCategory: any;
}




const PolicyCenter: React.FC<Props> = ({
    loading,
    getEmployeeDepartment,
    departmentNames,
    postPolicyCreation,
    getPolicyCategory,
    get_Policy_Category,
    createPolicyCategory,
    getPolicy,
    get_policy,
    updatePolicy,
    update_policy,
    policyLoading,
    createCategoryLoading,
    updatePolicyCategory,
    updateCategory,
}) => {
    const classes = useStyles()
    const timer = useRef<any>(0);
    const [policyCategoryData, setPolicyCategoryData] = useState<any>("");
    const [openPdf, setOpenPdf] = useState<boolean>(false)
    const [fileLink, setFileLink] = useState<any>("")
    const [selectDepartment, setSelectDepartment] = useState<any>([]);
    const [departmentId, setDepartmentid] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [policyId, setPolicyId] = useState<any>("");
    const [deletePolicy, setDeletePolicy] = useState<boolean>(false)
    const [deleteCategory, setDeleteCategoty] = useState<boolean>(false)
    const [editCategory, setEditCategoty] = useState<boolean>(false)
    const [selectedCategory, setSelectedCategory] = useState<any>(null)
    const [editCategoryName, setEditCategoryName] = useState<any>("")
    const [isActive, setIsActive] = useState(false);
    const history = useHistory();

    const handlePdfModelClose = () => {
        setOpenPdf(false)
    }

    const handleViewPdf = () => {
        setOpenPdf(true)
    }

    const [categoryId, setCategoryId] = useState<any>("");
    const [policyCategoryName, setPolicyCategoryName] = useState<any>("")
    const [policyCategoryTitle, setPolicyCategoryTitle] = useState<any>("")
    const [policyModal, setPolicyModal] = useState<boolean>(false);
    const [policyTitle, setPolicyTitle] = useState<any>("");
    const [policyDesc, setPolicyDesc] = useState<any>("")
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
    const [policyFile, setPolicyFile] = useState<any>("");
    const [addEnable, setAddEnable] = useState<any>(false);

    const resetAddPolicy = () => {
        isEdit && setIsEdit(false);
        setDepartmentid([]);
        setPolicyTitle("")
        setPolicyDesc("")
        setPolicyFile("")
        setIsAcknowledged(false)
        // setPolicyCategoryTitle("")
        setSelectedPolicy({})
        setOpenNewModal(false)
    }

    const handleCancleCategory = () => {
        setPolicyModal(false);
        setPolicyCategoryName("");
    }
    const [policyName, setPolicyName] = useState<string>("");
    const [policyList, setPolicyList] = useState<any>([]);
    const [openNewModal, setOpenNewModal] = useState<boolean>(false);
    const [selectedPolicy, setSelectedPolicy] = useState<any>({})
    const handleOpenNew = () => {
        setOpenNewModal(true);
    }
    const handleCloseNewModal = () => {
        isEdit && setIsEdit(false);
        setDepartmentid([]);
        setPolicyTitle("")
        setPolicyDesc("")
        setPolicyFile("")
        setIsAcknowledged(false)
        setSelectedPolicy({})
        setOpenNewModal(false)
    }
    const handlePolicyType = (category: any) => {
        if (category !== "") {
            getPolicy(`?policy_name=${category}`)

        }
    }
    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setPolicyFile(file);
        }
    }

    useEffect(() => {
        getEmployeeDepartment();
        getPolicyCategory()
    }, [])

    useEffect(() => {
        if (get_policy) {
            setPolicyList(get_policy?.[0]?.policy)
        }
    }, [get_policy]);

    useEffect(() => {
        if (get_Policy_Category) {
            setPolicyCategoryData(get_Policy_Category);
            setPolicyList([]);
        }
    }, [get_Policy_Category])

    const handleDepartment = (dept: any) => {
        if (dept && dept.length > 0) {
            const ids = dept.map((item: any) => item.id)
            setDepartmentid(ids);
        }
    }

    const handleButtonClick = (policy: any) => {
        handleOpenNew();
        setIsEdit(true);
        setSelectedPolicy(policy);
    };

    useEffect(() => {

        if (isEdit && selectedPolicy) {
            setPolicyId(selectedPolicy?.id)
            setPolicyTitle(selectedPolicy.name)
            setPolicyDesc(selectedPolicy.description)
            setIsAcknowledged(selectedPolicy.is_read)
            setDepartmentid(selectedPolicy.employee_department)
            setSelectDepartment(selectedPolicy.employee_department)
        }
    }, [isEdit, selectedPolicy])

    useEffect(() => {
        setEditCategoryName(selectedCategory?.policy_name)
    }, [editCategory])

    // POst policy Category
    const handlePolicyCategory = async () => {
        const formData = new FormData();
        formData.append("policy_name", policyCategoryName)
        // formData.append("department", departmentId);

        await createPolicyCategory(formData);
        setPolicyModal(false);
        setPolicyCategoryName("")
        getPolicyCategory()

    }

    const handlePolicyCreate = async () => {
        const formData = new FormData();
        formData.append("department", departmentId);
        formData.append("name", policyTitle);
        formData.append("policy_description", policyDesc)
        formData.append("policy_file", policyFile);
        formData.append("is_read", isAcknowledged.toString());
        policyCategoryTitle && formData.append("policy_name", policyCategoryTitle);

        await postPolicyCreation(formData)
        setOpenNewModal(false)
        setDepartmentid([]);
        setPolicyTitle("")
        setPolicyDesc("")
        setPolicyFile("")
        setIsAcknowledged(false)
        getEmployeeDepartment()
        getPolicy(`?policy_name=${policyCategoryTitle}`)
    }

    const handleUpdatePolicy = async () => {
        const formData = new FormData();
        formData.append("employee_department", departmentId);
        formData.append("name", policyTitle);
        formData.append("description", policyDesc)
        formData.append("policy_file", policyFile);
        formData.append("is_read", isAcknowledged.toString());
        formData.append("policy_name", policyCategoryTitle);

        await updatePolicy(formData, policyId);
        setOpenNewModal(false)
        getEmployeeDepartment()
        setIsEdit(false);
        setDepartmentid([]);
        setPolicyTitle("")
        setPolicyDesc("")
        setPolicyFile("")
        setIsAcknowledged(false)
        getPolicy(`?policy_name=${policyCategoryTitle}`)
    }

    const handleDeletePolicy = async (policy: any) => {
        const formData = new FormData();
        const isActive = "False";
        formData.append("is_active", isActive.toString());
        await updatePolicy(formData, policy?.id);
        getPolicy(`?policy_name=${policyCategoryTitle}`)
        setDeletePolicy(false);
    }

    const handleDeletePolicyCategory = async (policy: any) => {
        const formData = new FormData();
        const isActive = "False";
        formData.append("is_active", isActive.toString());
        await updatePolicyCategory(formData, categoryId);
        getPolicyCategory();
        setDeleteCategoty(false)
        setAddEnable(false);

    }

    const handleEditPolicyCategory = async () => {
        const formData = new FormData();
        formData.append("policy_name", editCategoryName);
        formData.append("is_active", selectedCategory?.is_active);
        await updateCategory(formData, selectedCategory?.id);
        getPolicyCategory();
        setEditCategoty(false)
        setEditCategoryName("");

    }

    const handleEditCancleCategory = () => {
        setEditCategoty(false);
        setEditCategoryName("");
    }


    const maxTitleLength = 200;
    const maxDescLength = 500;

    const handlePolicyTitleChange = (e:any) => {
        const inputText = e.target.value;
        if (inputText.length <= maxTitleLength) {
            setPolicyTitle(inputText);
        }
    };

    // Handle Policy Description change with character limit
    const handlePolicyDescChange = (e:any) => {
        const inputText = e.target.value;
        if (inputText.length <= maxDescLength) {
            setPolicyDesc(inputText);
        }
    };
    // Remaining characters for Title and Description
    const remainingTitleChars = maxTitleLength - policyTitle.length;
    const remainingDescChars = maxDescLength - policyDesc.length;

    // Check if the limits are reached
    const isTitleLimitReached = remainingTitleChars === 0;
    const isDescLimitReached = remainingDescChars === 0;

    let AddPolicyModal =
        <div>
            <h4 >Add Policy Category Here</h4>
            <Grid container spacing={3} style={{ paddingTop: "20px" }}>
                <Grid item
                    xs={12}
                    sm={12}
                    md={12}>
                    <p>Policy Category</p>
                    <TextField
                        className="input"
                        variant="outlined"
                        type="text"
                        id="category"
                        required
                        // label="Policy Title"
                        placeholder='Enter Policy Category'
                        size="small"
                        style={{ width: "100%", background: "white" }}
                        value={policyCategoryName}
                        onChange={(e: any) => {
                            const value = e.target.value;
                            if (value.trim() !== "") {
                                setPolicyCategoryName(value);
                            } else {
                                setPolicyCategoryName("");
                            }
                        }}
                    />
                </Grid>

                <Grid container spacing={2} style={{ padding: "12px", display: "flex", justifyContent: "end" }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={createCategoryLoading || policyCategoryName === "" || (!policyTitle && selectDepartment.length < 0)}
                            sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",

                            }}
                            size="large"
                            onClick={handlePolicyCategory}
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                // marginLeft: "10px"
                            }}
                            size="large"
                            onClick={handleCancleCategory}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>

const DepartmentList = ({ departments }: { departments: any[] }) => {
    const deptNamelist = departmentNames
      .filter((dept: any) => departments?.includes(dept.id)) 
      .map((dept: any) => dept.name);

        return (
            <Grid container spacing={1}>
                {deptNamelist.length > 0 && (
                    deptNamelist.map((name: any, index: any) => (
                        <Grid item >
                            <Chip label={name} disabled color='primary' />
                        </Grid>
                    ))
                )}
            </Grid>
        );
    };

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
                <h4
                    style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        backgroundColor: "rgb(28, 78, 128)",
                        color: "#fff",
                        textAlign: "center",
                        fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                        padding: "10px",
                        borderRadius: "15px",
                        letterSpacing: "0.5px",
                        textTransform: "capitalize",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    Policy center
                </h4>
                <Grid container spacing={2}>
                    <Grid item
                        xs={12}
                        sm={12}
                        md={5}>
                        <Paper className={classes.paper} style={{ background: "rgb(233, 236, 239)" }} >
                            <Grid container spacing={3} >
                                <Grid item
                                    xs={12} style={{ marginTop: "20px" }}>
                                    <h4 style={{ color: "#0e3f6c", textAlign: "center", fontSize: "2rem" }}>Policy Categories</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            fontSize: "1.3rem",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                        }}
                                        onClick={() => setPolicyModal(true)}
                                    >
                                        <CreateNewFolderIcon style={{ marginRight: "20px", height: "50px" }} />
                                        Add New Policy
                                    </Button>
                                </Grid>
                                {policyModal &&
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper3} elevation={15}>
                                            <Fade in={policyModal}>
                                                {AddPolicyModal}
                                            </Fade>
                                        </Paper>
                                    </Grid>}
                                <Grid item xs={12}>
                                    {loading ? <Loader /> :
                                        (
                                            policyCategoryData &&
                                            policyCategoryData.length > 0 &&
                                            policyCategoryData?.map((policy: any) => (
                                                <Grid xs={12}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        textAlign: "left",
                                                        background: isActive ? "rgb(223 221 228)" : "white",
                                                        padding: "15px",
                                                        width: "100%",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        margin: "10px 0",
                                                        transition: "background 0.3s ease",
                                                    }}
                                                    onClick={() => {
                                                        handlePolicyType(policy?.policy_name)
                                                        setCategoryId(policy?.id)
                                                        setPolicyCategoryTitle(policy?.policy_name)
                                                        setAddEnable(true)
                                                        setIsActive(!isActive);
                                                    }}
                                                    onMouseEnter={(e) => (e.currentTarget.style.background = "rgb(223 221 228)")}
                                                    onMouseLeave={(e) => (e.currentTarget.style.background = isActive ? "rgb(223 221 228)" : "white")}
                                                    key={policy.id}
                                                >

                                                    <Typography
                                                    >
                                                        {policy?.policy_name}
                                                    </Typography>
                                                    <div
                                                            style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "8px", 
                                                            }}
                                                        >
                                                            <IconButton
                                                            color="error"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setEditCategoty(true);
                                                                setSelectedCategory(policy);
                                                            }}
                                                            >
                                                            <ModeEditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                            color="error"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDeleteCategoty(true);
                                                                setCategoryId(policy?.id);
                                                            }}
                                                            >
                                                            <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    

                                                </Grid>
                                            ))
                                        )}

                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item
                        xs={12}
                        sm={12}
                        md={7}>
                        <Paper className={classes.paper} elevation={15} style={{ background: "rgb(233, 236, 239)" }}>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ margin: "20px 10px", }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item >
                                        <h2>Policies</h2>
                                    </Grid>
                                    <Grid item
                                        style={{ marginLeft: "auto" }}
                                    >
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                padding: "10px 20px",
                                                marginLeft: "10px"
                                            }}
                                            disabled={!addEnable}
                                            onClick={handleOpenNew}
                                        >
                                            Add New
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {loading ? <Loader /> :
                                    (
                                        policyList &&
                                        policyList.length > 0 &&
                                        policyList.map((policy: any) => (
                                            (policy?.is_active === true &&

                                                <Grid item xs={12}
                                                    style={{
                                                        margin: "6px 10px",
                                                        background: "white",
                                                        padding: "30px 30px",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        borderRadius: "10px"
                                                    }}>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                        <Grid item>
                                                            <h4>{policy.name}</h4>
                                                            <p> 👉{policy.description}</p>
                                                        </Grid>
                                                        <Grid item style={{ marginLeft: "auto" }}>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "bold",
                                                                    padding: "10px 20px",
                                                                    marginLeft: "10px"
                                                                }}
                                                                onClick={() => {
                                                                    setFileLink(policy.policy_file);
                                                                    setPolicyName(policy.name)
                                                                    handleViewPdf();
                                                                }}
                                                            >
                                                                VIEW
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "bold",
                                                                    padding: "10px 20px",
                                                                    marginLeft: "10px"
                                                                }}
                                                                onClick={() => {
                                                                    handleButtonClick(policy)

                                                                }}
                                                            >
                                                                EDIT
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "bold",
                                                                    padding: "10px 20px",
                                                                    marginLeft: "10px"
                                                                }}
                                                                onClick={() => {
                                                                    setDeletePolicy(true)
                                                                    setSelectedPolicy(policy)
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Created At :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.created_at.slice(0, 10)}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Updated At :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.updated_at.slice(0, 10)}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Acknowledged By Employee :</strong></p></Grid>
                                                        <Grid item ><p style={{ margin: "5px 0", fontSize: "1rem" }}>{policy.is_read === true ? "YES" : "NO"}</p></Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center" }}>
                                                        <Grid item ><p style={{ margin: "5px 0" }}><strong style={{ fontWeight: "500", fontSize: "1rem" }}>Department :</strong></p></Grid>
                                                        <Grid item xs={9}>
                                                            <p style={{ margin: "5px 0", fontSize: "1rem" }}>
                                                                {policy?.employee_department?.length > 0 && policy?.employee_department.includes(64)
                                                                ? "ALL"
                                                                : <DepartmentList departments={policy?.employee_department?.length > 0 ? policy?.employee_department : []} />
                                                                }
                                                            </p>
                                                            </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        ))

                                    )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                {openNewModal && (
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openNewModal}
                        onClose={handleCloseNewModal}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openNewModal} >
                            <Box sx={{ ...style, maxWidth: "75%", borderRadius: "10px" }}>
                                <div style={{ padding: "20px", width: "100%", border: "1px solid", borderRadius: "10px" }}>
                                    <h4 style={{ margin: "20px", fontSize: "2rem" }}>{isEdit ? "Edit Policy Here" : "Add Policy Here"}</h4>
                                    <Grid container spacing={3}>
                                        <Grid item
                                            xs={12}
                                            sm={12}
                                            md={12}>
                                            <p>Policy Title *</p>
                                            <TextField
                                                className="input"
                                                variant="outlined"
                                                type="text"
                                                required
                                                id="policyTitle"
                                                placeholder='Enter Policy Title'
                                                size="small"
                                                style={{ width: "100%", background: "white" }}
                                                value={policyTitle}
                                                onChange={handlePolicyTitleChange}
                                            />
                                            <p
                                                style={{
                                                    color: isTitleLimitReached ? 'green' : '#d32f2f',
                                                    fontSize: '12px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {remainingTitleChars} characters left
                                            </p>
                                        </Grid>
                                        <Grid item
                                            xs={12}
                                            sm={12}
                                            md={12}>
                                            <p>Policy Description *</p>
                                            <TextField
                                                className="input"
                                                variant="outlined"
                                                id="policyTitle"
                                                placeholder='Enter Policy Description'
                                                size="small"
                                                required
                                                multiline
                                                rows={4}
                                                style={{ width: "100%", background: "white" }}
                                                value={policyDesc}
                                                onChange={handlePolicyDescChange}
                                            />
                                            <p
                                                style={{
                                                    color: isDescLimitReached ? 'green' : '#d32f2f',
                                                    fontSize: '12px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {remainingDescChars} characters left
                                            </p>
                                        </Grid>

                                        <Grid item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                        >
                                            <p>Policy Department</p>
                                            <Autocomplete
                                                id="customerPackage"
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let packs: any = [];
                                                        newValue.map((val: any) => {
                                                            let obj = JSON.parse(
                                                                JSON.stringify(val, null, " ")
                                                            );
                                                            packs.push(obj);
                                                        });
                                                        setSelectDepartment(packs);
                                                        handleDepartment(packs)
                                                    }
                                                }}
                                                fullWidth
                                                multiple
                                                disableClearable
                                                options={departmentNames || []}
                                                limitTags={0}
                                                freeSolo
                                                disableCloseOnSelect
                                                defaultValue={isEdit ? departmentNames.filter((dept: any) => departmentId?.includes(dept.id)) : []}
                                                aria-required
                                                onInputChange={(event, newInputValue) => {
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        if (newInputValue) {
                                                            getEmployeeDepartment(`name=${newInputValue}`);
                                                        }
                                                    }, 700);
                                                }}
                                                getOptionLabel={(option: any) => {
                                                    return (
                                                        String(option.name)
                                                    );
                                                }}
                                                getOptionDisabled={(option: any) => {
                                                    return departmentId.includes(option?.id)
                                                }}
                                                renderOption={(option, { selected }) => (
                                                    <li
                                                        key={option?.id}
                                                        style={{ width: "100%", cursor: "pointer" }}>
                                                        <Checkbox
                                                            icon={
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon fontSize="small" />
                                                            }
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}

                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        placeholder="Department Name"
                                                        variant="outlined"
                                                        required
                                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            onKeyDown: (e) => {
                                                                if (e.key === "Enter") {
                                                                    e.stopPropagation();
                                                                }
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>


                                        <Grid item
                                            xs={12}
                                            sm={12}
                                            md={12}>
                                            <p>Select File</p>
                                            <input
                                                type="file"
                                                id="policyFile"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="policyFile">
                                                <Button
                                                    variant="outlined"
                                                    component="span"
                                                    style={{
                                                        width: '100%',
                                                        padding: '10px 20px',
                                                        borderRadius: '10px',
                                                        border: '2px solid',
                                                    }}
                                                >
                                                    <Typography variant="body1">Upload File</Typography>
                                                </Button>
                                            </label>
                                            {policyFile && <p> <b>{policyFile.name}</b></p>}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div
                                                className="checkList"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "fit-content",
                                                }}
                                            >
                                                <FormControl component="fieldset">
                                                    <p
                                                        style={{
                                                            fontSize: "large",
                                                            fontWeight: "bolder",
                                                            textAlign: "left",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        Is Acknowledged By Employee:
                                                    </p>
                                                    <div style={{ display: "flex", paddingLeft: "20px" }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={"yes"}
                                                                    checked={isAcknowledged === true}
                                                                    onChange={(e) => { setIsAcknowledged(true) }}
                                                                />
                                                            }
                                                            label="Yes"
                                                            style={{ marginRight: "20px" }}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={"no"}
                                                                    checked={isAcknowledged === false}
                                                                    onChange={(e) => {
                                                                        setIsAcknowledged(false)
                                                                    }}
                                                                />
                                                            }
                                                            label="No"
                                                        />
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid container>
                                            <Grid item style={{ marginLeft: "auto" }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        padding: "10px 20px",

                                                    }}
                                                    size="large"
                                                    disabled={policyTitle == "" || policyDesc === "" || selectDepartment.length === 0 || (!isEdit && policyFile === "") || policyLoading}
                                                    onClick={isEdit ? handleUpdatePolicy : handlePolicyCreate}
                                                >
                                                    Save
                                                </Button>

                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        padding: "10px 20px",
                                                        marginLeft: "10px"
                                                    }}
                                                    size="large"
                                                    onClick={resetAddPolicy}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>
                )}
                {openPdf === true &&

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openPdf}
                        onClose={handlePdfModelClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openPdf}>
                            <Box sx={{ ...style, borderRadius: "10px", }}>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <h4 style={{ margin: "1rem 3rem", textAlign: "center" }}>{policyName}</h4>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {
                                            (fileLink?.includes(".jpg") || fileLink?.includes(".jpeg") || fileLink?.includes(".png")) ?
                                                <div style={{
                                                    display: "flex", justifyContent: "center", maxWidth: "100%",
                                                    maxHeight: "700px", overflowY: "scroll", marginTop: "1rem"
                                                }}>
                                                    <img
                                                        src={`${fileLink}`}
                                                        style={{ width: "95vh", height: "100%" }}
                                                        title="IMAGE Document"
                                                    />
                                                </div>
                                                : (
                                                    (fileLink?.includes(".doc") || fileLink?.includes(".docx")) ?
                                                        <div id="mera-doc-viewer"
                                                            style={{ maxWidth: "100%", maxHeight: "800px", marginTop: "1rem" }}>
                                                            <DocViewer
                                                                style={{ height: "780px" }}
                                                                pluginRenderers={DocViewerRenderers}
                                                                documents={[{ uri: fileLink, fileType: "doc" }]}
                                                            />
                                                        </div>
                                                        :
                                                        <iframe
                                                            src={`${fileLink}`}
                                                            style={{ width: "100%", height: "800px" }}
                                                            title="PDF Document"
                                                        ></iframe>
                                                )
                                        }
                                    </Grid>

                                </Grid>
                            </Box>
                        </Fade>
                    </Modal>
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={deletePolicy}
                    onClose={setDeletePolicy}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={deletePolicy}>
                        <Box sx={{ ...style, borderRadius: "10px", width: "450px" }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h5" component="h6" style={{ textAlign: "center", fontWeight: "600" }} >
                                        Are you sure you want to Delete this Policy?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        size="large"
                                        disabled={policyLoading}
                                        onClick={() => {
                                            handleDeletePolicy(selectedPolicy);
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        size="large"
                                        onClick={() => { setDeletePolicy(false); setSelectedPolicy({}); }}
                                    >
                                        NO
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={deleteCategory}
                    onClose={setDeleteCategoty}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={deleteCategory}>
                        <Box sx={{ ...style, borderRadius: "10px", width: "450px" }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h5" component="h6" style={{ textAlign: "center", fontWeight: "600" }} >
                                        Are you sure you want to Delete this Category?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        disabled={createCategoryLoading}
                                        size="large"
                                        onClick={() => {
                                            handleDeletePolicyCategory(selectedPolicy)
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                            marginLeft: "10px"
                                        }}
                                        fullWidth
                                        size="large"
                                        onClick={() => { setDeleteCategoty(false); setSelectedPolicy({}) }}
                                    >
                                        NO
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={editCategory}
                    onClose={setEditCategoty}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={editCategory}>
                        <Box sx={{ ...style, borderRadius: "10px", width: "450px" }}>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h5" component="h6" style={{ textAlign: "center", fontWeight: "600" }} >
                                        Edit Policy Category
                                    </Typography>
                                </Grid>
                                <Grid container spacing={3} style={{ paddingTop: "20px" }}>
                                    <Grid item
                                        xs={12}
                                        sm={12}
                                        md={12}>
                                        <p>Policy Category</p>
                                        <TextField
                                            className="input"
                                            variant="outlined"
                                            type="text"
                                            id="category"
                                            required
                                            placeholder='Enter Policy Category'
                                            size="small"
                                            style={{ width: "100%", background: "white" }}
                                            value={editCategoryName}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                if (value.trim() !== "") {
                                                    setEditCategoryName(value);
                                                } else {
                                                    setEditCategoryName("");
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid container spacing={2} style={{ padding: "12px", display: "flex", justifyContent: "end" }}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                disabled={createCategoryLoading || editCategoryName === "" || (!policyTitle && selectDepartment.length < 0)}
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                                size="large"
                                                onClick={handleEditPolicyCategory}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                                size="large"
                                                onClick={handleEditCancleCategory}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.PayrollReducer.loading,
    departmentNames: state.loginReducer.departmentNames,
    policyCreation: state.loginReducer.policyCreation,
    get_Policy_Category: state.loginReducer.get_Policy_Category,
    create_policy_category: state.loginReducer.create_policy_category,
    get_policy: state.loginReducer.get_policy,
    policyLoading: state.loginReducer.policyLoading,
    createCategoryLoading: state.loginReducer.createCategoryLoading
})

export default connect(mapStateToProps, {
    updatePolicyCategory,
    getEmployeeDepartment,
    postPolicyCreation,
    getPolicyCategory,
    createPolicyCategory,
    getPolicy,
    updatePolicy,
    updateCategory,
})(PolicyCenter);

