import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";
import { getNotificationLogs } from "../../actions/PayrollActions";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
}));

type ModalProps = {
  openCommentModal: boolean;
  setOpenCommentModal: Function;
  empId: string;
  getNotificationLogs: Function;
  notificationLogs: any;
};

const CommentsModal: React.FC<ModalProps> = ({
  openCommentModal,
  setOpenCommentModal,
  getNotificationLogs,
  notificationLogs,
  empId,

}) => {
  const classes = useStyles();
  const [maxHeight, setMaxHeight] = React.useState<any>(300);

  const handleClose = () => {
    setOpenCommentModal(false);
  };

  useEffect(() => {
    if (empId && openCommentModal) {
      getNotificationLogs(`?employee_id=${empId}`)
    }
  }, [empId, openCommentModal])


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCommentModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCommentModal}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Comment History
              {Number(maxHeight) === Number(300) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomOutMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(600)
                        ? setMaxHeight(Number(600))
                        : setMaxHeight(Number(600))
                    }
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomInMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(300)
                        ? setMaxHeight(Number(300))
                        : setMaxHeight(Number(300))
                    }
                  />
                </Stack>
              )}
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: `${maxHeight}px`,
              }}
            >
              <div
                className="comment-history"
                style={{ height: `${maxHeight}px` }}
              >

                {notificationLogs && notificationLogs?.results && notificationLogs.count > 0 && notificationLogs?.results?.map((comment: any) => (
                  <p>
                    {comment.created_at.substring(0, 10)} :{" "}
                    {comment.created_at.substring(11, 19)} :{" "}
                    {comment.by_user} : {comment.comment}.{" "}
                  </p>
                ))
                }

              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  notificationLogs: state.PayrollReducer.notificationLogs,
});

export default connect(mapStateToProps, {
  getNotificationLogs,
})(CommentsModal);
