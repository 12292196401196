import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/storage"; // Import Firebase Storage for v8

const firebaseConfig = {
  apiKey: "AIzaSyDFZm-xt49cmxl4nu51i01dfe6B7NObI8o",
  authDomain: "redcliffelabs-backend.firebaseapp.com",
  projectId: "redcliffelabs-backend",
  storageBucket: "redcliffelabs-backend.appspot.com",
  messagingSenderId: "633054019343",
  appId: "1:633054019343:web:d0c1ffc1f36b25cb15487a"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // Use the existing app instance
}

// Initialize Firebase Storage and Messaging
const storage = firebase.storage(); // Using Firebase v8's storage API
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

// Get the messaging token
export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

// Listen for incoming messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

// Export Storage and other Firebase storage functions
export { storage };
