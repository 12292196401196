import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { TextField, Grid, Fade, Card } from "@material-ui/core";
import {
    getEmployeeListForReglarization,
    getEmployeeDepartment,
    getLocationList,
} from "../../../actions/loginActions";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from "@material-ui/core/Backdrop";
import BackupIcon from "@mui/icons-material/Backup";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@material-ui/icons/Close";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch } from "react-redux";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../Loader2";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateEmployeeFilter } from "../../../helper/generate";
import { useHistory } from "react-router";
import CustomDrawer from "../../../common/CustomDrawer";
import Button from "@mui/material/Button";
import {
    getEmployeFilterList,
    updateRegularizationCount,
} from "../../actions/PayrollActions";
import {
    postBulkRegularizationLimitUploader,
} from "../../../actions/loginActions";
import "./index.css";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableFooter,
    TablePagination,
} from "@material-ui/core";
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 2,
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        table: {
            minWidth: 700,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "1rem",
            padding: "0.5rem 2rem 2rem 2rem",
            width: "100%",
            borderRadius: "15px",
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
            minHeight: "64px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        hoveItem: {
            cursor: "pointer",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        input: {
            padding: "1px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        centerIt: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
        },
        paper2: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid green",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "1200px",
            overflow: 'scroll'
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: "3px solid green",
            borderRadius: "5px",
            boxShadow: theme.shadows[5],
            padding: "50px",
            width: "600px",
            textAlign: "center",
        },
        blinker: {
            animation: `$blinker 1.5s linear infinite`,
        },
        "@keyframes blinker": {
            "50%": {
                opacity: 0.5,
            },
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgb(14, 63, 108)",
            color: theme.palette.common.white,
            fontSize: "14px",
            fontWeight: "bolder",
        },
        body: {
            fontSize: 14,
            fontWeight: "bolder",
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loadingForRegularization: boolean;
    employeeListForRegularization: any;
    getEmployeeListForReglarization: any;
    getEmployeeDepartment: Function;
    departmentNames: any;
    locationList: any;
    getLocationList: any;
    employeesFilterList: any;
    getEmployeFilterList: any;
    updateRegularizationCount: any;
    postBulkRegularizationLimitUploader: any;
}
const Forms: React.FC<Props> = ({
    loadingForRegularization,
    employeeListForRegularization,
    getEmployeeListForReglarization,
    getEmployeeDepartment,
    departmentNames,
    locationList,
    getLocationList,
    employeesFilterList,
    getEmployeFilterList,
    updateRegularizationCount,
    postBulkRegularizationLimitUploader,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const history = useHistory();
    const [employeeUUid, setEmployeeUuid] = useState("");
    const [openAssignLimitModel, setOpenAssignLimitModel] = useState<boolean>(false);
    const [employeeDept, setEmployeeDept] = useState<any>("");
    const [location, setLocation] = useState<any>();
    const [selectedEmpIds, setSelectedEmpId] = useState<any>([]);
    const [limit, setLimit] = useState<any>();
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [FilterBtn, setFilterBtn] = useState<any>(false);
    const [emportEmployeeData, setEmportEmployeeData] = useState<any>(false);
    const [buttonState, setButtonState] = useState<boolean>(false);
    const [fileTypeErrorLeave, setFileTypeLeaveError] = useState<any>("");
    const [leaveFileName, setLeaveFileName] = React.useState<string>("");
    const [leaveFile, setLeaveFile] = React.useState<any>([]);
    const [OnDutylimit, setOnDutylimit] = React.useState<string>("");



    const handleLeaveFileChange = (e: React.ChangeEvent<{ files: any }>) => {
        setLeaveFile(e.target.files[0]);
        if (e.target.files[0]?.type === "text/csv") {
            setLeaveFileName(e.target.files[0].name);
            setFileTypeLeaveError("");
        } else {
            setFileTypeLeaveError("Please select csv file");
        }
    };

    useEffect(() => {
        // getEmployeeListForReglarization("get_all=true&for_reglarization=true&active_employee=true");
        getEmployeeDepartment();
        getLocationList();
        getEmployeFilterList("get_all=true");
    }, []);

    const handleFilter = () => {
        setFilterBtn(true);
        const body: any = {
            employee_id: employeeUUid,
            department: employeeDept !== 240 ? employeeDept : "",
            location: location
        };

        const url = generateEmployeeFilter(body).substring(2);
        getEmployeeListForReglarization(`get_all=true&for_reglarization=true&active_employee=true&${url}`);
        setPage(0);
    };

    const handleSubmit = async (e: any) => {
        const data = {
            employee_id: selectedEmpIds,
            count: limit,
            start_date: startDate,
            end_date: endDate,
            od_count: OnDutylimit
        };
        await updateRegularizationCount(data);
        setOpenAssignLimitModel(false);
        history.push("/dashboard/payrollmanager/regulization_limit");
    };

    const checkedLead = (event: any, EmployeeId: number, data: any) => {
        !event.target.checked
            ? setSelectedEmpId(selectedEmpIds.filter((lead: any) => lead !== EmployeeId))
            : setSelectedEmpId((prev: any) => [...prev, EmployeeId]);
        setLimit(data.regularization_count)
        setEndDate(data.end_date)
        setOnDutylimit(data.od_count)
        setStartDate(data.start_date)
    };

    const handleImportEmployeeData = () => {
        var rows = [];
        let row = employeeListForRegularization;
        rows.push([
            "Employee Name	",
            "Employee ID",
            "Work Location",
            "Department",
            "Regularization Count",
            "On Duty Count",
            "Start Date",
            "End Date",
            "Updated At",
            "Created By",
            "Mode",
        ]);

        for (var i = 0; i < row.length; i++) {
            rows.push([
                row[i]?.fullname,
                row[i]?.employee_id,
                row[i]?.location,
                row[i]?.department,
                row[i]?.regularization_count,
                row[i]?.od_count,
                row[i]?.start_date,
                row[i]?.end_date,
                row[i]?.updated_at,
                row[i]?.updated_by,
                row[i]?.mode_of_update,
            ]);
        }
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach(function (rowArray) {
            row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "EmployeeRegularizationList.csv");
        document.body.appendChild(link);
        link.click();
    };
    const handleEmportEmployeeData = () => {
        setEmportEmployeeData(true)
    };

    const handleClose = () => {

        setEmportEmployeeData(false);
    };



    const submitLeaveFile = async () => {
        const formData = new FormData();
        formData.append("file", leaveFile);
        await postBulkRegularizationLimitUploader(formData);
        setButtonState(true);
        setTimeout(() => {
            setButtonState(false);
        }, 1000 * 60 * 10);
        setLeaveFileName("");
        // setLeaveFile("");
        // setOpen(false);
    };



    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h4
                style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(28, 78, 128)",
                    color: "#fff",
                    textAlign: "center",
                    padding: "10px",
                    borderRadius: "15px",
                }}
            >
                Regularization Limit
            </h4>

            <div>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={3} md={4}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeDept(obj.id);
                                } else {
                                    setEmployeeDept("");
                                }
                            }}
                            id="department-name"
                            options={departmentNames || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeeDepartment(`name=${newInputValue}`);
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option.name;
                            }}
                            renderOption={(option: any) => {
                                return option?.name;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Department Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={5}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    setEmployeeUuid(obj.employee_id);
                                } else {
                                    setEmployeeUuid("");
                                }
                            }}
                            id="checkboxes-tags-demo"
                            options={employeesFilterList?.results || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    newInputValue &&
                                        getEmployeFilterList(
                                            `get_all=true&employee_id=${newInputValue}`
                                        );
                                }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                                return option?.employee_id + " " + option.name;
                            }}
                            renderOption={(option: any) => {
                                return (
                                    <p>
                                        <b style={{ color: "blue" }}>Employee Name:</b>
                                        <strong>
                                            {option?.name.toString()}
                                            <br />
                                        </strong>
                                        <b style={{ color: "red" }}>Employee Id:</b>
                                        {option?.employee_id?.toUpperCase()}
                                    </p>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Search By Employee Code Or Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Autocomplete
                            onChange={(event, obj) => {
                                if (obj) {
                                    const loc = obj.location;
                                    setLocation(loc);
                                } else {
                                    setLocation("");
                                }
                            }}
                            id={"City"}
                            options={locationList || []}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getLocationList(`name=${newInputValue}`);
                                }, 1000);
                            }}
                            value={location ? { location: location } : null}
                            getOptionLabel={(option: any) => {
                                return option.location;
                            }}
                            renderOption={(option: any) => {
                                return option.location;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Location"
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleFilter}
                            startIcon={<SearchIcon />}
                            // disabled={loadingForRegularization||(employeeDept===""||location===""||employeeUUid==="")}
                            style={{
                                backgroundColor: "#1976d2",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#1565c0";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#1976d2";
                                e.currentTarget.style.boxShadow = "none";
                            }}

                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            startIcon={<RotateLeftIcon />}
                            style={{
                                backgroundColor: "#9c27b0",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#7b1fa2";
                                e.currentTarget.style.boxShadow =
                                    "0px 2px 4px rgba(0, 0, 0, 0.1)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#9c27b0";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                            onClick={() =>
                                history.push("/dashboard/payrollmanager/regulization_limit")
                            }
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <LoadingButton
                            variant="contained"
                            fullWidth
                            style={{
                                backgroundColor:
                                    !FilterBtn || loadingForRegularization ? "gray" : "#009688",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                if (!loadingForRegularization) {
                                    e.currentTarget.style.backgroundColor = "#7b1fa2";
                                    e.currentTarget.style.boxShadow =
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }
                            }}
                            onMouseOut={(e) => {
                                if (!loadingForRegularization) {
                                    e.currentTarget.style.backgroundColor =
                                        !FilterBtn || loadingForRegularization ? "gray" : "#9c27b0";
                                    e.currentTarget.style.boxShadow = "none";
                                }
                            }}
                            loading={loadingForRegularization}
                            loadingPosition="start"
                            disabled={!FilterBtn || loadingForRegularization}
                            startIcon={loadingForRegularization ? null : <ArrowCircleDownIcon />}
                            onClick={handleImportEmployeeData}
                        >
                            Import  Employee Limit
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <LoadingButton
                            variant="contained"
                            fullWidth
                            style={{
                                backgroundColor: "#009688",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                            }}
                            onMouseOver={(e) => {
                                if (!loadingForRegularization) {
                                    e.currentTarget.style.backgroundColor = "#7b1fa2";
                                    e.currentTarget.style.boxShadow =
                                        "0px 2px 4px rgba(0, 0, 0, 0.1)";
                                }
                            }}
                            onMouseOut={(e) => {
                                if (!loadingForRegularization) {
                                    e.currentTarget.style.backgroundColor =
                                        "#9c27b0";
                                    e.currentTarget.style.boxShadow = "none";
                                }
                            }}
                            loadingPosition="start"
                            startIcon={<FileUploadIcon />}
                            onClick={handleEmportEmployeeData}
                        >
                            Export  Employee Limit
                        </LoadingButton>
                    </Grid>
                </Grid>

                <div
                    style={{
                        padding: "10px",
                        marginTop: "2.5rem",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        backgroundColor: "#f8d7da",
                        display: "inline-block",
                        fontSize: "16px",
                        color: "#721c24",
                    }}
                >
                    <span>
                        👉 Please select the checkbox to enable the Update Regularization
                        Button.
                    </span>
                </div>

                {selectedEmpIds.length > 0 && (
                    <Grid item xs={12} sm={4} md={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={selectedEmpIds?.length === 0 ? true : false}
                            style={{
                                backgroundColor:
                                    selectedEmpIds?.length === 0 ? "gray" : "#009688",
                                color: "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "none",
                                transition: "background-color 0.3s, box-shadow 0.3s",
                                boxShadow: "none",
                                marginTop: "10px",
                            }}
                            onClick={() => {
                                setOpenAssignLimitModel(true);
                            }}
                        >
                            Update Regularization Limit
                        </Button>
                    </Grid>
                )}
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    <input
                                        type="checkbox"
                                        className="input"
                                        name="selectall"
                                        id="selectall"
                                        style={{ height: "16px", width: "16px", cursor: "pointer" }}
                                        onChange={(e) => {
                                            e.target.checked
                                                ? setSelectedEmpId(
                                                    employeeListForRegularization &&
                                                    employeeListForRegularization?.map(
                                                        (data: any, index: number) => {
                                                            return data?.employee_id;
                                                        }
                                                    )
                                                )
                                                : setSelectedEmpId([]);
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">Employee Name</StyledTableCell>
                                <StyledTableCell align="center">Employee ID</StyledTableCell>
                                <StyledTableCell align="center">Work Location</StyledTableCell>
                                <StyledTableCell align="center">Department</StyledTableCell>
                                <StyledTableCell align="center"> Work from Home (WFH)</StyledTableCell>
                                <StyledTableCell align="center"> Regularization (WFO/OD)</StyledTableCell>
                                <StyledTableCell align="center"> Start Date</StyledTableCell>
                                <StyledTableCell align="center"> End Date</StyledTableCell>
                                <StyledTableCell align="center"> Updated At</StyledTableCell>
                                <StyledTableCell align="center"> Created By</StyledTableCell>
                                <StyledTableCell align="center"> Mode </StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        {loadingForRegularization ? (
                            <Loader />
                        ) : (
                            <TableBody>
                                {employeeListForRegularization &&
                                    employeeListForRegularization?.length > 0 ?
                                    employeeListForRegularization?.map((data: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    <input
                                                        type="checkbox"
                                                        name="selectall"
                                                        id="selectall"
                                                        style={{
                                                            height: "16px",
                                                            width: "16px",
                                                            cursor: "pointer",
                                                        }}
                                                        checked={selectedEmpIds.find(
                                                            (qa: any) => qa === data.employee_id
                                                        )}
                                                        onChange={(e) => checkedLead(e, data.employee_id, data)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.fullname || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.employee_id || "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.location !== null ? data.location : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.department || "NA"}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {data?.regularization_count}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.od_count}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.start_date}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.end_date}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">

                                                    {data?.updated_at
                                                        ? new Date(data?.updated_at).toLocaleString()
                                                        : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.updated_by}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.mode_of_update}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        );
                                    }) : (
                                        <StyledTableRow
                                            key={2}
                                        >
                                            <StyledTableCell colSpan={12}>
                                                <h4 style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 0 }}>
                                                    {FilterBtn ? "No Data Found" : "No filter is currently selected. Please select a filter and try again"}
                                                </h4>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                            </TableBody>
                        )}

                    </Table>
                </TableContainer>
                <CustomDrawer
                    drawerTitle={"Assign Regularization Limit"}
                    open={openAssignLimitModel}
                    toggleDrawer={() => setOpenAssignLimitModel(false)}
                >
                    <div>
                        <Card className="p_40">
                            <form style={{ marginTop: "0px" }} onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    spacing={4}
                                    direction="row"
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            name="limit"
                                            label="Work from Home (WFH)"
                                            className="input"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            value={limit}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (
                                                    value === "" ||
                                                    (Number(value) >= 0 && /^[0-9]*$/.test(value) && Number(value) <= 31)
                                                ) {
                                                    setLimit(value);
                                                }
                                            }}
                                            inputProps={{ max: 31 }}
                                        />
                                        <small>
                                            max limit 31
                                        </small>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            name="limit"
                                            label="Regularization (WFO/OD)"
                                            className="input"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            value={OnDutylimit}
                                           
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (
                                                    value === "" ||
                                                    (Number(value) >= 0 && /^[0-9]*$/.test(value) && Number(value) <= 31)
                                                ) {
                                                    setOnDutylimit(value);
                                                }
                                            }}
                                            inputProps={{ max: 31 }}
                                        />
                                        <small>
                                            max limit 31
                                        </small>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            className="input"
                                            name="start_date"
                                            label="Start Date"
                                            id="start-date"
                                            type="date"
                                            value={startDate}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            style={{ width: "100%" }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            className="input"
                                            name="end_date"
                                            id="end-date"
                                            type="date"
                                            label="End Date"
                                            value={endDate}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                min: startDate || "",
                                            }}
                                            disabled={!startDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={handleSubmit}
                                            style={{ marginLeft: "110px" }}
                                            disabled={!limit || !startDate || !endDate}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </div>
                </CustomDrawer>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={emportEmployeeData}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={emportEmployeeData}>
                    <div className={classes.paper2}>

                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                    padding: "0.5rem 1rem",
                                    fontSize: "1.2rem",
                                    fontWeight: "600",
                                    color: "#2c3e50",
                                    backgroundColor: "#f3f4f6",
                                    borderRadius: "6px",
                                    border: "1px solid #e1e4e8",
                                    textAlign: "center",
                                    lineHeight: "1.4",
                                    letterSpacing: "0.5px",
                                }}
                                id="transition-modal-title"
                            >
                                Bulk Uploader Employee Regularization Limit
                            </h4>

                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>

                            <Grid item xs={8}>
                                <div
                                    style={{
                                        position: "relative",
                                        left: "9%",
                                    }}
                                >
                                    <div
                                        className="uplaod-inner"
                                        style={{
                                            height: "100%",
                                            width: "800px",
                                            border: "2px dashed grey",
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            padding: "30px 10px",
                                            marginLeft: "64px"
                                        }}
                                    >
                                        <div className="files-first" style={{}}>
                                            <BackupIcon
                                                style={{
                                                    margin: "0 5px",
                                                    width: "100px",
                                                    color: "blue",
                                                }}
                                            />
                                            <h5>Upload File</h5>
                                        </div>
                                        <Grid item xs={12} sm={6} md={8}>
                                            <input
                                                className="button-17"
                                                type="text/csv"
                                                style={{
                                                    height: "50px",
                                                    padding: "6px 10px",
                                                    marginLeft: "255px",
                                                }}
                                                accept=".csv"
                                                placeholder="File Name"
                                                value={leaveFileName}
                                                disabled
                                            />
                                            <p style={{ color: "red", marginLeft: "255px" }}>
                                                {fileTypeErrorLeave}
                                            </p>
                                        </Grid>

                                        <div className="upload-btn">
                                            <Button
                                                className="button-17"
                                                style={{ height: "56px" }}
                                                component="label"
                                                id="upload-btn"
                                            >
                                                Select CSV File
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    hidden
                                                    onChange={handleLeaveFileChange}
                                                />
                                            </Button>
                                        </div>

                                        <div className="file-det">
                                            <span style={{ marginRight: "10px" }}>
                                                Supported file type:{" "}
                                            </span>
                                            <strong>Note : Only CSV File Can Be upload</strong>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            {loadingForRegularization ? (
                                                <Loader />
                                            ) : (
                                                <>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <Button
                                                            className="button-17"
                                                            startIcon={<CloudUploadIcon />}
                                                            component="label"
                                                            style={{
                                                                height: "56px",
                                                                width: "100%",
                                                                marginLeft: "-50px",
                                                                marginTop: "10px",
                                                            }}
                                                            onClick={submitLeaveFile}
                                                            disabled={
                                                                buttonState ||
                                                                leaveFileName === "" ||
                                                                fileTypeErrorLeave !== "" ||
                                                                loadingForRegularization
                                                            }
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <a
                                                            href="https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/9749/35e6212a-ed59-4904-93e4-15cee06e5622.csv"
                                                            target=""
                                                        >
                                                            <Button
                                                                className="button-17"
                                                                fullWidth
                                                                style={{
                                                                    height: "56px",
                                                                    width: "100",
                                                                    marginTop: "10px",
                                                                }}
                                                                startIcon={<CloudDownloadIcon />}
                                                                component="label"
                                                            >
                                                                Download Sample CSV File
                                                            </Button>
                                                        </a>
                                                    </Grid>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {loadingForRegularization && (
                                        <div
                                            className={classes.blinker}
                                            style={{
                                                color: "#007BFF",
                                                fontSize: "1.5em",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                margin: "20px auto",
                                                marginLeft: "20px",
                                                padding: "10px",
                                                border: "2px solid #007BFF",
                                                borderRadius: "10px",
                                                backgroundColor: "#f0f8ff",
                                                boxShadow: "0 4px 8px rgba(0, 123, 255, 0.2)",
                                                animation: "blinker 1.5s linear infinite",
                                                maxWidth: "800px",
                                                width: "100%",
                                            }}
                                        >
                                            Please wait, we are uploading Leave file Data. It will take a
                                            little bit of time...
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>


                    </div>
                </Fade>
            </Modal>
        </main >
    );
};
const mapStateToProps = (state: any) => ({
    employeeListForRegularization: state.loginReducer.employeeListForRegularization,
    departmentNames: state.loginReducer.departmentNames,
    locationList: state.loginReducer.locationList,
    employeesFilterList: state.PayrollReducer.employeesFilterList,
    loadingForRegularization: state.loginReducer.loadingForRegularization,
});
export default connect(mapStateToProps, {
    getEmployeeListForReglarization,
    getEmployeeDepartment,
    getLocationList,
    getEmployeFilterList,
    updateRegularizationCount,
    postBulkRegularizationLimitUploader
})(Forms);
